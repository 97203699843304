import "./template-0.scss";
import { useEffect, useState } from "react";
import PROFILE_MOCK from "../../../../assets/images/resume-builder/profile_mock.png";
import { InputResumeBuilderModel } from "../../../../model/resume-builder/input-resume-builder.model";
import { getOneResumeModel } from "../../../../model/resume-builder/get-one-resume.model";


const Template_0_Component = ({ containerRef, templ, resultResume }: any) => {
    const [result, setResult] = useState<getOneResumeModel>();
    const [inputTemplateModel, setInputTemplateModel] = useState<InputResumeBuilderModel>(templ);
    const [base64Image, setBase64Image] = useState('');
    useEffect(() => {
        if (templ) {
            setInputTemplateModel(templ);
        }

    }, [templ])

    useEffect(() => {
        if(resultResume){
            setResult(resultResume);
        }
    },[resultResume])

    async function convertImageToBase64(url) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Error converting image to Base64:", error);
            return null;
        }
    }

    useEffect(() => {
        if (result && result.is_profile_img) {
            console.log(result);
            convertImageToBase64(result.profile_img)
                .then((base64String) => {
                    // Ensure that base64String is a string before calling setBase64Image
                    if (typeof base64String === 'string') {
                        setBase64Image(base64String);
                    }
                })
                .catch(error => console.error("Error in conversion or setting the image:", error));
        }

    }, [result]);
    return (

        <>
            {
                inputTemplateModel &&
                <div className="template-0-control">
                    <div className="template-0-form-control" ref={containerRef}>


                        <div className="template-0-left-control" style={{
                            backgroundColor:
                                inputTemplateModel.color ?
                                    inputTemplateModel.color.value : "#fff"
                        }}>

                            <div className="template-0-profile-control">
                                {base64Image ?

                                    <img className="template-0-profile" src={base64Image}></img>
                                    :  <img className="template-0-profile" src={PROFILE_MOCK}></img>
                                   
                                }
                            </div>
                            {
                                inputTemplateModel.contact && inputTemplateModel.contact.value &&
                                <div className="template-0-item-control">
                                    <div className="d-flex">
                                        <span className="template-0-title-left">Contact</span>
                                    </div>
                                    <div className="template-0-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content" dangerouslySetInnerHTML={{
                                            __html:
                                                inputTemplateModel.contact ?
                                                    inputTemplateModel.contact.value : ""
                                        }}></div>
                                    </div>

                                </div>
                            }
                            {
                                inputTemplateModel.expertise && inputTemplateModel.expertise.value &&
                                <div className="template-0-item-control">
                                    <div className="d-flex">
                                        <span className="template-0-title-left">Expertise</span>
                                    </div>
                                    <div className="template-0-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.expertise ?
                                                        inputTemplateModel.expertise.value : ""
                                            }}></div>
                                    </div>
                                </div>
                            }
                            {
                                inputTemplateModel.language && inputTemplateModel.language.value &&
                                <div className="template-0-item-control">
                                    <div className="d-flex">
                                        <span className="template-0-title-left">Language</span>
                                    </div>
                                    <div className="template-0-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.language ?
                                                        inputTemplateModel.language.value : ""
                                            }}></div>

                                    </div>

                                </div>
                            }
                            {
                                inputTemplateModel.interest && inputTemplateModel.interest.value &&
                                <div className="template-0-item-control">
                                    <div className="d-flex">
                                        <span className="template-0-title-left">Interest</span>
                                    </div>
                                    <div className="template-0-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.interest ?
                                                        inputTemplateModel.interest.value : ""
                                            }}></div>

                                    </div>

                                </div>

                            }

                        </div>
                        <div className="template-0-right-control">
                            <div className="template-0-name-control">
                                <h1 className="template-0-name"></h1>
                                <div className="template-0-content-control">
                                    <span className="template-0-name" dangerouslySetInnerHTML={{
                                        __html:
                                            inputTemplateModel.name ?
                                                inputTemplateModel.name.value : ""
                                    }}></span>
                                </div>
                                <div className="template-0-content-control">
                                    <span className="template-0-position" dangerouslySetInnerHTML={{
                                        __html:
                                            inputTemplateModel.position ?
                                                inputTemplateModel.position.value : ""
                                    }}></span>
                                </div>
                                <div className="template-0-content-control">
                                    <span className="template-0-cover-letter" dangerouslySetInnerHTML={{
                                        __html:
                                            inputTemplateModel.cover_letter ?
                                                inputTemplateModel.cover_letter.value : ""
                                    }}></span>
                                </div>
                                <div className="template-0-exp-control">
                                    <div className="d-flex">
                                        <span className="template-0-exp-title">Experience</span>

                                    </div>
                                    <div className="template-0-exp-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content-black" dangerouslySetInnerHTML={{
                                            __html:
                                                inputTemplateModel.experience ?
                                                    inputTemplateModel.experience.value : ""
                                        }}></div>

                                    </div>
                                </div>
                                <div className="template-0-exp-control">
                                    <div className="d-flex">
                                        <span className="template-0-exp-title">Education</span>

                                    </div>

                                    <div className="template-0-exp-divider"></div>
                                    <div className="template-0-content-control">
                                        <div className="template-0-content-black" dangerouslySetInnerHTML={{
                                            __html:
                                                inputTemplateModel.education ?
                                                    inputTemplateModel.education.value : ""
                                        }}></div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            }

        </>
    )
}

export default Template_0_Component;