import { useContext, useEffect, useState } from "react";
import { Experience, ProfileModel, Skill } from "../../../../model/profile.model";
import "./skills.scss";
import { Button, Form } from "react-bootstrap";
import "../education/education.scss";
import RamaService from "../../../../services/sso/rama.service";
import { SkillsSuggestModel } from "../../../../model/jobs/skills.model";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import JobsInstanceService from "../../../../services/jobs/jobs-instance.service";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import UtilityService from "../../../../utility/utility.service";


const SkillsComponent = ({ profile, setProfile, setCloseModal, isApplicationForm }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [isSaving, setIsSaving] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const [skillDup, setSkillDup] = useState(new Array());
    const [skills, setSkills] = useState<Skill[]>(new Array()); // Initialize with one empty skill field
    const [skillSuggest, setSkillSuggest] = useState<SkillsSuggestModel>();

    useEffect(() => {
        if (profileMod) {
            if (profileMod.skills) {
                const skillDes: Skill[] = new Array();
                profileMod.skills.map((item: Skill, index: number) => {


                    skillDes.push(item);
                })
                setSkills(skillDes);
            }
            getSkills();
        }
    }, [profileMod])

    const closeModal = () => {
        setCloseModal();
    }

    const handleSkillChange = (index, value) => {
        if (skills) {
            const newSkills = [...skills];
            newSkills[index].skillName = value;

            setSkills(newSkills);
        }

    };

    const addSkillField = () => {
        if (skills) {
            setSkills([...skills, { skillDetail: '', skillName: '' }]);
        }
    };
    const addSkillSuggestField = (value) => {
        if (skills) {
            setSkills([...skills, { skillDetail: value, skillName: value }]);
        }
    };
    const removeSkillField = (index) => {
        if (skills) {
            if (skills.length >= 0) {
                const newSkills = skills.filter((_, i) => i !== index);
                setSkills(newSkills);
            }
        }
    };

    const submit = () => {
        const profileModEdit = { ...profileMod } as ProfileModel
        setIsSaving(true);
        if (skills) {
            profileModEdit.skills = skills;
            if (isApplicationForm) {
                const checkSkill = profileModEdit.skills.filter(item => item.skillName.length > 0);
                if (checkSkill.length == profileModEdit.skills.length) {
                    if (findDuplicateSkillIndices(checkSkill).length == 0) {
                        setProfile(profileModEdit);
                        setProfileMod(profileModEdit);
                        closeModal();
                    } else {
                        setSkillDup(findDuplicateSkillIndices(checkSkill))
                    }
                }

            } else {
                const checkSkill = profileModEdit.skills.filter(item => item.skillName.length > 0);
                if (checkSkill.length == profileModEdit.skills.length) {
                 
                    if (findDuplicateSkillIndices(checkSkill).length == 0) {
                        RamaService.updateProfile(profileModEdit).then((res) => {

                            if(res.data){
                                UtilityService().clickSendEvent("save_skills", "submit");
                                ProfileInstantService.updateProfilePublic(profileModEdit);
                                setProfile(profileModEdit);
                                setProfileMod(profileModEdit);
                                closeModal();
                            }
                           
                        })
                    } else {
                        setSkillDup(findDuplicateSkillIndices(checkSkill))
                    }

                }
            }

        }

        // Do something with the skills array, e.g., submit to a server
    };

    function setDateToMidnight(isoString) {
        const date = new Date(isoString);
        date.setHours(0, 0, 0, 0);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}T00:00:00Z`;
    }
    function getDistinctSkills(skills) {
        const uniqueSkills: Skill[] = new Array();
        const map = new Map();

        for (const skill of skills) {
            if (!map.has(skill.skillName)) {
                map.set(skill.skillName, true); // set any value to Map
                uniqueSkills.push(skill);
            }
        }
        return uniqueSkills;
    }
    function findDuplicateSkillIndices(skills) {
        const skillNameMap = new Map();
        
        skills.forEach((skill, index) => {
          if (!skillNameMap.has(skill.skillName)) {
            skillNameMap.set(skill.skillName, [index]); // Initialize with the first index
          } else {
            skillNameMap.get(skill.skillName).push(index); // Append subsequent indices
          }
        });
        
        // Filter out skillNames without duplicates
        const duplicateIndices = Array.from(skillNameMap.values()).filter(indices => indices.length > 1);
      
        // Flatten the array of indices if you want a single array containing all duplicate indices
        return duplicateIndices.flat();
      }
    const getSkills = () => {
        if (profileMod) {
            const experiences = {
                experiences: new Array()
            }
            profileMod.experiences.map((item: Experience, index: number) => {
                let endD = "";
                let startD = "";
                if (item.endDate) {

                    endD = setDateToMidnight(item.endDate);
                } else if (item.stillWorkHere) {
                    endD = setDateToMidnight(new Date().toISOString());
                }
                if (item.startDate) {

                    startD = setDateToMidnight(item.startDate);
                }
                const itemExp = {
                    endDate: endD,
                    jobTitle: item.jobTitle,
                    responsibilities: item.responsibilities,
                    startDate: startD
                }
                experiences.experiences.push(itemExp);
            })
            JobsInstanceService.getSkillSuggestion(experiences).then((res) => {

                if (res.data) {
                    setSkillSuggest(res.data);
                }
            })
        }

    }


    return (
        <>
            <div className="skills-control education">
                <div className="skill-title-control">

                    <span className="skills-title">{translate.profile.skill}</span>
                    <Button className="skills-add-button" onClick={addSkillField}>+ {translate.other.add}</Button>
                </div>

                {
                    skillSuggest && skillSuggest.skills && skillSuggest.skills.length > 0 &&
                    <>
                        <span className="skills-tag-title">{translate.profile.skill_suggest}</span>
                        <div className="skills-tag-border">
                            <div className="skills-tag-control">

                                {
                                    skillSuggest.skills.map((item: string, index: number) => {
                                        return (
                                            <div className="skills-tag-item" key={index} onClick={() => addSkillSuggestField(item)}>
                                                + {item}
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </>
                }

                <Form className="skills-form-control">
                    {skills && skills.map((skill, index) => (
                        <Form.Group key={index} className="skills-form-group-control">
                            <Form.Label className="skills-form-group-title"> {translate.profile.skill} {index + 1}</Form.Label>
                            <div className="skills-form-item-control">
                                <div className="skills-form-item-control-item">
                                    <Form.Control
                                        type="text"
                                        className="skills-form-input"
                                        placeholder={translate.profile.skill}
                                        value={skill.skillName}
                                        onChange={(e) => handleSkillChange(index, e.target.value)}
                                    />
                                    {
                                        skillDup && skillDup.length > 0 && skillDup.map((item, indexDup) => {

                                            return (
                                                item == index &&
                                                <span className="education-item-text-error" key={indexDup}>{translate.error.skill_duplicate}</span>
                                            )
                                        })
                                    }
                                    {
                                        isSaving && !skill.skillName &&

                                        <span className="education-item-text-error">{translate.error.input}</span>
                                    }
                                </div>
                                <Button className="skill-form-button-remove"

                                    onClick={() => removeSkillField(index)}
                                >
                                    {translate.other.delete}
                                </Button>
                            </div>
                        </Form.Group>
                    ))}

                    <div className="education-item-edit-button-control">
                        <Button className="education-item-edit-button" onClick={() => submit()}>{translate.other.save}</Button>
                        <Button className="education-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default SkillsComponent;