import { ResumeTemplateModel } from "../../model/resume-builder/resume-template.model";


class InputService {
    setTemplate(input) {
        localStorage.setItem('template', input);
    }
    getTemplate() {
        const user = localStorage.getItem('template');
        return user;
    }
    getInput() {
        const user = JSON.parse(localStorage.getItem('input_template')!) as ResumeTemplateModel;
        return user;
    }

    setInput(input) {
        localStorage.setItem('input_template', JSON.stringify(input));
    }

}

export default new InputService();