import apiPath from "../api-path"
import config from "../config"
import axios from "axios"
import tokenService from "./sso/token.service"


export default function ApiService() {

    return {
        async oauthToken(code, codeVerifier, path) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': config.authen.grant_type,
                    'code': code,
                    'code_verifier': codeVerifier,
                    'client_id': config.authen.client_id,
                    'redirect_uri': config.authen.redirect_uri + "?" + new URLSearchParams({
                        page: path
                    })
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }
        , async refreshToken(refresh_token) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': 'refresh_token',
                    'refresh_token': refresh_token
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }, async getMysaProfile() {

            return axios.get(config.authen.api.host_url + "/api/v2.0/user/profile", {
                 headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'Content-Type': "application/json",
                }
            }).then(res => res.data).catch(err => console.log(err))
        },


        async addResume(resume_name) {
            const resumeJson = {
                "resume_name": resume_name,
                "template_id": "0"
            }
            return axios.post(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume, resumeJson, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            )
                .then(res => res)
                .catch(errors => errors);
        },
     
        async getAllResume() {

            return fetch(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async checkResume() {

            return fetch(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume + "/" + apiPath.path.slot, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
       
        async getOneResume(resume_uuid) {

            return fetch(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid, {
                method: 'GET', headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },


        async getJobRolesSuggestion() {

            return axios.get(config.chat_gpt.base_url + apiPath.product_path + "job_roles", {
               headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res).catch(err => console.log(err))
        },

    }
}