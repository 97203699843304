import MenuComponent from "../menu/menu"
import "./guest.scss";


const GuestComponent = () => {



    return  (

        <>
            <div className="main-control">
                <MenuComponent></MenuComponent>
                <div className="guest-main">

                </div>
            </div>
        </>
    )
}

export default GuestComponent;