import MenuComponent from "../../../menu/menu";
import "../require-login.scss";
import BG_INTERVIEW from "../../../../assets/images/guest/interview-ai.jpg";
import IMG_AI_1 from "../../../../assets/images/guest/ai-1.png"
import IMG_AI_2 from "../../../../assets/images/guest/ai2.png"
import TEXT_INTERVIEW from "../../../../assets/images/guest/text-interview.png";
import DETAIL_INTERVIEW from "../../../../assets/images/guest/detail-interview.png";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import AuthFunction from "../../../../services/sso/auth.function";
import config from "../../../../config";
import navigationService from "../../../../services/navigation.service";
import tokenService from "../../../../services/sso/token.service";
import UtilityService from "../../../../utility/utility.service";

const GuestInterviewAIComponent = () => {

  
    const authenticateUrl = () => {
        const codeVerifier = AuthFunction().randomString(56);
        const codeChallenge = AuthFunction().PKCEencoding(codeVerifier);
        const state = AuthFunction().randomString(config.authen.length);
        let path = "/profile";
        if (navigationService.getPath()) {
            path = navigationService.getPath();
        }
        const urlReturn = config.authen.sso_url + "?" + new URLSearchParams({
            redirect_uri: config.authen.redirect_uri + "?" + new URLSearchParams({
                page: path
            }),
            state: state,
            code_challenge: codeChallenge,
            response_type: 'code',
            code_challenge_method: 'S256',
            client_id: config.authen.client_id
        })
        tokenService.setVerifyCode(codeVerifier)
        window.open(urlReturn, "_self");
    }
    const onClickRegister= () => {
        UtilityService().clickSendEvent("guest_interview_ai_register", "click");
        authenticateUrl();
    }
    const onClickLogin = () => {
        UtilityService().clickSendEvent("guest_interview_ai_login", "click");
        authenticateUrl();
    }
    return (

        <>
            <div className="main-control">
                <MenuComponent></MenuComponent>
                <div className="guest-require-login" style={{backgroundImage: `url(${BG_INTERVIEW})`}}>
                    <div className="guest-require-login-border-control">
                        <img src={IMG_AI_1} className="guest-require-login-img-ai-1"></img>
                        <img src={IMG_AI_2} className="guest-require-login-img-ai-4"></img>
                        <img src={TEXT_INTERVIEW} className="guest-require-login-headtext-control"></img>
                        <img src={DETAIL_INTERVIEW} className="guest-require-login-detail-control-2"></img>

                        <Button className="guest-require-login-button-register" onClick={onClickRegister}>ลงทะเบียน</Button>
                        <Button className="guest-require-login-button-login" onClick={onClickLogin}>เข้าสู่ระบบ</Button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default GuestInterviewAIComponent;