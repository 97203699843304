import RadarChartComponent from "./radar-graph"
import "./personality.scss";
import PERSONALITY_JSON from "../../../assets/json/personality/result-personality.json";
import { useContext, useEffect, useState } from "react";
import { ResultPersonalityModel, TextResult, Trait } from "../../../model/personality/result-personality.model";
import { CircularProgress, Divider } from "@mui/material";
import { ProvideContext } from "../../../context/provider.context";
import { TranslateModel } from "../../../model/translate.model";
import { Button } from "react-bootstrap";
import { ProfileModel } from "../../../model/profile.model";
import RamaService from "../../../services/sso/rama.service";
import { ProfileExtModel } from "../../../model/profile/profile-ext.model";
import ProfileService from "../../../services/profile/profile.service";
import { useLocation, useNavigate } from "react-router-dom";
import navigateConfig from "../../../navigate.config";
import PersonalityTestService from "../../../services/personality-test/personality-test.service";
import ProfileInstantService from "../../../services/profile/profile-instance.service";


const PersonalityComponent = ({ profileExtMod, resultPersonality }: any) => {
    const location = useLocation();
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [isStartTest, setIsStartTest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profileExt, setProfileExt] = useState<ProfileExtModel>(profileExtMod);
    const [isView, setIsView] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes('/profile/view/')) {
            setIsView(true);

        } else {
            setIsView(false);

        }
        if (resultPersonality) {
            setIsLoading(false);
        }else{
            setIsLoading(false);

        }
    }, [])

  



    const clickStartTest = () => {
        navigate(navigateConfig.personality.main)
    }



    return (
        <div className="personality-main">

            {
                resultPersonality ?
                    <>
                        <RadarChartComponent resultPersonality={resultPersonality}></RadarChartComponent>
                        <span className="personality-type-text">{resultPersonality.results.mbti_results.type}</span>
                        <Divider textAlign="center" className="personality-divider-with-text">{translate.personality.detail}</Divider>
                        {
                            resultPersonality.results.text_results.map((item: TextResult, index: number) => {

                                return (

                                    <div className="personality-detail-control" key={index}>
                                        <span className="personality-detail-title">
                                            {item.big_5_trait == 'O' && item.big_5_trait + " (" + translate.personality.O + ")"}
                                            {item.big_5_trait == 'C' && item.big_5_trait + " (" + translate.personality.C + ")"}
                                            {item.big_5_trait == 'E' && item.big_5_trait + " (" + translate.personality.E + ")"}
                                            {item.big_5_trait == 'A' && item.big_5_trait + " (" + translate.personality.A + ")"}
                                            {item.big_5_trait == 'N' && item.big_5_trait + " (" + translate.personality.N + ")"}

                                            :</span>
                                        <span className="personality-detail-desc">{(item.big_5_user_score * 100).toFixed(0) + '%'}</span>
                                    </div>
                                )
                            })
                        }

                        <Divider textAlign="center" className="personality-divider-with-text">{translate.personality.trait}</Divider>
                        <div className="personality-trait-control">
                            {
                                resultPersonality.results.mbti_results.traits.map((item: Trait, index: number) => {

                                    return (
                                        <div className="personality-trait-item-control" key={index}>
                                            <img src={item.image_url} className="personality-trait-item-img"></img>
                                            <span className="personality-trait-item-text">{item.name}</span>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        {
                            !isView && <Button className="personality-test-again-button" onClick={clickStartTest}>{translate.personality.test_again}</Button>
                        }


                    </> :
                    isLoading ?
                        <>
                            <div className="personality-loading-control">
                                <div className="personality-loading-main">
                                    <CircularProgress className="personality-loading-progress"></CircularProgress>
                                    <span className="personality-loading-text">{translate.personality.loading}</span>
                                </div>

                            </div>
                        </> :

                        <div className="personality-add-control">
                            <div className="personality-add-main-control">
                                <span className="personality-add-main-title">{translate.personality.no_test}</span>
                                {
                                    !isView &&
                                    <Button className="personality-add-button" onClick={clickStartTest}>{translate.personality.start_test}</Button>
                                }

                            </div>
                        </div>


            }


        </div>
    )
}

export default PersonalityComponent;