import { Modal } from "react-bootstrap";
import "./edit-profile.modal.scss";
import EducationComponent from "../components/profile/my-info/education/education";
import ExperienceComponent from "../components/profile/my-info/experience/experience";
import ContactComponent from "../components/profile/my-info/contact/contact";
import AboutComponent from "../components/profile/my-info/about/about";
import SkillsComponent from "../components/profile/my-info/skills/skills";
import InterestingComponent from "../components/profile/my-info/interesting/interesting";
import ActivitiesComponent from "../components/profile/my-info/activities/activities";
import CertificatesComponent from "../components/profile/my-info/certificates/certificates";
import AnyFileComponent from "../components/profile/my-info/any-file/any-file";
import JobOpportunityComponent from "../components/profile/my-info/job-opportunity/job-opportunity";
import IntroductionComponent from "../components/profile/my-info/introduction/introduction";

const EditProfileModal = ({ open, setOpen, profile, action, setProfile, isNew, setInterestingUpdate, interestingModel, isApplicationForm }: any) => {

    const handleClose = () => {
     
        setOpen(false);
        setProfile(profile);
    }

    const setCloseModal = () => {
        setOpen(false);
    }
    return (

        <>
            <Modal show={open} onHide={handleClose} backdrop='static' keyboard={false} className="edit-profile">
                <Modal.Body className="edit-profile-content">
                    {
                        action == 'education' && <EducationComponent
                        isApplicationForm={isApplicationForm}
                         profile={profile} setProfile={setProfile} isNew={isNew} setCloseModal={setCloseModal}>
                        </EducationComponent>
                    }
                    {
                        action == 'experience' && <ExperienceComponent
                        isApplicationForm={isApplicationForm}
                        profile={profile} setProfile={setProfile} isNew={isNew} setCloseModal={setCloseModal}>
                        </ExperienceComponent>
                    }
                     {
                        action == 'contact' && <ContactComponent profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </ContactComponent>
                    }
                     {
                        action == 'jobOpportunity' && <JobOpportunityComponent
                        profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </JobOpportunityComponent>
                    }
                    {
                        action == 'about' && <AboutComponent profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </AboutComponent>
                    }
                    {
                        action == 'activities' && <ActivitiesComponent 
                        isApplicationForm={isApplicationForm}
                        profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </ActivitiesComponent>
                    }
                    {
                        action == 'skills' && <SkillsComponent 
                        isApplicationForm={isApplicationForm}
                        profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </SkillsComponent>
                    }
                     {
                        action == 'certificates' && <CertificatesComponent 
                        isApplicationForm={isApplicationForm}
                        profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </CertificatesComponent>
                    }
                     {
                        action == 'candidateFiles' && <AnyFileComponent 
                        isApplicationForm={isApplicationForm}
                        profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </AnyFileComponent>
                    }
                    {
                        action == 'interesting' && <InterestingComponent
                        setInterestingUpdate={setInterestingUpdate} interestingModel={interestingModel}
                        profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </InterestingComponent>
                    }
                   
                    {
                        action == 'introduction' && <IntroductionComponent
                        profile={profile} setProfile={setProfile} setCloseModal={setCloseModal}>
                        </IntroductionComponent>
                    }
                </Modal.Body>


            </Modal>
        </>
    )
}

export default EditProfileModal;