import { Button, Modal } from "react-bootstrap"
import "./confirm-update-profile.scss";
import { ProvideContext } from "../context/provider.context";
import { useContext, useState } from "react";
import { TranslateModel } from "../model/translate.model";

const ConfirmUpdateProfileModal = ({ open, handleClose, saveCandidateInput }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);

    return (

        <>
            <Modal show={open} onHide={handleClose} backdrop='static' keyboard={false} className="confirm-profile">
                <Modal.Body className="confirm-profile-control">
                    <span className="confirm-profile-header">
                        {translate.other.confirm_update_profile}
                    </span>
                    <div className="confirm-profile-button-control">
                        <Button className="confirm-profile-button-confirm" onClick={() => saveCandidateInput(true)}>{translate.other.save}</Button>
                        <Button className="confirm-profile-button-no-update" onClick={() => saveCandidateInput(false)}>{translate.other.no_need_update}</Button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConfirmUpdateProfileModal;