import { QueryParamsModel } from "../model/query-params.model";
import queryParamService from "./query-param.service";
import storage from "./sso/storage";


class NavigationService {
   

    handleNavigation = (navigate) =>{
        if(queryParamService.getQueryParams()){
            const pathQuery = queryParamService.getQueryParams() as QueryParamsModel;
            const pathResult = pathQuery.path + "?" + new URLSearchParams(pathQuery.query);
            queryParamService.clearPath();
            console.log(pathResult);
            navigate(pathResult)
        }
       
    }

    getPath = () =>{
        if(queryParamService.getQueryParams()){
            const pathQuery = queryParamService.getQueryParams() as QueryParamsModel;
            const pathResult = pathQuery.path;
            return pathResult;
        }else{
            return "";
        }
      
    }
  }
  
  export default new NavigationService();