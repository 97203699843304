import { Button } from "react-bootstrap";
import "./my-resume.scss";
import ADD_IMG from "../../../assets/images/add.png";
import config from "../../../config";
import { useContext, useEffect, useMemo, useState } from "react";
import { AllResumeModel } from "../../../model/all-resume.model";
import { GetResumeIdModel } from "../../../model/get-resume.model";

import SaveModal from "../../../modal/save.modal";
import { Alert, CircularProgress, IconButton, MenuItem, Snackbar, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import RedirectModal from "../../../modal/redirect.modal";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { SlotResumeModel } from "../../../model/resume-builder/slot-resume.model";
import LoadingModal from "../../../modal/loading/loading";
import MenuComponent from "../../menu/menu";
import navigateConfig from "../../../navigate.config";
import { ProvideContext } from "../../../context/provider.context";
import { TranslateModel } from "../../../model/translate.model";
import ResumeBuilderInstantService from "../../../services/resume-builder/resume-builder-instance.service";
import { useNavigate } from "react-router-dom";
import UploadResumeModal from "../../../modal/jobs/upload-resume/upload-resume.modal";
import { useProfile } from "../../../context/profile.context";
import { getOneResumeModel } from "../../../model/resume-builder/get-one-resume.model";
import RemoveReseumeModal from "../../../modal/remove-resume";
import BG_CIRCLE_MAIN from "../../../assets/images/circle-bg-main.svg";
import UtilityService from "../../../utility/utility.service";
const MyResumeComponent = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [allResume, setAllResume] = useState<AllResumeModel>();
    const [isShowSaveModal, setIsShowSaveModal] = useState(false);
    const [isShowRedirectModal, setIsShowRedirectModal] = useState(false);
    const [slotModel, setSlotModel] = useState<SlotResumeModel>();
    const [isUploadResume, setIsUploadResume] = useState(false);
    const [isModalSnack, setIsModalSnack] = useState(false);
    const navigate = useNavigate();
    const [profile, error] = useProfile();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [isResumeFull, setIsResumeFull] = useState(false);
    const [resumeDelete, setResumeDelete] = useState<getOneResumeModel>();


    useEffect(() => {
        if (profile) {
            UtilityService().clickSendEvent("my_resumes_page", "load");
            getAllResumes();
        }
    }, [profile])

    useEffect(() => {
        if (!slotModel) {
            checkSlot();
        }
    }, [slotModel])
    const handleCloseSnack = () => {
        setIsModalSnack(false);
    }
    const getAllResumes = () => {
        setIsLoading(true);
        ResumeBuilderInstantService.getAllResume().then((res) => {
            setIsLoading(false);
            if (res.data) {

                const result = res.data as AllResumeModel;
                if (result.resumes) {
                    var allResumeMod: GetResumeIdModel[] = new Array();
                    result.resumes.map((item: GetResumeIdModel) => {
                        item.is_open = false;
                        allResumeMod.push(item);
                    })
                    result.resumes = allResumeMod;
                    setAllResume(result);

                }

            }
        })
    }

    const clickEdit = (e, resume_uuid) => {
        e.preventDefault();
        navigate(navigateConfig.resume.builder + resume_uuid);

    }

    const checkSlot = () => {

        ResumeBuilderInstantService.slotResume().then((res) => {
            if (res.data) {
                const result = res.data as SlotResumeModel;
                setSlotModel(result);
                setIsResumeFull(false);
            } 
        }).catch((errors) => {
            console.log(errors);
            if (errors.response.data.error == "Cannot create a new resume") {
                setIsResumeFull(true);
            }
        })
    }

    const clickAddNew = () => {
        if (slotModel && slotModel.can_add_resume) {
            navigate(navigateConfig.resume.choose_template);
        }
    }

    const handleUploadResume = () => {
        if (isUploadResume) {
            setIsUploadResume(false);
        } else {
            setIsUploadResume(true);
        }
    }

    const clickUpload = () => {
        setIsUploadResume(true);
    }

    const clickView = async (resume_uuid) => {
        window.open(config.url + 'resume/view/' + resume_uuid, "_blank");
        // navigate({ pathname: '/profile/resume/view', search: `?${createSearchParams(params)}` })
    }
    const clickShare = async (resume_uuid, resume_name) => {
        const shareDetails = { url: config.resume_builder + "resume/" + resume_uuid, title: resume_name, text: resume_name };

        if (navigator.canShare(shareDetails)) {
            try {
                await navigator.share(shareDetails)
            } catch (err: any) {
                if (err.name !== 'AbortError') {
                    console.error(err.name, err.message)
                }
            }
        } else {
            console.warn('Sharing not supported', shareDetails)
        }

    }

    const handleCloseConfirmDelete = () => {
        setIsConfirmDelete(false);
    }
    const clickDelete = (resume) => {
        setResumeDelete(resume);
        setIsConfirmDelete(true);

    }

    const confirmDelete = () => {
        if (resumeDelete) {
            setIsLoading(true);
            ResumeBuilderInstantService.deleteResume(resumeDelete?.resume_uuid).then((res) => {
                if (res.status == 200) {
                    getAllResumes();
                    checkSlot();
                    setIsLoading(false);
                    handleCloseConfirmDelete();
                    checkSlot();
                }

            })
        }

    }



    const onSelectChange = (e, resume_uuid, resume_status) => {
        if (resume_status == "PUBLISHED") {
            ResumeBuilderInstantService.updateResumeDefault(resume_uuid).then((res) => {
                if (res.data) {
                    getAllResumes();
                }

            })
        } else {
            setIsModalSnack(true);
        }


    }

    const setKey = (key) => {
        getAllResumes();
    }


    function Row(props: { resume: GetResumeIdModel, index }) {
        const { resume, index } = props;
        const [open, setOpen] = useState(false);

        const setOpenClick = () => {
            if (allResume) {
                const resumeMod = { ...allResume };
                if (resume.is_open) {
                    resume.is_open = false
                } else {
                    resume.is_open = true
                }
                resumeMod.resumes[index] = resume;
                setAllResume(resumeMod);
            }

        }

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell align="center" width={'50px'} className="my-resume-item-cell-text d-md-table-cell d-none">{index + 1}</TableCell>
                    <TableCell align="left" className="my-resume-item-cell-text">{resume.resume_name}</TableCell>
                    <TableCell align="left" className="my-resume-item-cell-text d-md-table-cell d-none ">
                        <div className="my-resume-item-status-control">
                            {
                                resume.resume_status == "PUBLISHED" ? translate.resume_builder.resume_published : translate.resume_builder.resume_draft
                            }
                        </div>
                    </TableCell>
                    <TableCell align="left" className="my-resume-item-cell-text d-md-table-cell  d-none ">{resume.created_at && new Date(resume.created_at).toDateString()}</TableCell>
                    <TableCell align="left" className="my-resume-item-cell-text">
                        <Switch defaultChecked={resume.is_default ? true : false}
                            onChange={(e) => onSelectChange(e.target.checked, resume.resume_uuid, resume.resume_status)}
                            inputProps={{ 'aria-label': 'ant design' }} />
                    </TableCell>
                    <TableCell align="left" className="d-md-table-cell d-none ">
                        <div className="my-resume-item-button-control">

                            {
                                Number(resume.template_id) != 99 ?
                                    <Button className="my-resume-item-share-view-button" onClick={() => clickView(resume.resume_uuid)}>{translate.resume_builder.view_resume}</Button>
                                    :
                                    <Button className="my-resume-item-share-view-button" onClick={() => clickView(resume.resume_uuid)}>{translate.resume_builder.view_resume}</Button>

                            }

                            <Button className="my-resume-item-delete-button" onClick={() => clickDelete(resume)}>{translate.resume_builder.delete_resume}</Button>

                        </div>
                    </TableCell>

                    <TableCell align="left" className="my-resume-item-cell-text d-table-cell d-md-none">
                        <IconButton
                            className="my-resume-collapse-size"
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenClick()}
                        >
                            {resume.is_open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>

                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                        <Collapse in={resume.is_open} timeout="auto" className="d-block d-md-none" unmountOnExit>
                            <Box sx={{ margin: 1 }}>

                                <Table >

                                    <TableBody>
                                        {allResume && allResume.resumes.filter(item => item.resume_uuid == resume.resume_uuid).map((resume: GetResumeIdModel, index: number) => (
                                            <TableRow key={index}>

                                                <TableCell style={{ border: 'none' }}>
                                                    <div className="my-resume-item-button-control">
                                                        {
                                                            Number(resume.template_id) != 99 ?
                                                                <Button className="my-resume-item-share-view-button" onClick={() => clickView(resume.resume_uuid)}>{translate.resume_builder.view_resume}</Button>
                                                                : <Button className="my-resume-item-share-view-button" onClick={() => clickView(resume.resume_uuid)}>{translate.resume_builder.view_resume}</Button>
                                                        }


                                                        <Button className="my-resume-item-delete-button" onClick={() => clickDelete(resume.resume_uuid)}>{translate.resume_builder.delete_resume}</Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }
    return (

        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="my-resume">
                <img src={BG_CIRCLE_MAIN} className="bg-circle-main"></img>
                <div className="my-resume-control">
                    <div className="my-resume-header-control">
                        <h1 className="my-resume-header">{translate.resume_builder.my_resume}</h1>
                        <div className="my-resume-add-button-control">
                            {
                                slotModel ?
                                    <>
                                        <span className="my-resume-add-button-text">{translate.resume_builder.total_resume}</span>
                                        <span className="my-resume-add-button-text-bold">{slotModel.used_slot}/{slotModel.total_slot}</span>
                                    </> :
                                    isResumeFull &&
                                    <>
                                        <span className="my-resume-add-button-text">{translate.resume_builder.total_resume}</span>
                                        <span className="my-resume-add-button-text-bold">{10}/{10}</span>
                                    </>


                            }
                            {
                                !isResumeFull &&

                                <>
                                    <Button className="my-resume-add-button" onClick={clickAddNew}><img src={ADD_IMG} className="my-resume-add-img"></img>{translate.resume_builder.add_resume}</Button>
                                    <span className="my-resume-import-or">{translate.other.or}</span>
                                    <span className="my-resume-import-resume" onClick={clickUpload}>{translate.resume_builder.import_resume}</span>
                                </>
                            }

                        </div>

                    </div>
                    {
                        (allResume && allResume.resumes.length > 0 && !isLoading) ?
                            <div className="my-resume-list-control-desktop">
                                <TableContainer >
                                    <Table className="my-resume-table-size" aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" style={{ width: '10%' }} className="d-md-table-cell d-none ">{translate.resume_builder.resume_order}</TableCell>
                                                <TableCell align="left" style={{ width: '30%' }}>{translate.resume_builder.resume_name}</TableCell>
                                                <TableCell align="left" style={{ width: '10%' }} >{translate.resume_builder.resume_status}</TableCell>
                                                <TableCell align="left" style={{ width: '10%' }} className="d-md-table-cell d-none ">{translate.resume_builder.create_at}</TableCell>
                                                <TableCell align="left" style={{ width: '10%' }} className="d-md-table-cell d-none ">{translate.resume_builder.resume_default}</TableCell>
                                                <TableCell align="left" style={{ width: '30%' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allResume.resumes.map((resume: GetResumeIdModel, index: number) => (
                                                <Row key={resume.resume_uuid} resume={resume} index={index} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                            :
                            isLoading ? <>
                                <div className="my-resume-loading-control">
                                    <div className="my-resume-loading-flex">
                                        <CircularProgress className="my-resume-loading"></CircularProgress>
                                        <span className="my-resume-loading-text">{translate.other.loading}</span>
                                    </div>
                                </div>
                            </> : allResume && allResume?.resumes.length == 0 && !isLoading &&
                            <div className="no-resume-control">
                                <div className="no-resume-text-control">
                                    <span className="no-resume-text">{translate.error.no_resume}</span>
                                    <Button className="no-resume-add-button" onClick={clickAddNew}><img src={ADD_IMG} className="my-resume-add-img"></img>{translate.other.add}</Button>
                                </div>

                            </div>
                    }

                </div>
            </div>
            {
                isShowSaveModal && <SaveModal open={isShowSaveModal} setOpen={setIsShowSaveModal}></SaveModal>
            }
            {
                isShowRedirectModal && <RedirectModal open={isShowRedirectModal} setOpen={setIsShowRedirectModal} product='resume-builder'></RedirectModal>
            }
            {
                isLoading && <LoadingModal open={isLoading} onclose={setIsLoading}></LoadingModal>
            }
            {
                isUploadResume && <UploadResumeModal open={isUploadResume} handleClose={handleUploadResume} setKey={setKey} pathName="resume_builder"> </UploadResumeModal>
            }

            <Snackbar open={isModalSnack} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleCloseSnack} >
                <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                    {translate.error.resume_change_default}
                </Alert>
            </Snackbar>
            {
                isConfirmDelete &&
                <RemoveReseumeModal open={isConfirmDelete} resume={resumeDelete} confirmDelete={confirmDelete} handleClose={handleCloseConfirmDelete}></RemoveReseumeModal>
            }
        </div>
    )
}

export default MyResumeComponent;