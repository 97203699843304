import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import "./education.scss";
import { useContext, useEffect, useState } from "react";
import { Education, ProfileModel } from "../../../../model/profile.model";
import EDUCATION_LEVEL_JSON from "../../../../assets/json/education-level.json";
import SelectItemModal from "../../../../model/select-item.model";
import { Button } from "react-bootstrap";
import RamaService from "../../../../services/sso/rama.service";
import CLOSE_IMG from "../../../../assets/images/close.png";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import UtilityService from "../../../../utility/utility.service";


const EducationComponent = ({ profile, setProfile, isNew, setCloseModal, isApplicationForm }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [educationLevel, setEducationLevel] = useState<SelectItemModal>();
    const [currentEducation, setCurrentEducation] = useState<Education>();
    const [editIndex, setEditIndex] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [isAddingNew, setAddingNew] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [yearEducation, setYearEducation] = useState<SelectItemModal[]>();
    const [endYearEducation, setEndyearEducation] = useState<SelectItemModal[]>();
    const [errorGPA, setErrorGPA] = useState(false);

    useEffect(() => {

        if (profileMod) {

            setCurrentEducation(profileMod.educations[0])
            initEducationYear();
            if (isNew) {
                addEmptyEducation();
            }
        }

    }, [])

    const clickEditEducation = (education, index) => {
        setEditIndex(index);
        setIsEditing(true);
        const educationCurrent = education as Education;
        setCurrentEducation(educationCurrent)
    }

    const initEducationLevel = (educationlv) => {
        let valueRet = "";
        EDUCATION_LEVEL_JSON.map((item: any, index: number) => {
            if (educationlv == item.value) {
                valueRet = item.name;
                setEducationLevel(item)
            }
        })
    }
    const textChange = (text, key, index) => {
        const profileModEdit = profileMod
        const profileEdit = profileMod.educations[index];
        let currentEditChange = { ...currentEducation } as Education;
        if (currentEditChange) {
            if (key == 'educationLevel') {
                currentEditChange.educationLevel = text
                initEducationLevel(text);
            } else if (key == 'institute') {
                currentEditChange.institute = text
            } else if (key == 'faculty') {
                currentEditChange.faculty = text
            } else if (key == 'fieldOfStudy') {
                currentEditChange.fieldOfStudy = text
            } else if (key == 'gpax') {
                if (isGpax(text)) {
                    if (/^\d*(\.\d{0,2})?$/.test(text)) {
                        currentEditChange.gpax = Number(text);
                        setErrorGPA(false);
                    }

                } else {
                    currentEditChange.gpax = ""
                    setErrorGPA(true);
                }

            }

            else if (key == 'startYear') {
                profileEdit.startYear = text
                currentEditChange.startYear = text
                endYearInit(text);

            } else if (key == 'graduationYear') {
                profileEdit.graduationYear = text
                currentEditChange.graduationYear = text
            }
            if (currentEducation) {
                setCurrentEducation(currentEducation => ({
                    ...currentEditChange
                }));
            }

        }

    }

    function isGpax(input) {
        const regex = /^(?:0(?:\.\d+)?|1(?:\.\d+)?|2(?:\.\d+)?|3(?:\.\d+)?|4(?:\.0+)?)$/;
        return regex.test(input);
    }
    const endYearInit = (startYear) => {
        const currentYear = Number(startYear) + 10

        const yearDataArray: SelectItemModal[] = [];

        for (let year = currentYear; year >= currentYear - 10; year--) {
            const yearData: SelectItemModal = {
                name: year + "",
                value: year + "",
            };
            yearDataArray.push(yearData);
        }
        setEndyearEducation(yearDataArray);
    }

    const initEducationYear = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const yearDataArray: SelectItemModal[] = [];

        for (let year = currentYear; year >= currentYear - 40; year--) {
            const yearData: SelectItemModal = {
                name: year + "",
                value: year + "",
            };
            yearDataArray.push(yearData);
        }
        setYearEducation(yearDataArray);
        endYearInit(yearDataArray);
    }

    const deleteItem = (index) => {
        let profileEdit = { ...profileMod };
        if (isApplicationForm) {
            profileEdit.educations.splice(index, 1);
            setIsEditing(false);
            setProfileMod(profileEdit);
            setProfile(profileEdit);
        } else if (profileEdit.educations[index] && currentEducation) {
            profileEdit.educations.splice(index, 1);
            RamaService.updateProfile(profileEdit).then((res) => {
                ProfileInstantService.updateProfilePublic(profileEdit);
                setProfileMod(profileEdit);
                setIsEditing(false);
                setProfile(profileEdit);
            })
        }
    }

    const addEmptyEducation = () => {
        let profileEdit = { ...profileMod };
        if (profileEdit.educations) {
            const addEducation: Education = {
                educationLevel: "",
                faculty: "",
                fieldOfStudy: "",
                gpax: "",
                graduationYear: "",
                honor: "",
                institute: "",
                startYear: ""
            }
            profileEdit.educations.unshift(addEducation);
            setProfileMod(profileEdit);
            setEditIndex(0)
            setAddingNew(true);
            setIsEditing(true);
            setCurrentEducation(addEducation)
        }
    }

    const cancelAddEducation = (index) => {
        let profileEdit = { ...profileMod };
        if (profileEdit.educations) {
            profileEdit.educations.splice(index, 1);
            setProfileMod(profileEdit)
            setEditIndex(9999)
            setIsEditing(false);
        }
    }

    const saveValue = (index) => {
        let profileEdit = { ...profileMod };
        setIsSaving(true);
        if (checkLevel()) {
            if (currentEducation
                && currentEducation.educationLevel
                && currentEducation.institute
                && currentEducation.startYear
                && currentEducation.graduationYear
                && currentEducation.gpax) {

                if (isApplicationForm) {
                    UtilityService().clickSendEvent("save_contact_apply", "submit");
                    profileEdit.educations[index] = currentEducation
                    setProfileMod(profileEdit);
                    setIsEditing(false);
                    setAddingNew(false);
                    setProfile(profileEdit);
                } else {
                    profileEdit.educations[index] = currentEducation
                    RamaService.updateProfile(profileEdit).then((res) => {

                        if(res.data){
                            UtilityService().clickSendEvent("save_contact", "submit");
                            ProfileInstantService.updateProfilePublic(profileEdit);
                            setProfileMod(profileEdit);
                            setIsEditing(false);
                            setAddingNew(false);
                            setProfile(profileEdit);
                        }
                       
                    })
                }

            }
        } else {
            if (currentEducation
                && currentEducation.educationLevel
                && currentEducation.institute
                && currentEducation.faculty
                && currentEducation.fieldOfStudy
                && currentEducation.startYear
                && currentEducation.graduationYear
                && currentEducation.gpax) {
                if (isApplicationForm) {
                    profileEdit.educations[index] = currentEducation
                    setProfileMod(profileEdit);
                    setIsEditing(false);
                    setAddingNew(false);
                    setProfile(profileEdit);
                } else {
                    profileEdit.educations[index] = currentEducation
                    RamaService.updateProfile(profileEdit).then((res) => {
                        ProfileInstantService.updateProfilePublic(profileEdit);
                        setProfileMod(profileEdit);
                        setIsEditing(false);
                        setAddingNew(false);
                        setProfile(profileEdit);
                    })
                }

            }
        }


    }

    const checkLevel = () => {
        if (currentEducation) {
            const value = currentEducation.educationLevel;
            if (value == 'BACHELOR' || value == 'MASTER' || value == 'DOCTORATE' || value == 'NO_EDUCATION') {
                return false;
            } else {
                return true;
            }
        }

    }

    const closeModal = () => {
        if (isAddingNew) {
            cancelAddEducation(editIndex);
            setAddingNew(false);
            setCloseModal();
        } else {
            setAddingNew(false);
            setCloseModal();
        }
    }

    return (
        <>
            <div className="education">
                <div className="education-close-control">
                    <img src={CLOSE_IMG} onClick={closeModal} className="education-close-img"></img>
                </div>
                <div className="d-flex">
                    <span className="education-header">{translate.profile.education}</span>
                    {
                        !isAddingNew && <Button className="education-item-add-button" onClick={addEmptyEducation}>+ {translate.other.add} </Button>
                    }

                </div>


                {
                    profileMod && profileMod.educations.map((item: Education, index: number) => {

                        return (
                            <>{
                                (editIndex == index && currentEducation && isEditing) ?

                                    <div className="education-grid">
                                        <div className="education-item">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label" className="education-item-label" >{translate.profile.education_level}</InputLabel>
                                                <Select

                                                    inputProps={{ className: "education-item-input-select" }}
                                                    value={currentEducation.educationLevel}
                                                    label={translate.profile.education_level}
                                                    onChange={(e) => textChange(e.target.value, 'educationLevel', index)}
                                                >
                                                    {
                                                        EDUCATION_LEVEL_JSON && EDUCATION_LEVEL_JSON?.map((item2: SelectItemModal, index2: number) => {
                                                            return (
                                                                item2.value == item.educationLevel ?
                                                                    <MenuItem value={item2.value} className="education-menu-item" key={index2} selected>{item2.name}</MenuItem> :
                                                                    <MenuItem value={item2.value} className="education-menu-item" key={index2}>{item2.name}</MenuItem>

                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            {
                                                (isSaving && !currentEducation.educationLevel) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }

                                        </div>
                                        <div className="education-item">
                                            <TextField inputProps={{ className: "education-item-input" }}
                                                InputLabelProps={{ className: "education-item-label" }}
                                                label={translate.profile.school} value={currentEducation.institute} variant="outlined"
                                                onChange={(e) => textChange(e.target.value, 'institute', index)} />
                                            {
                                                (isSaving && !currentEducation.institute) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }
                                        </div>
                                        <div className="education-item">
                                            <TextField inputProps={{ className: "education-item-input" }}
                                                InputLabelProps={{ className: "education-item-label" }}
                                                disabled={checkLevel()}
                                                label={translate.profile.faculty} variant="outlined" value={currentEducation.faculty}
                                                onChange={(e) => textChange(e.target.value, 'faculty', index)} />
                                            {
                                                (isSaving && !currentEducation.faculty && !checkLevel()) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }
                                        </div>
                                        <div className="education-item">
                                            <TextField inputProps={{ className: "education-item-input" }}
                                                InputLabelProps={{ className: "education-item-label" }}
                                                disabled={checkLevel()}
                                                label={translate.profile.field_of_study} variant="outlined" value={currentEducation.fieldOfStudy}
                                                onChange={(e) => textChange(e.target.value, 'fieldOfStudy', index)} />
                                            {
                                                (isSaving && !currentEducation.fieldOfStudy && !checkLevel()) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }
                                        </div>

                                        <div className="education-item">
                                            <TextField inputProps={{ className: "education-item-input" }}
                                                InputLabelProps={{ className: "education-item-label" }}
                                                type="number"
                                                label={translate.profile.gpa} variant="outlined" value={currentEducation.gpax}
                                                onChange={(e) => textChange(e.target.value, 'gpax', index)} />
                                            {
                                                (isSaving && !currentEducation.gpax) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }{
                                                errorGPA &&
                                                <span className="education-item-text-error">{translate.error.gpa}</span>
                                            }
                                        </div>
                                        <div className="education-item"></div>
                                        <div className="education-item">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label" className="education-item-label" >{translate.profile.start_date}</InputLabel>
                                                <Select
                                                    className="education-item-input-select"
                                                    inputProps={{ className: "education-item-input-select" }}
                                                    value={currentEducation.startYear}
                                                    label={translate.profile.start_date}
                                                    onChange={(e) => textChange(e.target.value, 'startYear', index)}
                                                >
                                                    {
                                                        yearEducation && yearEducation?.map((item2: SelectItemModal, index2: number) => {
                                                            return (
                                                                item2.value == item.startYear ?
                                                                    <MenuItem value={item2.value} className="education-menu-item" key={index2} selected>{item2.name}</MenuItem> :
                                                                    <MenuItem value={item2.value} className="education-menu-item" key={index2}>{item2.name}</MenuItem>

                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            {
                                                (isSaving && !currentEducation.startYear) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }
                                            <div className="education-item-edit-button-control">

                                                {
                                                    item.educationLevel ? <Button className="education-item-edit-button-cancel" onClick={() => setIsEditing(false)}>{translate.other.cancel}</Button>
                                                        :
                                                        <Button className="education-item-edit-button-cancel" onClick={() => cancelAddEducation(editIndex)}>{translate.other.cancel}</Button>
                                                }
                                                <Button className="education-item-edit-button" onClick={() => saveValue(index)}>{translate.other.save}</Button>
                                            </div>

                                        </div>
                                        <div className="education-item">
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label" className="education-item-label" >{translate.profile.end_year}</InputLabel>
                                                <Select
                                                    className="education-item-input-select"
                                                    inputProps={{ className: "education-item-input-select" }}
                                                    value={currentEducation.graduationYear}
                                                    label={translate.profile.end_year}
                                                    onChange={(e) => textChange(e.target.value, 'graduationYear', index)}
                                                >
                                                    {
                                                        endYearEducation && endYearEducation?.map((item2: SelectItemModal, index2: number) => {
                                                            return (
                                                                item2.value == item.startYear ?
                                                                    <MenuItem value={item2.value} className="education-menu-item" key={index2} selected>{item2.name}</MenuItem> :
                                                                    <MenuItem value={item2.value} className="education-menu-item" key={index2}>{item2.name}</MenuItem>

                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                            {
                                                (isSaving && !currentEducation.graduationYear) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }
                                        </div>

                                    </div>
                                    :
                                    <div className="education-grid" key={index}>
                                        <div className="education-item">
                                            <span className="education-item-title">{translate.profile.education_level}</span>
                                            {
                                                EDUCATION_LEVEL_JSON.map((item2: SelectItemModal, index2: number) => {
                                                    return (
                                                        item2.value == item.educationLevel &&
                                                        <span className="education-item-desc" key={index2}>{item2.name}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="education-item">
                                            <span className="education-item-title">{translate.profile.school}</span>
                                            <span className="education-item-desc">{item.institute ? item.institute : "ไม่ระบุ"}</span>
                                        </div>
                                        <div className="education-item">
                                            <span className="education-item-title">{translate.profile.faculty}</span>
                                            <span className="education-item-desc">{item.faculty ? item.faculty : "ไม่ระบุ"}</span>
                                        </div>
                                        <div className="education-item">
                                            <span className="education-item-title">{translate.profile.field_of_study}</span>
                                            <span className="education-item-desc">{item.fieldOfStudy ? item.fieldOfStudy : "ไม่ระบุ"}</span>
                                        </div>
                                        <div className="education-item">
                                            <span className="education-item-title">{translate.profile.gpa}</span>
                                            <span className="education-item-desc">{item.gpax ? item.gpax : "ไม่ระบุ"}</span>
                                        </div>
                                        <div className="education-item"></div>
                                        <div className="education-item">
                                            <span className="education-item-title">{translate.profile.start_date}</span>
                                            <span className="education-item-desc">{item.startYear ? item.startYear : "ไม่ระบุ"}</span>
                                        </div>
                                        <div className="education-item">
                                            <span className="education-item-title">{translate.profile.end_year}</span>
                                            <span className="education-item-desc">{item.graduationYear ? item.graduationYear : "ไม่ระบุ"}</span>
                                        </div>
                                        <div className="education-item-edit-button-control">
                                            <Button className="education-item-edit-button" onClick={() => clickEditEducation(item, index)}>{translate.other.edit}</Button>
                                            <Button className="education-item-edit-button-cancel" onClick={() => deleteItem(index)}>{translate.other.delete}</Button>
                                        </div>
                                    </div>
                            }

                            </>
                        )
                    })
                }



            </div>
        </>
    )
}

export default EducationComponent;