import { Modal, ModalBody } from "react-bootstrap"
import "./loading.scss";
import { CircularProgress } from "@mui/material";


const LoadingModal = ({open, onclose} : any) => {



    return (

        <Modal show={open} onHide={onclose(false)} backdrop='static'>
            <ModalBody className="loading-modal-control">

                <CircularProgress className="loading-modal-progress"></CircularProgress>

                <span className="loading-modal-text">กำลังโหลด</span>


            </ModalBody>

        </Modal>
    )
}

export default LoadingModal;