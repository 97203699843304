import "./login.scss";

import { Button } from "react-bootstrap";
import AuthFunction from "../../services/sso/auth.function";
import config from "../../config";
import tokenService from "../../services/sso/token.service";
import RamaService from "../../services/sso/rama.service";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import navigationService from "../../services/navigation.service";
import navigateConfig from "../../navigate.config";
import { useProfile } from "../../context/profile.context";
import featureConfig from "../../feature.config";
const LoginComponent = () => {

    const navigate = useNavigate();
    const [profile, error] = useProfile();


    const handleCloseDropModal = () => {
        navigate(navigateConfig.guest.drop_resume);
    }

    const authenticateUrl = () => {
        const codeVerifier = AuthFunction().randomString(56);
        const codeChallenge = AuthFunction().PKCEencoding(codeVerifier);
        const state = AuthFunction().randomString(config.authen.length);
        let path = "/profile";
        if (navigationService.getPath()) {
            path = navigationService.getPath();
        }
        const urlReturn = config.authen.sso_url + "?" + new URLSearchParams({
            redirect_uri: config.authen.redirect_uri + "?" + new URLSearchParams({
                page: path
            }),
            state: state,
            code_challenge: codeChallenge,
            response_type: 'code',
            code_challenge_method: 'S256',
            client_id: config.authen.client_id
        })
        tokenService.setVerifyCode(codeVerifier)
        window.open(urlReturn, "_self");
    }

    const clickStart = () => {

        if (profile) {
            tokenService.setProfile(profile);
            navigate('/profile');
        }else{
            authenticateUrl()
        }
    }

    return (
        <>
            <div className="login-main">
                <div className="login-main-control">
                    <img src={config.url + featureConfig.logo_image.main} className="login-main-logo"></img>
                    <div className="login-choose-control">
                        <div className="login-choose-border-control" onClick={clickStart}>
                            <span className="login-choose-title">เข้าใช้งานผ่าน</span>
                            <img src={config.url + featureConfig.logo_image.main} className="login-logo-choose"></img>
                            <div className="login-choose-list-control">
                                <FontAwesomeIcon icon={faCircleCheck} className="login-choose-list-icon" />
                                <span className="login-choose-list-title">สร้าง URL Public Profile ให้ผู้ว่าจ้างเห็นอย่างง่ายๆ</span>
                                <FontAwesomeIcon icon={faCircleCheck} className="login-choose-list-icon" />
                                <span className="login-choose-list-title">ระบบตรวจสอบ Resume</span>
                                <FontAwesomeIcon icon={faCircleCheck} className="login-choose-list-icon" />
                                <span className="login-choose-list-title">ระบบจำลองการสัมภาษณ์งานผ่าน AI</span>
                                <FontAwesomeIcon icon={faCircleCheck} className="login-choose-list-icon" />
                                <span className="login-choose-list-title">สร้าง Resume ฟรี</span>
                                <FontAwesomeIcon icon={faCircleCheck} className="login-choose-list-icon" />
                                <span className="login-choose-list-title">ระบบติดตามการสมัครงาน</span>

                            </div>
                            <span className="login-choose-click-title">(คลิก เพื่อเริ่มใช้งาน..)</span>

                        </div>
                        <div className="login-choose-border-control" onClick={handleCloseDropModal}>
                            <span className="login-choose-title">ฝากประวัติทันที</span>
                            <span className="login-choose-title-logo">ไม่ต้อง Login</span>
                            <div className="login-choose-list-control">
                                <FontAwesomeIcon icon={faCircleCheck} className="login-choose-list-icon" />
                                <span className="login-choose-list-title">ส่งข้อมูลไปยังผู้ว่าจ้างอย่างรวดเร็ว</span>
                                <FontAwesomeIcon icon={faCircleXmark} className="login-choose-list-icon-red" />
                                <span className="login-choose-list-title">สร้าง URL Public Profile ให้ผู้ว่าจ้างเห็นอย่างง่ายๆ</span>
                                <FontAwesomeIcon icon={faCircleXmark} className="login-choose-list-icon-red" />
                                <span className="login-choose-list-title">ระบบตรวจสอบ Resume</span>
                                <FontAwesomeIcon icon={faCircleXmark} className="login-choose-list-icon-red" />
                                <span className="login-choose-list-title">ระบบจำลองการสัมภาษณ์งานผ่าน AI</span>
                                <FontAwesomeIcon icon={faCircleXmark} className="login-choose-list-icon-red" />
                                <span className="login-choose-list-title">สร้าง Resume ฟรี</span>
                                <FontAwesomeIcon icon={faCircleXmark} className="login-choose-list-icon-red" />
                                <span className="login-choose-list-title">ระบบติดตามการสมัครงาน</span>
                            </div>
                            <span className="login-choose-click-title">(คลิก เพื่อเริ่มใช้งาน..)</span>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default LoginComponent;