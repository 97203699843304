import { Button, Modal } from "react-bootstrap"
import "./file-limit-modal.scss";
import { useContext, useState } from "react";
import { ProvideContext } from "../context/provider.context";
import { TranslateModel } from "../model/translate.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const FileLimitModal = ({ open, handleClose, fileError, limitSize }: any) => {

    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    return (

        <>
            <Modal show={open} onHide={handleClose} backdrop='static' keyboard={false} className="file-limit-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="file-limit-content">
                    <div className="file-limit-container">
                        <FontAwesomeIcon icon={faCircleExclamation} className="file-limit-icon"></FontAwesomeIcon>
                        <span className="file-limit-header">
                            {
                                fileError.name == "FileTypeError" ? translate.error.file_not_support :

                                    translate.error.file_limit + limitSize + " MB"
                            }
                        </span>

                        <Button className="file-limit-button" onClick={handleClose}>{translate.other.close}</Button>


                    </div>



                </Modal.Body>
            </Modal>
        </>
    )
}

export default FileLimitModal;