import { faBrain, faBriefcase, faFile, faFileLines, faGear, faMagnifyingGlass, faPeopleArrows, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons"
import navigateConfig from "../../navigate.config"

import GroupMenuModel, { GroupMenuItemModel } from "../../model/menu/menu.model";
import featureConfig from "../../feature.config";
import { faFileCode, faMoneyBill1 } from "@fortawesome/free-regular-svg-icons";


export class MenuItemService {
    MenuItem(translate) {
        const Menuitem: GroupMenuModel[] = [
            {
                group_id: 1,
                group_image: faUser,
                isOpen: featureConfig.menu.profile,
                path: navigateConfig.profile.main,
                group: translate.menu.profile,
                list_menu: [
                ]
            },
            {
                group_id: 2,
                group_image: faFileLines,
                isOpen: featureConfig.menu.my_resume,
                path: navigateConfig.resume.main,
                group: translate.menu.resumes,
                list_menu: [
                ]
            },
            {
                group_id: 3,
                group_image: faFileCode,
                isOpen: featureConfig.menu.resume_checker,
                path: navigateConfig.resume.checker,
                group: translate.menu.check_resume,
                list_menu: [
                ]
            },
            {
                group_id: 4,
                group_image: faBriefcase,
                isOpen: featureConfig.menu.jobs,
                path: navigateConfig.jobs.main,
                group: translate.menu.my_job,
                list_menu: [
                  
                ]
            },
            {
                group_id: 5,
                path: '/salary-checker',
                group_image: faMoneyBill1,
                isOpen: featureConfig.menu.salary_checker,
                group: translate.menu.check_salary,
                list_menu: [
                ]
            },
            {
                group_id: 6,
                path: '/interview-ai',
                group_image: faPeopleArrows,
                isOpen: featureConfig.menu.interview_ai,
                group: translate.menu.interview_ai,
                list_menu: [
                ]
            }
            ,
            {
                group_id: 7,
                path: navigateConfig.setting.main,
                group_image: faGear,
                isOpen: featureConfig.menu.settings,
                group: translate.menu.setting,
                list_menu: [
                ]
            },
            {
                group_id: 8,
                path: navigateConfig.login.logout,
                group_image: faRightFromBracket,
                isOpen: true,
                group: translate.menu.logout,
                list_menu: [
                ]
            }
        ]
        return new Promise((resolve, reject) => {
            // Simulate an asynchronous operation
            setTimeout(() => {

                resolve(Menuitem);
            }, 1000); // Delay to simulate async behavior
        });
    }

    MenuItemGuest(translate) {
        const MenuitemGuest: GroupMenuModel[] = [
            {
                group_id: 90,
                group_image: faUser,
                isOpen: featureConfig.menu.drop_resume,
                path: navigateConfig.guest.drop_resume,
                group: translate.menu.guest.drop_resume,
                list_menu: [
                ]
            },
            {
                group_id: 91,
                group_image: faUser,
                isOpen: featureConfig.menu.profile,
                path: navigateConfig.guest.profile,
                group: translate.menu.profile,
                list_menu: [
                ]
            },
            {
                group_id: 92,
                group_image: faFileLines,
                isOpen: featureConfig.menu.my_resume,
                path: navigateConfig.guest.my_resume,
                group: translate.menu.resumes,
                list_menu: [
                ]
            },
            {
                group_id: 93,
                group_image: faFileCode,
                isOpen: featureConfig.menu.resume_checker,
                path: navigateConfig.guest.resume_checker,
                group: translate.menu.check_resume,
                list_menu: [
                ]
            },
            {
                group_id: 94,
                group_image: faMoneyBill1,
                isOpen: featureConfig.menu.salary_checker,
                path: navigateConfig.guest.salary_checker,
                group: translate.menu.check_salary,
                list_menu: [
                ]
            },
            {
                group_id: 95,
                group_image: faPeopleArrows,
                isOpen: featureConfig.menu.interview_ai,
                path: navigateConfig.guest.interview_ai,
                group: translate.menu.interview_ai,
                list_menu: [
                ]
            }
            ,
            {
                group_id: 96,
                path: navigateConfig.login.main,
                group_image: faRightFromBracket,
                isOpen: true,
                group: translate.menu.login,
                list_menu: [
                ]
            }
        ]
        return new Promise((resolve, reject) => {
            // Simulate an asynchronous operation
            setTimeout(() => {
                resolve(MenuitemGuest);
            }, 1000); // Delay to simulate async behavior
        });
    }
}