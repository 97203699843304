import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Activities, Certificate, Education, Experience, File, Skill } from "../../../../model/profile.model";
import EditProfileModal from "../../../../modal/edit-profile.modal";
import EDUCATION_LEVEL_JSON from "../../../../assets/json/education-level.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faEdit, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import SKILL_IMG from "../../../../assets/images/profile/skill.png";
import { ApplicationInputModel } from "../../../../model/jobs/application-form.model";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import { ProfileApplyModel } from "../../../../model/jobs/profile-apply.model";
import RamaService from "../../../../services/sso/rama.service";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import ConfirmUpdateProfileModal from "../../../../modal/confirm-update-profile";
import { Divider } from "@mui/material";
const ExperienceApply = ({ profileApplyMod, setProfileApplyMod,
    inputExperienceModel, setInputExperienceModel,
    isExperienceEdit, setIsExperienceEdit, isBigSubmit }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [isEditProfile, setIsEditProfile] = useState("");
    const [isNew, setIsNew] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isConfirmUpdate, setIsConfirmUpdate] = useState(false);
    useEffect(() => {
        if (isBigSubmit) {
            setIsSubmit(true);
        }

    }, [isBigSubmit])
    useEffect(() => {

        if (profileApplyMod) {

            if (inputExperienceModel) {
                const inputMod: ApplicationInputModel[] = [...inputExperienceModel];
                const profileMod: ProfileApplyModel = profileApplyMod;
                inputExperienceModel.map((item: ApplicationInputModel, index: number) => {

                    if (item.key == "experiences") {
                        inputMod[index].value = profileMod.experiences;
                    } else if (item.key == "activities") {
                        inputMod[index].value = profileMod.activities;
                    } else if (item.key == "skills") {
                        inputMod[index].value = profileMod.skills;
                    } else if (item.key == "certificates") {
                        inputMod[index].value = profileMod.certificates;
                    } else if (item.key == "educations") {
                        inputMod[index].value = profileMod.educations;
                    }
                })
                setInputExperienceModel(inputMod);
            }

        }
    }, [profileApplyMod])
    const handleExperienceEdit = () => {
        if (isExperienceEdit) {
            setIsExperienceEdit(false)
        } else {
            setIsExperienceEdit(true)
        }

    }
    const handleCloseUpdate = () => {

        setIsConfirmUpdate(false);
    }
    function calculateExperience(startDate: Date, endDate: Date): string {
        let years = endDate.getFullYear() - startDate.getFullYear();
        let months = endDate.getMonth() - startDate.getMonth();
        let days = endDate.getDate() - startDate.getDate();

        if (days < 0) {
            months -= 1;
            days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        let yearText = years > 0 ? `${years} ปี${years > 1 ? '' : ''}` : '';
        let monthText = months > 0 ? `${months} เดือน${months > 1 ? '' : ''}` : '';
        let dayText = days > 0 ? `${days} วัน${days > 1 ? '' : ''}` : '';

        return [yearText, monthText, dayText].filter((text) => text).join(', ');
    }
    const addNew = (action) => {
        if (action == 'education') {
            setIsNew(true);
            setShowEditProfileModal(true);
            setIsEditProfile("education");
        } else if (action == 'contact') {
            setShowEditProfileModal(true);
            setIsEditProfile("education");
        } else if (action == 'activities') {
            setShowEditProfileModal(true);
            setIsEditProfile("activities");
        } else if (action == 'certificates') {
            setShowEditProfileModal(true);
            setIsEditProfile("certificates");
        } else if (action == 'candidateFiles') {
            setShowEditProfileModal(true);
            setIsEditProfile("candidateFiles");
        }

        else {
            setIsNew(true);
            setShowEditProfileModal(true);
            setIsEditProfile("experience");
        }
    }
    const editProfile = (action) => {
        if (action == 'education') {
            setShowEditProfileModal(true);
            setIsEditProfile("education");
        } else if (action == 'experience') {
            setShowEditProfileModal(true);
            setIsEditProfile("experience");
        }
        else if (action == 'skills') {
            setShowEditProfileModal(true);
            setIsEditProfile("skills");
        }
        else if (action == 'certificates') {
            setShowEditProfileModal(true);
            setIsEditProfile("certificates");
        }
        else if (action == 'activities') {
            setShowEditProfileModal(true);
            setIsEditProfile("activities");
        }

    }
    const checkLevel = (level) => {
        const value = level;
        if (value == 'BACHELOR' || value == 'MASTER' || value == 'DOCTORATE' || value == 'NO_EDUCATION') {
            return false;
        } else {
            return true;
        }
    }
    const initEducationLevel = (educationlv) => {
        const filteredData = EDUCATION_LEVEL_JSON.filter(item => item.value == educationlv);
        if (filteredData.length > 0) {
            // The item with the specified id exists in the filtered array
            const foundItem = filteredData[0];
            return foundItem.name;
        } else {
            // The item with the specified id does not exist
            return "-"
        }
    }


    const saveExperience = (isUpdate) => {
        setIsSubmit(true);
        if (profileApplyMod) {
            let isChecking = true;
            if (inputExperienceModel.filter(item => item.status == "MANDATORY" && item.value == "").length == 0) {
                inputExperienceModel.map((item: ApplicationInputModel, index: number) => {
                    profileApplyMod[item.key] = item.value;
                })
                setProfileApplyMod(profileApplyMod);
            } else {
                isChecking = false;
            }

            if (isUpdate) {
                RamaService.updateProfile(profileApplyMod).then((res1) => {

                    if (res1.data) {
                        ProfileInstantService.updateProfilePublic(profileApplyMod).then((res) => {
                            if (res.data) {
                                setIsExperienceEdit(false);
                                handleCloseUpdate();
                            }
                        })
                    }
                })


            } else {
                setIsExperienceEdit(false);
                handleCloseUpdate();
            }

        }

    }
    return (

        <>
            <div className="jobs-apply-main-item-control">
                <div className="jobs-apply-main-item-step-control" onClick={handleExperienceEdit}>
                    <div className="jobs-apply-main-item-step">
                        <span className="jobs-apply-main-item-step-text">2</span>
                    </div>
                    <span className="jobs-apply-main-item-step-header">{translate.job_apply.experiences}</span>
                    <div className="jobs-apply-main-item-step-button-control">

                        <FontAwesomeIcon icon={isExperienceEdit ? faChevronUp : faChevronDown} className="jobs-apply-main-item-step-button"></FontAwesomeIcon>

                    </div>

                </div>
                <div className="jobs-apply-main-item-expand-control">
                    {
                        isExperienceEdit &&
                        <>
                            <div className="jobs-apply-main-item-expand-divider"></div>

                            {
                                inputExperienceModel && inputExperienceModel.map((item: ApplicationInputModel, index: number) => {

                                    return (
                                        item.key == "experiences" ?
                                            <div className={
                                                isSubmit && item.status == "MANDATORY" && item.value.length == 0 ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                            } key={index}>
                                                <div className="d-flex">
                                                    <div className="jobs-apply-section-header-control">
                                                        <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.profile.experience} </span></Divider>

                                                    </div>
                                                    {

                                                        item.value && item.value.length > 0 &&
                                                        <div className="my-info-item-header-button-control" onClick={() => editProfile('experience')}>
                                                            <FontAwesomeIcon icon={faEdit} className="my-info-item-header-button-icon"></FontAwesomeIcon>
                                                            <span className="my-info-item-header-button">{translate.other.edit}</span>
                                                        </div>
                                                    }

                                                </div>

                                                {
                                                    item.value && item.value.length > 0 ?
                                                        item.value.map((item: Experience, index: number) => {

                                                            return (
                                                                <div className="my-info-timeline-control" key={index}>
                                                                    <div className="my-info-timeline-line-control">
                                                                        <div className="my-info-timeline-dot"></div>
                                                                        {
                                                                            index != profileApplyMod.experiences.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                        }

                                                                    </div>
                                                                    <div className="my-info-timeline-desc-control">
                                                                        <span className="my-info-timeline-desc-header">{item.jobTitle} ที่ {item.companyName}</span>
                                                                        <div className="my-info-timeline-desc-info-control">
                                                                            <span className="my-info-timeline-desc-info-title">{translate.profile.start_date}: {new Date(item.startDate).toLocaleDateString()} - {
                                                                                item.stillWorkHere ? "ปัจจุบัน" : item.endDate && new Date(item.endDate).toLocaleDateString()
                                                                            }</span>

                                                                            <span className="my-info-timeline-desc-info-title">{translate.profile.exp_total}: {
                                                                                item.stillWorkHere ?
                                                                                    calculateExperience(new Date(item.startDate), new Date()) :
                                                                                    item.endDate &&
                                                                                    calculateExperience(new Date(item.startDate), new Date(item.endDate))
                                                                            }</span>
                                                                            <span className="my-info-timeline-desc-info-title">{translate.profile.salary}: {
                                                                                item.salary.toLocaleString() + " บาท"
                                                                            }</span>
                                                                            <span className="my-info-timeline-desc-info-title">{translate.profile.job_title}: {
                                                                                item.employmentType == "FULL_TIME" ? "งานประจำ" :
                                                                                    item.employmentType == "PART_TIME" ? "งาน part time" : "อื่นๆ"
                                                                            }</span>
                                                                            <span className="my-info-timeline-desc-info-title">{translate.profile.responsibility}: {item.responsibilities}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : <div className="no-info-control">
                                                            <div className="no-info-control-flex">
                                                                <span className="no-info-control-text">{translate.error.no_information}</span>
                                                                <Button className="no-info-control-add-button" onClick={() => addNew('experience')}>
                                                                    {translate.other.add}{translate.profile.experience}</Button>
                                                            </div>
                                                        </div>
                                                }


                                            </div> :
                                            item.key == "educations" ?
                                                <div className={
                                                    isSubmit && item.status == "MANDATORY" && item.value.length == 0 ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                                } key={index}>
                                                    <div className="d-flex">
                                                        <div className="jobs-apply-section-header-control">
                                                            <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.profile.education} </span></Divider>

                                                        </div>
                                                        {

                                                            item.value && item.value.length > 0 &&
                                                            <div className="my-info-item-header-button-control" onClick={() => editProfile('education')}>
                                                                <FontAwesomeIcon icon={faEdit} className="my-info-item-header-button-icon"></FontAwesomeIcon>
                                                                <span className="my-info-item-header-button">{translate.other.edit}</span>
                                                            </div>
                                                        }

                                                    </div>
                                                    {
                                                        profileApplyMod.educations.length > 0 ?
                                                            profileApplyMod.educations.map((item: Education, index: number) => {

                                                                return (
                                                                    <div className="my-info-timeline-control" key={index}>
                                                                        <div className="my-info-timeline-line-control">
                                                                            <div className="my-info-timeline-dot"></div>
                                                                            {
                                                                                index != profileApplyMod.educations.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                            }

                                                                        </div>
                                                                        <div className="my-info-timeline-desc-control">
                                                                            <span className="my-info-timeline-desc-header">
                                                                                {
                                                                                    checkLevel(item.educationLevel) ?
                                                                                        <>{initEducationLevel(item.educationLevel)}  {translate.profile.text_at}  {item.institute} </>
                                                                                        : <>{item.faculty},{item.fieldOfStudy} {translate.profile.text_at} {item.institute} </>
                                                                                }
                                                                            </span>
                                                                            <div className="my-info-timeline-desc-info-control">
                                                                                <span className="my-info-timeline-desc-info-title">{translate.profile.education_level}:
                                                                                    <span className="my-info-timeline-desc-info-desc">{initEducationLevel(item.educationLevel)}</span></span>
                                                                                <span className="my-info-timeline-desc-info-title">{translate.profile.gpa}:
                                                                                    <span className="my-info-timeline-desc-info-desc">{item.gpax ? item.gpax : "-"}</span></span>
                                                                                <span className="my-info-timeline-desc-info-title">{translate.profile.start_date}:
                                                                                    <span className="my-info-timeline-desc-info-desc">{item.startYear ? item.startYear : "-"}</span></span>
                                                                                <span className="my-info-timeline-desc-info-title">{translate.profile.end_year}:
                                                                                    <span className="my-info-timeline-desc-info-desc">{item.graduationYear ? item.graduationYear : "-"}</span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : <div className="no-info-control">
                                                                <div className="no-info-control-flex">
                                                                    <span className="no-info-control-text">{translate.error.no_information}</span>
                                                                    <Button className="no-info-control-add-button" onClick={() => addNew('education')}>
                                                                        {translate.other.add}{translate.profile.education}</Button>
                                                                </div>
                                                            </div>
                                                    }

                                                </div> :
                                                item.key == "activities" ?
                                                    <div className={
                                                        isSubmit && item.status == "MANDATORY" && item.value.length == 0 ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                                    } key={index}>
                                                        <div className="d-flex">
                                                            <div className="jobs-apply-section-header-control">
                                                                <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.profile.activiy_interest} </span></Divider>

                                                            </div>
                                                            {

                                                                item.value && item.value.length > 0 &&
                                                                <div className="my-info-item-header-button-control" onClick={() => editProfile('activities')}>
                                                                    <FontAwesomeIcon icon={faEdit} className="my-info-item-header-button-icon"></FontAwesomeIcon>
                                                                    <span className="my-info-item-header-button">{translate.other.edit}</span>
                                                                </div>
                                                            }

                                                        </div>

                                                        {
                                                            item.value && item.value.length > 0 ?
                                                                item.value.map((item: Activities, index: number) => {

                                                                    return (
                                                                        <div className="my-info-timeline-control" key={index}>
                                                                            <div className="my-info-timeline-line-control">
                                                                                <div className="my-info-timeline-dot"></div>
                                                                                {
                                                                                    index != profileApplyMod.activities.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                                }

                                                                            </div>
                                                                            <div className="my-info-timeline-desc-control">
                                                                                <span className="my-info-timeline-desc-header">{item.role} {translate.profile.text_at} {item.organizationName}</span>
                                                                                <div className="my-info-timeline-desc-info-control">
                                                                                    <span className="my-info-timeline-desc-info-title">{translate.activity_type.start_date}:

                                                                                        <span className="my-info-timeline-desc-info-desc">{new Date(item.startDate).toLocaleDateString()} - {
                                                                                            item.isOnGoing ? "ปัจจุบัน" : item.endDate && new Date(item.endDate).toLocaleDateString()
                                                                                        }</span></span>

                                                                                    <span className="my-info-timeline-desc-info-title">{translate.profile.exp_total}:
                                                                                        <span className="my-info-timeline-desc-info-desc">{
                                                                                            item.isOnGoing ?
                                                                                                calculateExperience(new Date(item.startDate), new Date()) :
                                                                                                item.endDate &&
                                                                                                calculateExperience(new Date(item.startDate), new Date(item.endDate))
                                                                                        }</span></span>

                                                                                    <span className="my-info-timeline-desc-info-title">{translate.activity_type.responsibility}:
                                                                                        <span className="my-info-timeline-desc-info-desc">{item.summary}</span></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }) : <div className="no-info-control">
                                                                    <div className="no-info-control-flex">
                                                                        <span className="no-info-control-text">{translate.error.no_information}</span>
                                                                        <Button className="no-info-control-add-button" onClick={() => addNew('activities')}>
                                                                            {translate.other.add}{translate.profile.activiy_interest}</Button>
                                                                    </div>
                                                                </div>
                                                        }


                                                    </div> :

                                                    item.key == "certificates" ?
                                                        <div className={
                                                            isSubmit && item.status == "MANDATORY" && item.value.length == 0 ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                                        } key={index}>
                                                            <div className="d-flex">
                                                                <div className="jobs-apply-section-header-control">
                                                                    <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.profile.certificate} </span></Divider>

                                                                </div>
                                                                {

                                                                    item.value && item.value.length > 0 &&
                                                                    <div className="my-info-item-header-button-control" onClick={() => editProfile('certificates')}>
                                                                        <FontAwesomeIcon icon={faEdit} className="my-info-item-header-button-icon"></FontAwesomeIcon>
                                                                        <span className="my-info-item-header-button">{translate.other.edit}</span>
                                                                    </div>
                                                                }

                                                            </div>

                                                            {
                                                                item.value && item.value.length > 0 ?
                                                                    item.value.map((item: Certificate, index: number) => {

                                                                        return (
                                                                            <div className="my-info-timeline-control" key={index}>
                                                                                <div className="my-info-timeline-line-control">
                                                                                    <div className="my-info-timeline-dot"></div>
                                                                                    {
                                                                                        index != profileApplyMod.certificates.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                                    }

                                                                                </div>
                                                                                <div className="my-info-timeline-desc-control">
                                                                                    <span className="my-info-timeline-desc-header">{translate.profile.cert_name}: <span className="my-info-timeline-desc-info-desc">{item.certificateName}</span></span>
                                                                                    <div className="my-info-timeline-desc-info-control">
                                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.cert_desc}:
                                                                                            <span className="my-info-timeline-desc-info-desc">{item.certificateDetail}</span></span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) : <div className="no-info-control">
                                                                        <div className="no-info-control-flex">
                                                                            <span className="no-info-control-text">{translate.error.no_information}</span>
                                                                            <Button className="no-info-control-add-button" onClick={() => addNew('certificates')}>
                                                                                {translate.other.add}{translate.profile.skill}</Button>
                                                                        </div>
                                                                    </div>
                                                            }


                                                        </div> :
                                                        item.key == "candidateFiles" ?
                                                            <div className={
                                                                isSubmit && item.status == "MANDATORY" && item.value.length == 0 ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                                            } key={index}>

                                                                <div className="d-flex">
                                                                    <div className="jobs-apply-section-header-control">
                                                                        <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.profile.file} </span></Divider>

                                                                    </div>
                                                                    {

                                                                        item.value && item.value.length > 0 &&
                                                                        <div className="my-info-item-header-button-control" onClick={() => editProfile('candidateFiles')}>
                                                                            <FontAwesomeIcon icon={faEdit} className="my-info-item-header-button-icon"></FontAwesomeIcon>
                                                                            <span className="my-info-item-header-button">{translate.other.edit}</span>
                                                                        </div>
                                                                    }

                                                                </div>
                                                                {
                                                                    item.value && item.value.length > 0 ?
                                                                        item.value.map((item: File, index: number) => {

                                                                            return (
                                                                                <div className="my-info-timeline-control" key={index}>
                                                                                    <div className="my-info-timeline-line-control">
                                                                                        <div className="my-info-timeline-dot"></div>
                                                                                        {
                                                                                            index != profileApplyMod.candidateFiles.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                                        }

                                                                                    </div>
                                                                                    <div className="my-info-timeline-desc-control">
                                                                                        <span className="my-info-timeline-desc-header">{translate.profile.file_name}:
                                                                                            <span className="my-info-timeline-desc-info-desc">{item.fileName}</span></span>
                                                                                        <div className="my-info-timeline-desc-info-control">
                                                                                            <span className="my-info-timeline-desc-info-title">{translate.job_apply.detail}:
                                                                                                <span className="my-info-timeline-desc-info-desc">{item.fileSource}</span></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) : <div className="no-info-control">
                                                                            <div className="no-info-control-flex">
                                                                                <span className="no-info-control-text">{translate.error.no_information}</span>
                                                                                <Button className="no-info-control-add-button" onClick={() => addNew('candidateFiles')}>
                                                                                    {translate.other.add}{translate.profile.certificate}</Button>
                                                                            </div>
                                                                        </div>
                                                                }


                                                            </div> :
                                                            <div className={
                                                                isSubmit && item.status == "MANDATORY" && item.value.length == 0 ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                                            } key={index}>

                                                                <div className="d-flex">
                                                                    <div className="jobs-apply-section-header-control">
                                                                        <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.profile.skill} </span></Divider>

                                                                    </div>
                                                                    {

                                                                        item.value && item.value.length > 0 &&
                                                                        <div className="my-info-item-header-button-control" onClick={() => editProfile('skills')}>
                                                                            <FontAwesomeIcon icon={faEdit} className="my-info-item-header-button-icon"></FontAwesomeIcon>
                                                                            <span className="my-info-item-header-button">{translate.other.edit}</span>
                                                                        </div>
                                                                    }

                                                                </div>
                                                                
                                                                <span className="my-info-item-desc">
                                                                    {
                                                                        profileApplyMod.skills && profileApplyMod.skills.length > 0 ?
                                                                            <><FontAwesomeIcon icon={faLightbulb} className="my-info-item-icon"></FontAwesomeIcon>
                                                                                <span className="my-info-item-header">{translate.profile.skill} :</span></> :
                                                                            <span className="my-info-item-no-info">{translate.error.no_information}</span>
                                                                    }

                                                                </span>
                                                                <span className="my-info-item-desc">
                                                                    {
                                                                        profileApplyMod.skills &&
                                                                        <ul>
                                                                            {profileApplyMod.skills.map((item: Skill, index: number) => {

                                                                                return (
                                                                                    <div className="my-info-timeline-desc-info-control" key={index}>
                                                                                        <li>
                                                                                            <span className="my-info-timeline-desc-info-desc">
                                                                                                {item.skillName}</span>
                                                                                        </li>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    }
                                                                </span>

                                                            </div>

                                    )
                                })
                            }



                            <Button className="jobs-apply-button-save" onClick={() => setIsConfirmUpdate(true)}>{translate.other.save}</Button>
                        </>
                    }

                </div>
            </div>
            {
                showEditProfileModal && <EditProfileModal open={showEditProfileModal} setOpen={setShowEditProfileModal} action={isEditProfile}
                    profile={profileApplyMod} setProfile={setProfileApplyMod} isNew={false} isApplicationForm={true}></EditProfileModal>
            }

            {
                isConfirmUpdate &&

                <ConfirmUpdateProfileModal open={isConfirmUpdate} handleClose={handleCloseUpdate} saveCandidateInput={saveExperience}></ConfirmUpdateProfileModal>
            }

        </>
    )
}

export default ExperienceApply;