import { Button, Modal, ModalBody } from "react-bootstrap";
import "./upload-resume.modal.scss";
import { useFilePicker } from "use-file-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTrash } from "@fortawesome/free-solid-svg-icons";
import ResumeService from "../../../services/resume.service";
import GPTService from "../../../services/gpt.service";
import { TranslateModel } from "../../../model/translate.model";
import { useContext, useState } from "react";
import { ProvideContext } from "../../../context/provider.context";
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from "use-file-picker/validators";
import config from "../../../config";
import FileLimitModal from "../../file-limit-modal";
import { FileErrorModel } from "../../../model/error-file-select.model";


const UploadResumeModal = ({ open, handleClose, setKey, pathName }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [isLimitError, setIsLimitError] = useState(false);
    const [fileError, setFileError] = useState<FileErrorModel>();
    const { openFilePicker, filesContent, loading, errors, clear } = useFilePicker({
        multiple: true,
        readAs: 'DataURL',
        accept: ['image/*', '.pdf'],
        limitFilesConfig: { min: 0, max: 1 },
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(['jpg', 'png', 'jpeg', 'pdf']),
            new FileSizeValidator({ maxFileSize: config.MAX_ATTACHMENT_SIZE * 1024 * 1024 })
        ],
        onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {

        },
        onFilesRejected: ({ errors }) => {
            console.log(errors);
            if (errors.length > 0) {
                const errorsResult = errors as FileErrorModel[];
                setIsLimitError(true);
                setFileError(errorsResult[0]);
            }
        }
    });


    const clearFile = () => {
        clear()
    }

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }

    const uploadResume = () => {

        if (filesContent.length > 0) {
            const file = DataURIToBlob(filesContent[0]["content"]);

            if (pathName == "one_profile") {
                ResumeService.uploadResumeOneProfile(file, filesContent[0].name).then((res) => {

                    if (res.data.resume_key) {
                        GPTService.addResumeWithResumeKey(filesContent[0].name, "99", res.data.resume_key).then((result) => {

                            if (result.data) {
                                setKey(filesContent[0].name,result.data.resume_key)
                                handleClose();

                            }
                        })
                    }

                })
            } else {
                ResumeService.uploadResumeBuilder(file, filesContent[0].name).then((res) => {

                    if (res.data.resume_key) {
                        GPTService.addResumeWithResumeKey(filesContent[0].name, "99", res.data.resume_key).then((result) => {

                            if (result.data) {
                                handleClose();
                                setKey(filesContent[0].name,res.data.resume_key)
                            }
                        })
                    }

                })
            }



        }

    }


    return (

        <>
            <Modal show={open} onHide={handleClose} className="upload-resume-modal-main">
                <Modal.Header className="upload-resume-modal-header" closeButton>
                    <div className="d-flex flex-column">
                        {translate.resume_builder.upload_your_resume}
                        <span className="upload-resume-modal-header-sub">{translate.error.file_limit} {config.MAX_ATTACHMENT_SIZE} MB</span>

                    </div>

                </Modal.Header>
                <ModalBody>
                    <div className="upload-resume-modal-control">
                        {
                            filesContent.length > 0 ?
                                <div className="upload-resume-modal-content-control">
                                    <div className="upload-resume-modal-selected-control">
                                        <FontAwesomeIcon icon={faFile} className="upload-resume-modal-selected-icon"></FontAwesomeIcon>
                                        <div className="upload-resume-modal-selected-filename">
                                            <span className="upload-resume-modal-desc">{filesContent[0].name}</span>
                                        </div>
                                        <FontAwesomeIcon icon={faTrash} onClick={clearFile}
                                            className="upload-resume-modal-selected-icon-delete"></FontAwesomeIcon>
                                    </div>

                                </div> :
                                <div className="upload-resume-modal-content-control">
                                    <span className="upload-resume-modal-desc">{translate.resume_builder.choose_file_title}</span>
                                    <span className="upload-resume-modal-header-sub text-center">{translate.error.file_support}</span>
                                    <Button onClick={openFilePicker} className="upload-resume-modal-button">{translate.resume_builder.choose_file}</Button>

                                </div>
                        }


                    </div>
                    <Button className={
                        filesContent.length > 0 ? "upload-resume-modal-upload-button" : "upload-resume-modal-upload-button upload-resume-modal-upload-button-disable"
                    }

                        onClick={uploadResume}

                    >อัปโหลด</Button>
                </ModalBody>
            </Modal>
            {
                isLimitError &&

                <FileLimitModal open={isLimitError} handleClose={() => setIsLimitError(false)} fileError={fileError}  limitSize={config.MAX_ATTACHMENT_SIZE}></FileLimitModal>
            }


        </>
    )
}

export default UploadResumeModal;