import "./not-found.scss";

import { useNavigate } from "react-router-dom";
import UtilityService from "../../utility/utility.service";
import config from "../../config";
import featureConfig from "../../feature.config";

const NotFoundComponent = () => {

    const navigate = useNavigate();

    const backtoHome = () => {
        UtilityService().clickSendEvent("not_found_back_to_home", "click")
        navigate('/');
    }

    return (

        <>
            <div className="not-found-main">
                <div className="not-found-main-control">
                    <img src={config.url + featureConfig.logo_image.main} className="not-found-main-logo"></img>

                    <span className="not-found-main-text">ไม่พบหน้าที่คุณต้องการ</span>
                    <span className="not-found-main-link" onClick={backtoHome}>กลับหน้าหลัก</span>

                </div>

            </div>
        </>
    )
}

export default NotFoundComponent;