import { useContext } from "react";
import apiPath from "../../api-path";
import ProfileInstantService from "../profile/profile-instance.service";
import instance from "./auth-context.service";
import { ProvideContext } from "../../context/provider.context";



const getProfile = () => {
  return instance.get(apiPath.rama_path.profile);
};
const getMeProfile = () => {
  return instance.get(apiPath.rama_path.me);
};
const getSetting = () => {
  return instance.get(apiPath.rama_path.setting);
};

const getProfileForApply = () => {
  return instance.get(apiPath.rama_path.profile_apply);
};
const getCareerRecommend = () => {
  return instance.get(apiPath.rama_path.career_recommend + "?" + new URLSearchParams({
    pageSize: "100", page: "0"
  }));
};
const getCareerRecommendsExclude = (id) => {
  return instance.get(apiPath.rama_path.career_recommend + "?" + new URLSearchParams({
    pageSize: "10", page: "0", excludeId: id
  }));
};
const getCareerDetail = (id) => {
  return instance.get(apiPath.rama_path.career + "/" + id);
};
const updateGoalCareer = (object) => {

  return instance.put(apiPath.rama_path.me, object, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

const uploadFile = (fileUpload, filename) => {
  const formData = new FormData();
  formData.append("files", fileUpload, filename);
  return instance.post(apiPath.rama_path.uploadFile, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const uploadProfileImage = (fileUpload) => {
  return instance.post(apiPath.rama_path.uploadProfileImage, {
    files: fileUpload
  }, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateProfile = async (profileModel) => {
  const result = await instance.put(apiPath.rama_path.profile, profileModel, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return result
};
const updateSkillDevelopment = (skillGroup) => {
  return instance.put(apiPath.rama_path.career_skill, skillGroup, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const updateSetting = (settingModel) => {
  return instance.put(apiPath.rama_path.setting, settingModel, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const createProfile = () => {
  return instance.post(apiPath.rama_path.create_users, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};


const RamaService = {
  getMeProfile,
  getProfile,
  getProfileForApply,
  uploadFile,
  updateProfile,
  createProfile,
  uploadProfileImage,
  getSetting,
  updateSetting,
  getCareerRecommend,
  updateGoalCareer,
  getCareerDetail,
  getCareerRecommendsExclude,
  updateSkillDevelopment
};

export default RamaService;