const feature_core = {
    logo_image: {
        main: "images/logo-main.png",
        white: "images/logo-white.png"
    },
    profile: {
        contact: true,
        resume: true,
        work_experience: true,
        education: true,
        certificate: true,
        activities: true,
        about: true,
        personality: true,
        interest_job: true,
        skill: true,
        upload_file: true
    },
    menu:{
        drop_resume: true,
        profile: true,
        my_resume: true,
        resume_checker:true,
        jobs: false,
        salary_checker: true,
        interview_ai: true,
        settings: true

    }
};


const feature_360 = {
    logo_image: {
        main: "360/images/logo-main.png",
        white: "360/images/logo-white.png"
    },
    profile: {
        contact: true,
        resume: true,
        work_experience: true,
        education: true,
        certificate: true,
        activities: true,
        about: true,
        personality: false,
        interest_job: false,
        skill: true,
        upload_file: true
    },
    menu:{
        drop_resume: true,
        profile: true,
        my_resume: true,
        resume_checker:true,
        jobs: true,
        salary_checker: true,
        interview_ai: true,
        settings: true
       

    }
    
};
const featureConfig =
    process.env.REACT_APP_ENVIRONMENT?.includes("360") ?  feature_360 
            : feature_core;

export default {
    ...featureConfig
};