
import { useContext, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './crop.modal.scss';
import { CircularProgress } from '@mui/material';
import { ProvideContext } from '../context/provider.context';
import { TranslateModel } from '../model/translate.model';
import Cropper from 'react-easy-crop'
import getCroppedImg from './crop-img';

const CropModal = ({ open, setOpen, src, setCropSuccess, uuid }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [uploading, setUploading] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, onZoomChange] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState<any>()
    const [rotation, setRotation] = useState(0)
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }
    const handleClose = () => {
        setOpen(false);
    }

    const showCroppedImage = async () => {
        try {
            if(croppedAreaPixels){
                const croppedImage = await getCroppedImg(
                    src,
                    croppedAreaPixels,
                    rotation
                  )
                
                  if(croppedImage){
                    setCroppedImage(croppedImage)
                    // setCropSuccess(croppedImage);
                    const response = await fetch(croppedImage);
                    const blob = await response.blob();
                    // Create a file from the blob
                    const file = new File([blob], uuid, { type: blob.type });
                
                    setCropSuccess(file);
                    setUploading(true);
                  }
                  
            }
         
        } catch (e) {
          console.error(e)
        }
      }
  
    return (
        <>
            <Modal show={open} onHide={handleClose} >

                <Modal.Body className="crop-modal">
                    {
                        !uploading ? <>
                            <Cropper
                                image={src}
                                crop={crop}
                                rotation={rotation}
                                zoom={zoom}
                                aspect={1}
                                cropShape="round"
                                showGrid={false}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={onZoomChange}
                               
                            />
                           
                            <div className='d-flex'>
                                <Button onClick={showCroppedImage} className='crop-modal-button-save'>{translate.other.save}</Button>
                            </div>
                        </> : <>
                            <div className='crop-uploading-control'>
                                <CircularProgress className='crop-uploading-progress'></CircularProgress>
                                <span className='crop-uploading-progress-text'>กำลังอัปโหลดรูปโปรไฟล์</span>
                            </div>
                        </>
                    }

                </Modal.Body>


            </Modal>

        </>
    )
}
export default CropModal;