import { useContext, useEffect, useState } from "react";
import "../education/education.scss";
import { Experience, ProfileModel } from "../../../../model/profile.model";
import SelectItemModal from "../../../../model/select-item.model";
import { Button } from "react-bootstrap";
import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RamaService from "../../../../services/sso/rama.service";
import dayjs from 'dayjs';
import CLOSE_IMG from "../../../../assets/images/close.png";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import JOB_ROLE_JSON from "../../../../assets/json/job-role-suggestion.json";
import { Typeahead } from "react-bootstrap-typeahead";
import { JobRole, JobRoleSuggestionModel } from "../../../../model/job-role-suggestion.model";
import moment from "moment";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import UtilityService from "../../../../utility/utility.service";
const ExperienceComponent = ({ profile, setProfile, isNew, setCloseModal, isApplicationForm }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [experienceLevel, setExperienceLevel] = useState<SelectItemModal>();
    const [currentExperience, setCurrentExperience] = useState<Experience>();
    const [editIndex, setEditIndex] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [yearExperience, setYearExperience] = useState<SelectItemModal[]>();
    const [isAddingNew, setAddingNew] = useState(false);
    const [flattenedRoles, setFlattenedRoles] = useState(new Array());
    const [errorDate, setErrorDate] = useState(false);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    useEffect(() => {
        getRoleOptions(JOB_ROLE_JSON);
        if (profileMod) {
            if(profileMod.experiences && profileMod.experiences.length > 0){
                setCurrentExperience(profileMod.experiences[0])
                initExperienceYear();
             
                console.log(profileMod);
                if (isNew) {
                    addEmptyExperience();
                } else {
                    if (profileMod.experiences[0].endDate) {
                        const converDayJS = dayjs(profileMod.experiences[0].endDate);
                        setEndDate(converDayJS);
                    }
    
                    if (profileMod.experiences[0].startDate) {
                        const converDayJS = dayjs(profileMod.experiences[0].startDate);
                        setStartDate(converDayJS);
                    }
                }
            }else{
                addEmptyExperience();
            }
          
        }

    }, [])
    const initExperienceLevel = (educationlv) => {
        let valueRet = "";
        translate.job_type.map((item: any, index: number) => {
            if (educationlv == item.name) {
                valueRet = item.value;
                setExperienceLevel(item)
            }
        })
    }

    const initExperienceYear = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const yearDataArray: SelectItemModal[] = [];

        for (let year = currentYear; year >= currentYear - 40; year--) {
            const yearData: SelectItemModal = {
                name: year + "",
                value: year + "",
            };
            yearDataArray.push(yearData);
        }
        setYearExperience(yearDataArray);
    }

    const addEmptyExperience = () => {
        let profileEdit = { ...profileMod };
        if (profileEdit.experiences) {
            const addExperience: Experience = {
                companyName: "",
                employmentType: "",
                jobTitle: "",
                responsibilities: "",
                salary: 0,
                salaryCurrency: "",
                startDate: new Date().toISOString(),
                stillWorkHere: false,
                endDate: new Date().toISOString()
            }
            profileEdit.experiences.unshift(addExperience);
            setProfileMod(profileEdit);
            setEditIndex(0);
            setIsSaving(false);
            setAddingNew(true);
            setIsEditing(true);
            setCurrentExperience(addExperience)
        }
    }
    const textChange = (text, key, index) => {
        const profileModEdit = profileMod
        const profileEdit = profileMod.educations[index];
        let currentEditChange = { ...currentExperience } as Experience;
        if (currentEditChange && currentExperience) {

            if (key == 'employmentType') {
                currentEditChange.employmentType = text
                initExperienceLevel(text);
            } else if (key == 'companyName') {
                currentEditChange.companyName = text
            } else if (key == 'jobTitle') {
                currentEditChange.jobTitle = text
            } else if (key == 'salary') {
                currentEditChange.salary = text
            } else if (key == 'responsibilities') {
                currentEditChange.responsibilities = text
            }


            if (currentExperience) {
                setCurrentExperience(currentEducation => ({
                    ...currentEditChange
                }));
            }

        }

    }

    const clickEditExperience = (item, index) => {
        setEditIndex(index);
        setIsEditing(true);
        const experienceCurrent = item as Experience;
        setCurrentExperience(experienceCurrent)
    }

    const deleteItem = (index) => {
        let profileEdit = { ...profileMod };
        if (isApplicationForm) {
            profileEdit.experiences.splice(index, 1);
            setIsEditing(false);
            setProfileMod(profileEdit);
            setProfile(profileEdit);
        } else if (profileEdit.experiences[index] && currentExperience) {
            profileEdit.experiences.splice(index, 1);
            RamaService.updateProfile(profileEdit).then((res) => {
                if(res.data){
                    UtilityService().clickSendEvent("delete_experience", "submit");
                    ProfileInstantService.updateProfilePublic(profileEdit);
                    setProfileMod(profileEdit);
                    setIsEditing(false);
                    setProfile(profileEdit);
                }
               
            })
        }
    }

    const cancelAddEducation = (index) => {
        let profileEdit = { ...profileMod };
        if (profileEdit.experiences) {
            profileEdit.experiences.splice(index, 1);
            setProfileMod(profileEdit)
            setEditIndex(9999)
            setIsEditing(false);
        }
    }

    const saveValue = (index) => {
        let profileEdit = { ...profileMod };
        setIsSaving(true);

        if (currentExperience && currentExperience.stillWorkHere) {
            if (currentExperience
                && currentExperience.companyName
                && currentExperience.employmentType
                && currentExperience.jobTitle
                && currentExperience.responsibilities
                && currentExperience.salary
                && currentExperience.startDate
            ) {

                if (isApplicationForm) {
                    UtilityService().clickSendEvent("save_experience_apply", "submit");
                    profileEdit.experiences[index] = currentExperience
                    setProfileMod(profileEdit);
                    setIsEditing(false);
                    setAddingNew(false);
                    setProfile(profileEdit);
                } else {
                    profileEdit.experiences[index] = currentExperience
                    RamaService.updateProfile(profileEdit).then((res) => {

                        if(res.data){
                            UtilityService().clickSendEvent("save_experience", "submit");
                            ProfileInstantService.updateProfilePublic(profileEdit);
                            setProfileMod(profileEdit);
                            setIsEditing(false);
                            setAddingNew(false);
                            setProfile(profileEdit);
                        }
                       
                    })
                }

            }
        } else {
            if (currentExperience
                && currentExperience.companyName
                && currentExperience.employmentType
                && currentExperience.endDate
                && currentExperience.jobTitle
                && currentExperience.responsibilities
                && currentExperience.salary
                && currentExperience.startDate
            ) {
                if (isApplicationForm) {
                    profileEdit.experiences[index] = currentExperience
                    setProfileMod(profileEdit);
                    setIsEditing(false);
                    setAddingNew(false);
                    setProfile(profileEdit);
                } else {
                    profileEdit.experiences[index] = currentExperience
                    RamaService.updateProfile(profileEdit).then((res) => {
                        ProfileInstantService.updateProfilePublic(profileEdit);
                        setProfileMod(profileEdit);
                        setIsEditing(false);
                        setAddingNew(false);
                        setProfile(profileEdit);
                    })
                }

            }
        }
    }

    const changeCurrentWork = (value) => {
        let currentEditChange = { ...currentExperience } as Experience;

        if (currentExperience) {
            currentEditChange.stillWorkHere = value;
            setCurrentExperience(currentEducation => ({
                ...currentEditChange
            }));
        }

    }

    const dateChange = (value, action) => {
        let currentEditChange = { ...currentExperience } as Experience;
        if (currentEditChange) {
            if (action == 'end') {
                currentEditChange.endDate = new Date(value).toISOString();
                currentEditChange.stillWorkHere = false;
                setEndDate(value);
                if (currentEditChange.startDate) {
                    if (new Date(value) < new Date(currentEditChange.startDate)) {
                        setErrorDate(true);
                    } else {
                        setErrorDate(false);
                    }
                }
                setCurrentExperience(currentEducation => ({
                    ...currentEditChange
                }));
            } else {
                currentEditChange.startDate = new Date(value).toISOString();
                setStartDate(value);
                if (currentEditChange.endDate) {
                    if (new Date(currentEditChange.endDate) < new Date(value)) {
                        currentEditChange.endDate = "";
                        setErrorDate(true);
                    } else {
                        setErrorDate(false);
                    }
                }

                setCurrentExperience(currentEducation => ({
                    ...currentEditChange
                }));
            }


        }

    }
    const closeModal = () => {
        if (isAddingNew) {
            cancelAddEducation(editIndex);
            setAddingNew(false);
            setCloseModal();
        } else {
            setAddingNew(false);
            setCloseModal();
        }
    }

    const onSelectChange = (key, value) => {
        let currentEditChange = { ...currentExperience } as Experience;
        if (key == 'jobTitle') {
            currentEditChange.jobTitle = value
            JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {
                const itemResult = item.job_roles.filter((items => items.job_title == value))
                if (itemResult.length > 0) {
                    let strArray = "";
                    if (itemResult[0] && itemResult[0].responsibilities && itemResult[0].responsibilities?.length > 0) {
                        itemResult[0].responsibilities.map((itemRespons: string) => {
                            strArray += itemRespons + " "
                        })

                    }
                    currentEditChange.responsibilities = strArray
                }
            })

        }

        if (currentExperience) {
            setCurrentExperience(currentEducation => ({
                ...currentEditChange
            }));
        }
    }


    function getRoleOptions(roles) {
        let flatItem = new Array();
        JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {

            const jobPush = {
                label: item.category,
                options: new Array()
            }
            let strgArray = new Array();
            item.job_roles.map((items: JobRole) => {

                if (items.job_title) {
                    strgArray.push(items.job_title);
                }

            })
            jobPush.options = strgArray;
            flatItem.push(jobPush);

        })
        setFlattenedRoles(flatItem)
    }
    return (
        <>
            <div className="education">
                <div className="education-close-control">
                    <img src={CLOSE_IMG} onClick={closeModal} className="education-close-img"></img>
                </div>
                <div className="d-flex">
                    <span className="education-header">{translate.profile.experience}</span>
                    {
                        !isAddingNew && <Button className="education-item-add-button" onClick={addEmptyExperience}>+ {translate.other.add} </Button>
                    }
                </div>


                {
                    profileMod && profileMod.experiences.map((item: Experience, index: number) => {

                        return (
                            <>{
                                (editIndex == index && currentExperience && isEditing) ?
                                    <div className="d-flex flex-column experience-grid-edit">
                                        <div className="experience-grid">
                                            <div className="education-item">
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label" className="education-item-label" >{translate.profile.employment_type}</InputLabel>
                                                    <Select

                                                        inputProps={{ className: "education-item-input-select" }}
                                                        value={currentExperience.employmentType}
                                                        label={translate.profile.employment_type}
                                                        onChange={(e) => textChange(e.target.value, 'employmentType', index)}
                                                    >
                                                        {
                                                            translate.job_type && translate.job_type.map((item2: SelectItemModal, index2: number) => {
                                                                return (
                                                                    item2.value == item.employmentType ?
                                                                        <MenuItem value={item2.name} className="education-menu-item" key={index2} selected>{item2.value}</MenuItem> :
                                                                        <MenuItem value={item2.name} className="education-menu-item" key={index2}>{item2.value}</MenuItem>

                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                                {
                                                    (isSaving && !currentExperience.employmentType) &&
                                                    <span className="education-item-text-error">{translate.error.input}</span>
                                                }

                                            </div>
                                            <div className="education-item">
                                                <TextField inputProps={{ className: "education-item-input" }}
                                                    InputLabelProps={{ className: "education-item-label" }}
                                                    label={translate.profile.company_name} value={currentExperience.companyName} variant="outlined"
                                                    onChange={(e) => textChange(e.target.value, 'companyName', index)} />
                                                {
                                                    (isSaving && !currentExperience.companyName) &&
                                                    <span className="education-item-text-error">{translate.error.input}</span>
                                                }
                                            </div>
                                            <div className="education-item">
                                                <Autocomplete
                                                    options={flattenedRoles.map(group => group.options).flat()}
                                                    groupBy={(option) =>
                                                        flattenedRoles.find(group => group.options.includes(option)).label
                                                    }
                                                    value={currentExperience.jobTitle}
                                                    className="education-item-input"
                                                    onInputChange={(event, newValue) => {
                                                        onSelectChange("jobTitle", newValue);
                                                        // Do something with the selected name
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Roles" variant="outlined" />}
                                                />
                                                {/* <TextField inputProps={{ className: "education-item-input" }}
                                                    InputLabelProps={{ className: "education-item-label" }}
                                                    label={translate.profile.job_title} variant="outlined" value={currentExperience.jobTitle}
                                                    onChange={(e) => textChange(e.target.value, 'jobTitle', index)} /> */}
                                                {
                                                    (isSaving && !currentExperience.jobTitle) &&
                                                    <span className="education-item-text-error">{translate.error.input}</span>
                                                }
                                            </div>
                                            <div className="education-item">
                                                <TextField inputProps={{ className: "education-item-input" }}
                                                    InputLabelProps={{ className: "education-item-label" }}
                                                    label={translate.profile.salary} variant="outlined" value={currentExperience.salary}
                                                    onChange={(e) => textChange(e.target.value, 'salary', index)} />
                                                {
                                                    (isSaving && !currentExperience.salary) &&
                                                    <span className="education-item-text-error">{translate.error.input}</span>
                                                }
                                            </div>
                                            <div className="education-item">
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DemoContainer components={['DatePicker']} >

                                                        <DatePicker
                                                            value={startDate}
                                                            onChange={(e) => dateChange(e, 'start')}
                                                            className="education-item-input-select" label={translate.profile.start_date} views={['month', 'year']} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className="education-item">
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DemoContainer components={['DatePicker']} >

                                                        <DatePicker
                                                            value={endDate}
                                                            disabled={currentExperience.stillWorkHere}
                                                            minDate={startDate}
                                                            onChange={(e) => dateChange(e, 'end')}
                                                            className="education-item-input-select" label={translate.profile.end_year} views={['month', 'year']} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                {
                                                    errorDate &&
                                                    <span className="education-item-text-error">{translate.error.input_date_end}</span>
                                                }
                                            </div>
                                            <div className="education-item">
                                                <FormControlLabel control={<Checkbox checked={currentExperience.stillWorkHere} onChange={(e) => changeCurrentWork(e.target.checked)} />} className="experience-current-work-control" label={translate.profile.still_work_here} />
                                            </div>
                                            <div className="education-item">
                                            </div>


                                        </div>
                                        <div className="education-item">
                                            <span className="education-form-title">{translate.profile.responsibility}</span>
                                            <TextareaAutosize
                                                className="education-textarea-control"
                                                minRows={5}
                                                value={currentExperience.responsibilities}
                                                onChange={(e) => textChange(e.target.value, 'responsibilities', index)} />
                                            {
                                                (isSaving && !currentExperience.responsibilities) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }
                                        </div>
                                        <div className="education-item-edit-button-control">

                                            {
                                                item.employmentType ? <Button className="education-item-edit-button-cancel" onClick={() => setIsEditing(false)}>{translate.other.cancel}</Button>
                                                    :
                                                    <Button className="education-item-edit-button-cancel" onClick={() => cancelAddEducation(editIndex)}>{translate.other.cancel}</Button>
                                            }
                                            <Button className="education-item-edit-button" onClick={() => saveValue(index)}>{translate.other.save}</Button>
                                        </div>


                                    </div>
                                    :
                                    <div className="education-grid" key={index}>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.responsibility}</span>
                                            {
                                                translate.job_type.map((item2: SelectItemModal, index2: number) => {
                                                    return (
                                                        item2.name == item.employmentType &&
                                                        <span className="education-item-desc" key={index2}>{item2.value}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.company_name}</span>
                                            <span className="education-item-desc">{item.companyName ? item.companyName : translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.job_title}</span>
                                            <span className="education-item-desc">{item.jobTitle ? item.jobTitle : translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.salary}</span>
                                            <span className="education-item-desc">{item.salary ? item.salary : translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.start_date}</span>
                                            <span className="education-item-desc">{startDate ? moment(new Date(startDate.toDate())).format("MMMM YYYY") : translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.end_year}</span>
                                            <span className="education-item-desc">{item.stillWorkHere ? translate.profile.still_work_here :
                                                endDate ? moment(new Date(endDate.toDate())).format("MMMM YYYY") :
                                                    translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.responsibility}</span>
                                            <span className="education-item-desc">{item.responsibilities ? item.responsibilities : translate.other.not_specified}</span>
                                        </div>

                                        <div className="education-item-edit-button-control">
                                            <Button className="education-item-edit-button" onClick={() => clickEditExperience(item, index)}>{translate.other.edit}</Button>
                                            <Button className="education-item-edit-button-cancel" onClick={() => deleteItem(index)}>{translate.other.delete}</Button>
                                        </div>
                                    </div>
                            }

                            </>
                        )
                    })
                }



            </div>
        </>

    )

}

export default ExperienceComponent;