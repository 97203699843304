import { useContext, useEffect, useState } from "react"
import { Language, ProfileModel } from "../../../../model/profile.model"
import CLOSE_IMG from "../../../../assets/images/close.png";
import { Checkbox, CircularProgress, Divider, FormControlLabel, TextField } from "@mui/material";
import "../education/education.scss";
import { Button, Form } from "react-bootstrap";
import RamaService from "../../../../services/sso/rama.service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LANGUAGE_LEVEL_JSON from "../../../../assets/json/language-level.json";
import "./about.scss";
import { TranslateModel } from "../../../../model/translate.model";
import { useLanguage } from "../../../../provider/language.provider";
import TRANSLATE_EN from "../../../../assets/json/en.json";
import MARITAL_STATUS from "../../../../assets/json/jobs/married.json"
import { OptionInputSelectModel } from "../../../../model/jobs/application-form.model";
import MILITARY from "../../../../assets/json/jobs/military.json";
import RELIGION_STATUS from "../../../../assets/json/jobs/religion.json";
import NATIONALITY_STATUS from "../../../../assets/json/jobs/nationality.json";
import GENDER_STATUS from "../../../../assets/json/jobs/gender.json";
import { ProvideContext } from "../../../../context/provider.context";
import languageService from "../../../../services/language.service";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import UtilityService from "../../../../utility/utility.service";
const AboutComponent = ({ profile, setProfile, setCloseModal }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [isSaving, setIsSaving] = useState(false);
    const [dob, setDob] = useState('');
    const [phone, setPhone] = useState('');
    const [languageLevel, setLanguageLevel] = useState<Language[]>(LANGUAGE_LEVEL_JSON);

    const closeModal = () => {
        setCloseModal();
    }

    useEffect(() => {

        if (profileMod.dateOfBirth) {
            setDob(new Date(profileMod.dateOfBirth).toLocaleDateString());
        }

        if (profileMod.languages && profileMod.languages.length > 0) {
            const languageMod: Language[] = new Array();
            profileMod.languages.map((item: Language, index: number) => {
                if (item.language == "en") {
                    item.value = "English";
                } else if (item.language == "th") {
                    item.value = "Thai";
                } else if (item.language == "jp") {
                    item.value = "Japanese";
                } else if (item.language == "ch") {
                    item.value = "Chinese";
                }
                languageMod.push(item);
            })
            setLanguageLevel(languageMod);
        } else {
            const languageMod: Language[] = LANGUAGE_LEVEL_JSON;

            setLanguageLevel(languageMod);
        }

    }, [profileMod])

    const textChange = (text, key) => {

        const profileModEdit = { ...profileMod } as ProfileModel
        if (key == 'dateOfBirth') {
            setPhone(text);

        }

    }


    const saveValue = () => {
        const profileModEdit = { ...profileMod } as ProfileModel
        setIsSaving(true);
        profileModEdit.dateOfBirth = new Date(dob).toISOString();
        profileModEdit.languages = languageLevel;
        RamaService.updateProfile(profileModEdit).then((res) => {

            if(res.data){
                UtilityService().clickSendEvent("save_about_me", "submit");
                ProfileInstantService.updateProfilePublic(profileModEdit);
                setProfile(profileModEdit);
                setProfileMod(profileModEdit);
                closeModal();
            }
        
        })
    }

    const dateChange = (value, action) => {
        setDob(new Date(value).toISOString())
    }

    const changeActionLang = (value, key, index) => {

        const languageLevelEdit = [...languageLevel];
        if (key == 'listening') {
            languageLevelEdit[index].listening = value;
        } else if (key == 'reading') {
            languageLevelEdit[index].reading = value;
        } else if (key == 'writing') {
            languageLevelEdit[index].writing = value;
        } else if (key == 'speaking') {
            languageLevelEdit[index].speaking = value;
        }

        setLanguageLevel(languageLevelEdit);

        if (key == 'carLicense') {
            profileMod.carLicense = value
        } else if (key == 'bikeLicense') {
            profileMod.bikeLicense = value
        } else if (key == 'truckLicense') {
            profileMod.truckLicense = value
        }

        setProfileMod(profileMod);
        setProfile(profileMod)
    }

    function getValueByKey(array, value) {
        const item = array.find(obj => obj.key === value);
        return item ? item.value : undefined;
    }

    const onSelectChange = (action, value) => {
        if (action == "maritalStatus") {
            profileMod.maritalStatus = value;
        } else if (action == "militaryStatus") {
            profileMod.militaryStatus = value;
        } else if (action == "religion") {
            profileMod.religion = value;
        } else if (action == "nationality") {
            profileMod.nationality = value;
        } else if (action == "gender") {
            profileMod.gender = value;
        }

        setProfileMod(profileMod);
        setProfile(profileMod);
    }

    return (

        <>
            <div className="education">
                <div className="education-close-control">
                    <img src={CLOSE_IMG} onClick={closeModal} className="education-close-img"></img>
                </div>
                <div className="d-flex">
                    <span className="education-header">{translate.profile.about}</span>
                </div>
                {
                    isSaving ? <div className="profile-edit-loading-control">

                        <div className="profile-edit-loading-flex">
                            <CircularProgress className="profile-edit-progress"></CircularProgress>
                            <span className="profile-edit-text">กำลังอัปเดทข้อมูล</span>
                        </div>
                    </div>

                        :
                        <div className="d-flex flex-column experience-grid-edit">
                            <Divider textAlign="left" className="education-item-title">{translate.application_form.birthDate}</Divider>
                            <div className="experience-grid">

                                <div className="education-item">
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer components={['DatePicker']} >

                                            <DatePicker defaultValue={profileMod.dateOfBirth && dayjs(profileMod.dateOfBirth)}
                                                onChange={(e) => dateChange(e, 'start')}
                                                className="education-item-input-select" label={'วันเกิด'} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                                <div className="education-item">

                                </div>

                            </div>
                            <div className="education-item">
                                <Divider textAlign="left" className="education-item-title">{translate.application_form.gender}</Divider>
                                <div className="about-lang-control">
                                    <span className="about-lang-title">{translate.application_form.gender}</span>
                                    <Form.Select className={""}
                                        defaultValue={profileMod.gender}
                                        onChange={(e) => onSelectChange("gender", e.target.value)}>
                                        {
                                            GENDER_STATUS &&
                                            GENDER_STATUS.map((inputItem: OptionInputSelectModel, index: number) => {
                                                return (
                                                    <option value={inputItem.key} key={index}>{
                                                        inputItem.value == "Male" ? translate.gender_type.male :
                                                            inputItem.value == "Female" ? translate.gender_type.female :
                                                                translate.gender_type.unspecified}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="education-item">
                                <Divider textAlign="left" className="education-item-title">{translate.application_form.language}</Divider>
                                {
                                    languageLevel.map((item: Language, index: number) => {


                                        return (

                                            <div className="about-lang-control" key={index}>

                                                <span className="about-lang-title">{
                                                    item.value == "English" ? translate.language_type.english :
                                                        item.value == "Thai" ? translate.language_type.thai :
                                                            item.value == "Chinese" ? translate.language_type.chinese : translate.language_type.japanese
                                                }</span>
                                                <div className="about-lang-check-grid">
                                                    <FormControlLabel control={<Checkbox
                                                        checked={item.listening} onChange={(e) => changeActionLang(e.target.checked, 'listening', index)} />}
                                                        className="about-lang-check-item" label={translate.language_type.listening} />
                                                    <FormControlLabel control={<Checkbox
                                                        checked={item.reading} onChange={(e) => changeActionLang(e.target.checked, 'reading', index)} />}
                                                        className="about-lang-check-item" label={translate.language_type.reading} />
                                                    <FormControlLabel control={<Checkbox
                                                        checked={item.speaking} onChange={(e) => changeActionLang(e.target.checked, 'speaking', index)} />}
                                                        className="about-lang-check-item" label={translate.language_type.speaking} />
                                                    <FormControlLabel control={<Checkbox
                                                        checked={item.writing} onChange={(e) => changeActionLang(e.target.checked, 'writing', index)} />}
                                                        className="about-lang-check-item" label={translate.language_type.writing} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }



                            </div>
                            <div className="education-item">
                                <Divider textAlign="left" className="education-item-title">{translate.job_apply.licenses}</Divider>
                                <div className="about-lang-control">

                                    <div className="about-lang-check-grid-3">
                                        <FormControlLabel control={<Checkbox
                                            checked={profileMod.bikeLicense} onChange={(e) => changeActionLang(e.target.checked, 'bikeLicense', 0)} />}
                                            className="about-lang-check-item" label={translate.application_form.bikeLicense} />
                                        <FormControlLabel control={<Checkbox
                                            checked={profileMod.carLicense} onChange={(e) => changeActionLang(e.target.checked, 'carLicense', 0)} />}
                                            className="about-lang-check-item" label={translate.application_form.carLicense} />
                                        <FormControlLabel control={<Checkbox
                                            checked={profileMod.truckLicense} onChange={(e) => changeActionLang(e.target.checked, 'truckLicense', 0)} />}
                                            className="about-lang-check-item" label={translate.application_form.truckLicense} />

                                    </div>
                                </div>
                            </div>
                            <div className="education-item">
                                <Divider textAlign="left" className="education-item-title">{translate.application_form.otherStatus}</Divider>
                                <div className="about-lang-control">
                                    <span className="about-lang-title">{translate.application_form.maritalStatus}</span>
                                    <Form.Select className={""}
                                        defaultValue={profileMod.maritalStatus}
                                        onChange={(e) => onSelectChange("maritalStatus", e.target.value)}>
                                        {
                                            MARITAL_STATUS &&
                                            MARITAL_STATUS.map((inputItem: OptionInputSelectModel, index: number) => {
                                                return (
                                                    <option value={inputItem.key} key={index}>{
                                                        inputItem.value == "Single" ? translate.married_type.single :
                                                            inputItem.value == "Married" ? translate.married_type.married :
                                                                inputItem.value == "Divorced" ? translate.married_type.divorced :
                                                                    inputItem.value == "Not Select" ? translate.married_type.not_select :
                                                                        translate.married_type.widowed
                                                    }</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                                <div className="about-lang-control">
                                    <span className="about-lang-title">{translate.application_form.militaryStatus}</span>
                                    <Form.Select className={""}
                                        defaultValue={profileMod.militaryStatus}
                                        onChange={(e) => onSelectChange("militaryStatus", e.target.value)}>
                                        {
                                            MILITARY &&
                                            MILITARY.map((inputItem: OptionInputSelectModel, index: number) => {
                                                return (
                                                    <option value={inputItem.key} key={index}>{
                                                        inputItem.value == "Exempted" ? translate.military_type.exempted :
                                                            inputItem.value == "Completed" ? translate.military_type.completed :
                                                                inputItem.value == "Serving" ? translate.military_type.serving :
                                                                    inputItem.value == "Not Yet Qualified" ? translate.military_type.not_yet_qualified :
                                                                        translate.military_type.not_select
                                                    }</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                                <div className="about-lang-control">
                                    <span className="about-lang-title">{translate.application_form.religion}</span>
                                    <Form.Select className={""}
                                        defaultValue={profileMod.religion}
                                        onChange={(e) => onSelectChange("religion", e.target.value)}>
                                        {
                                            RELIGION_STATUS &&
                                            RELIGION_STATUS.map((inputItem: OptionInputSelectModel, index: number) => {
                                                return (
                                                    <option value={inputItem.key} key={index}>

                                                        {
                                                            inputItem.value == "Buddhism" ? translate.religion_type.buddhism :
                                                            inputItem.value == "Islam" ? translate.religion_type.islam :
                                                            inputItem.value == "Christianity" ? translate.religion_type.christianity :
                                                            inputItem.value == "Hinduism" ? translate.religion_type.hinduism :
                                                            inputItem.value == "Sikhism" ? translate.religion_type.sikhism :
                                                            inputItem.value == "Judaism" ? translate.religion_type.judaism :
                                                            inputItem.value == "Jainism" ? translate.religion_type.jainism :
                                                            inputItem.value == "Zoroastrianism" ? translate.religion_type.zoroastrianism :
                                                            inputItem.value == "Bahá'í Faith" ? translate.religion_type.bahai :
                                                            inputItem.value == "No Religion" ? translate.religion_type.no_religion :
                                                            inputItem.value == "Not Select" ? translate.religion_type.not_select :
                                                            translate.religion_type.others
                                                        }
                                                    </option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                                <div className="about-lang-control">
                                    <span className="about-lang-title">{translate.application_form.nationality}</span>
                                    <Form.Select className={""}
                                        defaultValue={profileMod.nationality}
                                        onChange={(e) => onSelectChange("nationality", e.target.value)}>
                                        {
                                            NATIONALITY_STATUS &&
                                            NATIONALITY_STATUS.map((inputItem: OptionInputSelectModel, index: number) => {
                                                return (
                                                    <option value={inputItem.key} key={index}>{inputItem.value}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                            </div>
                            <div className="education-item-edit-button-control">
     
                                <Button className="education-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                                <Button className="education-item-edit-button" onClick={() => saveValue()}>{translate.other.save}</Button>
                            </div>

                        </div>
                }


            </div>

        </>
    )
}

export default AboutComponent;