import React, { useContext, useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Collapse, IconButton, ListItemButton, ListItemIcon } from '@mui/material';
import "./menu.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBrain, faChevronDown, faChevronUp, faFileLines, faGear, faHamburger, faL, faMagnifyingGlass, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import GroupMenuModel, { GroupMenuItemModel } from '../../model/menu/menu.model';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProvideContext } from '../../context/provider.context';
import navigateConfig from '../../navigate.config';
import CheckingToken from '../checking-auth/checking-token/checking-token';
import { TranslateModel } from '../../model/translate.model';

import tokenService from '../../services/sso/token.service';
import { MenuItemService } from './menu-item';
import featureConfig from '../../feature.config';
import config from '../../config';
import UtilityService from '../../utility/utility.service';


const MenuComponent = () => {
    const { translateGlobal, languageGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [isStart, setIsStart] = useState(true);
    const [open, setOpen] = useState(false);
    const [menuItem, setMenuItem] = useState<GroupMenuModel[]>();
    const navigate = useNavigate();
    const { menuKey, setMenuKey }: any = useContext(ProvideContext);
    const location = useLocation();
    const jsonService = new MenuItemService();
    useEffect(() => {
        if (location.pathname.includes('guest')) {
            jsonService.MenuItemGuest(translate).then((res) => {
                const result = res as GroupMenuModel[]
                setMenuItem(result);
            })
        } else {
            jsonService.MenuItem(translate).then((res) => {
                const result = res as GroupMenuModel[]
                setMenuItem(result);
            })
        }

    }, [location])
    const clickDrawer = () => {
        setIsStart(false);
        if (open) {
            setOpen(false);
        } else {
            setOpen(true)
        }
    }



    const clickMenu = (index) => {
        if (menuItem) {
            const groupMenuMod = [...menuItem];
            const group_id = index;
            const item = groupMenuMod.filter((item) => item.group_id == group_id)[0]

            UtilityService().clickSendEvent("menu_"+item.group, "click");
            if (group_id == 1) {
                navigate(item.path + "");
            } else if (group_id == 8) {
                tokenService.clearAll();
                localStorage.clear();
                navigate('/');
            }
            else if (group_id == 90) {

                if (location.pathname.includes('talent-sauce')) {
                    navigate(navigateConfig.guest.drop_resume_ts + "")
                } else {
                    navigate(item.path + "")
                }

            } else if (group_id == 93) {
                window.open("https://resume.jobhack.co", "_blank");

            } else if (group_id == 94) {
               window.open("https://salary.scoutout.co", "_blank");

            }
            else if (group_id == 96) {
                navigate("/" + item.path)
            } else if (group_id == 5) {
                window.open(config.salary_checker.url, "_blank")
            }
            else {
                navigate(item.path + "")
            }

            setMenuItem(groupMenuMod);
        }
    }

    const clickChildMenu = (path, id) => {
        setMenuKey(id)
        navigate(path);
        clickDrawer();
    }


    return (
        <>

            {

                menuItem &&


                <div className={open && !isStart ?

                    "menu-main-active" : isStart ? "menu-main-no-animation" : "menu-main"}>

                    <div className="menu-icon-control">
                        <FontAwesomeIcon icon={faBars} onClick={clickDrawer} className='menu-icon-size'></FontAwesomeIcon>
                        <img src={config.url + featureConfig.logo_image.white} className=
                            {
                                open ? 'menu-icon-logo-active' : 'menu-icon-logo'
                            }
                        ></img>


                    </div>
                    <div className='menu-list-item-control'>
                        {open ?
                            menuItem.filter((item) => item.isOpen).map((item: GroupMenuModel, index: number) => {
                                return (
                                    <ListGroup key={index} className="menu-list-group-control" >
                                        <ListGroupItem aria-expanded={false}

                                        >
                                            <ListItemButton className='menu-list-group-item'
                                                onClick={() => clickMenu(item.group_id)}
                                            >
                                                <ListItemIcon>
                                                    <FontAwesomeIcon icon={item.group_image}
                                                        className="menu-list-group-item-icon"
                                                    ></FontAwesomeIcon>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <span className='menu-list-item-text-control'>{item.group}</span>
                                                </ListItemText>

                                            </ListItemButton>

                                        </ListGroupItem>
                                    </ListGroup>
                                )
                            })
                            : <div className='menu-item-group-mobile-control'>
                                {
                                    menuItem.filter((item) => item.isOpen).map((item: GroupMenuModel, index: number) => {
                                        return (
                                            <div className='menu-item-group-mobile' key={index} onClick={() => clickMenu(item.group_id)}>
                                                <FontAwesomeIcon icon={item.group_image} className='menu-item-group-mobile-icon'></FontAwesomeIcon>
                                                <span className="menu-item-group-mobile-text">
                                                    {item.group}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default MenuComponent;
