import "./template-2.scss";
import { useEffect, useState } from "react";
import { InputResumeBuilderModel } from "../../../../model/resume-builder/input-resume-builder.model";
import { ProfileModel } from "../../../../model/profile.model";
import { ResumeTemplateModel } from "../../../../model/resume-builder/resume-template.model";
import PROFILE_MOCK from "../../../../assets/images/resume-builder/profile_mock.png";
import { FileContent } from "use-file-picker/dist/interfaces";

const Template_2_Component = ({ containerRef, templ, profile }: any) => {

    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [templateMod, setTemplateMod] = useState<ResumeTemplateModel>(templ);
    const [inputTemplateModel, setInputTemplateModel] = useState<InputResumeBuilderModel>(templ);


    return (

        <>
            {
                inputTemplateModel &&
                <div className="template-2-control">
                    <div className="template-2-form-control" ref={containerRef}>

                        <div className="template-2-profile-control" style={{
                            backgroundImage: 'linear-gradient(to bottom, ' +

                                inputTemplateModel.color ? inputTemplateModel.color?.value : '#000' + ' 50%, white 50%)'
                        }}>
                            <div className="template-2-profile-left-control">
                                {templ.is_profile_img ?
                                    templ.profile_img!.map((file: any, index) => (
                                        <div className="template-2-profile-img" style={{ backgroundImage: 'url(' + file.content + ')' }}></div>
                                    ))
                                    :
                                    <div className="template-2-profile">
                                        <img src={PROFILE_MOCK} className="template-2-profile-img"></img>
                                    </div>
                                }
                            </div>
                            <div className="template-2-profile-right-control">
                                <div className="template-2-profile-right-top-control">
                                    <span className="template-2-profile-right-top-name" dangerouslySetInnerHTML=
                                        {{
                                            __html:
                                                inputTemplateModel.name ?
                                                    inputTemplateModel.name.value : ""
                                        }}></span>
                                    <span className="template-2-profile-right-top-position" dangerouslySetInnerHTML=
                                        {{
                                            __html:
                                                inputTemplateModel.position ?
                                                    inputTemplateModel.position.value : ""
                                        }}></span>
                                </div>
                                <div className="template-2-profile-right-bottom-control">
                                    {
                                        inputTemplateModel && inputTemplateModel.contact &&
                                        <div className="template-2-profile-right-bottom-contact-control">
                                            <div className="template-2-profile-right-bottom-contact-text-control">
                                            <span className="template-2-profile-right-bottom-contact-text"
                                                dangerouslySetInnerHTML={{__html:inputTemplateModel.contact.value}}></span>
                                            </div>
                                            {/* <span className="template-2-profile-right-bottom-contact-text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        inputTemplateModel.contact ?
                                                            inputTemplateModel.contact.value : ""
                                                }}>

                                            </span> */}
                                        </div>
                                    }

                                    {
                                        profileMod && profileMod.email &&
                                        <div className="template-2-profile-right-bottom-contact-control">
                                            <span className="template-2-profile-right-bottom-contact-title">
                                                Email
                                            </span>
                                            <span className="template-2-profile-right-bottom-contact-text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        profileMod.email ?
                                                            profileMod.email : ""
                                                }}>

                                            </span>
                                        </div>
                                    }
                                    {
                                        profileMod && profileMod.wechatId &&
                                        <div className="template-2-profile-right-bottom-contact-control">
                                            <span className="template-2-profile-right-bottom-contact-title">
                                                WeChat
                                            </span>
                                            <span className="template-2-profile-right-bottom-contact-text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        profileMod.wechatId ?
                                                            profileMod.wechatId : ""
                                                }}>

                                            </span>
                                        </div>
                                    }
                                    {
                                        profileMod && profileMod.whatsappId &&
                                        <div className="template-2-profile-right-bottom-contact-control">
                                            <span className="template-2-profile-right-bottom-contact-title">
                                                What app Id
                                            </span>
                                            <span className="template-2-profile-right-bottom-contact-text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        profileMod.whatsappId ?
                                                            profileMod.whatsappId : ""
                                                }}>

                                            </span>
                                        </div>
                                    }
                                    {
                                        profileMod && profileMod.lineId &&
                                        <div className="template-2-profile-right-bottom-contact-control">
                                            <span className="template-2-profile-right-bottom-contact-title">
                                                LINE ID
                                            </span>
                                            <span className="template-2-profile-right-bottom-contact-text"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        profileMod.lineId ?
                                                            profileMod.lineId : ""
                                                }}>

                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="template-2-desc-control">
                            <span className="template-2-desc-about-title">ABOUT ME</span>
                            <span className="template-2-desc-about-desc" dangerouslySetInnerHTML={{
                                __html:
                                    inputTemplateModel.cover_letter ?
                                        inputTemplateModel.cover_letter.value : ""
                            }}></span>
                            <div className="template-2-divider"></div>
                            <div className="template-2-desc-grid-control">

                                <div className="template-2-desc-section-control">
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Skills</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.expertise ?
                                                        inputTemplateModel.expertise.value : ""
                                            }}></span>
                                        <div className="template-2-divider"></div>
                                    </div>
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Language</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.language ?
                                                        inputTemplateModel.language.value : ""
                                            }}></span>
                                        <div className="template-2-divider"></div>
                                    </div>
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Interest</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML={{
                                            __html:
                                                inputTemplateModel.interest ?
                                                    inputTemplateModel.interest.value : ""
                                        }}></span>
                                    </div>
                                </div>
                                <div className="template-2-desc-section-control">
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Experience</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.experience ?
                                                        inputTemplateModel.experience.value : ""
                                            }}></span>
                                    </div>
                                    <div className="template-2-desc-section-control">
                                        <span className="template-2-desc-title">Education</span>
                                        <span className="template-2-desc-content" dangerouslySetInnerHTML={{
                                            __html:
                                                inputTemplateModel.education ?
                                                    inputTemplateModel.education.value : ""
                                        }}></span>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                    <div className="template-2-footer-control" style={{ backgroundColor: templ.color }}></div>
                </div>
            }
        </>
    )
}

export default Template_2_Component;
