import { useContext, useEffect, useState } from "react";
import { TranslateModel } from "../../../../model/translate.model";
import { Button, Form, FormControl } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { ApplicationInputModel, OptionInputSelectModel } from "../../../../model/jobs/application-form.model";
import { AllResumeModel } from "../../../../model/all-resume.model";
import { GetResumeIdModel } from "../../../../model/get-resume.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, Box, Checkbox, Divider, FormControlLabel, TextField } from "@mui/material";
import COUNTRY_JSON from "../../../../assets/json/jobs/country.json";
import UploadResumeModal from "../../../../modal/jobs/upload-resume/upload-resume.modal";
import LANGUAGE_LEVEL_JSON from "../../../../assets/json/language-level.json";
import { Language, ProfileModel } from "../../../../model/profile.model";
import { ProvideContext } from "../../../../context/provider.context";
import RamaService from "../../../../services/sso/rama.service";
import GPTService from "../../../../services/gpt.service";
import { useFilePicker } from "use-file-picker";
import PROFILE_MOCK from "../../../../assets/images/profile_mock.png";
import CropModal from "../../../../modal/crop.modal";
import ResumeBuilderInstantService from "../../../../services/resume-builder/resume-builder-instance.service";
import ConfirmUpdateProfileModal from "../../../../modal/confirm-update-profile";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import { useProfile } from "../../../../context/profile.context";

const CandidateProfile = ({ profileApplyMod, inputCandidateModel,
    setInputCandidateModel,
    setProfileApplyMod,
    inputLicenseModel, setInputLicenseModel,
    inputContactModel, setInputContactModel,
    inputLanguageModel, setInputLanguageModel,
    setIsCandidateEdit, isCandidateEdit, isBigSubmit }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [isSubmit, setIsSubmit] = useState(false);
    const [allResume, setAllResume] = useState<AllResumeModel>();
    const [isUploadResume, setIsUploadResume] = useState(false);
    const [languageLevel, setLanguageLevel] = useState<Language[]>(LANGUAGE_LEVEL_JSON);
    const [fileUploadCrop, setFileUploadCrop] = useState<any>();
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [isConfirmUpdate, setIsConfirmUpdate] = useState(false);
    const [profile, error] = useProfile();
    const { openFilePicker, filesContent, loading, errors } = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['image/*'],
        limitFilesConfig: { min: 0, max: 1 },
        maxFileSize: 20,
        onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
            // this callback is called when there were no validation errors
            setFileUploadCrop(filesContent[0].content)
            setShowProfileModal(true);
        }
    });
    interface Option {
        firstName: string;
        lastName: string;
    }

    useEffect(() => {
        if (isBigSubmit) {
            setIsSubmit(true);
        }

    }, [isBigSubmit])

    useEffect(() => {

        if (profileApplyMod) {
            console.log(inputCandidateModel);
            getResumesList();

            if (profileApplyMod.languages && profileApplyMod.languages.length > 0) {
                const languageMod: Language[] = new Array();
                profileApplyMod.languages.map((item: Language) => {
                    if (item.language == "en") {
                        item.value = "English";
                    } else if (item.language == "th") {
                        item.value = "Thai";
                    } else if (item.language == "jp") {
                        item.value = "Japanese";
                    } else if (item.language == "ch") {
                        item.value = "Chinese";
                    }
                    languageMod.push(item);
                })
                setLanguageLevel(languageMod);
            } else {
                const languageMod: Language[] = LANGUAGE_LEVEL_JSON;

                setLanguageLevel(languageMod);
            }
        }
    }, [])

    const setHandleClose = () => {
        setIsUploadResume(false);
        getResumesList();
    }

    const handleCloseUpdate = () => {

        setIsConfirmUpdate(false);
    }

    const getResumesList = () => {

        GPTService.getAllResume().then((res) => {

            if (res) {
                const result = res.data as AllResumeModel;
                const filterOut = result.resumes.filter(item => item.resume_key)
                result.resumes = filterOut
                if (filterOut.length > 0) {
                    setAllResume(result);
                }

            }

        }).catch((err) => {

            if (err.response.status == 401) {

                if (profile) {
                    getResumesList();
                }
            }
        })
    }
    const setCropSuccess = (file) => {
        if (profileApplyMod) {

            const profileEdit = { ...profileApplyMod };
            RamaService.uploadProfileImage(file).then((res) => {
                if (res.data) {
                    profileEdit.profileImageKey = res.data[0]
                    RamaService.updateProfile(profileEdit).then((res2) => {
                        if (res2.data) {
                            const res2Profile = res2.data as ProfileModel
                            setProfileApplyMod(res2Profile)
                            setShowProfileModal(false);
                        }

                    })

                }

            })
        }




    }

    const onAutoCompleteChange = (action, value, index) => {

       


    }
    const onSelectChange = (action, value, index) => {

        if (inputCandidateModel) {
            if (action == "militaryStatus" || action == "maritalStatus" || action == "religion" || action == "gender") {
                setInputCandidateModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === index ? { ...item, value: value } : item
                    )
                );
            } else if (action == "resume") {

                if (value) {
                    ResumeBuilderInstantService.getOneResume(value).then((resumeResult) => {
                        if (resumeResult.data) {
                            if (resumeResult.data.resume_key) {
                                const profileApply = { ...profileApplyMod };
                                profileApply.resumeKey = resumeResult.data.resume_key
                                setProfileApplyMod(profileApply);
                                setInputCandidateModel((prevItems) =>
                                    prevItems.map((item, idx) =>
                                        idx === index ? { ...item, value: resumeResult.data.resume_key } : item
                                    )
                                );
                            }
                        }


                    })
                }

            } else if (action == "country") {
                console.log(value);

            
                setInputContactModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === index ? { ...item, value: value } : item
                    )
                );
            }else  if (action == "nationality") {
                console.log(value);
                setInputCandidateModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === index ? { ...item, value: value } : item
                    )
                );
                console.log(inputCandidateModel);
            }

        }
    }

    const handleCandidateEdit = () => {
        if (isCandidateEdit) {
            setIsCandidateEdit(false);

        } else {
            setIsCandidateEdit(true);
        }
    }
    const clickUploadNewResume = () => {
        setIsUploadResume(true);
    }


    const onTextChange = (action, value, indexItem) => {

        if (action == "email") {
            if (!validateEmail(value)) {
                setInputCandidateModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === indexItem ? { ...item, value: value, error: "อีเมลผิดรูปแบบ" } : item
                    )
                );
            } else {
                setInputCandidateModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === indexItem ? { ...item, value: value, error: "" } : item
                    )
                );
            }
        } else if (action == 'weight') {
            if (profileApplyMod) {
                if (value > 0 && value <= 200) {
                    const profileApply = { ...profileApplyMod };
                    profileApply.weight = value
                    setProfileApplyMod(profileApply);
                    const weightHeight = profileApply.weight + "," + profileApply.height;
                    setInputCandidateModel((prevItems) =>
                        prevItems.map((item, idx) =>
                            idx === indexItem ? { ...item, value: weightHeight, error: "" } : item
                        ));
                } else {
                    const profileApply = { ...profileApplyMod };
                    const weightHeight = profileApply.weight + "," + profileApply.height;
                    setInputCandidateModel((prevItems) =>
                        prevItems.map((item, idx) =>
                            idx === indexItem ? { ...item, value: weightHeight, error: translate.error.weight_error } : item
                        ));
                }

            }

        } else if (action == 'height') {
            if (profileApplyMod) {
                if (value > 120 && value <= 250) {
                    const profileApply = { ...profileApplyMod };
                    profileApply.height = value
                    setProfileApplyMod(profileApply);
                    const weightHeight = profileApply.weight + "," + profileApply.height;
                    setInputCandidateModel((prevItems) =>
                        prevItems.map((item, idx) =>
                            idx === indexItem ? { ...item, value: weightHeight, error: "" } : item
                        ));
                } else {
                    const profileApply = { ...profileApplyMod };
                    const weightHeight = profileApply.weight + "," + profileApply.height;
                    setInputCandidateModel((prevItems) =>
                        prevItems.map((item, idx) =>
                            idx === indexItem ? { ...item, value: weightHeight, error: translate.error.height_error } : item
                        ));
                }

            }

        } else if (action == 'firstname') {
            if (profileApplyMod) {
                const profileApply = { ...profileApplyMod };
                profileApply.firstName = value
                setProfileApplyMod(profileApply);
                const name = profileApply.firstName + "," + profileApply.lastName;
                setInputCandidateModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === indexItem ? { ...item, value: name, error: "" } : item
                    ));
            }
        } else if (action == 'lastname') {
            if (profileApplyMod) {
                const profileApply = { ...profileApplyMod };
                profileApply.lastName = value
                setProfileApplyMod(profileApply);
                const name = profileApply.firstName + "," + profileApply.lastName;
                setInputCandidateModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === indexItem ? { ...item, value: name, error: "" } : item
                    ));
            }
        }
        else {
            setInputCandidateModel((prevItems) =>
                prevItems.map((item, idx) =>
                    idx === indexItem ? { ...item, value: value, error: "" } : item
                )
            );
        }



    }
    const onTextChangeContact = (action, value, index) => {
        if (action == 'facebookId') {
            const regex = /^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9(\.\-)?]+\/?$/;
            if (regex.test(value)) {
                setInputContactModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === index ? { ...item, value: value, error: "" } : item
                    )
                );
            } else {
                setInputContactModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === index ? { ...item, value: value, error: translate.error.facebook_error } : item
                    )
                );
            }

        } else {
            setInputContactModel((prevItems) =>
                prevItems.map((item, idx) =>
                    idx === index ? { ...item, value: value, error: "" } : item
                )
            );
        }

    }
    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // +1 because months are zero-indexed
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };
    function validateEmail(email) {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    }
    const saveCandidateInput = (isUpdate) => {
        setIsSubmit(true);
        let isChecking = true;
        if (profileApplyMod) {
            console.log(profileApplyMod);
            if (inputCandidateModel.filter(item => item.status == "MANDATORY" && item.value == "" && item.error == "").length == 0) {
                inputCandidateModel.map((item: ApplicationInputModel) => {
                    if (item.key == "weightAndHeight") {
                        const weight = String(item.value).split(',');
                        profileApplyMod.weight = weight[0];
                        profileApplyMod.height = weight[1]
                    } else if (item.key == "resume") {
                        profileApplyMod.resumeKey = item.value;
                    }
                    else {
                        profileApplyMod[item.key] = item.value;
                    }
                })
            } else {
                isChecking = false;
            }
            if (inputContactModel.filter(item => item.status == "MANDATORY" && item.value == "" && item.error == "").length == 0) {
                inputContactModel.map((item: ApplicationInputModel) => {
                    profileApplyMod[item.key] = item.value;
                })
            } else {
                isChecking = false;
            }
            if (inputLicenseModel.filter(item => item.status == "MANDATORY" && item.value == "" && item.error == "").length == 0) {
                inputLicenseModel.map((item: ApplicationInputModel) => {
                    profileApplyMod[item.key] = item.value;
                })
            } else {
                isChecking = false;
            }
            if (inputLanguageModel.filter(item => item.status == "MANDATORY" && item.value == "" && item.error == "").length == 0) {
                inputLanguageModel.map((item: ApplicationInputModel) => {
                    profileApplyMod[item.key] = item.value;
                })
            } else {
                isChecking = false;
            }
            if (isChecking) {

            }

            if (isUpdate) {
                RamaService.updateProfile(profileApplyMod).then((res1) => {

                    if (res1.data) {
                        ProfileInstantService.updateProfilePublic(profileApplyMod).then((res) => {
                            if (res.data) {
                                setProfileApplyMod(profileApplyMod);
                                setIsCandidateEdit(false);
                                handleCloseUpdate();
                            }
                        })
                    }
                })


            } else {
                setProfileApplyMod(profileApplyMod);
                setIsCandidateEdit(false);
                handleCloseUpdate();
            }

        }

    }
    const onCheckChange = (action, value, index) => {
        setInputLicenseModel((prevItems) =>
            prevItems.map((item, idx) =>
                idx === index ? { ...item, value: value, error: "" } : item
            ));
    }




    const changeActionLang = (value, key, index) => {

        const languageLevelEdit = [...languageLevel];
        if (key == 'listening') {
            languageLevelEdit[index].listening = value;
        } else if (key == 'reading') {
            languageLevelEdit[index].reading = value;
        } else if (key == 'writing') {
            languageLevelEdit[index].writing = value;
        } else {
            languageLevelEdit[index].speaking = value;
        }
        const profileMod = { ...profileApplyMod };
        profileMod.language = languageLevelEdit;
        setProfileApplyMod(profileMod)

        setLanguageLevel(languageLevelEdit);
    }
    const openCropModal = () => {
        openFilePicker()
    }
    return (
        <div className="jobs-apply-main-item-control">
            <div className="jobs-apply-main-item-step-control" onClick={handleCandidateEdit}>
                <div className="jobs-apply-main-item-step">
                    <span className="jobs-apply-main-item-step-text">1</span>
                </div>
                <span className="jobs-apply-main-item-step-header">{translate.job_apply.candidate_profile}</span>
                <div className="jobs-apply-main-item-step-button-control">

                    <FontAwesomeIcon icon={isCandidateEdit ? faChevronUp : faChevronDown} className="jobs-apply-main-item-step-button"></FontAwesomeIcon>

                </div>

            </div>
            <div className="jobs-apply-main-item-expand-control">

                {
                    isCandidateEdit && inputCandidateModel.filter((items: ApplicationInputModel) => items.status != "NOT_REQUIRED").length > 0 &&
                    <>
                        <div className="jobs-apply-main-item-expand-divider"></div>

                        <div className="jobs-apply-main-item-expand-grid">

                            {
                                inputCandidateModel &&
                                inputCandidateModel.filter((items: ApplicationInputModel) => items.status != "NOT_REQUIRED").map((item: ApplicationInputModel, index: number) => {

                                    return (
                                        <div className="jobs-apply-main-item-expand-flex-control-all-item" key={index + item.key}>
                                            {
                                                index == 2 &&
                                                <div className="jobs-apply-section-header-control">
                                                    <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.job_apply.personal_info}</span></Divider>

                                                </div>
                                            }
                                            {
                                                item.key == "profileImage" ?
                                                    <div className="jobs-apply-main-item-expand-grid-item">
                                                        <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                        <div className="jobs-apply-main-item-profile-img-border">
                                                            <img src={profileApplyMod.profileImageUrl ? profileApplyMod.profileImageUrl : PROFILE_MOCK} className="jobs-apply-main-item-profile-img" onClick={openCropModal}></img>
                                                            <span className="jobs-apply-main-item-profile-button mx-auto" onClick={openCropModal}>{translate.profile.edit_profile_image}</span>
                                                        </div>
                                                        {
                                                            (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                            && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                        }
                                                    </div> :
                                                    item.key == "weightAndHeight" ?

                                                        <div className="jobs-apply-main-item-expand-grid-item">

                                                            <div className="jobs-apply-main-item-expand-grid-item-half">
                                                                <div className="jobs-apply-main-item-expand-grid-item">
                                                                    <span className="jobs-apply-main-item-expand-title">{translate.job_apply.weight}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                    <FormControl type="number" className={
                                                                        isSubmit && item.status == "MANDATORY" && item.value.includes("null") ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                    }
                                                                        defaultValue={item.value != null ? String(item.value).split(",")[0] : "0"}
                                                                        onChange={(e) => onTextChange('weight', e.target.value, index)}></FormControl>

                                                                </div>
                                                                <div className="jobs-apply-main-item-expand-grid-item">
                                                                    <span className="jobs-apply-main-item-expand-title">{translate.job_apply.height}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                    <FormControl type="number" className={
                                                                        isSubmit && item.status == "MANDATORY" && item.value.includes("null") ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                    }
                                                                        defaultValue={item.value != null ? String(item.value).split(",")[1] : "0"}
                                                                        onChange={(e) => onTextChange('height', e.target.value, index)}></FormControl>

                                                                </div>
                                                                {
                                                                    (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                        ? <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span> :
                                                                        item.error && <span className="jobs-apply-main-item-expand-error">{item.error}</span>
                                                                }
                                                            </div>
                                                        </div> :
                                                        item.key == "resume" ?
                                                            <div className="jobs-apply-main-item-expand-grid-item">
                                                                <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                <span className="jobs-apply-main-item-expand-sub-title">{translate.job_apply.choose_resume}</span>
                                                                <Form.Select className={
                                                                    isSubmit && item.status == "MANDATORY" && item.value == "" ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                }
                                                                    onChange={(e) => onSelectChange("resume", e.target.value, index)}>
                                                                    <option value="" >{translate.job_apply.choose_resume}</option>
                                                                    {
                                                                        allResume && allResume.resumes && allResume.resumes.length > 0 &&
                                                                        allResume.resumes.map((resume: GetResumeIdModel, indexChild: number) => {
                                                                            return (
                                                                                resume.resume_key == item.value ?
                                                                                    <option value={resume.resume_uuid} key={indexChild} selected>{resume.resume_name}</option> :
                                                                                    <option value={resume.resume_uuid} key={indexChild}>{resume.resume_name}</option>

                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                                <span className="jobs-apply-main-item-expand-sub-title">{translate.job_apply.or_upload_resume}
                                                                    <span className="jobs-apply-main-item-expand-link" onClick={() => clickUploadNewResume()}>{translate.job_apply.click}</span></span>
                                                                {
                                                                    (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                    && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                }
                                                            </div>
                                                            :
                                                            item.type == "date" ?
                                                                <div className="jobs-apply-main-item-expand-grid-item">
                                                                    <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                    <Form.Control
                                                                        className={
                                                                            (isSubmit && item.status == "MANDATORY" && (item.value == "" || item.value == "NOT_SELECT")) ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                        }
                                                                        type="date"
                                                                        name="Date of birth"
                                                                        placeholder="birthday"
                                                                        defaultValue={formatDate(item.value)}
                                                                        onChange={(e) => onTextChange(item.key, e.target.value, index)}
                                                                    />
                                                                    {
                                                                        (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                        && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                    }
                                                                </div> :
                                                                item.type == "select" ?
                                                                    <div className="jobs-apply-main-item-expand-grid-item">
                                                                        <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                        <Form.Select className={
                                                                            (isSubmit && item.status == "MANDATORY" && (item.value == "" || item.value == "NOT_SELECT")) ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                        }
                                                                            defaultValue={item.value}
                                                                            onChange={(e) => onSelectChange(item.key, e.target.value, index)}>
                                                                            {
                                                                                item.option && item.option.length > 0 &&
                                                                                item.option.map((inputItem: OptionInputSelectModel, indexChild: number) => {
                                                                                    return (
                                                                                        <option value={inputItem.key} key={indexChild}>{inputItem.value}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                        {
                                                                            (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                            && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                        }
                                                                    </div> :
                                                                    item.type == "autocomplete" ?

                                                                        <div className="jobs-apply-main-item-expand-grid-item">
                                                                            {
                                                                                item.option && item.option.length > 0 &&
                                                                                <>
                                                                                    <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}
                                                                                        <span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>

                                                                                    {
                                                                                        item.option &&
                                                                                        <Typeahead
                                                                                            onInputChange={(e) => onAutoCompleteChange(item.key, e, index)}
                                                                                            onChange={(e) => onAutoCompleteChange(item.key, e, index)}
                                                                                            className={
                                                                                                isSubmit && item.status == "MANDATORY" && item.value.includes("null") ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                                            }
                                                                                            id="menu-align-example"
                                                                                            labelKey="value"
                                                                                            defaultInputValue={item.value.value}
                                                                                            options={item.option}
                                                                                            placeholder=""
                                                                                        />
                                                                                    }


                                                                                    {
                                                                                        (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                                        && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                                    }
                                                                                </>
                                                                            }

                                                                        </div> :
                                                                        item.key == "name" ?
                                                                            <>
                                                                                <div className="jobs-apply-main-item-expand-grid-item">

                                                                                    <div className="jobs-apply-main-item-expand-grid-item">
                                                                                        <span className="jobs-apply-main-item-expand-title">{translate.job_apply.first_name}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                                        <FormControl type="text" className={
                                                                                            isSubmit && item.status == "MANDATORY" && item.value.includes("null") ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                                        }
                                                                                            defaultValue={item.value != null ? String(item.value).split(",")[0] : ""}
                                                                                            onChange={(e) => onTextChange('firstname', e.target.value, index)}></FormControl>
                                                                                        {
                                                                                            (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                                            && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="jobs-apply-main-item-expand-grid-item">
                                                                                    <div className="jobs-apply-main-item-expand-grid-item">
                                                                                        <span className="jobs-apply-main-item-expand-title">{translate.job_apply.last_name}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                                        <FormControl type="text" className={
                                                                                            isSubmit && item.status == "MANDATORY" && item.value.includes("null") ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                                        }
                                                                                            defaultValue={item.value != null ? String(item.value).split(",")[1] : ""}
                                                                                            onChange={(e) => onTextChange('lastname', e.target.value, index)}></FormControl>
                                                                                        {
                                                                                            (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                                            && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :

                                                                            <div className="jobs-apply-main-item-expand-grid-item">
                                                                                <span className="jobs-apply-main-item-expand-title">{item.key}
                                                                                    <span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                                <FormControl type="text" className={
                                                                                    isSubmit && item.status == "MANDATORY" && item.value == "" ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                                }
                                                                                    defaultValue={item.value}
                                                                                    required={item.status == 'MANDATORY' && true}
                                                                                    onChange={(e) => onTextChange(item.key, e.target.value, index)}></FormControl>
                                                                                {
                                                                                    (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                                    && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                                }
                                                                                {
                                                                                    (item.error)
                                                                                    && <span className="jobs-apply-main-item-expand-error">{item.error}</span>
                                                                                }
                                                                            </div>
                                            }
                                        </div>

                                    )
                                })
                            }

                        </div>
                        {
                            inputContactModel && inputContactModel.filter((items: ApplicationInputModel) => items.status != "NOT_REQUIRED").length > 0 &&

                            <>
                                <div className="jobs-apply-main-item-expand-grid-item-full">
                                    <div className="jobs-apply-section-header-control">
                                        <Divider textAlign="left"> <span className="jobs-apply-section-header-text">{translate.job_apply.contact_information}</span></Divider>

                                    </div>
                                    <div className="jobs-apply-main-item-expand-grid">
                                        {
                                            inputContactModel.filter((items: ApplicationInputModel) => items.status != "NOT_REQUIRED").map((item: ApplicationInputModel, index: number) => {

                                                return (
                                                    item.type == "autocomplete" ?
                                                        <div className="jobs-apply-main-item-expand-grid-item" key={index}>
                                                            <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}
                                                                <span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                            {
                                                                item.option &&
                                                                <Typeahead
                                                                    onInputChange={(e) => onAutoCompleteChange(item.key, e, index)}
                                                                    onChange={(e) => onAutoCompleteChange(item.key, e, index)}
                                                                    className={
                                                                        isSubmit && item.status == "MANDATORY" && item.value.includes("null") ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                    }
                                                                    id="menu-align-example"
                                                                    labelKey="value"
                                                                    defaultInputValue={item.value}
                                                                    options={item.option}
                                                                    placeholder=""
                                                                />
                                                            }


                                                            {
                                                                (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                            }
                                                        </div> :
                                                        item.type == "select" ?
                                                            <div className="jobs-apply-main-item-expand-grid-item">
                                                                <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                <Form.Select className={
                                                                    (isSubmit && item.status == "MANDATORY" && (item.value == "" || item.value == "NOT_SELECT")) ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                }
                                                                    defaultValue={item.value}
                                                                    onChange={(e) => onSelectChange(item.key, e.target.value, index)}>
                                                                    {
                                                                        item.option && item.option.length > 0  && item.key != "country" ?
                                                                        item.option.map((inputItem: OptionInputSelectModel, indexChild: number) => {
                                                                            return (
                                                                                <option value={inputItem.key} key={indexChild}>{inputItem.value}</option>
                                                                            )
                                                                        }): item.option && item.option.length > 0  && item.key == "country" &&
                                                                        item.option.map((inputItem: OptionInputSelectModel, indexChild: number) => {
                                                                            return (
                                                                                <option value={inputItem.value} key={indexChild}>{inputItem.value}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                                {
                                                                    (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                    && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                }
                                                            </div> :


                                                            <div className="jobs-apply-main-item-expand-grid-item" key={index}>
                                                                <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}
                                                                    <span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                <FormControl type="text" className={
                                                                    isSubmit && item.status == "MANDATORY" && item.value == "" ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                }
                                                                    defaultValue={item.value}
                                                                    required={item.status == 'MANDATORY' && true}
                                                                    onChange={(e) => onTextChangeContact(item.key, e.target.value, index)}></FormControl>
                                                                {
                                                                    (isSubmit && item.status == "MANDATORY" && item.value == "")
                                                                    && <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                                }
                                                                {
                                                                    (item.error)
                                                                    && <span className="jobs-apply-main-item-expand-error">{item.error}</span>
                                                                }
                                                            </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {
                            inputLanguageModel && inputLanguageModel.filter((items: ApplicationInputModel) => items.status != "NOT_REQUIRED").length > 0 &&
                            <>
                                <div className="jobs-apply-main-item-expand-grid-item-full">
                                    <Divider textAlign="left" className="jobs-apply-main-item-divider-with-title">{translate.job_apply.language}</Divider>

                                    {
                                        inputLanguageModel.filter((items: ApplicationInputModel) => items.status != "NOT_REQUIRED").map((item: ApplicationInputModel, index: number) => {

                                            return (
                                                <div className={
                                                    isSubmit && item.status == "MANDATORY" && languageLevel.filter((item) =>
                                                    (!item.listening &&
                                                        !item.reading &&
                                                        !item.speaking &&
                                                        !item.writing)
                                                    ).length == 4 ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                                } key={index}>
                                                    {
                                                        languageLevel.map((item: Language, index: number) => {
                                                            return (

                                                                <div className="about-lang-control" key={index}>
                                                                    <span className="about-lang-title">{item.value}</span>
                                                                    <div className="about-lang-check-grid">
                                                                        <FormControlLabel control={<Checkbox
                                                                            checked={item.listening} onChange={(e) => changeActionLang(e.target.checked, 'listening', index)} />}
                                                                            className="about-lang-check-item" label="listening" />
                                                                        <FormControlLabel control={<Checkbox
                                                                            checked={item.reading} onChange={(e) => changeActionLang(e.target.checked, 'reading', index)} />}
                                                                            className="about-lang-check-item" label="reading" />
                                                                        <FormControlLabel control={<Checkbox
                                                                            checked={item.speaking} onChange={(e) => changeActionLang(e.target.checked, 'speaking', index)} />}
                                                                            className="about-lang-check-item" label="speaking" />
                                                                        <FormControlLabel control={<Checkbox
                                                                            checked={item.writing} onChange={(e) => changeActionLang(e.target.checked, 'writing', index)} />}
                                                                            className="about-lang-check-item" label="writing" />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        isSubmit && item.status == "MANDATORY" && languageLevel.filter((item) =>
                                                        (!item.listening &&
                                                            !item.reading &&
                                                            !item.speaking &&
                                                            !item.writing)
                                                        ).length == 4 &&
                                                        <span className="jobs-apply-main-item-expand-error">
                                                            {translate.job_apply.choose_language}
                                                        </span>
                                                    }

                                                </div>

                                            )
                                        })
                                    }


                                </div>
                            </>
                        }
                        {
                            inputLicenseModel && inputLicenseModel.filter((items: ApplicationInputModel) => items.status != "NOT_REQUIRED").length > 0 &&



                            <>
                                <div className="jobs-apply-main-item-expand-grid-item-full">
                                    <Divider textAlign="left" className="jobs-apply-main-item-divider-with-title">{translate.job_apply.licenses}</Divider>
                                    <div className="jobs-apply-item-checkbox-flex-control">
                                        {
                                            inputLicenseModel.map((item: ApplicationInputModel, index: number) => {

                                                return (
                                                    <Form.Check
                                                        className={
                                                            isSubmit && item.status == "MANDATORY" && item.value == false ?
                                                                "jobs-apply-item-checkbox jobs-apply-item-checkbox-error" : "jobs-apply-item-checkbox"
                                                        }
                                                        key={index}
                                                        label={item.key
                                                            == "bikeLicense" ? translate.job_apply.bikeLicense :
                                                            item.key == "carLicense" ? translate.job_apply.carLicense
                                                                : translate.job_apply.truckLicense}
                                                        value={item.value}
                                                        defaultChecked={item.value}
                                                        onChange={(e) => onCheckChange(item.key, e.target.checked, index)}
                                                    />

                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        <Button className="jobs-apply-button-save" onClick={() => setIsConfirmUpdate(true)}>{translate.other.save}</Button>
                    </>
                }

            </div>
            {
                showProfileModal && <CropModal open={showProfileModal} setCropSuccess={setCropSuccess}
                    setOpen={setShowProfileModal} src={fileUploadCrop} uuid={profileApplyMod.uuid}></CropModal>
            }
            {
                isUploadResume &&
                <UploadResumeModal open={isUploadResume} handleClose={setHandleClose}></UploadResumeModal>
            }
            {
                isConfirmUpdate &&

                <ConfirmUpdateProfileModal open={isConfirmUpdate} handleClose={handleCloseUpdate} saveCandidateInput={saveCandidateInput}></ConfirmUpdateProfileModal>
            }
        </div>
    )
}

export default CandidateProfile;