import { useEffect, useState } from "react";
import MyInfoComponent from "../my-info/my-info";
import "./view-profile.scss";
import { useLocation, useParams } from "react-router-dom";
import ProfileService from "../../../services/profile/profile.service";
import { ProfileExtModel } from "../../../model/profile/profile-ext.model";
import ERROR_PROFILE from "../../../assets/images/error-profile-public.png";
import ProfileInstantService from "../../../services/profile/profile-instance.service";
import { Helmet } from "react-helmet";
import axios from "axios";
import apiPath from "../../../api-path";
import config from "../../../config";
import UtilityService from "../../../utility/utility.service";

const ViewProfileComponent = () => {

    const location = useLocation();
    const [profileMod, setProfileMod] = useState<ProfileExtModel>();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (location.pathname.includes('/profile/view/')) {
            const pathUrl = location.pathname.split('/profile/view/');
            getProfile(pathUrl[1]);
        }

    }, []);

    const getProfile = (path) => {

        axios.get(config.chat_gpt.base_url + apiPath.gpt_interview + "profile/public/"+ path).then((res) => {

            if(res.data){
                UtilityService().clickSendEvent("view_profile_public", "load");
                setProfileMod(res.data)
            }
        }).catch((err) => {

            if (err.response.status == 400) {
                setIsError(true);
            }
        })
        // ProfileInstantService.getProfilePublic(path).then((res) => {
        //     if (res.data) {
        //         setProfileMod(res.data)
        //     }

        // }).catch((err) => {

        //     if (err.response.status == 400) {
        //         setIsError(true);
        //     }
        // })

       
    }

    return (
        <>
          

            <div className="view-profile-main">
                <div className="view-profile-main-control">

                    {
                        (profileMod && profileMod.profile_json && !isError) && <MyInfoComponent profile={profileMod.profile_json} isView={true}></MyInfoComponent>
                    }
                    {
                        isError &&
                        <div className="view-profile-error-control">
                            <div className="view-profile-error-main">
                                <img src={ERROR_PROFILE} className="view-profile-error-img"></img>
                                <span className="view-profile-error-text">Profile is not public</span>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    )
}

export default ViewProfileComponent;