import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import tokenService from "../../services/sso/token.service";
import { OauthModel } from "../../services/sso/oauth.model";
import RamaService from "../../services/sso/rama.service";
import ApiService from "../../services/api.service";
import { CircularProgress } from "@mui/material";
import config from "../../config";
import "./checking-auth.scss";
import { useProfile } from "../../context/profile.context";
import ProfileInstantService from "../../services/profile/profile-instance.service";
import { ProfileExtModel } from "../../model/profile/profile-ext.model";
import navigationService from "../../services/navigation.service";
import storage from "../../services/sso/storage";


const CheckingAuth = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [profile, error] = useProfile();
    const [paramQuery, setParamQuery] = useState<URLSearchParams>();
    useEffect(() => {
        if (searchParams.get('code') && searchParams.get('state')) {
            tokenService.setCode(searchParams.get('code') + "");
            getAccessToken(searchParams.get('code') + "", tokenService.getVerifyCode(), searchParams.get('page'));
        } else if (tokenService.getUser() && searchParams.get('page')) {
            getProfile(searchParams.get('page'), false);
        }
    }, [])


    useEffect(() => {
        const paramQuert = new URLSearchParams(location.search)
        setParamQuery(paramQuert)
    }, [])

    const getAccessToken = (code, codeVerifier, path) => {
        try {
            ApiService().oauthToken(code, codeVerifier, path).then((res: OauthModel) => {
                if (res) {
                    tokenService.setUser(res);
                    getProfile(path, false);
                } else {
                    tokenService.clearAll();
                }
            })
        } catch {
            tokenService.clearAll();
        }


    }

    const getProfile = (path, isChecking) => {


        RamaService.getProfile().then((res) => {

            if (res.data) {
                tokenService.setProfile(res.data);
                if (isChecking) {


                } else {

                    ProfileInstantService.getProfile().then((resProfile) => {

                        if (resProfile.data) {
                            const profileExt = resProfile.data as ProfileExtModel;

                            if (profileExt.profile_public_settings) {
                                if (path.includes("jobs/apply")) {
                                    navigate(path);
                                } else {
                                    navigate('/profile');
                                }
                            } else {
                                profileExt.profile_public_settings = {
                                    about: true,
                                    activities: true,
                                    certificates: true,
                                    contact: true,
                                    educations: true,
                                    experiences: true,
                                    interest: true,
                                    skills: true,
                                    uploadFile: true,
                                    personality: true,
                                    resume: true
                                }
                                ProfileInstantService.updateProfileExt(profileExt).then((res) => {
                                    if (res.data) {
                                        if (path.includes("jobs/apply")) {
                                            navigate(path);
                                        } else {
                                            navigate('/profile');
                                        }
                                    }


                                }).catch((err) => {


                                })
                            }
                        }
                    }).catch((err) => {
                        if (err.response.status == 400) {
                            navigationService.getPath()
                            if(location && location.search.includes("page=")){
                                const path = location.search;
                                const params = new URLSearchParams(path);
                                console.log(params.get('page'));
                                const page = params.get('page');
                                if (err.response.data.error == "Profile not found") {
                                    if (page && page.includes("jobs/apply")) {
                                        localStorage.setItem(storage.path_redirect, page);
                                        navigate("/onboard");
                                    } else {
                                        navigate("/onboard");
                                    }
                                }
                            }else{
                                if (err.response.data.error == "Profile not found") {
                                    navigate("/onboard");
    
                                }
                            }
                          
                           
                        }

                    })

                }
            } else if (res.status !== 200) {
                console.log(location);
                navigate('/onboard');
            }
        }).catch((err) => {
            if (err.response.data.message == "User not found") {
                console.log(location);
                navigate('/onboard');
            }
        })


    }

    return (

        <>
            <div className="checking-auth">
                <div className="checking-auth-loading-control">
                    <CircularProgress className="checking-auth-loading"></CircularProgress>
                    <span className="checking-auth-loading-text">กำลังตรวจสอบการเข้าถึง...</span>
                </div>

            </div>
        </>
    )

}

export default CheckingAuth;