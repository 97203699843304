import { useContext, useEffect, useState } from "react"
import { ProfileModel } from "../../../../model/profile.model"
import CLOSE_IMG from "../../../../assets/images/close.png";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import "../education/education.scss";
import { Button } from "react-bootstrap";
import RamaService from "../../../../services/sso/rama.service";
import COUNTRY_JSON from "../../../../assets/json/jobs/country.json";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import { OptionInputSelectModel } from "../../../../model/jobs/application-form.model";
import UtilityService from "../../../../utility/utility.service";

const ContactComponent = ({ profile, setProfile, setCloseModal }: any) => {
    const {translateGlobal } :any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [isSaving, setIsSaving] = useState(false);
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [phone, setPhone] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [lineId, setLineId] = useState('');
    const [isValidPhoneLINE, setIsValidPhoneLINE] = useState(true);
    const [whatsapp, setWhatsApp] = useState('');
    const [wechat, setWeChat] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const closeModal = () => {
        setCloseModal();
    }

    useEffect(() => {

        if (profileMod.email) {
            setEmail(profileMod.email);
        }
        if (profileMod.phone) {
            setPhone(profileMod.phone)
        }
        if (profileMod.lineId) {
            setLineId(profileMod.lineId)
        }
        if (profileMod.whatsappId) {
            setWhatsApp(profileMod.whatsappId)
        }
        if (profileMod.wechatId) {
            setWeChat(profileMod.wechatId)
        }
        if (profileMod.address) {
            setAddress(profileMod.address)
        }
        if (profileMod.country) {
            setCountry(profileMod.country)
        }
    }, [profileMod])

    const textChange = (text, key) => {

        const profileModEdit = { ...profileMod } as ProfileModel
        if (key == 'phone') {
            setPhone(text);
            if (text.length > 0) {
                if (validatePhoneNumber(text)) {
                    setIsValidPhone(true)
                } else {
                    setIsValidPhone(false)
                }
            } else {
                setIsValidPhone(true)
            }
        } else if (key == 'email') {
            setEmail(text);
            if (text.length > 0) {
                if (validateEmail(text)) {
                    setIsValidEmail(true)
                } else {
                    setIsValidEmail(false)
                }
            } else {
                setIsValidEmail(true)
            }

        } else if (key == 'lineId') {
            setLineId(text);
            if (text.length > 0) {
                if (validateLineId(text)) {
                    setIsValidPhoneLINE(true)
                } else {
                    setIsValidPhoneLINE(false)
                }
            } else {
                setIsValidPhoneLINE(true)
            }

        } else if (key == 'whatsappId') {
            setWhatsApp(text);
        } else if (key == 'wechatId') {
            setWeChat(text);
        } else if (key == 'address') {
            setAddress(text);
        }else if (key == 'country') {
            setCountry(text);
        }



    }

    const saveValue = () => {
        const profileModEdit = { ...profileMod } as ProfileModel
        setIsSaving(true);
        profileModEdit.phone = phone;
        profileModEdit.email = email;
        profileModEdit.lineId = lineId;
        profileModEdit.address = address;
        profileModEdit.country = country;
        profileModEdit.whatsappId = whatsapp;
        profileModEdit.wechatId = wechat
        RamaService.updateProfile(profileModEdit).then((res) => {

            if(res.data){
                UtilityService().clickSendEvent("save_contact", "submit");
                ProfileInstantService.updateProfilePublic(profileModEdit);
                setProfile(profileModEdit);
                setProfileMod(profileModEdit);
                closeModal();
            }
           
        })
    }
    const validatePhoneNumber = (phoneNumber) => {
        // Regular expression for Thai landline phone numbers
        const thaiLandlineRegex = /^0\d{1,2}[-\s]?\d{3}[-\s]?\d{4}$/;

        // Regular expression for Thai mobile phone numbers
        const thaiMobileRegex = /^0[89]\d{1}[-\s]?\d{7}$/;

        return thaiLandlineRegex.test(phoneNumber) || thaiMobileRegex.test(phoneNumber);
    };
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        return emailRegex.test(email);
    };

    const validateLineId = (lineId) => {
        // Regular expression for LINE ID validation
        const lineIdRegex = /^[a-zA-Z0-9_-]{4,33}$/;

        return lineIdRegex.test(lineId);
    };
    return (

        <>
            <div className="education">
                <div className="education-close-control">
                    <img src={CLOSE_IMG} onClick={closeModal} className="education-close-img"></img>
                </div>
                <div className="d-flex">
                    <span className="education-header">{translate.profile.contact}</span>
                </div>
                {
                    isSaving ? <div className="profile-edit-loading-control">

                        <div className="profile-edit-loading-flex">
                            <CircularProgress className="profile-edit-progress"></CircularProgress>
                            <span className="profile-edit-text">{translate.other.updating}</span>
                        </div>
                    </div>

                        :
                        <div className="d-flex flex-column experience-grid-edit">
                            <div className="experience-grid">
                                <div className="education-item">
                                    <TextField inputProps={{ className: "education-item-input" }}
                                        InputLabelProps={{ className: "education-item-label" }}
                                        type="text"
                                        label="ที่อยู่ (Address)" variant="outlined" value={address}
                                        onChange={(e) => textChange(e.target.value, 'address')}

                                    />
                                </div>
                                <div className="education-item">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label" className="education-item-label" >{translate.profile.country}</InputLabel>
                                        <Select

                                            inputProps={{ className: "education-item-input-select" }}
                                            value={country}
                                            label={translate.profile.country}
                                            onChange={(e) => textChange(e.target.value, 'country')}
                                        >
                                            {
                                                COUNTRY_JSON && COUNTRY_JSON?.map((item2: OptionInputSelectModel, index2: number) => {
                                                    return (
                                                        country == item2.key ?
                                                            <MenuItem value={item2.value} className="education-menu-item" key={index2} selected>{item2.value}</MenuItem> :
                                                            <MenuItem value={item2.value} className="education-menu-item" key={index2}>{item2.value}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="education-item">
                                    <TextField inputProps={{ className: "education-item-input" }}
                                        InputLabelProps={{ className: "education-item-label" }}
                                        type="number"
                                        label={translate.profile.phone_number} variant="outlined" value={phone}
                                        onChange={(e) => textChange(e.target.value, 'phone')}
                                        error={!isValidPhone}
                                        helperText={!isValidPhone ? translate.error.invalid_tel : ''}
                                    />
                                </div>
                                <div className="education-item">
                                    <TextField inputProps={{ className: "education-item-input" }}
                                        InputLabelProps={{ className: "education-item-label" }}
                                        type="text"
                                        label={translate.profile.email} variant="outlined" value={email}
                                        onChange={(e) => textChange(e.target.value, 'email')}
                                        error={!isValidEmail}
                                        helperText={!isValidEmail ?  translate.error.invalid_email: ''} />
                                </div>
                                <div className="education-item">
                                    <TextField inputProps={{ className: "education-item-input" }}
                                        InputLabelProps={{ className: "education-item-label" }}
                                        type="text"
                                        label={translate.profile.line_id} variant="outlined" value={lineId}
                                        onChange={(e) => textChange(e.target.value, 'lineId')}
                                        error={!isValidPhoneLINE}
                                        helperText={!isValidPhoneLINE ? translate.error.invalid_lineid : ''} />
                                </div>
                                <div className="education-item">
                                    <TextField inputProps={{ className: "education-item-input" }}
                                        InputLabelProps={{ className: "education-item-label" }}
                                        type="text"
                                        label={translate.profile.whatsappId} variant="outlined" value={whatsapp}
                                        onChange={(e) => textChange(e.target.value, 'whatsappId')} />
                                </div>
                                <div className="education-item">
                                    <TextField inputProps={{ className: "education-item-input" }}
                                        InputLabelProps={{ className: "education-item-label" }}
                                        type="text"
                                        label={translate.profile.wechatId} variant="outlined" value={wechat}
                                        onChange={(e) => textChange(e.target.value, 'wechatId')} />
                                </div>
                            </div>
                            <div className="education-item-edit-button-control">
                                <Button className="education-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                                <Button className="education-item-edit-button" onClick={() => saveValue()}>{translate.other.save}</Button>
                            </div>

                        </div>
                }


            </div>

        </>
    )
}

export default ContactComponent;