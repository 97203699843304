



import axios from "axios"
import config from "../../config"
import apiPath from "../../api-path"
import tokenService from "../sso/token.service"
import RamaService from "../sso/rama.service"


export default function PersonalityTestService() {

    return {

        async getResult(answer_id) {
            const objectResult = { answer_id: answer_id }
            return axios.post(config.personality_api.base_url + "/big5_results",
                objectResult, {
                headers: {
                    "Content-Type": 'application/json',
                    'x-api-key': config.personality_api.x_api_key_quiz,
                }
            }
            ).then(res => res.data).catch(err => Promise.reject(err))
        }

    }
}