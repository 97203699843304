import { useContext, useEffect, useState } from "react";
import "./my-info.scss";
import { Activities, Certificate, Education, Experience, File, Language, ProfileModel, Skill } from "../../../model/profile.model";

import config from "../../../config";
import { Button, Form } from "react-bootstrap";
import CropModal from "../../../modal/crop.modal";
import { useFilePicker } from 'use-file-picker';
import {
    FileAmountLimitValidator,
    FileTypeValidator,
    FileSizeValidator,
    ImageDimensionsValidator,
} from 'use-file-picker/validators';
import RamaService from "../../../services/sso/rama.service";
import PROFILE_MOCK from "../../../assets/images/profile_mock.png";
import EditProfileModal from "../../../modal/edit-profile.modal";
import EDUCATION_LEVEL_JSON from "../../../assets/json/education-level.json";
import { useLocation, useNavigate } from "react-router-dom";
import { ProvideContext } from "../../../context/provider.context";
import ProfileService from "../../../services/profile/profile.service";
import { Alert, FormControlLabel, Snackbar, Switch } from "@mui/material";
import { ProfileExtModel } from "../../../model/profile/profile-ext.model";
import ErrorModal from "../../../modal/error/error";
import { TranslateModel } from "../../../model/translate.model";

import ImportResumeComponent from "../import-resume/import-resume";
import ProfileInstantService from "../../../services/profile/profile-instance.service";
import moment from "moment";
import ResumeBuilderInstantService from "../../../services/resume-builder/resume-builder-instance.service";
import { AllResumeModel } from "../../../model/all-resume.model";
import { GetResumeIdModel } from "../../../model/get-resume.model";
import featureConfig from "../../../feature.config";
import axios from "axios";
import apiPath from "../../../api-path";
import UIRightInfoComponent from "./ui-right-info/ui-right-info";
import UtilityService from "../../../utility/utility.service";
const MyInfoComponent = ({ profile, isView }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const location = useLocation();
    const navigate = useNavigate();
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [profileExt, setProfileExt] = useState<ProfileExtModel>();
    const [urlLink, setUrlLink] = useState('');
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [isEditUrl, setIsEditUrl] = useState(false);
    const [fileUpload, setFileUpload] = useState<any>();
    const [fileUploadCrop, setFileUploadCrop] = useState<any>();
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [isEditProfile, setIsEditProfile] = useState("");
    const [isNew, setIsNew] = useState(false);
    const [errorHandler, setErrorHandler] = useState(false);
    const [isViewOnly, setIsViewOnly] = useState(isView || false);
    const [isEditName, setIsEditName] = useState(false);
    const [isImportResume, setIsImportResume] = useState(false);
    const [resumeModel, setResumeModel] = useState<GetResumeIdModel>();

    const [isCopy, setIsCopy] = useState(false);
    const { openFilePicker, filesContent, loading, errors } = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['image/*'],
        limitFilesConfig: { min: 0, max: 1 },
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(['jpg', 'png', 'jpeg']),
            new FileSizeValidator({ maxFileSize: config.MAX_ATTACHMENT_SIZE * 1024 * 1024 /* 50 MB */ })
        ],
        onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
            // this callback is called when there were no validation errors
            setFileUploadCrop(filesContent[0].content)
            setShowProfileModal(true);
        },
        onFilesRejected: ({ errors }) => {
            // this callback is called when there were validation errors
            console.log(errors);
            alert(errors[0]["reason"] + ". Limit file is " + config.MAX_ATTACHMENT_SIZE + "MB");
        },
    });

    useEffect(() => {
        if (profile) {
            if (!isView) {
                UtilityService().clickSendEvent("profile_page", "load");
                getUrl();
                getAllResume();
            }

        }
    }, [profile])

    useEffect(() => {
        if (profileMod) {
            console.log(profileMod)
            if (profileMod.profileImageUrl) {
                setFileUpload(profileMod.profileImageUrl);
            }

        }
    }, [profileMod])



    useEffect(() => {

        if (isView) {
            UtilityService().clickSendEvent("profile_page_view", "load");
            if (location.pathname.includes('/profile/view/')) {
                const pathUrl = location.pathname.split('/profile/view/');
                axios.get(config.chat_gpt.base_url + apiPath.gpt_interview + "profile/public/" + pathUrl[1]).then((res) => {

                    if (res.data) {
                        const resultProfile = res.data as ProfileExtModel;
                        if (resultProfile.resume) {
                            // getResumeUrl(resultProfile.resume.resume_uuid);
                            setResumeModel(resultProfile.resume!);

                        }
                        setProfileExt(resultProfile);
                    }
                }).catch((err) => {

                    if (err.response.status == 400) {
                        // setIsError(true);
                    }
                })
                // ProfileInstantService.getProfilePublic(pathUrl[1]).then((res) => {
                //     if (res.data) {
                //         setProfileExt(res.data);
                //     }
                // })
            }
        }


    }, [])

    const getAllResume = () => {

        ResumeBuilderInstantService.getAllResume().then((res) => {
            if (res.data) {

                const result = res.data as AllResumeModel;
                if (result.resumes) {
                    const resumeItem = result.resumes.filter((item) => item.is_default);
                    if (resumeItem.length > 0) {
                        getResumeUrl(resumeItem[0].resume_uuid);
                        setResumeModel(resumeItem[0]);
                    }
                }
            }
        })
    }

    const getResumeUrl = (resume_uuid) => {
        ResumeBuilderInstantService.getResumeUrl(resume_uuid).then((res) => {
            if (res.data) {
                setResumeModel(res.data);
            }
        })
    }


    const getUrl = () => {
        if (profileMod) {
            ProfileInstantService.getProfile().then((res) => {
                console.log(res);
                if (res.data) {
                    setProfileExt(res.data);
                    if (res.data.profile_path) {
                        setUrlLink(res.data.profile_path)
                    } else {
                        setUrlLink(profileMod.uuid)
                    }

                }

            }).catch((errors) => {
                if (errors.response.status == 400) {
                    if (errors.response.data.error == "Profile not found") {
                        navigate("/onboard");
                    }
                }
                // if(errors);
                setUrlLink(profileMod.uuid)
            })
        }
    }
    const editUrl = () => {
        if (isEditUrl && profileExt && profileMod) {
            setIsEditUrl(false);
            profileExt.profile_path = urlLink;

            profileMod.resumeUrl = urlLink;
            RamaService.updateProfile(profileMod).then((res2) => {
                ProfileInstantService.updateProfilePublic(profileMod);
                setProfileMod(profileMod);
            })
            ProfileInstantService.updatePublicProfileURL(profileExt).then((res) => {
                if (res.data) {
                    setProfileExt(profileExt)
                }
            })
        } else {
            setIsEditUrl(true);
        }
    }

    const editProfile = (action) => {
        UtilityService().clickSendEvent("profile_page_edit_" + action, "click");
        if (action == 'education') {
            setShowEditProfileModal(true);
            setIsEditProfile("education");
        } else if (action == 'experience') {
            setShowEditProfileModal(true);
            setIsEditProfile("experience");
        } else if (action == 'contact') {
            setShowEditProfileModal(true);
            setIsEditProfile("contact");
        } else if (action == 'about') {
            setShowEditProfileModal(true);
            setIsEditProfile("about");
        }
        else if (action == 'skills') {
            setShowEditProfileModal(true);
            setIsEditProfile("skills");
        } else if (action == 'interesting') {
            setShowEditProfileModal(true);
            setIsEditProfile("interesting");
        } else if (action == 'activities') {
            setShowEditProfileModal(true);
            setIsEditProfile("activities");
        } else if (action == 'candidateFiles') {
            setShowEditProfileModal(true);
            setIsEditProfile("candidateFiles");
        } else if (action == 'jobOpportunity') {
            setShowEditProfileModal(true);
            setIsEditProfile("jobOpportunity");
        } else if (action == 'certificates') {
            setShowEditProfileModal(true);
            setIsEditProfile("certificates");
        } else if (action == 'profile') {
            setIsEditName(true);
        } else if (action == 'introduction') {
            setShowEditProfileModal(true);
            setIsEditProfile("introduction");
        }

    }

    const setProfile = (profile) => {
        console.log(profile);
        setProfileMod(profile)
    }

    const setCropSuccess = (file) => {


        RamaService.uploadProfileImage(file).then((res) => {
            if (res.data && profileMod) {
                profileMod.profileImageKey = res.data[0]
                RamaService.updateProfile(profileMod).then((res2) => {
                    if (res2.data) {
                        const res2Profile = res2.data as ProfileModel
                        ProfileInstantService.updateProfilePublic(res2Profile);
                        setFileUpload(res2Profile.profileImageUrl)
                        setShowProfileModal(false);
                    }

                })

            }

        })
    }

    const openCropModal = () => {
        if(!isViewOnly){
            openFilePicker()
        }
      
    }

    const initEducationLevel = (educationlv) => {
        const filteredData = EDUCATION_LEVEL_JSON.filter(item => item.value == educationlv);
        if (filteredData.length > 0) {
            // The item with the specified id exists in the filtered array
            const foundItem = filteredData[0];
            return foundItem.name;
        } else {
            // The item with the specified id does not exist
            return "-"
        }
    }
    const changeUrl = (value) => {
        setUrlLink(value);
    }


    const viewProfile = (url) => {

        if (!isEditUrl) {
            navigate('/profile/view/' + url)
        }

    }


    const clickImportResume = () => {

        setIsImportResume(true);
    }
    const checkLevel = (level) => {
        const value = level;
        if (value == 'BACHELOR' || value == 'MASTER' || value == 'DOCTORATE' || value == 'NO_EDUCATION') {
            return false;
        } else {
            return true;
        }
    }

    const addNew = (action) => {
        UtilityService().clickSendEvent("profile_page_add_new_" + action, "click");
        if (action == 'education') {
            setIsNew(true);
            setShowEditProfileModal(true);
            setIsEditProfile("education");
        } else if (action == 'contact') {
            setShowEditProfileModal(true);
            setIsEditProfile("education");
        } else if (action == 'activities') {
            setShowEditProfileModal(true);
            setIsEditProfile("activities");
        } else if (action == 'certificates') {
            setShowEditProfileModal(true);
            setIsEditProfile("certificates");
        }

        else {
            setIsNew(true);
            setShowEditProfileModal(true);
            setIsEditProfile("experience");
        }
    }

    function calculateExperience(startDate: Date, endDate: Date): string {
        let years = endDate.getFullYear() - startDate.getFullYear();
        let months = endDate.getMonth() - startDate.getMonth();
        let days = endDate.getDate() - startDate.getDate();

        if (days < 0) {
            months -= 1;
            days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }

        let yearText = years > 0 ? `${years} ${translate.profile.year}${years > 1 ? '' : ''}` : '';
        let monthText = months > 0 ? `${months} ${translate.profile.month}${months > 1 ? '' : ''}` : '';
        let dayText = days > 0 ? `${days} ${translate.profile.day}${days > 1 ? '' : ''}` : '';

        return [yearText, monthText, dayText].filter((text) => text).join(', ');
    }
    const clickViewResume = async (resume_uuid) => {
        if (isViewOnly) {
            if (resumeModel && resumeModel.resume_uuid) {
                const pathUrl = location.pathname.split('/profile/view/');
                axios.get(config.chat_gpt.base_url + apiPath.gpt_interview + "profile/public/" + pathUrl[1] + "/resume/url").then((res) => {

                    if (res.data) {
                        UtilityService().clickSendEvent("view_public_resume", "click");
                        window.open(res.data.resume_url, "_blank");
                    }
                })

            }

        } else {
            UtilityService().clickSendEvent("view_resume", "click");
            window.open(config.url + 'resume/view/' + resume_uuid, "_blank");
        }

    }


    const onTextChange = (action, value) => {
        if (profileMod) {
            const profileEdit = { ...profileMod };
            if (action == 'firstName') {
                profileEdit.firstName = value;
            } else if (action == 'lastName') {
                profileEdit.lastName = value;
            }
            setProfileMod(profileEdit)
        }

    }

    const saveName = () => {
        RamaService.updateProfile(profileMod).then((res) => {

            if (res.data) {
                ProfileInstantService.updateProfilePublic(profileMod);
                setProfileMod(res.data);
                setIsEditName(false);
            }
        })
    }

    const navigatePublic = (url) => {
        navigate(url)
    }

    const handleCloseImportResume = () => {

        setIsImportResume(false);
    }


    return (

        <>
            <div className={
                isViewOnly ? "my-info my-info-view-only" : "my-info"
            }>
                {
                    (profileMod && profileExt) &&
                    <>
                        <div>
                            <div className="my-info-control">
                                <div className="my-info-text-control">
                                    <div className="my-info-text-img-control">
                                        
                                            <img src={fileUpload ? fileUpload : PROFILE_MOCK} className="my-info-text-img" onClick={openCropModal}></img>
                                        
                                        {
                                            !isViewOnly &&
                                            <Button className="my-info-text-url-edit-button mx-auto" onClick={openCropModal}>{translate.profile.edit_profile_image}</Button>
                                        }
                                    </div>

                                    <div className="my-info-text-name-control">
                                        {
                                            !isViewOnly && <Button className="my-info-item-header-button" onClick={() => editProfile('profile')}>{translate.other.edit}</Button>
                                        }

                                        {
                                            isEditName ?
                                                <div className="my-info-edit-name-control">
                                                    <div className="my-info-edit-name-item">
                                                        <span className="my-info-edit-name-title">
                                                            {translate.profile.first_name}
                                                        </span>
                                                        <Form.Control type="text"
                                                            className="my-info-edit-name-input"
                                                            value={profileMod.firstName} placeholder={translate.profile.first_name}
                                                            onChange={(e) => onTextChange('firstName', e.target.value)}></Form.Control>
                                                    </div>
                                                    <div className="my-info-edit-name-item">
                                                        <span className="my-info-edit-name-title">
                                                            {translate.profile.last_name}
                                                        </span>
                                                        <Form.Control type="text"
                                                            className="my-info-edit-name-input"
                                                            value={profileMod.lastName} placeholder={translate.profile.last_name}
                                                            onChange={(e) => onTextChange('lastName', e.target.value)}></Form.Control>
                                                    </div>
                                                    <div className="my-info-edit-name-item">
                                                        <Button className="my-info-edit-name-save-button" onClick={saveName}>{translate.other.save}</Button>
                                                    </div>
                                                </div>
                                                : <span className="my-info-text-name">{profileMod.firstName} {profileMod.lastName}</span>
                                        }


                                        <span className="my-info-text-position">{profileMod.email}</span>
                                        <span className="my-info-text-address">
                                            {profileMod.country && profileMod.country}</span>
                                        {
                                            !isViewOnly &&
                                            <div className="my-info-text-url-control">
                                                <span className="my-info-text-url">{translate.profile.my_profile_url}
                                                </span>
                                                <div className="my-info-text-url-link-control"

                                                    onClick={() => viewProfile(urlLink)}><span className="my-info-text-url-link-black">{config.url + "profile/view/"}</span>
                                                    {
                                                        isEditUrl ?
                                                            <Form.Control type="text" className="my-info-text-url-edit-input" value={urlLink} placeholder={urlLink} onChange={(e) => changeUrl(e.target.value)}></Form.Control> :
                                                            <span className="my-info-text-url-link-black">{urlLink}</span>
                                                    }
                                                </div>
                                                {
                                                    !isViewOnly &&
                                                    <Button className="my-info-text-url-edit-button" onClick={editUrl}>{isEditUrl ? translate.other.save : translate.other.edit}</Button>
                                                }

                                            </div>
                                        }
                                        {
                                            isViewOnly && resumeModel &&
                                            <div className="my-info-text-url-control">
                                                {
                                                    profileMod.resumePublished &&
                                                    <Button className="my-info-button-view-resume"
                                                        onClick={() => clickViewResume(resumeModel.resume_uuid)}>{translate.profile.view_resume}</Button>
                                                }

                                            </div>
                                        }
                                        {/* <Button className="my-info-button-view-resume"
                                            onClick={clickImportResume}>Import resume</Button> */}

                                    </div>
                                </div>
                            </div>
                            <div className="d-lg-none d-block">
                                <UIRightInfoComponent
                                    profileExt={profileExt}
                                    profileMod={profileMod}
                                    setProfileExt={setProfileExt}
                                    setErrorHandler={setErrorHandler}
                                    isViewOnly={isViewOnly}
                                    resumeModel={resumeModel}
                                    setResumeModel={setResumeModel}
                                    clickViewResume={clickViewResume}
                                    editProfile={editProfile}
                                ></UIRightInfoComponent>
                            </div>

                            {
                                profileMod &&
                                <div>
                                    <div className="my-info-item-control">
                                        <div className="d-flex">
                                            <span className="my-info-item-header">{translate.profile.introduce}</span>
                                            {
                                                !isViewOnly &&
                                                <Button className="my-info-item-header-button"
                                                    onClick={() => editProfile('introduction')}>{translate.other.edit}</Button>
                                            }
                                        </div>
                                        <div className="my-info-item-divider"></div>
                                        {
                                            profileMod.introduction &&

                                            <span className="my-info-item-desc">{profileMod.introduction}</span>
                                        }

                                    </div>
                                    {
                                        featureConfig.profile.work_experience &&
                                        <div className={
                                            (isViewOnly && !profileExt.profile_public_settings.experiences) ? "d-none" : "my-info-item-control"
                                        }>
                                            <div className="d-flex">
                                                <div className="my-info-item-header-control">
                                                    <span className="my-info-item-header">{translate.profile.experience}</span>
                                                    {/* {
                                                        !isViewOnly && featureConfig.menu.jobs &&
                                                        <Button className="my-info-item-header-extra-button"
                                                            onClick={() => navigatePublic(navigateConfig.jobs.main)}
                                                        >{translate.profile.start_apply_jobs}</Button>

                                                    }
                                                    {
                                                        !isViewOnly && featureConfig.menu.jobs &&
                                                        <Button className="my-info-item-header-extra-button"
                                                            onClick={() => navigatePublic(navigateConfig.jobs.interview)}>{translate.interview_ai.title}</Button>
                                                    } */}
                                                </div>
                                                {
                                                    !isViewOnly &&
                                                    profileMod.experiences.length > 0 && <Button className="my-info-item-header-button" onClick={() => editProfile('experience')}>{translate.other.edit}</Button>
                                                }
                                            </div>

                                            <div className="my-info-item-divider"></div>
                                            {
                                                profileMod.experiences.length > 0 ?
                                                    profileMod.experiences.map((item: Experience, index: number) => {

                                                        return (
                                                            <div className="my-info-timeline-control" key={index}>
                                                                <div className="my-info-timeline-line-control">
                                                                    <div className="my-info-timeline-dot"></div>
                                                                    {
                                                                        index != profileMod.experiences.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                    }

                                                                </div>
                                                                <div className="my-info-timeline-desc-control">
                                                                    <span className="my-info-timeline-desc-header">{item.jobTitle} ที่ {item.companyName}</span>
                                                                    <div className="my-info-timeline-desc-info-control">
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.start_date}: {moment(new Date(item.startDate)).format("MMMM YYYY")} - {
                                                                            item.stillWorkHere ? "ปัจจุบัน" : item.endDate && moment(new Date(item.endDate)).format("MMMM YYYY")
                                                                        }</span>

                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.exp_total}: {
                                                                            item.stillWorkHere ?
                                                                                calculateExperience(new Date(item.startDate), new Date()) :
                                                                                item.endDate &&
                                                                                calculateExperience(new Date(item.startDate), new Date(item.endDate))
                                                                        }</span>
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.salary}: {
                                                                            item.salary.toLocaleString() + " บาท"
                                                                        }</span>
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.job_type}: {
                                                                            item.employmentType == "FULL_TIME" ? translate.job_type[0].value :
                                                                                item.employmentType == "PART_TIME" ? translate.job_type[1].value :
                                                                                    item.employmentType == "CONTRACT" ? translate.job_type[2].value :
                                                                                        item.employmentType == "INTERNSHIP" ? translate.job_type[3].value :
                                                                                            item.employmentType == "FREELANCE" ? translate.job_type[4].value :
                                                                                                translate.job_type[5].value
                                                                        }</span>
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.responsibility}: {item.responsibilities}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <div className="no-info-control">
                                                        <div className="no-info-control-flex">
                                                            <span className="no-info-control-text">{translate.error.no_information}</span>
                                                            {
                                                                !isViewOnly &&
                                                                <Button className="no-info-control-add-button" onClick={() => addNew('experience')}>+ {translate.other.add}</Button>
                                                            }
                                                        </div>
                                                    </div>
                                            }


                                        </div>
                                    }
                                    {
                                        featureConfig.profile.education &&
                                        <div className={
                                            (isViewOnly && !profileExt.profile_public_settings.educations) ? "d-none" : "my-info-item-control"
                                        }>
                                            <div className="d-flex">
                                                <span className="my-info-item-header">{translate.profile.education}</span>
                                                {
                                                    !isViewOnly &&
                                                    profileMod.educations.length > 0 && <Button className="my-info-item-header-button" onClick={() => editProfile('education')}>{translate.other.edit}</Button>
                                                }

                                            </div>
                                            <div className="my-info-item-divider"></div>
                                            {
                                                profileMod.educations.length > 0 ?
                                                    profileMod.educations.map((item: Education, index: number) => {

                                                        return (
                                                            <div className="my-info-timeline-control" key={index}>
                                                                <div className="my-info-timeline-line-control">
                                                                    <div className="my-info-timeline-dot"></div>
                                                                    {
                                                                        index != profileMod.educations.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                    }

                                                                </div>
                                                                <div className="my-info-timeline-desc-control">
                                                                    <span className="my-info-timeline-desc-header">
                                                                        {
                                                                            checkLevel(item.educationLevel) ?
                                                                                <>{initEducationLevel(item.educationLevel)}  {translate.profile.text_at}  {item.institute} </>
                                                                                : <>{item.faculty},{item.fieldOfStudy} {translate.profile.text_at} {item.institute} </>
                                                                        }
                                                                    </span>
                                                                    <div className="my-info-timeline-desc-info-control">
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.education_level}: {initEducationLevel(item.educationLevel)}</span>
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.gpa}: {item.gpax ? item.gpax : "-"}</span>
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.start_date}: {item.startYear ? item.startYear : "-"}</span>
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.end_year}: {item.graduationYear ? item.graduationYear : "-"}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <div className="no-info-control">
                                                        <div className="no-info-control-flex">
                                                            <span className="no-info-control-text">{translate.error.no_information}</span>
                                                            {
                                                                !isViewOnly &&
                                                                <Button className="no-info-control-add-button" onClick={() => addNew('education')}>+ {translate.other.add}</Button>
                                                            }
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    }
                                    {
                                        featureConfig.profile.activities &&
                                        <div className={
                                            (isViewOnly && !profileExt.profile_public_settings.activities) ? "d-none" : "my-info-item-control"
                                        }>
                                            <div className="d-flex">
                                                <span className="my-info-item-header">{translate.profile.activiy_interest}</span>
                                                {
                                                    !isViewOnly &&
                                                    profileMod.activities.length > 0 && <Button className="my-info-item-header-button" onClick={() => editProfile('activities')}>{translate.other.edit}</Button>
                                                }
                                            </div>

                                            <div className="my-info-item-divider"></div>
                                            {
                                                profileMod.activities.length > 0 ?
                                                    profileMod.activities.map((item: Activities, index: number) => {

                                                        return (
                                                            <div className="my-info-timeline-control" key={index}>
                                                                <div className="my-info-timeline-line-control">
                                                                    <div className="my-info-timeline-dot"></div>
                                                                    {
                                                                        index != profileMod.activities.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                    }

                                                                </div>
                                                                <div className="my-info-timeline-desc-control">
                                                                    <span className="my-info-timeline-desc-header">{item.role} ที่ {item.organizationName}</span>
                                                                    <div className="my-info-timeline-desc-info-control">
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.start_date}: {moment(new Date(item.startDate)).format("MMMM YYYY")} - {
                                                                            item.isOnGoing ? "ปัจจุบัน" : item.endDate && moment(new Date(item.endDate)).format("MMMM YYYY")
                                                                        }</span>

                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.exp_total}: {
                                                                            item.isOnGoing ?
                                                                                calculateExperience(new Date(item.startDate), new Date()) :
                                                                                item.endDate &&
                                                                                calculateExperience(new Date(item.startDate), new Date(item.endDate))
                                                                        }</span>
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.responsibility}: {item.summary}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <div className="no-info-control">
                                                        <div className="no-info-control-flex">
                                                            <span className="no-info-control-text">{translate.error.no_information}</span>
                                                            {
                                                                !isViewOnly &&
                                                                <Button className="no-info-control-add-button" onClick={() => addNew('activities')}>+ {translate.other.add}</Button>
                                                            }

                                                        </div>
                                                    </div>
                                            }


                                        </div>
                                    }
                                    {
                                        featureConfig.profile.certificate &&
                                        <div className={
                                            (isViewOnly && !profileExt.profile_public_settings.certificates) ? "d-none" : "my-info-item-control"
                                        }>
                                            <div className="d-flex">
                                                <span className="my-info-item-header">{translate.profile.certificates}</span>
                                                {
                                                    !isViewOnly &&
                                                    profileMod.certificates.length > 0 && <Button className="my-info-item-header-button" onClick={() => editProfile('certificates')}>{translate.other.edit}</Button>
                                                }
                                            </div>

                                            <div className="my-info-item-divider"></div>
                                            {
                                                profileMod.certificates.length > 0 ?
                                                    profileMod.certificates.map((item: Certificate, index: number) => {

                                                        return (
                                                            <div className="my-info-timeline-control" key={index}>
                                                                <div className="my-info-timeline-line-control">
                                                                    <div className="my-info-timeline-dot"></div>
                                                                    {
                                                                        index != profileMod.certificates.length - 1 && <div className="my-info-timeline-line-vertical"></div>
                                                                    }

                                                                </div>
                                                                <div className="my-info-timeline-desc-control">
                                                                    <span className="my-info-timeline-desc-header">{item.certificateName}</span>
                                                                    <div className="my-info-timeline-desc-info-control">
                                                                        <span className="my-info-timeline-desc-info-title">{translate.profile.cert_desc}: {item.certificateDetail}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <div className="no-info-control">
                                                        <div className="no-info-control-flex">
                                                            <span className="no-info-control-text">{translate.error.no_information}</span>
                                                            {
                                                                !isViewOnly &&
                                                                <Button className="no-info-control-add-button" onClick={() => addNew('certificates')}>+ {translate.other.add}</Button>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="d-lg-block d-none">
                            <UIRightInfoComponent
                                profileExt={profileExt}
                                profileMod={profileMod}
                                setProfileExt={setProfileExt}
                                setErrorHandler={setErrorHandler}
                                isViewOnly={isViewOnly}
                                resumeModel={resumeModel}
                                setResumeModel={setResumeModel}
                                clickViewResume={clickViewResume}
                                editProfile={editProfile}
                            ></UIRightInfoComponent>
                        </div>
                    </>
                }
            </div>

            {
                showProfileModal && profileMod && <CropModal open={showProfileModal} setCropSuccess={setCropSuccess}
                    setOpen={setShowProfileModal} src={fileUploadCrop} uuid={profileMod.uuid}></CropModal>
            }
            {
                showEditProfileModal && <EditProfileModal open={showEditProfileModal} setOpen={setShowEditProfileModal} action={isEditProfile}
                    profile={profileMod} setProfile={setProfile} isNew={isNew} isApplicationForm={false}></EditProfileModal>
            }

            {
                errorHandler &&
                <ErrorModal open={errorHandler} onclose={setErrorHandler} message={translate.error.smt_wrong}></ErrorModal>
            }
            {
                isImportResume &&

                <ImportResumeComponent open={isImportResume} handleClose={handleCloseImportResume}> </ImportResumeComponent>
            }
            <Snackbar open={isCopy} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setIsCopy(false)} >
                <Alert onClose={() => setIsCopy(false)} severity="success" sx={{ width: '100%' }}>
                    Copy to clipboard.
                </Alert>
            </Snackbar>
        </>
    )


}
export default MyInfoComponent;