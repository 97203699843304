// index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import config from './config';
import App from './App';
import AppOneProfile from './App.oneprofile';
import { LanguageProvider } from './provider/language.provider';
import App360 from './App.360';
const env = process.env.REACT_APP_ENVIRONMENT; // Get the current environment
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'one_profile_production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}
// Import and use the appropriate environment-specific index.tsx file
switch (env) {
  case 'one_profile_production':
    root.render(
      <LanguageProvider>
        <App />
      </LanguageProvider>
    );
    break;
  case '360_local':
    root.render(
      <LanguageProvider>
        <App360 />
      </LanguageProvider>
    );
    break;
  case '360_test':
    root.render(
      <LanguageProvider>
        <App360 />
      </LanguageProvider>
    );
    break;
  default:
    // Handle other environments or provide a default fallback
    root.render(
      <LanguageProvider>
        <App />
      </LanguageProvider>
    );

    break;
}
reportWebVitals();
ReactGA.initialize([
  {
    trackingId: config.ga_4,
  }
]);
