import OnBoardStep1Component from "./step-1/step-1";
import "./onboard.scss";
import { useState } from "react";
import OnBoardStep2Component from "./step-2/step-2";
import { ProfileModel } from "../../model/profile.model";
import OnBoardStep3Component from "./stpe-3/step-3";


const OnBoardComponent = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const [profile, setProfile] = useState<ProfileModel>();


    const clickMoveStep = (step) => {
        setCurrentStep(step)
    }

    const updateProfileModel = (profileModel) => {
        setProfile(profileModel);
    }
    return (
        <>
            <div className="onboard-main">
                <div className="onboard-main-control">
                    <div className="onboard-steps-control">
                        <div className="onboard-steps-number-control-active"
                            onClick={() => clickMoveStep(1)}
                        >1</div>
                        <div className={
                            currentStep > 1 ? "onboard-steps-divider-active" : "onboard-steps-divider"
                        }></div>
                        <div className=
                            {
                                currentStep > 1 ? "onboard-steps-number-control-active" : "onboard-steps-number-control"
                            }
                            onClick={() => clickMoveStep(2)}
                        >2</div>
                        <div className={
                            currentStep > 2 ? "onboard-steps-divider-active" : "onboard-steps-divider"
                        }></div>
                        <div className={
                            currentStep > 2 ? "onboard-steps-number-control-active" : "onboard-steps-number-control"
                        }>3</div>
                    </div>
                    {
                        currentStep == 1 && <OnBoardStep1Component clickMoveStep={clickMoveStep} updateProfileModel={updateProfileModel}></OnBoardStep1Component>
                    }
                    {
                        currentStep == 2 && <OnBoardStep2Component profileMod={profile} clickMoveStep={clickMoveStep} updateProfileModel={updateProfileModel}></OnBoardStep2Component>
                    }
{
                        currentStep == 3 && <OnBoardStep3Component  profileMod={profile} clickMoveStep={clickMoveStep} updateProfileModel={updateProfileModel}></OnBoardStep3Component>
                    }
                </div>

            </div>
        </>
    )
}

export default OnBoardComponent;