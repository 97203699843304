import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { ApplicationInputModel, Question } from "../../../../model/jobs/application-form.model";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck as faCircleCheckActive } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark as faCircleXmarkActive } from "@fortawesome/free-solid-svg-icons";
import { TextareaAutosize } from "@mui/material";
import { Button } from "react-bootstrap";
import { CandidateAnswers } from "../../../../model/jobs/profile-apply.model";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";

const QuestionsJobApply = ({ profileApplyMod, setProfileApplyMod, inputOtherModel, setInputOtherModel, isQuestionEdit, setIsQuestionEdit, isBigSubmit }: any) => {
    const [isSubmit, setIsSubmit] = useState(false);

    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);

    useEffect(() => {
        if (isBigSubmit) {
            setIsSubmit(true);
        }

    }, [isBigSubmit])
    const handleQuestionEdit = () => {
        if (isQuestionEdit) {
            setIsQuestionEdit(false);
        } else {
            setIsQuestionEdit(true);
        }
    }

    const clickAnswerTRUEFALSE = (index, value) => {
        setInputOtherModel((prevItems) =>
            prevItems.map((item, idx) =>
                idx === index ? { ...item, value: value, error: "" } : item
            )
        );

    }

    const saveQuestionsAnswer = () => {

        setIsSubmit(true);
        if (profileApplyMod) {

            let isChecking = true;
            if (inputOtherModel.filter(item => item.question_type == "TRUE_FALSE_QUESTION" && item.status == "MANDATORY" && item.value == "none").length > 0) {
                isChecking = false;
            }
            if (inputOtherModel.filter(item => item.question_type == "SUBJECTIVE" && item.status == "MANDATORY" && item.value == null).length > 0) {
                isChecking = false;
            }
            if (isChecking) {
                setIsQuestionEdit(false);
                const answerModel: CandidateAnswers[] = new Array();

                inputOtherModel.map((item: ApplicationInputModel, index: number) => {
                    if (item.question_id) {
                        const answerItem: CandidateAnswers = {
                            answer: item.value,
                            questionId: item.question_id
                        }

                        answerModel.push(answerItem);
                    }
                })
                profileApplyMod.candidateAnswers = answerModel;
                setProfileApplyMod(profileApplyMod);
            }
        }
    }

    const textChange = (value, index) => {
        setInputOtherModel((prevItems) =>
            prevItems.map((item, idx) =>
                idx === index ? { ...item, value: value, error: "" } : item
            )
        );
    }
    return (

        <>
            <div className="jobs-apply-main-item-control">
                <div className="jobs-apply-main-item-step-control" onClick={handleQuestionEdit}>
                    <div className="jobs-apply-main-item-step">
                        <span className="jobs-apply-main-item-step-text">3</span>
                    </div>
                    <span className="jobs-apply-main-item-step-header">{translate.job_apply.questions}</span>
                    <div className="jobs-apply-main-item-step-button-control">

                        <FontAwesomeIcon icon={isQuestionEdit ? faChevronUp : faChevronDown} className="jobs-apply-main-item-step-button"></FontAwesomeIcon>

                    </div>

                </div>
                <div className="jobs-apply-main-item-expand-control">

                    {
                        isQuestionEdit &&
                        <>
                            <div className="jobs-apply-main-item-expand-divider"></div>
                            {
                                inputOtherModel && inputOtherModel.map((item: ApplicationInputModel, index: number) => {

                                    return (
                                        item.question_type == "TRUE_FALSE_QUESTION" ?
                                            <div className={
                                                isSubmit && item.status == "MANDATORY" && item.value == "none" ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                            } key={index}>
                                                {
                                                    item.question_type == "TRUE_FALSE_QUESTION" && item.question &&

                                                    <div className="jobs-apply-questions-true-false-control">
                                                        <div className="jobs-apply-questions-text-question-control">
                                                            <span className="jobs-apply-questions-text-question">{item.question_number}.
                                                                <span className=
                                                                    {
                                                                        isSubmit && item.status == "MANDATORY" && item.value == "none" ? "jobs-apply-questions-text-question-html-error" : "jobs-apply-questions-text-question-html"
                                                                    }
                                                                    dangerouslySetInnerHTML={{ __html: item.question }}></span></span>
                                                        </div>

                                                        <div className="jobs-apply-questions-true-false-icon-control">
                                                            <FontAwesomeIcon icon={
                                                                item.value == true ? faCircleXmark :
                                                                    item.value == false ? faCircleXmarkActive :
                                                                        item.value == "none" ? faCircleXmark
                                                                            : faCircleXmark
                                                            }
                                                                className="jobs-apply-questions-true-false-icon-false"
                                                                onClick={() => clickAnswerTRUEFALSE(index, false)}></FontAwesomeIcon>
                                                            <FontAwesomeIcon icon={
                                                                item.value == true ? faCircleCheckActive :
                                                                    item.value == false ? faCircleCheck
                                                                        : faCircleCheck
                                                            } className="jobs-apply-questions-true-false-icon-true"
                                                                onClick={() => clickAnswerTRUEFALSE(index, true)}></FontAwesomeIcon>
                                                        </div>
                                                    </div>
                                                }


                                            </div> :
                                            <div className={
                                                isSubmit && item.status == "MANDATORY" && (item.value == null || item.value == "") ? "my-info-item-control my-info-item-control-error" : "my-info-item-control"
                                            } key={index}>


                                                {
                                                    item.question_type == "SUBJECTIVE" && item.question &&

                                                    <div className="jobs-apply-questions-answer-control">
                                                        <span className="jobs-apply-questions-text-question">{item.question_number}.
                                                            <span className={
                                                                isSubmit && item.status == "MANDATORY" && (item.value == null || item.value == "") ? "jobs-apply-questions-text-question-html-error" : "jobs-apply-questions-text-question-html"
                                                            } dangerouslySetInnerHTML={{ __html: item.question }}></span></span>

                                                        <TextareaAutosize
                                                            className="jobs-apply-questions-answer-textarea"
                                                            minRows={5}
                                                            value={item.value}
                                                            onChange={(e) => textChange(e.target.value, index)} />
                                                        {
                                                            (isSubmit && item.value == null) &&
                                                            <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span>
                                                        }
                                                    </div>
                                                }

                                            </div>


                                    )
                                })
                            }
                            <Button className="jobs-apply-button-save" onClick={saveQuestionsAnswer}>{translate.other.save}</Button>
                        </>

                    }

                </div>

            </div>

        </>
    )
}
export default QuestionsJobApply;