
import { useContext, useEffect, useRef, useState } from "react";
import "./view-resume.scss";
import html2canvas from "html2canvas";
import { Button, Container } from "react-bootstrap";
import Template_0_Component from "../template/0/template-0";
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import Template_1_Component from "../template/1/template-1";
import Template_2_Component from "../template/2/template-2";
import { CircularProgress, Divider, MenuItem, Select, Stack, Switch, Typography } from "@mui/material";
import { ResumeTemplateModel } from "../../../model/resume-builder/resume-template.model";
import { ProvideContext } from "../../../context/provider.context";
import { ProfileModel } from "../../../model/profile.model";
import UtilityService from "../../../utility/utility.service";
import { GetResumeIdModel } from "../../../model/get-resume.model";
import config from "../../../config";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TranslateModel } from "../../../model/translate.model";
import RamaService from "../../../services/sso/rama.service";
import navigateConfig from "../../../navigate.config";
import ResumeService from "../../../services/resume.service";
import ResumeBuilderInstantService from "../../../services/resume-builder/resume-builder-instance.service";
import { getOneResumeModel } from "../../../model/resume-builder/get-one-resume.model";
import { useProfile } from "../../../context/profile.context";
import axios from "axios";
import apiPath from "../../../api-path";
const ViewResumeComponent = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [resumeModel, setResumeModel] = useState<getOneResumeModel>();
    const [templ, setTempl] = useState<ResumeTemplateModel>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isDowload, setIsDownload] = useState(false);
    const [idResume, setIDResume] = useState('');
    const [resumeName, setResumeName] = useState("");
    const [isViewOnly, setIsViewOnly] = useState(false);
    const [isKey, setIsKey] = useState(false);
    const [isEditStatus, setIsEditStatus] = useState(false);
    const params = useParams();
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const navigate = useNavigate();
    const iframeRef = useRef(null);
    const [profile, error] = useProfile();
    const [scale, setScale] = useState(getScale());

    useEffect(() => {
        function handleResize() {
            setScale(getScale());
        }

        // Set the scale based on the current window width when the component mounts
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // Helper function to determine scale based on viewport width
    function getScale(): number {
        const width = window.innerWidth;
        if (width < 768) { // sm breakpoint
            return 0.46;
        } else if (width >= 768 && width < 1024) { // md breakpoint
            return 0.9;
        } else { // lg and above
            return 1;
        }
    }
    useEffect(() => {

        if (profileMod) {
            if (location.pathname.includes(navigateConfig.resume.view_only) && params.id) {
                getResumeData(params.id)
                setIsViewOnly(true);
            } else if (params.id) {
                getResumeData(params.id)
                setIsViewOnly(false);
            }
        }

    }, [profileMod]);

    useEffect(() => {
        if (profile) {
            setProfileMod(profile);
        } else {

        }
    }, [profile])
    const downloadPdf = async () => {
        // Temporarily set scale to 1 for PDF download
        setScale(1);
        setIsDownload(true);
        setTimeout(async () => {
            const element = document.querySelector('.page-container') as HTMLElement;
            const canvas = await html2canvas(element);
            const originalWidth = canvas.width; // For a canvas, or use img.naturalWidth for an image
            const originalHeight = canvas.height; // For a canvas, or use img.naturalHeight for an image

            const aspectRatio = originalWidth / originalHeight;
            const scaledHeight = 230 / aspectRatio;
            const imgData = canvas.toDataURL('image/jpeg');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: [230, scaledHeight]
            });

            // Since the page is custom-sized to match the image, add it without scaling
            pdf.addImage(imgData, 'JPEG', 0, 0, 230, scaledHeight);

           
            setIsDownload(false);
            // After PDF download, revert scale back according to viewport size
            console.log(getScale());
            setScale(getScale());
            const file = DataURIToBlob(pdf.output('datauristring'));
            let stringWithHyphens = "";
            if (resumeName.includes(".pdf")) {
                stringWithHyphens = resumeName;
            } else {
                stringWithHyphens = resumeName + ".pdf";
            }
          
            const uploadResult = await ResumeService.uploadResumeBuilder(file, stringWithHyphens);
            if (uploadResult.data && templ) {
                const resumeObject: GetResumeIdModel = {
                    resume_key: uploadResult.data.resume_key,
                    resume_name: stringWithHyphens,
                    resume_uuid: idResume,
                    template_id: templ.template_id,
                    resume_status: "PUBLISHED"
                }
                ResumeBuilderInstantService.updateResumeWithResumeKey(resumeObject).then((result) => {

                    if (result.data) {
                        setIsDownload(false);
                        UtilityService().clickSendEvent("download_resume_ios", "click")
                        pdf.save(stringWithHyphens);
                    }
                })

            }
        }, 0); // setTimeout ensures this runs after the scale update
    };

    const getResumeDataPublic = (resume_uuid) => {

        axios.get(config.chat_gpt.base_url + apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid).then((res) => {

            if (res.data) {
                console.log(res.data);
                setResumeModel(res.data);
                setIDResume(resume_uuid);
                if (res.data.resume_key) {
                    setIsKey(true);
                } else {
                    setIsKey(false)
                }

                setTempl(res.data.resume_json);
                setResumeName(res.data.resume_name)
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        })
    }

    const getResumeData = (resume_uuid) => {

        ResumeBuilderInstantService.getOneResume(resume_uuid).then((res) => {

            if (res.data) {
                console.log(res.data);
                setResumeModel(res.data);
                setIDResume(resume_uuid);
                if (res.data.resume_key) {
                    setIsKey(true);
                } else {
                    setIsKey(false)
                }

                setTempl(res.data.resume_json);
                setResumeName(res.data.resume_name)
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        })

    }

    function iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    async function convertHtmlToPdfWithResizing(htmlElement: HTMLElement, resizeWidth: number, resizeHeight: number) {
        try {
            const contentHeight = htmlElement.offsetHeight; // Measure the height of the content
            const pdf = new jsPDF('p', 'pt', [htmlElement.offsetWidth, contentHeight]);

            const canvas = await html2canvas(htmlElement, { scale: 5 });
            const imgData = canvas.toDataURL('image/jpeg');

            const pdfWidth = pdf.internal.pageSize.getWidth();
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, contentHeight);

            return pdf;
        } catch (error) {
            console.error('Error converting HTML to PDF with resizing:', error);
            throw error;
        }
    }

    const handleDownload = (dataUrl, fileName) => {
        if (dataUrl) {
            const a = document.createElement('a');
            a.href = dataUrl;
            a.download = fileName;
            a.click();
        }
    };

    const edit = (resume_uuid) => {

        navigate(navigateConfig.resume.builder + resume_uuid);
    }

    const download = async (isDownloaded) => {
        if (containerRef.current && templ) {
            setScale(1);
            setIsDownload(true);
            const resizeWidth = 2381.10;
            const resizeHeight = 3067.55;
            const pdf = await convertHtmlToPdfWithResizing(containerRef.current, resizeWidth, resizeHeight);


            //Save the PDF as a file
            if (!iOS()) {
                downloadPdf()

            } else {
                const canvas = await html2canvas(containerRef.current);
                const imgData = canvas.toDataURL('image/jpeg');
                let stringWithHyphens = "";
                if (resumeName.includes("jpg")) {
                    stringWithHyphens = resumeName;
                } else {
                    stringWithHyphens = resumeName + ".jpg";
                }

                const file = DataURIToBlob(imgData);
                const uploadResult = await ResumeService.uploadResumeBuilder(file, stringWithHyphens);
                if (uploadResult.data) {
                    const resumeObject: GetResumeIdModel = {
                        resume_key: uploadResult.data.resume_key,
                        resume_name: stringWithHyphens,
                        resume_uuid: idResume,
                        template_id: templ.template_id,
                        resume_status: "PUBLISHED"
                    }
                    ResumeBuilderInstantService.updateResumeWithResumeKey(resumeObject).then((result) => {

                        if (result.data) {
                            setIsDownload(false);
                            UtilityService().clickSendEvent("download_resume_android", "click")
                            {
                                isDownloaded && handleDownload(imgData, stringWithHyphens);
                            }

                        }
                    })

                }
            }
        }
    }


    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }

    const templateFree = () => {
        return (
            (templ && resumeModel) &&
                Number(templ.template_id) == 0 ?
                <Template_0_Component templ={templ} containerRef={containerRef} resultResume={resumeModel}></Template_0_Component> :
                templ && Number(templ.template_id) == 1 ?
                    <Template_1_Component templ={templ} containerRef={containerRef} resultResume={resumeModel}></Template_1_Component> :
                    templ && Number(templ.template_id) == 2 ?
                        <Template_2_Component templ={templ} containerRef={containerRef}></Template_2_Component> :
                        <></>
        )
    }

    const clickOpenPublicResume = (id) => {
        window.open(config.url + "resume/view/only/" + id, "_blank");
    }

    const onSelectChange = (value) => {
        if (value) {
            if (resumeModel) {
                if (resumeModel.resume_json) {
                    download(false);
                }
                const resumeObject = {
                    resume_uuid: resumeModel.resume_uuid,
                    resume_status: "PUBLISHED"
                }
                ResumeBuilderInstantService.updateResumeWithResumeKey(resumeObject).then((result) => {

                    if (result.data) {

                        UtilityService().clickSendEvent("change_status_resume_to_publish", "click")


                    }
                })

            }

        } else {
            const resumeObject = {
                resume_uuid: idResume,
                resume_status: "DRAFT"
            }
            ResumeBuilderInstantService.updateResumeWithResumeKey(resumeObject).then((result) => {

                if (result.data) {

                    UtilityService().clickSendEvent("change_status_resume_to_draft", "click")


                }
            })
        }
    }

    const backHome = () => {
        navigate("/resumes");
    }

    return (
        <>



            {
                !isViewOnly && resumeModel &&
                <div className="view-resume-grid-control">
                    <div className="view-builder-control">

                        {
                            idResume && <div className="view-builder-text-control">
                                <div className="view-resume-header-control">
                                    <Button className="view-resume-back-button"
                                        onClick={() => backHome()}>กลับหน้าหลัก</Button>
                                    <Button className="view-resume-edit-button"
                                        onClick={() => edit(idResume)}>{translate.other.edit}</Button>

                                </div>
                                <div className="view-resume-header-control">

                                    <span className="view-resume-header-title">สถานะ Resume</span>
                                    <Stack direction="row" alignItems="center" className="view-resume-switch-control">
                                        <Typography className="view-resume-switch-text">Draft</Typography>
                                        <Switch defaultChecked={resumeModel.resume_status == "PUBLISHED" ? true : false}
                                            onChange={(e) => onSelectChange(e.target.checked)}
                                            inputProps={{ 'aria-label': 'ant design' }} />
                                        <Typography className="view-resume-switch-text">Published</Typography>
                                    </Stack>
                                </div>
                                <Divider textAlign="left" className="view-resume-divider-text">รายละเอียด</Divider>

                                <div className="view-resume-text-grid-control">
                                    <span className="view-resume-text-title">URL ของคุณ:</span>
                                    <div className="view-builder-resume-url-control">
                                        <span className="view-builder-text-a" onClick={() => clickOpenPublicResume(idResume)}>{config.url}resume/view/public/only/{idResume}</span>
                                    </div>
                                </div>
                                {/* <div className="view-resume-text-grid-control">
                                    <span className="view-resume-text-title">สถานะ:</span>
                                    {
                                        isEditStatus ?
                                            <Select
                                                className="view-resume-input-select"
                                                inputProps={{ className: "view-resume-input-select" }}
                                                value={isKey ? "Published" : "Draft"}
                                                onChange={(e) => onSelectChange(e.target.value)}
                                            >
                                                <MenuItem value="Draft" >Draft</MenuItem>
                                                <MenuItem value="Published" >Published</MenuItem>
                                            </Select> :
                                            <div className="flex">
                                                <span className="view-resume-text-desc">{isKey ? "Published" : "Draft"}</span>
                                            </div>
                                    }

                                </div> */}
                                <div className="view-resume-text-grid-control">
                                    {
                                        idResume && isDowload ?
                                            <div className="view-builder-downloading-control">
                                                <CircularProgress className="view-builder-downloading-progress" size={15} />
                                                <span className="view-builder-downloading-tex">{translate.resume_builder.downloading}</span>
                                            </div> :
                                            !isDowload ?
                                                <div className="d-flex flex-column">
                                                    <Button className="view-builder-download" onClick={() => download(true)}>{translate.resume_builder.download}</Button>
                                                    {
                                                        <span className="view-builder-text-consent" dangerouslySetInnerHTML={{ __html: translate.resume_builder.terms }}>

                                                        </span>
                                                    }

                                                </div> : null
                                    }
                                </div>
                            </div>

                        }




                    </div>
                    <div className="view-resume-control">

                        <div className="page-container"
                            style={{
                                transform: `scale(${scale})`,

                            }}
                        >
                            {
                                resumeModel.resume_json && templ && templateFree()
                            }
                            {
                                !resumeModel.resume_json && resumeModel.resume_url &&
                                <div className="view-resume-iframe">
                                    <iframe
                                        src={resumeModel.resume_url}
                                        width="100%"
                                        height="100%"
                                        ref={iframeRef}
                                    ></iframe>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                isViewOnly && resumeModel &&
                <div className="view-resume-control">
                    {
                        isDowload && <div className="page-container">
                            {
                                resumeModel.resume_json && templ && templateFree()
                            }
                            {
                                !resumeModel.resume_json && resumeModel.resume_url &&
                                <div className="view-resume-iframe">
                                    <iframe
                                        src={resumeModel.resume_url}
                                        width="100%"
                                        height="100%"
                                        ref={iframeRef}
                                    ></iframe>
                                </div>
                            }
                        </div>
                    }

                </div>
            }


        </>
    )
}

export default ViewResumeComponent;