import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "./save.modal.scss";
import ApiService from "../services/api.service";
import tokenService from "../services/sso/token.service";
import { useNavigate } from "react-router-dom";
import { AllResumeModel } from "../model/all-resume.model";
import { GetResumeIdModel } from "../model/get-resume.model";
import { CircularProgress } from "@mui/material";
import config from "../config";
import { ProvideContext } from "../context/provider.context";
import { TranslateModel } from "../model/translate.model";


const SaveModal = ({ open, setOpen }: any) => {
    const {translateGlobal } :any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [name, setName] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrMsg] = useState("");
    const [isRedirect, setIsRedirect] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

    }, [])

    const submit = () => {

        setIsSubmit(true);
        setIsRedirect(true);
        if (name) {
            ApiService().addResume( name).then((res) => {
                if (res.data) {
                    setIsRedirect(false);
                    handleClose();
                    window.open(config.resume_builder + "choose-template" + "?" + new URLSearchParams({
                        resume_uuid: res.data.resume_uuid,
                        access_token: tokenService.getLocalAccessToken()
                    }), "_self")
                  
                } else if (res.response.status == 400) {
                    setIsError(true);
                    setErrMsg(res.response.data.error);
                }

            }).catch(error => {
            })
        } else {

        }

    }

    const onTextChange = (e) => {
        setName(e.target.value);
    }


    return (

        <>
            <Modal show={open} onHide={handleClose} className="profile-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="save-modal">
                    {
                        !isRedirect && <div className='save-modal-control'>
                            <span className='save-modal-name'>ตั้งชื่อเรซูเม่ของคุณ</span>
                            <Form>
                                <Form.Control type='text' className='save-modal-input' onChange={(e) => onTextChange(e)}></Form.Control>
                                {
                                    (isSubmit && !name) && <span className="save-modal-error">กรุณาตั้งชื่อ</span>
                                }
                            </Form>
                            {
                                (isError) && <span className="save-modal-error">พบข้อผิดพลาด: {errorMsg}</span>
                            }
                            <Button onClick={submit} className="save-modal-button">{translate.other.save}</Button>
                        </div>
                    }

                    {
                        isRedirect &&
                        <div className="loading-progress-control">
                            <CircularProgress className="loading-progress" />
                            <span className="loading-progress-text">ระบบกำลังนำทางไปยังหน้าสร้างเรซูเม่ ....</span>
                        </div>
                    }


                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>

            </Modal>
        </>
    )
}

export default SaveModal;