import apiPath from "../../api-path";
import instanceGPTUpload from "../auth-gpt-upload.service";
import instanceGPT from "../auth-gpt.service";




const sugestonPost = (desc, path) => {

    return instanceGPT.post(apiPath.gpt_interview + apiPath.resume + apiPath.generator + path,  JSON.stringify(desc))
}

const getResumeId = (resumeKey) => {
    return instanceGPT.get( apiPath.gpt_interview + apiPath.resume + "/" + apiPath.path.upload, resumeKey)
}
const getResumeUrl = (resumeUUID) => {
    return instanceGPT.get( apiPath.gpt_interview + apiPath.resume + "/" + resumeUUID, {})
}
const getAllResume = () => {
    return instanceGPT.get( apiPath.gpt_interview + apiPath.resume)
}

const updateResumeWithResumeKey = (objectResult) => {
    return instanceGPT.put(apiPath.gpt_interview + apiPath.resume + "/" + objectResult.resume_uuid, objectResult)
}

const addResumeWithOutJson = (resume_name, tempalte_id, is_profile_img, profile_img) => {
    const resumeJson = {
        "resume_name": resume_name,
        "is_profile_img" : is_profile_img,
        "profile_img": profile_img,
        "resume_json" : {
            "is_save" : false
        },
        "template_id":tempalte_id
    }
    console.log(resumeJson);
    return instanceGPT.post(apiPath.gpt_interview + apiPath.resume, resumeJson)
}

const updateProfileImg = (resume_uuid,fileUpload, filename) => {
  
    const formData = new FormData();
    formData.append("file", fileUpload, filename);
    return instanceGPTUpload.put(apiPath.gpt_interview + apiPath.resume + "/image/"+ resume_uuid , formData)
}

const addResume= (resumeObject, resume_name, template_id) => {
    const resumeJson = {
        "resume_json": resumeObject,
        "resume_name": resume_name,
        "template_id": template_id
    }
    return instanceGPT.post(apiPath.gpt_interview + apiPath.resume, resumeJson)
}

const deleteResume = (resume_uuid) => {

    return instanceGPT.delete(apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid)
}

const updateResumeTemplate = (resume_uuid, template_id, is_profile_img) => {
    const resumeJson = {
        template_id: template_id + ""
        
    }
    return instanceGPT.put(apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid, resumeJson);
}

const updateResumeJson = (resume_uuid, resumeObject) => {
    const resumeJson = {
        "is_profile_img" : resumeObject.is_profile_img,
        "profile_img" :  resumeObject.profile_img,
        "resume_json": resumeObject
    }
    return instanceGPT.put(apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid, resumeJson)
}
const updateResumeStatus = (resume_uuid, resume_status) => {
    const resumeJson = {
        "resume_status" : resume_status
    }
    return instanceGPT.put(apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid, resumeJson)
}

const updateResumeDefault = (resume_uuid) => {
   
    return instanceGPT.post(apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid+ "/default", {})
}
const getOneResume= (resume_uuid) => {

    return instanceGPT.get(apiPath.gpt_interview + apiPath.resume + "/" + resume_uuid)

}

const slotResume = () => {

    return instanceGPT.get(apiPath.gpt_interview + apiPath.resume + "/" + "slot")
    
}

const createCoverLetter = (objectResult) => {

    return instanceGPT.post(apiPath.gpt_interview + apiPath.resume + "/" + "cover_letter", objectResult);
}

const ResumeBuilderInstantService = {
    sugestonPost,
    getResumeId,
    getResumeUrl,
    updateResumeWithResumeKey,
    addResumeWithOutJson,
    addResume,
    deleteResume,
    updateResumeTemplate,
    updateResumeJson,
    getAllResume,
    getOneResume,
    slotResume,
    createCoverLetter,
    updateResumeStatus,
    updateResumeDefault,
    updateProfileImg
};

export default ResumeBuilderInstantService;