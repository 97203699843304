import { useContext, useEffect, useState } from "react";
import { ProfileModel, Skill } from "../../../../model/profile.model";
import "../education/education.scss";
import "./introduction.scss";
import { Button, Form, FormControl } from "react-bootstrap";
import CLOSE_IMG from "../../../../assets/images/close.png";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import RamaService from "../../../../services/sso/rama.service";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import UtilityService from "../../../../utility/utility.service";


const IntroductionComponent = ({ profile, setProfile, setCloseModal }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [isSaving, setIsSaving] = useState(false);
    const [introValue, setIntroValue] = useState("");


    useEffect(() => {
        if (profileMod) {
            if (profileMod.introduction) {
                setIntroValue(profileMod.introduction);
            }
        }

    }, [profileMod])
    const closeModal = () => {
        setCloseModal();
    }

    const handleChange = (value) => {
        setIntroValue(value);
    }


    const submit = () => {
        const profileModEdit = { ...profileMod } as ProfileModel
        setIsSaving(true);
            profileModEdit.introduction = introValue;
            RamaService.updateProfile(profileModEdit).then((res) => {

                if(res.data){
                    UtilityService().clickSendEvent("save_introduction", "submit");
                    ProfileInstantService.updateProfilePublic(profileModEdit);
                    setProfile(profileModEdit);
                    setProfileMod(profileModEdit);
                    closeModal();
                }
              
            })

    };





    return (
        <>
            <div className="introduction-control">
                <div className="education-close-control">
                    <img src={CLOSE_IMG} onClick={closeModal} className="education-close-img"></img>
                </div>
                <div className="introduction-title-control">

                    <span className="introduction-title">{translate.profile.introduce}
                    </span>
                </div>

                <Form className="skills-form-control">
                <span className="introduction-suggest">{translate.error.introduce_length}</span>
                    <FormControl as="textarea" className="introduction-form-text-area"
                        rows={5} value={introValue}
                        maxLength={300}
                        onChange={(e) => handleChange(e.target.value)} ></FormControl>
                        
                    <div className="introduction-item-edit-button-control">
                        <Button className="introduction-item-edit-button" onClick={() => submit()}>{translate.other.save}</Button>
                        <Button className="introduction-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default IntroductionComponent;