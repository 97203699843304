import { useContext, useEffect, useState } from "react";
import { Certificate, Experience, ProfileModel, Skill } from "../../../../model/profile.model";
import "./certificates.scss";
import { Button, Form } from "react-bootstrap";
import "../education/education.scss";
import RamaService from "../../../../services/sso/rama.service";
import CERT_IMG from "../../../../assets/images/certificate.png";
import { TextareaAutosize } from "@mui/material";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import UtilityService from "../../../../utility/utility.service";


const CertificatesComponent = ({ profile, setProfile, setCloseModal, isApplicationForm }: any) => {
    const {translateGlobal } :any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [isSaving, setIsSaving] = useState(false);
    const [certificate, setCertificate] = useState<Certificate[]>(new Array()); // Initialize with one empty skill field

    useEffect(() => {
        if (profileMod) {
            if (profileMod.skills) {
                const certDesc: Certificate[] = new Array();
                profileMod.certificates.map((item: Certificate, index: number) => {


                    certDesc.push(item);
                })
                setCertificate(certDesc);
            }
        }
    }, [profileMod])

    const closeModal = () => {
        setCloseModal();
    }

    const handleSkillChange = (action, index, value) => {
        if (certificate) {
            const newSkills = [...certificate];
            if (action == "name") {
                newSkills[index].certificateName = value;
            } else {
                newSkills[index].certificateDetail = value;
            }

            setCertificate(newSkills);
        }

    };

    const addSkillField = () => {
        if (certificate) {
            setCertificate([...certificate, { certificateDetail: '', certificateName: '' }]);
        }
    };

    const removeSkillField = (index) => {
        if (certificate) {
            if (certificate.length >= 0) {
                const newSkills = certificate.filter((_, i) => i !== index);
                setCertificate(newSkills);
            }
        }
    };

    const submit = () => {
        const profileModEdit = { ...profileMod } as ProfileModel
        setIsSaving(true);
        if (certificate) {
            
            profileModEdit.certificates = certificate;
            if (isApplicationForm) {
                UtilityService().clickSendEvent("added_certificate_apply", "submit");
                const checkCert = certificate.filter(item => item.certificateName.length > 0 && item.certificateDetail.length > 0);
                if(checkCert.length == certificate.length){
                    setProfile(profileModEdit);
                    setProfileMod(profileModEdit);
                    closeModal();
                }else{

                }
                
            } else {
                UtilityService().clickSendEvent("added_certificate", "submit");
                const checkCert = profileModEdit.certificates.filter(item => item.certificateName.length > 0 && item.certificateDetail.length > 0);
                if(checkCert.length == certificate.length){
                    RamaService.updateProfile(profileModEdit).then((res) => {
                        ProfileInstantService.updateProfilePublic(profileModEdit);
                        setProfile(profileModEdit);
                        setProfileMod(profileModEdit);
                        closeModal();
                    })
                }else{

                }
               
            }

        }

        // Do something with the skills array, e.g., submit to a server
    };

    function setDateToMidnight(isoString) {
        const date = new Date(isoString);
        date.setHours(0, 0, 0, 0);
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2);
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}T00:00:00Z`;
    }



    return (
        <>
            <div className="certificates-control education">
                <div className="certificates-title-control">

                    <span className="certificates-title">{translate.profile.certificate}</span>
                    <Button className="certificates-add-button" onClick={addSkillField}>+ {translate.profile.add_certificate}</Button>
                </div>



                <Form className="certificates-form-control">
                    {certificate && certificate.map((item: Certificate, index: number) => (
                        <Form.Group key={index} className="certificates-form-group-control">
                            <div className="d-flex">
                                <Form.Label className="certificates-form-group-title">{translate.profile.certificate}{index + 1}</Form.Label>
                                <Button className="certificates-form-button-remove"

                                    onClick={() => removeSkillField(index)}
                                >
                                   {translate.other.delete}
                                </Button>
                            </div>

                            <div className="certificates-form-item-control">
                                <div className="d-flex flex-column">

                                    <span className="certificates-form-input-title">{translate.profile.cert_name}</span>
                                    <Form.Control
                                        type="text"
                                        className="certificates-form-input"
                                        placeholder={translate.profile.cert_name}
                                        value={item.certificateName}
                                        onChange={(e) => handleSkillChange("name", index, e.target.value)}
                                    />
                                    {
                                        isSaving && !item.certificateName && <span className="education-item-text-error">
                                            {translate.error.input}
                                        </span>
                                    }
                                    <span className="certificates-form-input-title">{translate.profile.cert_desc}</span>
                                    <TextareaAutosize
                                        className="certificates-form-input-textarea"
                                        minRows={5}
                                        value={item.certificateDetail}
                                        onChange={(e) => handleSkillChange("detail", index, e.target.value)} />
                                        {
                                        isSaving && <span></span>
                                    }
{
                                        isSaving && !item.certificateDetail && <span className="education-item-text-error">
                                            {translate.error.input}
                                        </span>
                                    }
                                </div>

                            </div>

                        </Form.Group>
                    ))}

                    {
                        certificate.length == 0 &&
                        <div className="certificate-no-control">

                            <div className="certificate-no-main">
                            <img src={CERT_IMG} className="certificate-no-img"></img>
                            <span className="certificate-no-text">ยังไม่มีใบประกาศ</span>
                            </div>
                          
                        </div>
                    }

                    <div className="education-item-edit-button-control">
                       
                     
                        <Button className="education-item-edit-button" onClick={() => submit()}>{translate.other.save}</Button>
                        <Button className="education-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default CertificatesComponent;