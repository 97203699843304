import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react"
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import { faFacebook, faLinkedin, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard, faEllipsis, faFile, faIdBadge, faVenusMars } from "@fortawesome/free-solid-svg-icons";
import config from "../../../../config";
import { ProfileExtModel } from "../../../../model/profile/profile-ext.model";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import { FormControlLabel, Switch } from "@mui/material";
import { Button } from "react-bootstrap";
import { GetResumeIdModel } from "../../../../model/get-resume.model";
import { useNavigate } from "react-router-dom";
import featureConfig from "../../../../feature.config";
import PersonalityComponent from "../../personality/personality";
import { ResultPersonalityModel } from "../../../../model/personality/result-personality.model";
import PersonalityTestService from "../../../../services/personality-test/personality-test.service";
import DOB_IMG from "../../../../assets/images/profile/dob.png"
import LANGUAGE_IMG from "../../../../assets/images/profile/language.png"
import LINE_IMG from "../../../../assets/images/profile/line.png"
import MAIL_IMG from "../../../../assets/images/profile/mail.png"
import WECHAT_IMG from "../../../../assets/images/profile/wechat.png"
import WHATAPP_IMG from "../../../../assets/images/profile/whatsapp.png"
import TEL_IMG from "../../../../assets/images/profile/phone-call.png"
import MARITAL_STATUS from "../../../../assets/json/jobs/married.json";
import MILITARY from "../../../../assets/json/jobs/military.json";
import RELIGION_STATUS from "../../../../assets/json/jobs/religion.json";
import GENDER_STATUS from "../../../../assets/json/jobs/gender.json";
import NATIONALITY_STATUS from "../../../../assets/json/jobs/nationality.json"
import { File, Language, Skill } from "../../../../model/profile.model";
import HOME_ADDRESS_IMG from "../../../../assets/images/profile/home-address.png";
import COUNTRIES_IMG from "../../../../assets/images/profile/countries.png";
import SKILL_IMG from "../../../../assets/images/profile/skill.png";
import moment from "moment";
import 'moment/locale/th';
import { useLanguage } from "../../../../provider/language.provider";
import UtilityService from "../../../../utility/utility.service";
const UIRightInfoComponent = React.memo(({ profileExt, 
    profileMod, 
    setProfileExt, 
    setErrorHandler, 
    isViewOnly,
    resumeModel, 
    setResumeModel,
    clickViewResume,
    editProfile }:any) => {
    const { translateGlobal }: any = useContext(ProvideContext);
    const {languageGlobal} : any = useContext(ProvideContext);
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [isCopy, setIsCopy] = useState(false);
    const navigate = useNavigate();
    const [resultPersonality, setResultPersonality] = useState<ResultPersonalityModel>();
    const { language, setLanguage } = useLanguage();


    useEffect(() => {
        if (profileExt) {
            // alert(languageGlobal);
            if(languageGlobal == "th"){
                moment.locale('th'); 
            }else{
                moment.locale('en'); 
            }
           
            if (profileExt.personality_answer_id) {
                PersonalityTestService().getResult(profileExt.personality_answer_id).then((res) => {
                    if (res) {
                        setResultPersonality(res);
                    }
                })
            }

        }
    }, [profileExt])


    const shareClick = (channel, profile: ProfileExtModel) => {
        const url = config.url + "public_profile/" + profile.profile_path;
        const title = profileMod.firstName + " " + profileMod.lastName + " 's Profile";
        const text = profileMod.introduction;
        UtilityService().clickSendEvent("share_"+channel, "click");
        if (channel == "facebook") {
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
        } else if (channel == "linkedin") {
            window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(text)}&source=ONEPROFILE`, '_blank')
        } else if (channel == "twitter") {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`, '_blank')
        } else {
            handleNativeShare(url, title, text)
        }

    }

    
    const handleNativeShare = async( url, title, text) => {
        if (navigator.share) {
            navigator.share({
                title: title,
                text: text,
                url: url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing:', error));
        } else {
            try {
                await navigator.clipboard.writeText(url);
                setIsCopy(true);
              } catch (err) {
                console.error('Failed to copy: ', err); // Handle errors (e.g., clipboard permissions denied)
              }
           
        }
    };

    const editMyResume = () => {

        navigate("/resumes");
    }

    const onSwitchChange = (value) => {
        if (profileExt) {
            profileExt.profile_public = value;
            ProfileInstantService.updatePublicProfileStatus(profileExt).then((res) => {
                if (res.data) {
                    setProfileExt(res.data);
                }

            }).catch((err) => {
                if (err.response.status == 500) {

                }
                setErrorHandler(true);
            })
        }

    }
    function getValueByKey(array, value) {
        const item = array.find(obj => obj.key === value);
        return item ? item.value : undefined;
    }
    return (

        <>
            <div className="my-info-about-control">

                <>
                    {
                        <div className="my-info-item-control my-info-item-control-default">

                            <div className="d-flex">
                                <span className="my-info-item-highlight-header ">{translate.profile.share_profile}</span>

                                <div className="my-info-share-icon-control">

                                    <FontAwesomeIcon icon={faFacebook} className="icon" onClick={() => shareClick("facebook", profileExt)} />
                                    <FontAwesomeIcon icon={faLinkedin} className="icon" onClick={() => shareClick("linkedin", profileExt)} />
                                    <FontAwesomeIcon icon={faXTwitter} className="icon" onClick={() => shareClick("twitter", profileExt)} />
                                    <FontAwesomeIcon icon={faEllipsis} className="icon" onClick={() => shareClick("other", profileExt)} />
                                </div>

                            </div>
                        </div>
                    }
                    {
                        !isViewOnly &&
                        <div className="my-info-item-control my-info-item-control-highlight">

                            <div className="d-flex">
                                <span className="my-info-item-highlight-header ">{translate.profile.public_profile}</span>
                                <FormControlLabel control={<Switch

                                    defaultChecked={profileExt.profile_public}
                                    onChange={(e) => onSwitchChange(e.target.checked)} />} label="" className="my-info-text-name-switch-status" />
                            </div>
                        </div>
                    }
                    {
                        resumeModel &&
                        <div className="my-info-item-control">
                            <div className="d-flex">
                                <span className="my-info-item-header">{translate.profile.my_resume_url}</span>
                                {
                                    !isViewOnly &&
                                    <Button className="my-info-item-header-button" onClick={() => editMyResume()}>{translate.other.edit}</Button>
                                }
                            </div>
                            <div className="my-info-item-divider"></div>

                            <span className="my-info-item-desc">
                                {translate.resume_builder.resume_name} :
                                <span className="my-info-item-desc-value">{resumeModel.resume_name}</span>
                            </span>
                            <span className="my-info-item-desc">
                                {translate.resume_builder.resume_status} :
                                <span className="my-info-item-desc-value">{resumeModel.resume_status == "PUBLISHED"
                                    ? translate.resume_builder.resume_published : translate.resume_builder.resume_draft}</span>
                            </span>
                            <Button className="my-info-view-resume-button" onClick={() => clickViewResume(resumeModel.resume_uuid)}> {translate.resume_builder.view_resume}</Button>

                        </div>
                    }
                    {
                        featureConfig.profile.personality &&
                        /* Personality Section */
                        <div className=
                            {
                                (isViewOnly && !profileExt.profile_public_settings.personality) ? "d-none" : "my-info-item-control"
                            }
                        >
                            <div className="d-flex">
                                <span className="my-info-item-header">{translate.profile.my_personality}</span>

                            </div>
                            <div className="my-info-item-divider"></div>
                            <PersonalityComponent profileExtMod={profileExt} resultPersonality={resultPersonality}></PersonalityComponent>


                        </div>
                    }


                    {
                        featureConfig.profile.about &&
                        /* About Section */
                        <div className={
                            (isViewOnly && !profileExt.profile_public_settings.about) ? "d-none" : "my-info-item-control"
                        }>

                            <div className="d-flex">
                                <span className="my-info-item-header">{translate.profile.about}</span>
                                {
                                    !isViewOnly &&
                                    <Button className="my-info-item-header-button" onClick={() => editProfile('about')}>{translate.other.edit}</Button>
                                }

                            </div>
                            <div className="my-info-item-divider"></div>
                            <span className="my-info-item-desc">
                                <img src={DOB_IMG} className="my-info-item-icon"></img>{translate.application_form.birthDate} :
                                <span className="my-info-item-desc-value">{moment(profileMod.dateOfBirth).format("DD MMMM YYYY")}</span>
                            </span>
                            <span className="my-info-item-desc">
                                <FontAwesomeIcon icon={faVenusMars} className="my-info-item-icon"></FontAwesomeIcon>{translate.application_form.gender} :
                                <span className="my-info-item-desc-value">{
                                    getValueByKey(GENDER_STATUS, profileMod.gender) == "Male" ? translate.gender_type.male :
                                        getValueByKey(GENDER_STATUS, profileMod.gender) == "Female" ? translate.gender_type.female :
                                            translate.gender_type.unspecified

                                }</span>
                            </span>
                            <div className="">
                                <span className="my-info-item-desc">
                                    <img src={LANGUAGE_IMG} className="my-info-item-icon"></img>{translate.application_form.language} :
                                    {
                                        profileMod.languages && profileMod.languages.length == 0 && <span className="my-info-item-no-info"> {translate.error.no_information}</span>
                                    }
                                </span>

                                {
                                    profileMod.languages.map((item: Language, index: number) => {
                                        return (
                                            <div className="my-info-about-lang-control" key={index}>
                                                <span className="my-info-about-lang-title">{item.language == 'th' ? 'ภาษาไทย' : item.language == 'en' ? 'English' :
                                                    item.language == 'ch' ? 'Chinese' : item.language == 'jp' ? 'Japanese'
                                                        : ''}: </span>
                                                <div className="my-info-about-lang-grid">
                                                    {
                                                        item.listening &&
                                                        <span className="my-info-about-lang-text">{translate.choice.listening}</span>
                                                    }
                                                    {
                                                        item.writing &&
                                                        <span className="my-info-about-lang-text">{translate.choice.writing}</span>
                                                    }
                                                    {
                                                        item.speaking &&
                                                        <span className="my-info-about-lang-text">{translate.choice.speaking}</span>
                                                    }
                                                    {
                                                        item.reading &&
                                                        <span className="my-info-about-lang-text">{translate.choice.reading}</span>
                                                    }


                                                </div>
                                            </div>
                                        )
                                    })}

                                <span className="my-info-item-desc-value"></span>
                            </div>
                            <div className="">
                                <span className="my-info-item-desc">
                                    <FontAwesomeIcon icon={faCreditCard} className="my-info-item-icon"></FontAwesomeIcon>{translate.job_apply.licenses} :

                                </span>
                                <div className="my-info-about-lang-control" >
                                    <span className="my-info-about-lang-title">{translate.job_apply.carLicense}: </span>
                                    <div className="my-info-about-lang-grid">
                                        <span className="my-info-about-lang-text">{profileMod.carLicense ? translate.choice.yes : translate.choice.no}</span>
                                    </div>
                                </div>
                                <div className="my-info-about-lang-control" >
                                    <span className="my-info-about-lang-title">{translate.job_apply.bikeLicense}: </span>
                                    <div className="my-info-about-lang-grid">
                                        <span className="my-info-about-lang-text">{profileMod.bikeLicense ? translate.choice.yes : translate.choice.no}</span>
                                    </div>
                                </div>
                                <div className="my-info-about-lang-control" >
                                    <span className="my-info-about-lang-title">{translate.job_apply.truckLicense}: </span>
                                    <div className="my-info-about-lang-grid">
                                        <span className="my-info-about-lang-text">{profileMod.truckLicense ? translate.choice.yes : translate.choice.no}</span>
                                    </div>
                                </div>

                            </div>
                            <div className="">
                                <span className="my-info-item-desc">
                                    <FontAwesomeIcon icon={faIdBadge} className="my-info-item-icon"></FontAwesomeIcon>{translate.application_form.otherStatus} :

                                </span>
                                <div className="my-info-about-lang-control" >
                                    <span className="my-info-about-lang-title">{translate.application_form.maritalStatus}: </span>
                                    <div className="my-info-about-lang-grid">
                                        <span className="my-info-about-lang-text">{profileMod.maritalStatus ?
                                            getValueByKey(MARITAL_STATUS, profileMod.maritalStatus) == "Single" ? translate.married_type.single :
                                                getValueByKey(MARITAL_STATUS, profileMod.maritalStatus) == "Married" ? translate.married_type.married :
                                                    getValueByKey(MARITAL_STATUS, profileMod.maritalStatus) == "Widowed" ? translate.married_type.widowed :
                                                        getValueByKey(MARITAL_STATUS, profileMod.maritalStatus) == "Divorced" ? translate.married_type.divorced :
                                                            translate.married_type.not_select

                                            : "-"}</span>
                                    </div>
                                </div>
                                <div className="my-info-about-lang-control" >
                                    <span className="my-info-about-lang-title">{translate.application_form.militaryStatus}: </span>
                                    <div className="my-info-about-lang-grid">
                                        <span className="my-info-about-lang-text">{profileMod.militaryStatus ?

                                            getValueByKey(MILITARY, profileMod.militaryStatus) == "Exempted" ? translate.military_type.exempted :
                                                getValueByKey(MILITARY, profileMod.militaryStatus) == "Completed" ? translate.military_type.completed :
                                                    getValueByKey(MILITARY, profileMod.militaryStatus) == "Serving" ? translate.military_type.serving :
                                                        getValueByKey(MILITARY, profileMod.militaryStatus) == "Not Yet Qualified" ? translate.military_type.not_yet_qualified :
                                                            translate.military_type.not_select
                                            : "-"}</span>
                                    </div>
                                </div>
                                <div className="my-info-about-lang-control" >
                                    <span className="my-info-about-lang-title">{translate.application_form.religion}: </span>
                                    <div className="my-info-about-lang-grid">
                                        <span className="my-info-about-lang-text">{profileMod.religion ?
                                            getValueByKey(RELIGION_STATUS, profileMod.religion) == "Buddhism" ? translate.religion_type.buddhism :
                                                getValueByKey(RELIGION_STATUS, profileMod.religion) == "Islam" ? translate.religion_type.islam :
                                                    getValueByKey(RELIGION_STATUS, profileMod.religion) == "Christianity" ? translate.religion_type.christianity :
                                                        getValueByKey(RELIGION_STATUS, profileMod.religion) == "Hinduism" ? translate.religion_type.hinduism :
                                                            getValueByKey(RELIGION_STATUS, profileMod.religion) == "Sikhism" ? translate.religion_type.sikhism :
                                                                getValueByKey(RELIGION_STATUS, profileMod.religion) == "Judaism" ? translate.religion_type.judaism :
                                                                    getValueByKey(RELIGION_STATUS, profileMod.religion) == "Jainism" ? translate.religion_type.jainism :
                                                                        getValueByKey(RELIGION_STATUS, profileMod.religion) == "Zoroastrianism" ? translate.religion_type.zoroastrianism :
                                                                            getValueByKey(RELIGION_STATUS, profileMod.religion) == "No Religion" ? translate.religion_type.no_religion :
                                                                                getValueByKey(RELIGION_STATUS, profileMod.religion) == "Not Select" ? translate.religion_type.not_select :
                                                                                    getValueByKey(RELIGION_STATUS, profileMod.religion) == "Others" ? translate.religion_type.others :
                                                                                        translate.religion_type.bahai
                                            : "-"}</span>
                                    </div>
                                </div>
                                <div className="my-info-about-lang-control" >
                                    <span className="my-info-about-lang-title">{translate.application_form.nationality}: </span>
                                    <div className="my-info-about-lang-grid">
                                        <span className="my-info-about-lang-text">{profileMod.nationality ? getValueByKey(NATIONALITY_STATUS, profileMod.nationality) : "-"}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }

                    {
                        featureConfig.profile.contact &&

                        /* Contact Section */
                        <div className={
                            (isViewOnly && !profileExt.profile_public_settings.contact) ? "d-none" : "my-info-item-control"
                        }>

                            <div className="d-flex">
                                <span className="my-info-item-header">{translate.profile.contact}</span>
                                {
                                    !isViewOnly &&
                                    <Button className="my-info-item-header-button" onClick={() => editProfile('contact')}>{translate.other.edit}</Button>
                                }

                            </div>

                            <div className="my-info-item-divider"></div>
                            <span className="my-info-item-desc">
                                <img src={HOME_ADDRESS_IMG} className="my-info-item-icon"></img>{translate.profile.address} :
                                <span className="my-info-item-desc-value">{profileMod.address ? profileMod.address : "-"}</span></span>
                            <span className="my-info-item-desc">
                                <img src={COUNTRIES_IMG} className="my-info-item-icon"></img>{translate.profile.country} :
                                <span className="my-info-item-desc-value">{profileMod.country ? profileMod.country : "-"}</span></span>
                            {
                                profileMod.postcode &&
                                <span className="my-info-item-desc">
                                    <img src={COUNTRIES_IMG} className="my-info-item-icon"></img>{translate.profile.postcode} :
                                    <span className="my-info-item-desc-value">{profileMod.postcode ? profileMod.postcode : "-"}</span></span>
                            }

                            <span className="my-info-item-desc">
                                <img src={TEL_IMG} className="my-info-item-icon"></img>{translate.profile.phone_number} :
                                <span className="my-info-item-desc-value">{profileMod.phone ? profileMod.phone : "-"}</span></span>
                            <span className="my-info-item-desc">
                                <img src={MAIL_IMG} className="my-info-item-icon"></img>{translate.profile.email} :
                                <span className="my-info-item-desc-value">{profileMod.email ? profileMod.email : "-"}</span></span>
                            <span className="my-info-item-desc">
                                <img src={LINE_IMG} className="my-info-item-icon"></img>{translate.profile.line_id} :
                                <span className="my-info-item-desc-value">{profileMod.lineId ? profileMod.lineId : "-"}</span></span>
                            <span className="my-info-item-desc">
                                <img src={WHATAPP_IMG} className="my-info-item-icon"></img>{translate.profile.whatsappId} :
                                <span className="my-info-item-desc-value">{profileMod.whatsappId ? profileMod.whatsappId : "-"}</span></span>
                            <span className="my-info-item-desc">
                                <img src={WECHAT_IMG} className="my-info-item-icon"></img>{translate.profile.wechatId} :
                                <span className="my-info-item-desc-value">{profileMod.wechatId ? profileMod.wechatId : "-"}</span></span>
                        </div>
                    }

                    {
                        featureConfig.profile.interest_job &&
                        <div className={
                            (isViewOnly && !profileExt.profile_public_settings.interest) ? "d-none" : "my-info-item-control"
                        }>

                            <div className="d-flex">
                                <span className="my-info-item-header">{translate.profile.job_opportunity}</span>
                                {
                                    !isViewOnly &&
                                    <Button className="my-info-item-header-button" onClick={() => editProfile('jobOpportunity')}>{translate.other.edit}</Button>
                                }

                            </div>
                            <div className="my-info-item-divider"></div>
                            <div className="my-info-about-jobs-control" >
                                <span className="my-info-about-jobs-title">{translate.profile.interest_job}</span>
                            </div>
                            {
                                (profileMod.preferredJobRole && profileMod.preferredJobRole.length > 0) ?
                                    profileMod.preferredJobRole.map((item: string, index: number) => {

                                        return (
                                            item &&
                                            <div className="my-info-about-jobs-control" key={index}>
                                                <span className="my-info-about-jobs-desc">{index + 1}.  {item}</span>

                                            </div>
                                        )
                                    }) : <div className="my-info-about-salary-control" >
                                        <span className="my-info-about-salary-title">{translate.profile.interest_job}: </span>
                                        <span className="my-info-about-salary-desc">-</span>
                                    </div>

                            }
                            <div className="my-info-about-salary-control" >
                                <span className="my-info-about-salary-title">{translate.profile.minimum_salary}: </span>
                                <span className="my-info-about-salary-desc">{profileMod.expectedMinSalary ? Number(profileMod.expectedMinSalary).toLocaleString() + " บาท" : "-"}</span>
                            </div>
                            <div className="my-info-about-salary-control" >
                                <span className="my-info-about-salary-title">{translate.profile.maximum_salary}: </span>
                                <span className="my-info-about-salary-desc">{profileMod.expectedMaxSalary ? Number(profileMod.expectedMaxSalary).toLocaleString() + " บาท" : "-"}</span>
                            </div>
                            <div className="my-info-about-salary-control" >
                                <span className="my-info-about-salary-title">{translate.profile.prefer_job_role}: </span>
                                <span className="my-info-about-salary-desc">{profileMod.preferredJobLevel ? profileMod.preferredJobLevel : "-"}</span>
                            </div>

                        </div>
                    }
                    {
                        featureConfig.profile.skill &&
                        <div className={
                            (isViewOnly && !profileExt.profile_public_settings.skills) ? "d-none" : "my-info-item-control"
                        }>

                            <div className="d-flex">
                                <span className="my-info-item-header">{translate.profile.skill}</span>
                                {
                                    !isViewOnly &&
                                    <Button className="my-info-item-header-button" onClick={() => editProfile('skills')}>{translate.other.edit}</Button>
                                }
                            </div>
                            <div className="my-info-item-divider"></div>
                            <span className="my-info-item-desc">
                                {
                                    profileMod.skills && profileMod.skills.length > 0 ?
                                        <><img src={SKILL_IMG} className="my-info-item-icon"></img>  {translate.application_form.skills} :</> : <span className="my-info-item-no-info">{translate.error.no_information}</span>
                                }

                            </span>
                            <span className="my-info-item-desc">
                                {
                                    profileMod.skills &&
                                    <ul>
                                        {profileMod.skills.map((item: Skill, index: number) => {

                                            return (
                                                <li key={index}>{item.skillName}</li>
                                            )
                                        })}
                                    </ul>
                                }
                            </span>

                        </div>
                    }
                    {
                        featureConfig.profile.upload_file &&
                        <div className={
                            (isViewOnly && !profileExt.profile_public_settings.uploadFile) ? "d-none" : "my-info-item-control"
                        }>

                            <div className="d-flex">
                                <span className="my-info-item-header">{translate.application_form.uploadFiles}</span>
                                {
                                    !isViewOnly &&
                                    <Button className="my-info-item-header-button" onClick={() => editProfile('candidateFiles')}>{translate.other.edit}</Button>
                                }

                            </div>
                            <div className="my-info-item-divider"></div>
                            <span className="my-info-item-desc">
                                {
                                    profileMod.files && profileMod.files.length > 0 ?
                                        <>
                                            <FontAwesomeIcon icon={faFile} className="my-info-item-icon"></FontAwesomeIcon>
                                            Files :</> : <span className="my-info-item-no-info">{translate.error.no_information}</span>
                                }

                            </span>
                            <span className="my-info-item-desc">
                                {
                                    profileMod.files &&
                                    <ul>
                                        {profileMod.files.map((item: File, index: number) => {

                                            return (
                                                <li key={index}>{item.fileName}</li>
                                            )
                                        })}
                                    </ul>
                                }
                            </span>

                        </div>
                    }
                </>
            </div>
        </>
    )
});

export default UIRightInfoComponent;