import { useContext, useEffect, useState } from "react";
import { Certificate, Experience, File, ProfileModel, Skill } from "../../../../model/profile.model";
import "./any-file.scss";
import { Button, Form } from "react-bootstrap";
import "../education/education.scss";
import RamaService from "../../../../services/sso/rama.service";
import { useFilePicker } from "use-file-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from "use-file-picker/validators";
import config from "../../../../config";
import { FileErrorModel } from "../../../../model/error-file-select.model";
import FileLimitModal from "../../../../modal/file-limit-modal";
import UtilityService from "../../../../utility/utility.service";


const AnyFileComponent = ({ profile, setProfile, setCloseModal, isApplicationForm }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [isSaving, setIsSaving] = useState(false);
    const [files, setFiles] = useState<File[]>(new Array()); // Initialize with one empty skill field
    const [currentFile, setCurrentFile] = useState(0);
    const [isLimitError, setIsLimitError] = useState(false);
    const [fileError, setFileError] = useState<FileErrorModel>();
    const { openFilePicker, filesContent, loading, errors, clear } = useFilePicker({
        multiple: true,
        readAs: 'DataURL',
        accept: ['.pdf', 'image/*'],
        limitFilesConfig: { min: 0, max: 1 },
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(['jpg', 'png', 'jpeg', 'pdf']),
            new FileSizeValidator({ maxFileSize: config.MAX_ATTACHMENT_SIZE * 1024 * 1024 /* 50 MB */ })
        ],
        onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
            // this callback is always called, even if there are errors
            const newFile = [...files];
            const fileMod = filesContent[0];
            const fileItem: File = {
                file: fileMod,
                fileName: fileMod.name,
                fileSize: plainFiles[0].size,
                fileSource: "LEARN"
            }
            newFile.push(fileItem)
            setFiles(newFile);
        },
        onFilesRejected: ({ errors }) => {
            if(errors.length > 0){
                console.log(errors);
                const errorsResult = errors as FileErrorModel[];
                setIsLimitError(true);
                setFileError(errorsResult[0]);
            }
          
        }
    });

    const clickUpload = (index) => {

        openFilePicker();
    }



    useEffect(() => {
        if (profileMod) {
            if (isApplicationForm) {
                if (profileMod.candidateFiles) {
                    const certDesc: File[] = new Array();
                    profileMod.candidateFiles.map((item: File, index: number) => {
                        certDesc.push(item);
                    })
                    setFiles(certDesc);
                }
            } else {
                if (profileMod.files) {
                    const certDesc: File[] = new Array();
                    profileMod.files.map((item: File, index: number) => {
                        certDesc.push(item);
                    })
                    setFiles(certDesc);
                }
            }

        }
    }, [profileMod])

    const closeModal = () => {
        setCloseModal();
    }

    const addSkillField = () => {
        if (files) {

            setFiles([...files, {}]);
        }
    };

    const removeSkillField = (index) => {
        if (files) {
            if (files.length >= 0) {
                const newSkills = files.filter((_, i) => i !== index);
                setFiles(newSkills);
            }
        }
    };

    const submit = async () => {
        const profileModEdit = { ...profileMod } as ProfileModel
        setIsSaving(true);
        if (files) {
            profileModEdit.candidateFiles = files;
            if (isApplicationForm) {
                setProfile(profileModEdit);
                setProfileMod(profileModEdit);
                closeModal();
            } else {
                UtilityService().clickSendEvent("uploaded_file", "submit");
                processFiles().finally(() => {
                    closeModal();
                })


            }

        }

        // Do something with the skills array, e.g., submit to a server
    };

    useEffect(() => {
        if (files) {
        }
    }, [files])

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }
    async function processFiles(): Promise<void> {
        let index = 0;
        const profileModEdit = { ...profileMod } as ProfileModel
        if (isApplicationForm) {
            profileModEdit.candidateFiles = new Array();
        } else {
            profileModEdit.files = new Array();
        }

        if (files.length > 0) {
            for (const fileContent of files) {
                // Prepare your POST request data based on fileContent
                if (fileContent.file) {

                    if (!fileContent.fileKey) {
                        const file = DataURIToBlob(fileContent.file.content);
                        const response = await RamaService.uploadFile(file, fileContent.fileName);
                        // Process the result here
                        const result = await response.data;
                        if (result) {
                            const newFiles: File = {
                                fileKey: result[0],
                                fileName: fileContent.fileName,
                                fileSize: fileContent.fileSize,
                                fileSource: "LEARN"
                            }
                            if (isApplicationForm) {
                                profileModEdit.candidateFiles.push(newFiles);
                            } else {
                                profileModEdit.files.push(newFiles);
                            }

                            RamaService.updateProfile(profileModEdit).then((res) => {
                                ProfileInstantService.updateProfilePublic(profileModEdit);
                                setProfile(profileModEdit);
                                setProfileMod(profileModEdit);

                            })
                            index++
                        }
                    }


                } else {
                    const newFiles: File = {
                        fileKey: fileContent.fileKey,
                        fileName: fileContent.fileName,
                        fileSize: fileContent.fileSize,
                        fileSource: "LEARN"
                    }
                    if (isApplicationForm) {
                        profileModEdit.candidateFiles.push(newFiles);
                    } else {
                        profileModEdit.files.push(newFiles);
                    }
                    RamaService.updateProfile(profileModEdit).then((res) => {
                        ProfileInstantService.updateProfilePublic(profileModEdit);
                        setProfile(profileModEdit);
                        setProfileMod(profileModEdit);

                    })
                }
            }
        } else {

            if (isApplicationForm) {
                profileModEdit.candidateFiles = files;
            } else {
                profileModEdit.files = files;
            }
            RamaService.updateProfile(profileModEdit).then((res) => {
                ProfileInstantService.updateProfilePublic(profileModEdit);
                setProfile(profileModEdit);
                setProfileMod(profileModEdit);

            })
        }


    }

    return (
        <>
            <div className="anyfile-control education">
                <div className="anyfile-title-control">
                    <div className="d-flex flex-column">
                        <span className="anyfile-title">{translate.profile.add_file}</span>
                        <span className="anyfile-title-limit">{translate.error.file_limit} {config.MAX_ATTACHMENT_SIZE} MB</span>
                    </div>
                    <Button className="anyfile-upload-button" onClick={openFilePicker}>
                        <FontAwesomeIcon className="anyfile-upload-button-icon" icon={faUpload}></FontAwesomeIcon>
                        <span className="anyfile-upload-button-text">{translate.profile.add_file}</span></Button>

                </div>



                <Form className="anyfile-form-control">
                    {files && files.map((item: File, index: number) => (
                        <Form.Group key={index} className="anyfile-form-group-control">
                            <div className="d-flex">
                                <Form.Label className="anyfile-form-group-title">{translate.profile.file}. {index + 1}</Form.Label>

                            </div>

                            <div className="anyfile-form-item-control">
                                <div className="anyfile-form-input-title-control">
                                    {
                                        item.fileName &&

                                        <>
                                            <span className="anyfile-form-input-title">{translate.profile.file_name}: {item.fileName}</span>
                                        </>
                                    }
                                </div>
                                <Button className="anyfile-form-button-remove"

                                    onClick={() => removeSkillField(index)}
                                >
                                    {translate.other.delete}
                                </Button>

                            </div>

                        </Form.Group>
                    ))}

                    <div className="education-item-edit-button-control">
                        <Button className="education-item-edit-button" onClick={() => submit()}>{translate.other.save}</Button>
                        <Button className="education-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                    </div>
                </Form>
            </div>
            {
            isLimitError &&

            <FileLimitModal open={isLimitError} handleClose={() => setIsLimitError(false)} fileError={fileError} limitSize={config.MAX_ATTACHMENT_SIZE}></FileLimitModal>
        }
        </>
    )
}

export default AnyFileComponent;