
import axios from "axios"
import config from "../../config"
import apiPath from "../../api-path"
import tokenService from "../sso/token.service"
import RamaService from "../sso/rama.service"


export default function ProfileService() {

    return {
      

        async getJobRolesLeela() {
            return axios.get(config.leela.host_url + apiPath.leela.job_roles, {
                headers: {
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => console.log(err))
        },
      
        async downloadResume(bucket: string, key: string, candidate_id, user_name) {

            return axios.get(config.view_resume.base_url + "/resume" + "?" + new URLSearchParams({
                bucket: bucket,
                key: key,
                candidate_id: candidate_id,
                user_name: user_name,
            }),
                {
                    headers: {
                        "x-api-key": config.view_resume.x_api_key
                    }
                }).then(res => res.data).catch(error => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        return 400;
                    } else if (error.request) {
                        // The request was made but no response was received
                        return 400;
                    } else {
                        // Something happened in setting up the request
                        return 400;
                    }
                })
        },
    }
}