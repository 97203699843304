import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import UploadResumeModal from "../../../modal/jobs/upload-resume/upload-resume.modal";
import { CircularProgress } from "@mui/material";
import "./import-resume.scss";
import ResumeService from "../../../services/resume.service";
import GPTService from "../../../services/gpt.service";
import { ResultImportResumeModel } from "../../../model/import-resume/result-import-resume.model";
import ResumeParserComponent from "./resume-parser";


const ImportResumeComponent = ({ open, handleClose }: any) => {

    const [isUploadResume, setIsUploadResume] = useState(false);
    const [key, setKey] = useState("");
    const [result, setResult] = useState<ResultImportResumeModel>();


    useEffect(() => {

        if(key){
            parsingResume(key);
        }

    },[key])

    const clickUploadResume = () => {
        setIsUploadResume(true);
    }
    

    const handleCloseUpload = () => {
        setIsUploadResume(false);
      
    }

    const parsingResume = (resumeKey) => {
       
        const objectResult = {
            resume_key: resumeKey
        }
        GPTService.resumeParser(objectResult).then((res) => {
            if(res.data){
                setResult(res.data);
            }
        })
    }

    return (

        <>
            <div className="import-resume-main">
                <Modal show={open} onHide={handleClose} className="import-resume-main">
                    <ModalHeader closeButton></ModalHeader>
                    <ModalBody>
                        {
                            key && !result &&
                            <div className="import-resume-parsing-control">
                                <div className="import-resume-parsing-main">
                                    <span className="import-resume-parsing-key-text">{key}</span>
                                    <div className="import-resume-parsing-main">
                                        <CircularProgress className="import-resume-parsing-loading"></CircularProgress>
                                        <span className="import-resume-parsing-loading-text">กำลังดึงข้อมูล...</span>
                                    </div>

                                </div>
                            </div>

                        }
                        {
                            !key &&
                            <Button onClick={clickUploadResume}>Upload resume</Button>
                        }
                        {
                            result &&
                            <ResumeParserComponent dataMod={result} ></ResumeParserComponent>
                        }

                    </ModalBody>
                </Modal>
            </div>

            {
                isUploadResume &&
                <UploadResumeModal open={isUploadResume} handleClose={handleCloseUpload} setKey={setKey} pathName={"one_profile"}></UploadResumeModal>
            }

        </>
    )
}

export default ImportResumeComponent;