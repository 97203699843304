

const templateConfig = {
    primaryColor: "#2E3F88",
    softBGColor: "#9bb2d4",
    fontFamily: {
        bold: "LINESeedSansTH_He",
        regular: "LINESeedSansTH_Rg",
        medium: "LINESeedSansTH_Bd",
        light: "LINESeedSansTH_Th"
    }
}

export default {
    // Add common config values here
    ...templateConfig
};