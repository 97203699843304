import { Button, Table } from "react-bootstrap";
import "./interview-history.scss";
import MenuComponent from "../../menu/menu";
import { useContext, useEffect, useState } from "react";
import { ProvideContext } from "../../../context/provider.context";
import { TranslateModel } from "../../../model/translate.model";
import { useNavigate } from "react-router-dom";
import navigateConfig from "../../../navigate.config";
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AppliedJob, ProfileModel } from "../../../model/profile.model";
import InterviewAIService from "../../../services/interview-ai/interview.service";
import RamaService from "../../../services/sso/rama.service";
import { HistoryInterviewModel, LogRequest } from "../../../model/interview-ai/history-interview.model";
import InterviewResultModal from "../../../modal/interview-ai/interview-result.modal";
import { useProfile } from "../../../context/profile.context";
import UtilityService from "../../../utility/utility.service";

const InterviewAIHistory = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const navigate = useNavigate();
    const [historyList, setHistoryList] = useState<HistoryInterviewModel[]>();
    const [result, setResult] = useState<HistoryInterviewModel>();
    const [isShowModal, setIsShowModal] = useState(false);

    useEffect(() => {
        getHistory();
    },[])

    const handleCloseModal = () => {

        setIsShowModal(false);
    }

    const startInterview = () => {

        navigate(navigateConfig.jobs.interview);
    }

    const getHistory = () => {

        InterviewAIService().getHistory().then((res) => {
            if (res) {
                UtilityService().clickSendEvent("interview_ai_load_history", "load");
                setHistoryList(res)
            }
        })
    }

    const clickViewMore = (item: HistoryInterviewModel) => {
        setResult(item);
        setIsShowModal(true);
    }
    function Row(props: { item: HistoryInterviewModel, index }) {
        const { item, index } = props;
        const [open, setOpen] = useState(false);

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className="interview-history-table-list-row-control">
                    <TableCell align="right" width={'50px'} className="interview-history-table-list-desc">{index + 1}</TableCell>
                    <TableCell align="right" className="interview-history-table-list-desc">{item.log_request.job_title}</TableCell>
                    <TableCell align="right" className="interview-history-table-list-desc">{item.log_request.job_salary}</TableCell>
                    <TableCell align="right" className="interview-history-table-list-desc">{new Date(item.log_at).toLocaleString()}</TableCell>
                    <TableCell align="right" className="interview-history-table-list-desc">
                        <Button className="interview-history-table-list-button"
                            onClick={() => clickViewMore(item)}>{translate.interview_ai.history_view}</Button>
                    </TableCell>
                </TableRow>

            </>
        );
    }
    return (
        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="interview-history-main">
                <div className="interview-history-control">
                    <div className="interview-history-header-control">
                        <span className="interview-history-header">{translate.interview_ai.title}</span>
                        <Button className="interview-history-start-button" onClick={startInterview}>
                            {translate.interview_ai.start_interview}
                        </Button>
                    </div>

                    {
                        (historyList && historyList.length > 0) ?
                            <TableContainer className="interview-history-table-list-control">
                                <Table className="interview-history-table-list-header" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right" style={{ width: 100 }} className="interview-history-table-list-header">{translate.interview_ai.history_no}</TableCell>
                                            <TableCell align="right" className="interview-history-table-list-header">{translate.interview_ai.history_job_title}</TableCell>
                                            <TableCell align="right" className="interview-history-table-list-header">{translate.interview_ai.history_salary}</TableCell>
                                            <TableCell align="right" className="interview-history-table-list-header">{translate.interview_ai.history_log_at}</TableCell>
                                            <TableCell align="right" className="interview-history-table-list-header"></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            historyList.map((item: HistoryInterviewModel, index: number) => {

                                                return (
                                                    <Row key={item.log_at} item={item} index={index} />
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer> :

                            <div className="interview-history-no-list-main">
                                <div className="interview-history-no-list-control">
                                    <span className="interview-history-no-list-text">

                                        {translate.other.no_result_history}
                                    </span>
                                </div>

                            </div>
                    }
                </div>

            </div>
            {
                isShowModal &&
                <InterviewResultModal open={isShowModal} handleClose={handleCloseModal} result={result}></InterviewResultModal>
            }
        </div>
    )


}

export default InterviewAIHistory;