import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress } from "@mui/material"
import { useContext, useState } from "react"
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap"
import "./error.scss";
import { ProvideContext } from "../../context/provider.context"
import { TranslateModel } from "../../model/translate.model"



const ErrorModal = ({ open, onclose, message }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    return (
        <div className="error-modal">
            <Modal show={open} onHide={onclose} backdrop='static'>
                <ModalHeader closeButton></ModalHeader>
                <ModalBody className="error-modal">

                    <div className="error-modal-control">
                        <FontAwesomeIcon icon={faTriangleExclamation} className="error-icon" />

                        <span className="error-text">{message}</span>

                        <Button className="error-button-close" onClick={onclose}>{translate.other.close}</Button>
                    </div>

                </ModalBody>

            </Modal>
        </div>
    )
}

export default ErrorModal;