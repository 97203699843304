import { useContext, useEffect, useState } from "react";
import { ProfileModel, Skill } from "../../../../model/profile.model";
import "../skills/skills.scss";
import { Button, Form } from "react-bootstrap";
import "../education/education.scss";
import RamaService from "../../../../services/sso/rama.service";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import UtilityService from "../../../../utility/utility.service";


const InterestingComponent = ({ profile, setProfile, setCloseModal, setInterestingUpdate, interestingModel }: any) => {
    const {translateGlobal } :any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [isSaving, setIsSaving] = useState(false);
    const [skills, setSkills] = useState<any[]>(new Array()); // Initialize with one empty skill field



    useEffect(() => {
        if(interestingModel){
           setSkills(interestingModel);
        }
    },[interestingModel])

    const closeModal = () => {
        setCloseModal();
    }

    const handleSkillChange = (index, value) => {
        if (skills) {
            const newSkills = [...skills];
            newSkills[index] = value;
            setSkills(newSkills);
        }

    };

    const addSkillField = () => {
        if (skills) {
            setSkills([...skills, ""]);
        }
    };

    const removeSkillField = (index) => {
        if (skills) {
            if (skills.length > 1) {
                const newSkills = skills.filter((_, i) => i !== index);
                setSkills(newSkills);
            }
        }
    };

    const submit = () => {
        const profileModEdit = { ...profileMod } as ProfileModel
        setIsSaving(true);
        if (skills) {
            UtilityService().clickSendEvent("save_interesting", "submit");
            setInterestingUpdate(skills);
            closeModal();
        }

        // Do something with the skills array, e.g., submit to a server
    };





    return (
        <>
            <div className="skills-control education">
                <div className="skill-title-control">

                    <span className="skills-title">{translate.profile.activiy_interest}</span>
                    <Button className="skills-add-button" onClick={addSkillField}>+ {translate.other.add}</Button>
                </div>

                <Form className="skills-form-control">
                    {skills && skills.map((skill, index) => (
                        <Form.Group key={index} className="skills-form-group-control">
                            <Form.Label className="skills-form-group-title"> {index + 1}. {translate.profile.activiy_interest}</Form.Label>
                            <div className="skills-form-item-control">
                                <Form.Control
                                    type="text"
                                    className="skills-form-input"
                                    placeholder=""
                                    value={skill}
                                    onChange={(e) => handleSkillChange(index, e.target.value)}
                                />
                                {index !== 0 && (
                                    <Button className="skill-form-button-remove"

                                        onClick={() => removeSkillField(index)}
                                    >
                                        {translate.other.delete}
                                    </Button>
                                )}
                            </div>
                        </Form.Group>
                    ))}

                    <div className="education-item-edit-button-control">
                        <Button className="education-item-edit-button" onClick={() => submit()}>{translate.other.save}</Button>
                        <Button className="education-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default InterestingComponent;