import { useContext, useEffect, useState } from "react";
import MenuComponent from "../../menu/menu";
import "./drop-resume.scss";
import { DropResumeModel } from "../../../model/guest/drop-resume/drop-resume.model";
import { Alert, Autocomplete, CircularProgress, FormControl, Snackbar, TextField, TextareaAutosize } from "@mui/material";
import { useFilePicker } from "use-file-picker";
import { Button } from "react-bootstrap";
import JOB_ROLE_JSON from "../../../assets/json/job-role-suggestion.json"
import { JobRole, JobRoleSuggestionModel } from "../../../model/job-role-suggestion.model";
import { ProvideContext } from "../../../context/provider.context";
import { TranslateModel } from "../../../model/translate.model";
import { TextFields } from "@mui/icons-material";
import ApiService from "../../../services/api.service";
import DropResumeService from "../../../services/drop-resume/drop-resume.service";
import { InputDropResumeModel } from "../../../model/guest/drop-resume/input-drop-resume.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import UtilityService from "../../../utility/utility.service";

const DropResumeComponent = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [flattenedRoles, setFlattenedRoles] = useState(new Array());
    const [files, setFiles] = useState<any>(new Array()); // Initialize with one empty skill field
    const [isSaving, setIsSaving] = useState(false);
    const [isModalSnack, setIsModalSnack] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorsJson, setErrors] = useState({
        email: '',
        resume: '',
        salary: '',
        introduce: '',
        jobRole: ''
    });
    const [inputModel, setInputModel] = useState<DropResumeModel>({
        email: '',
        introduction: '',
        jobRole: '',
        salary: ''
    });
    const { openFilePicker, filesContent, loading, errors, clear } = useFilePicker({
        multiple: false,
        readAs: 'DataURL',
        accept: ['image/*', '.pdf'],
        limitFilesConfig: { min: 0, max: 1 },
        maxFileSize: 20,
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            // this callback is always called, even if there are errors
            setFiles(filesContent)
            const inputMod = { ...inputModel }
            inputMod.resume = filesContent[0].name;
            setInputModel(inputMod);
        },
        onFilesRejected: ({ errors }) => {
            // this callback is called when there were validation errors
        }
    });

    const clearFile = () => {
        clear()
    }

    const handleCloseModalSnack = () => {
        setIsModalSnack(false);
    }

    const reset = () => {
        const inputMod = { ...inputModel };
        inputMod.email = '';
        inputMod.introduction = '';
        inputMod.jobRole = '';
        inputMod.salary = '';
        setFiles(null);
        setInputModel(inputMod);

    }

    useEffect(() => {
        getRoleOptions();
        // getJobRole();
    }, [])

    const getJobRole = () => {

        ApiService().getJobRolesSuggestion().then((res) => {

            if (res) {
            }
        })
    }

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }

    function getRoleOptions() {
        let flatItem = new Array();
        JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {

            const jobPush = {
                label: item.category,
                options: new Array()
            }
            let strgArray = new Array();
            item.job_roles.map((items: JobRole) => {

                if (items.job_title) {
                    strgArray.push(items.job_title);
                }

            })
            jobPush.options = strgArray;
            flatItem.push(jobPush);

        })
        setFlattenedRoles(flatItem)
    }

    const clickSubmit = () => {
        setIsSaving(true);
        let isError = false;

        if (errorsJson.email.length > 0
            && errorsJson.resume.length > 0
        ) {
            isError = true;
        }

        if (!isError && filesContent.length > 0 && inputModel.email && inputModel.resume) {
            const file = DataURIToBlob(filesContent[0]["content"]);
            setIsSubmitting(true);
            DropResumeService().uploadResumeOneProfile(file, filesContent[0].name).then((res) => {

                if (res.resume_key) {
                    const objectResult: InputDropResumeModel = {
                        email: inputModel.email,
                        expected_salary: Number(inputModel.salary),
                        introduction: inputModel.introduction,
                        preferred_job_role: inputModel.jobRole,
                        resume_key: res.resume_key

                    }
                    DropResumeService().postDropResume(objectResult).then((result) => {

                        if (result) {
                            UtilityService().clickSendEvent("drop_resume_complete", "submit");

                            setIsSaving(false);
                            setIsSubmitting(false);
                            setIsModalSnack(true);
                            setIsComplete(true);
                            reset();
                        }
                    }).catch((err) => {
                        setIsSaving(false);
                        setIsSubmitting(false);
                    })
                }

            }).catch((err) => {
                setIsSaving(false);
                setIsSubmitting(false);
            })

        } else {

        }

    }

    const onSelectChange = (action, value) => {
        setIsSaving(false);
        const inputMod = { ...inputModel }
        const errorsMod = { ...errorsJson };
        if (action == 'jobTitle') {
            inputMod.jobRole = value
            // JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {
            //     const itemResult = item.job_roles.filter((items => items.job_title == value))
            //     if (itemResult.length > 0) {
            //         let strArray = "";
            //         if(itemResult[0] && itemResult[0].responsibilities && itemResult[0].responsibilities?.length > 0){
            //             itemResult[0].responsibilities.map((itemRespons: string) => {
            //                 strArray += itemRespons+" "
            //             })

            //         }
            //         currentEditChange.responsibilities = strArray
            //     } 
            // })

        } else if (action == 'email') {
            if (isValidEmail(value)) {
                inputMod.email = value;
                errorsMod.email = '';
            } else {
                inputMod.email = value;
                errorsMod.email = translate.error.invalid_email
            }

        } else if (action == 'salary') {
            if (isValidSalary(value)) {
                inputMod.salary = value;
                errorsMod.salary = '';
            } else {
                inputMod.salary = value;
                errorsMod.salary = translate.error.expected_salary_error
            }

        } else if (action == 'introduction') {
            inputMod.introduction = value;
        }
        setInputModel(inputMod);
        setErrors(errorsMod);
    }
    function isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    }
    function isValidSalary(value) {
        if (value >= 1) {
            return true;
        } else {
            return false;
        }

    }
    return (
        <>
            <div className="main-control">
                <MenuComponent></MenuComponent>
                <div className="drop-resume-main">
                    <div className="drop-resume-cover-header-control">
                        {
                            !isComplete && !isSubmitting &&
                            <FormControl className="drop-resume-control">
                                <div className="drop-resume-title-control">
                                    <span className="drop-resume-title-white">Become a <span className="drop-resume-title-orange">Talent!
                                    </span></span>
                                </div>
                                <span className="drop-resume-desc-text">ฝากเรซูเม่ของคุณไว้ เพื่อเปิดโอกาสสู่การทำงานในบริษัทชั้นนำ</span>


                                <div className="drop-resume-form-control">
                                    <div className="drop-resume-field-item-33">
                                        <span className="drop-resume-title">
                                            อัปโหลด เรซูเม่ ของคุณ
                                        </span>
                                        <div className="drop-resume-upload-file">
                                            <Button onClick={openFilePicker} className="drop-resume-upload-file-button">เลือกไฟล์</Button>
                                            <span className="drop-resume-upload-file-text">{
                                                files && files.length > 0 ? files[0].name : "ไม่มีไฟล์ที่เลือก"
                                            }</span>
                                        </div>

                                        {
                                            (isSaving && !inputModel.resume) &&
                                            <span className="drop-resume-error">{translate.error.input}</span>
                                        }
                                    </div>
                                    <div className="drop-resume-field-item-33">
                                        <span className="drop-resume-title">
                                            เลือกประเภทงานที่ต้องการ
                                        </span>
                                        <Autocomplete
                                            options={flattenedRoles.map(group => group.options).flat()}
                                            groupBy={(option) =>
                                                flattenedRoles.find(group => group.options.includes(option)).label
                                            }

                                            value={inputModel?.jobRole}
                                            className="drop-resume-input"
                                            onInputChange={(event, newValue) => {
                                                onSelectChange("jobTitle", newValue);
                                                // Do something with the selected name
                                            }}
                                            renderInput={(params) => <TextField {...params} placeholder="เลือกประเภทงาน" variant="outlined" />}
                                        />
                                        {
                                            errorsJson.jobRole &&
                                            <span className="drop-resume-error">{errorsJson.jobRole}</span>
                                        }
                                        {/* {
                                            (isSaving && !inputModel.jobRole) &&
                                            <span className="drop-resume-error">{translate.error.input}</span>
                                        } */}
                                    </div>
                                    <div className="drop-resume-field-item-33">
                                        <span className="drop-resume-title">
                                            อีเมล
                                        </span>
                                        <TextField id="outlined-basic"
                                            placeholder="ex. talentsauce@gmail.com"
                                            value={inputModel.email}
                                            className="drop-resume-input" variant="outlined"
                                            type="email" onChange={(e) => onSelectChange("email", e.target.value)} />
                                        {
                                            errorsJson.email &&
                                            <span className="drop-resume-error">{errorsJson.email}</span>
                                        }
                                        {
                                            (isSaving && !inputModel.email) &&
                                            <span className="drop-resume-error">{translate.error.input}</span>
                                        }
                                    </div>
                                </div>
                                <div className="drop-resume-form-control">
                                    <div className="drop-resume-field-item-50">
                                        <span className="drop-resume-title">
                                            เงินเดือนที่ต้องการ
                                        </span>
                                        <TextField id="outlined-basic"
                                            placeholder="0 บาท"
                                            value={inputModel.salary}
                                            className="drop-resume-input" variant="outlined"
                                            type="number" onChange={(e) => onSelectChange("salary", e.target.value)} />
                                        {
                                            errorsJson.salary &&
                                            <span className="drop-resume-error">{errorsJson.salary}</span>
                                        }
                                        {/* {
                                            (isSaving && !inputModel.salary) &&
                                            <span className="drop-resume-error">{translate.error.input}</span>
                                        } */}
                                    </div>


                                </div>
                                <div className="drop-resume-form-control">
                                    <div className="drop-resume-field-item-50">
                                        <span className="drop-resume-title">
                                            แนะนำตัวเองในแบบของคุณ
                                        </span>
                                        <TextareaAutosize
                                            className="drop-resume-text-area"
                                            minRows={5}
                                            placeholder="เขียนแนะนำตัว"
                                            value={inputModel.introduction}
                                            onChange={(e) => onSelectChange('introduction', e.target.value)} />
                                        {/* {
                                            (isSaving && !inputModel.introduction) &&
                                            <span className="drop-resume-error">{translate.error.input}</span>
                                        } */}
                                    </div>
                                </div>
                                <div className="drop-resume-form-control drop-resume-form-control-full">

                                    <Button className="drop-resume-submit-button" onClick={clickSubmit}>ส่งเรซูเม่ของคุณ</Button>
                                </div>

                            </FormControl>
                        }
                        {
                            !isComplete && isSubmitting &&
                            <div className="drop-resume-control">
                                <div className="drop-resume-loading-control">
                                    <div className="drop-resume-loading-item">
                                        <CircularProgress className="drop-resume-loading-progress" />

                                        <span className="drop-resume-loading-text">
                                            กำลังส่งข้อมูล . . .
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            isComplete &&

                            <div className="drop-resume-control">
                                <div className="drop-resume-complete-control">
                                    <div className="drop-resume-complete-main">
                                        <FontAwesomeIcon icon={faCircleCheck} className="drop-resume-complete-icon"></FontAwesomeIcon>
                                        <span className="drop-resume-complete-text">ส่งใบฝากประวัติสำเร็จ</span>
                                        <span className="drop-resume-complete-text-send-more" onClick={() => setIsComplete(false)}>ฝากประวัติอีกครั้ง</span>
                                    </div>
                                </div>


                            </div>
                        }

                    </div>
                </div >
            </div >

        </>
    )
}

export default DropResumeComponent;