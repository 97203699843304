

const apiPath = {
    resume: "profile/resume",
    generator: "generate/",
    gpt_interview: "one-profile/",
    product_path: "one-profile/",
    resume_interview: "resume-interview/",
    resume_checker: {
        base: "resume-checker",
        analyze: "/resume/analyze",
    },
    leela: {
        company_jobs: "companies/",
        public: "public/",
        skills: "lambda/skill-suggestion",
        job_roles: "public/job-roles/non-custom"
    },
    path: {
        resume_parser: "resume/parser",
        jd_suggest: "job_suggestion",
        cover_letter: "cover_letter",
        skills: "skills",
        education: "education",
        experience: "experience",
        interest: "interest",
        upload: "upload",
        user: "user",
        slot: "slot",
        url: "url",
        job: "jobs",
        company: "companies",
        page_limit: "10"
    },
    rama_path: {
        me: "/api/me",
        create_users: "/api/users",
        profile: "/api/me/profile",
        setting: "/api/me/profile/setting",
        profile_apply: "/api/me/profile/career-site",
        uploadProfileImage: "/api/me/profile/images",
        uploadFile: "/api/me/profile/files",
        career_recommend: "/api/careers/recommendations",
        career: "/api/careers",
        career_skill: "/api/me/careers/skills"
    },

    menu: {
        profile: "/profile",
        resume: "/resume",
        resume_builder: "/resume/builder",
        resume_checker: "/resume/checker"
    },
    career_craft: {
        course_recommend: "/courses/recommendations"
    }
}

export default {
    // Add common config values here
    ...apiPath
};