import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import RamaService from "../../../services/sso/rama.service";
import MenuComponent from "../../menu/menu";
import "./job-apply.scss";
import { ApplicationFormModel, ApplicationInputModel, OptionInputSelectModel, Question } from "../../../model/jobs/application-form.model";
import { Button, Form, FormControl } from "react-bootstrap";
import { JobDetailModel } from "../../../model/jobs/job-detail.model";
import { TranslateModel } from "../../../model/translate.model";
import { ProfileApplyModel } from "../../../model/jobs/profile-apply.model";
import GENDER_JSON from "../../../assets/json/jobs/gender.json";
import NATIONALITY_JSON from "../../../assets/json/jobs/nationality.json";
import COUNTRY_JSON from "../../../assets/json/jobs/country.json";
import RELIGION_JSON from "../../../assets/json/jobs/religion.json";
import MARRIED_JSON from "../../../assets/json/jobs/married.json";
import MILITARY_JSON from "../../../assets/json/jobs/military.json";
import CandidateProfile from "./candidate-profile/candidate-profile";
import ExperienceApply from "./experience/experience-apply";
import APPLICATIONFORM_MOCK from "../../../assets/json/jobs/application-form.json";
import QuestionsJobApply from "./other-info-apply/other-info-apply";
import { ProvideContext } from "../../../context/provider.context";
import languageService from "../../../services/language.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import navigateConfig from "../../../navigate.config";
import BG_CIRCLE_MAIN from "../../../assets/images/circle-bg-main.svg";
import { Alert, Snackbar } from "@mui/material";
import JobsInstanceService from "../../../services/jobs/jobs-instance.service";
import { useProfile } from "../../../context/profile.context";
import navigationService from "../../../services/navigation.service";
import AuthFunction from "../../../services/sso/auth.function";
import config from "../../../config";
import tokenService from "../../../services/sso/token.service";
const JobApplyComponent = () => {
    const navigate = useNavigate();
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileApplyMod, setProfileApplyMod] = useState<ProfileApplyModel>();
    const param = useParams();
    const [jobID, setJobID] = useState("");
    const [applicationForm, setApplicationForm] = useState<ApplicationFormModel>();
    const [isExperienceEdit, setIsExperienceEdit] = useState(false);
    const [isCandidateEdit, setIsCandidateEdit] = useState(false);
    const [isQuestion, setIsQuestion] = useState(false);
    const [isQuestionEdit, setIsQuestionEdit] = useState(false);
    const [jobDetailModel, setJobDetailModel] = useState<JobDetailModel>();
    const [inputCandidateModel, setInputCandidateModel] = useState<ApplicationInputModel[]>(new Array());
    const [inputLicenseModel, setInputLicenseModel] = useState<ApplicationInputModel[]>(new Array());
    const [inputJobModel, setInputJobModel] = useState<ApplicationInputModel[]>(new Array());
    const [inputLanguageModel, setInputLanguageModel] = useState<ApplicationInputModel[]>(new Array());
    const [inputContactModel, setInputContactModel] = useState<ApplicationInputModel[]>(new Array());
    const [inputExperienceModel, setInputExperienceModel] = useState<ApplicationInputModel[]>(new Array());
    const [inputOtherModel, setInputOtherModel] = useState<ApplicationInputModel[]>(new Array());
    const [isSubmit, setIsSubmit] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [companyDomain, setCompanyDomain] = useState("");
    const [isSendingSubmit, setIsSendingSubmit] = useState(false);
    const [isReadySubmit, setIsReadySubmit] = useState(false);
    const [isExpectedSalary, setIsExpectedSalary] = useState(false);
    const [profile,error] = useProfile();
    const location = useLocation();
    useEffect(() => {
        if (profileApplyMod) {
            if (param.id && searchParams.get('company_name')) {
                setCompanyDomain(searchParams.get('company_name') + "")
                setJobID(param.id);


            }
        }
    }, [profileApplyMod])

    useEffect(() => {
        try{
            if(error){
                if(error.response.status != undefined){
                    if(error.response.status != 200){
                        authenticateUrl();
                    }  
                }
            }
        }catch{
            authenticateUrl();
        }
    },[error])

    const authenticateUrl = () => {
        const codeVerifier = AuthFunction().randomString(56);
        const codeChallenge = AuthFunction().PKCEencoding(codeVerifier);
        const state = AuthFunction().randomString(config.authen.length);
        let path = "/profile";
        if (location) {
            console.log(location);
            path = location.pathname + location.search;
            console.log(path);
        }
        const urlReturn = config.authen.sso_url + "?" + new URLSearchParams({
            redirect_uri: config.authen.redirect_uri + "?" + new URLSearchParams({
                page: path
            }),
            state: state,
            code_challenge: codeChallenge,
            response_type: 'code',
            code_challenge_method: 'S256',
            client_id: config.authen.client_id
        })
        tokenService.setVerifyCode(codeVerifier)
        window.open(urlReturn, "_self");
    }

    useEffect(() => {

        if (jobID && companyDomain) {
            getApplicationForm(param.id);
            getJobsDetail(param.id);
        }

    }, [jobID && companyDomain])

    useEffect(() => {
        getProfile();

    }, [])




    const getJobsDetail = (id) => {
        JobsInstanceService.getJobsDetail(id, companyDomain).then((res) => {
            if (res.data) {
                setJobDetailModel(res.data);
            }
        })
    }

    const getApplicationForm = (id) => {
        setApplicationForm(APPLICATIONFORM_MOCK);


        JobsInstanceService.getApplicationForm(id, companyDomain).then((res) => {
            if (res.data && profileApplyMod) {
                const result = res.data as ApplicationFormModel;
                console.log(result);
                setApplicationForm(result);
                const inputLanguageMod: ApplicationInputModel[] = new Array();
                const inputContactMod: ApplicationInputModel[] = new Array();
                const inputCandidateMod: ApplicationInputModel[] = new Array();
                const inputExperienceMod: ApplicationInputModel[] = new Array();
                const inputLicenseMod: ApplicationInputModel[] = new Array();
                const inputJobsDetalMod: ApplicationInputModel[] = new Array();
                const inputOtherMod: ApplicationInputModel[] = new Array();
                const startInput: ApplicationInputModel[] = [
                    {
                        key: "profileImage",
                        value: profileApplyMod.profileImageUrl,
                        type: "upload",
                        status: result.formFields.profileImage,
                        error: "",
                    },
                    {
                        key: "resume",
                        value: "",
                        type: "upload",
                        status: result.formFields.resume,
                        error: "",
                    }
                ];
                startInput.map((item) => {
                    inputCandidateMod.push(item);
                })

                Object.keys(result.formFields).map((keys: string, index: number) => {

                    if (result.formFields[keys] != "NOT_REQUIRED") {
                        let inputItem: ApplicationInputModel = {
                            key: keys,
                            value: "",
                            type: "text",
                            status: result.formFields[keys],
                            error: ""
                        }


                        if (keys == "name") {
                            inputItem.value = profileApplyMod.firstName + "," + profileApplyMod.lastName
                            inputItem.type = "text"
                            inputCandidateMod.push(inputItem)
                        }
                        else if (keys == "email") {
                            inputItem.value = profileApplyMod.email
                            inputItem.type = "text"
                            inputContactMod.push(inputItem)
                        }
                        else if (keys == "phone") {
                            inputItem.value = profileApplyMod.phone
                            inputItem.type = "text"
                            inputContactMod.push(inputItem)
                        }
                        else if (keys == "lineId") {
                            inputItem.value = profileApplyMod.lineId
                            inputItem.type = "text"
                            inputContactMod.push(inputItem)
                        } else if (keys == "wechatId") {
                            inputItem.value = profileApplyMod.wechatId
                            inputItem.type = "text"
                            inputContactMod.push(inputItem)
                        } else if (keys == "facebookId") {
                            inputItem.value = profileApplyMod.facebookId
                            inputItem.type = "text"
                            inputContactMod.push(inputItem)
                        }
                        else if (keys == "whatsappId") {
                            inputItem.value = profileApplyMod.whatsappId
                            inputItem.type = "text"
                            inputContactMod.push(inputItem)
                        } else if (keys == "address") {
                            inputItem.value = profileApplyMod.address
                            inputItem.type = "text"
                            inputContactMod.push(inputItem)
                            let inputItemPostcode: ApplicationInputModel = {
                                key: "postcode",
                                value: profileApplyMod.postcode,
                                type: "number",
                                status: result.formFields[keys],
                                error: ""
                            }
                            inputContactMod.push(inputItemPostcode)
                            let inputItemCountry: ApplicationInputModel = {
                                key: "country",
                                value: profileApplyMod.country,
                                type: "select",
                                option: COUNTRY_JSON,
                                status: result.formFields[keys],
                                error: ""
                            }
                            inputContactMod.push(inputItemCountry)

                        } else if (keys == "country") {
                            inputItem.value = profileApplyMod.country
                            inputItem.type = "select"
                            inputItem.option = COUNTRY_JSON
                            inputContactMod.push(inputItem)
                            
                        }
                        else if (keys == "gender") {
                            inputItem.value = profileApplyMod.gender
                            inputItem.type = "select"
                            inputItem.option = GENDER_JSON
                            inputCandidateMod.push(inputItem)
                        }

                        else if (keys == "birthDate") {
                            inputItem.value = profileApplyMod.dateOfBirth
                            inputItem.type = "date"
                            inputCandidateMod.push(inputItem)
                        } else if (keys == "weightAndHeight") {
                            inputItem.value = profileApplyMod.weight + "," + profileApplyMod.height
                            inputItem.type = "number"
                            inputCandidateMod.push(inputItem)
                        } else if (keys == "nationality") {
                            inputItem.value = profileApplyMod.nationality
                            inputItem.type = "select"
                            inputItem.option = NATIONALITY_JSON
                            inputCandidateMod.push(inputItem)
                        } else if (keys == "religion") {
                            inputItem.value = profileApplyMod.religion
                            inputItem.type = "select"
                            inputItem.option = RELIGION_JSON

                            inputCandidateMod.push(inputItem)
                        } else if (keys == "maritalStatus") {
                            inputItem.value = profileApplyMod.maritalStatus
                            inputItem.type = "select"
                            inputItem.option = MARRIED_JSON
                            inputCandidateMod.push(inputItem)
                        } else if (keys == "militaryStatus") {
                            inputItem.value = profileApplyMod.militaryStatus
                            inputItem.type = "select"
                            inputItem.option = MILITARY_JSON
                            inputCandidateMod.push(inputItem)
                        } else if (keys == "experiences") {
                            inputItem.value = profileApplyMod.experiences
                            inputItem.type = "custom"
                            inputExperienceMod.push(inputItem)
                        } else if (keys == "educations") {
                            inputItem.value = profileApplyMod.educations
                            inputItem.type = "custom"
                            inputExperienceMod.push(inputItem)
                        } else if (keys == "skills") {
                            inputItem.value = profileApplyMod.skills
                            inputItem.type = "custom"
                            inputExperienceMod.push(inputItem)
                        } else if (keys == "activities") {
                            inputItem.value = profileApplyMod.activities
                            inputItem.type = "custom"
                            inputExperienceMod.push(inputItem)
                        }
                        else if (keys == "bikeLicense") {
                            inputItem.value = profileApplyMod.bikeLicense
                            inputItem.type = "checkbox"
                            inputLicenseMod.push(inputItem)
                        } else if (keys == "carLicense") {
                            inputItem.value = profileApplyMod.carLicense
                            inputItem.type = "checkbox"
                            inputLicenseMod.push(inputItem)
                        } else if (keys == "truckLicense") {
                            inputItem.value = profileApplyMod.truckLicense
                            inputItem.type = "checkbox"
                            inputLicenseMod.push(inputItem)
                        } else if (keys == "carLicense") {
                            inputItem.value = profileApplyMod.carLicense
                            inputItem.type = "checkbox"
                            inputLicenseMod.push(inputItem)
                        }
                        else if (keys == "language") {
                            inputItem.value = profileApplyMod.languages
                            inputItem.type = "custom"
                            inputLanguageMod.push(inputItem)
                        }
                        else if (keys == "certificates") {
                            inputItem.value = profileApplyMod.certificates
                            inputItem.type = "custom"
                            inputExperienceMod.push(inputItem)
                        } else if (keys == "candidateFiles") {
                            inputItem.value = profileApplyMod.candidateFiles
                            inputItem.type = "custom"
                            inputExperienceMod.push(inputItem)
                        } else if (keys == "expectedSalary") {
                            inputItem.value = profileApplyMod.expectedSalary
                            inputItem.type = "text"
                            inputJobsDetalMod.push(inputItem)
                        }
                    }

                })
                if (result.questions && result.questions.length > 0) {
                    setIsQuestion(true);
                }

                result.questions && result.questions.map((item: Question, index: number) => {

                    let inputItem: ApplicationInputModel = {
                        key: item.id + "",
                        question: item.question,
                        question_id: item.id,
                        question_number: item.number,
                        question_type: item.questionType,
                        value: null,
                        type: "question",
                        status: "MANDATORY",
                        error: ""
                    }
                    if (item.questionType == "TRUE_FALSE_QUESTION") {
                        inputItem.value = "none";
                    }

                    inputOtherMod.push(inputItem)
                })
                setInputJobModel(inputJobsDetalMod);
                setInputCandidateModel(inputCandidateMod);
                setInputExperienceModel(inputExperienceMod);
                setInputOtherModel(inputOtherMod);
                setInputLanguageModel(inputLanguageMod);
                setInputContactModel(inputContactMod);
                setInputLicenseModel(inputLicenseMod)
            }
        })
    }

    const getProfile = () => {
        RamaService.getProfileForApply().then((res) => {
            if (res.data) {
                setProfileApplyMod(res.data);
            }
        }).catch((err) => {
        })
    }

    const valueConvert = (action, value) => {
        if (action == 'salary') {
            return translate.salary_type.filter(item => item.name == value)[0].value;
        } else if (action == 'job_type') {
            return translate.job_type.filter(item => item.name == value)[0].value;
        }
    }

    const onTextChange = (action, value, index) => {

        if (action == "expectedSalary") {
            if (value && profileApplyMod) {
                if (value >= 1) {
                    profileApplyMod.expectedSalary = Number(value);
                    setInputJobModel((prevItems) =>
                        prevItems.map((item, idx) =>
                            idx === index ? { ...item, value: value, error: "" } : item
                        )
                    );
                } else {
                    setInputJobModel((prevItems) =>
                        prevItems.map((item, idx) =>
                            idx === index ? { ...item, value: Number(value), error: translate.error.expected_salary_error } : item
                        )
                    );
                }

            } else {
                setInputJobModel((prevItems) =>
                    prevItems.map((item, idx) =>
                        idx === index ? { ...item, value: "", error: translate.error.expected_salary_error } : item
                    )
                );
            }
        }



    }


    const applyJobs = () => {

        if (!isCandidateEdit && !isExperienceEdit) {
            setIsReadySubmit(false);
            if (profileApplyMod && applicationForm) {

                const applyFormProfile: ProfileApplyModel = {};
                setIsSubmit(true);
                let isChecking = false;
                if (inputCandidateModel.filter(item => item.status == "MANDATORY" && !item.value).length > 0) {
                    setIsCandidateEdit(true);
                    isChecking = true;

                }

                if (inputContactModel.filter(item => item.status == "MANDATORY" && !item.value).length > 0) {
                    setIsCandidateEdit(true);
                    isChecking = true;
                }

                if (inputLicenseModel.filter(item => item.status == "MANDATORY" && !item.value).length > 0) {
                    setIsCandidateEdit(true);
                    isChecking = true;
                }

                if (inputLanguageModel.filter(item => item.status == "MANDATORY" && !item.value).length > 0) {
                    setIsCandidateEdit(true);
                    isChecking = true;
                }
                if (inputExperienceModel.filter(item => item.status == "MANDATORY" && !item.value).length > 0) {
                    setIsExperienceEdit(true);
                    isChecking = true;
                }
                if (inputExperienceModel.filter(item => item.key == "certificates" &&
                    item.status == "MANDATORY" && item.value.length == 0).length > 0) {
                    setIsExperienceEdit(true);
                    isChecking = true;
                }
                if (inputJobModel.filter(item => item.status == "MANDATORY" && !item.value).length > 0) {
                    isChecking = true;
                }
                if (isQuestion) {
                    if (inputOtherModel.filter(item => item.question_type == "TRUE_FALSE_QUESTION" && item.status == "MANDATORY" && item.value == "none").length > 0) {
                        isChecking = true;
                        setIsQuestionEdit(true);
                    }
                    if (inputOtherModel.filter(item => item.question_type == "SUBJECTIVE" && item.status == "MANDATORY" && item.value == "").length > 0) {
                        isChecking = true;
                        setIsQuestionEdit(true);
                    }
                }


                if (!isChecking) {
                    Object.keys(applicationForm.formFields).map((keys: string, index: number) => {

                        if (applicationForm.formFields[keys] != "NOT_REQUIRED") {
                            if (keys == "name") {
                                if (profileApplyMod[keys]) {
                                    applyFormProfile.firstName = profileApplyMod[keys].split(",")[0];
                                    applyFormProfile.lastName = profileApplyMod[keys].split(",")[1];
                                } else {
                                    applyFormProfile.firstName = profileApplyMod.firstName;
                                    applyFormProfile.lastName = profileApplyMod.lastName;
                                }
                            } else if (keys == "resume") {
                                applyFormProfile.resumeKey = profileApplyMod.resumeKey
                            } else if (keys == "weightAndHeight") {
                                applyFormProfile.weight = profileApplyMod.weight
                                applyFormProfile.height = profileApplyMod.height
                            } else if (keys == "nationality") {
                                inputCandidateModel.filter(item => item.key == "nationality").map((item: ApplicationInputModel) => {
                                    if(item.value[0]){
                                        const option = item.value[0] as OptionInputSelectModel;
                                        const nationalityVal = NATIONALITY_JSON.filter((item2) => item2.key == item.value)[0]
                                        applyFormProfile.nationality = nationalityVal.value
                                    }
                                 
                                })
                            } else if (keys == "address") {
                                applyFormProfile.address = profileApplyMod.address
                                applyFormProfile.postcode = profileApplyMod.postcode
                                inputContactModel.filter(item => item.key == "country").map((item: ApplicationInputModel) => {
                                    const option = item.value[0] as OptionInputSelectModel;
                                    const countryVal = COUNTRY_JSON.filter((item2) => item2.value == item.value)[0]
                                    applyFormProfile.country = countryVal.value
                                })
                            }
                            else {
                                applyFormProfile[keys] = profileApplyMod[keys]
                            }
                        }
                    })
                    applyFormProfile.candidateAnswers = profileApplyMod.candidateAnswers
                    if(profileApplyMod.profileImageUrl){
                        applyFormProfile.profileImageUrl = profileApplyMod.profileImageUrl;
                        applyFormProfile.profileImageKey = profileApplyMod.profileImageKey;
                    }
                    setIsSendingSubmit(true);
                    console.log(applyFormProfile);
                    JobsInstanceService.applyJobs(applyFormProfile, companyDomain, jobID).then((res) => {

                        navigate(navigateConfig.jobs.main)
                        setIsSendingSubmit(false);

                    }).catch((err) => {

                        setIsSendingSubmit(false);
                    })
                }

            } else if (profileApplyMod && profileApplyMod.expectedSalary == undefined) {
                alert(profileApplyMod.expectedSalary)
                setIsExpectedSalary(true);
            }
        } else {
            setIsReadySubmit(true);
        }

    }

    return (
        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="job-apply-main-control">
                <img src={BG_CIRCLE_MAIN} className="bg-circle-main"></img>
                <span className="job-apply-header">
                    สมัครงาน
                </span>
                {
                    (jobDetailModel && profileApplyMod) &&
                    <div className="jobs-apply-grid-control">
                        {
                            !isSendingSubmit &&
                            <div className="job-apply-send-control">

                                <div className="job-apply-send-text-header">{translate.job.application_form} : {jobDetailModel.name}</div>
                                {
                                    !isCandidateEdit && !isExperienceEdit && !isQuestionEdit ?
                                        <Button className="job-apply-send-button" onClick={applyJobs}>{translate.job_apply.send_application}</Button> :

                                        <Button className="job-apply-send-button-disabled" onClick={applyJobs}>{translate.job_apply.send_application}</Button>
                                }
                            </div>
                        }




                        {
                            isSendingSubmit &&

                            <>
                                <div className="jobs-apply-submitting-control">
                                    <div className="jobs-apply-submitting-main">

                                        <FontAwesomeIcon icon={faBriefcase} className="jobs-apply-submitting-loading-icon"></FontAwesomeIcon>
                                        <span className="jobs-apply-submitting-loading-text">{translate.job.submitting} {jobDetailModel.name}....</span>
                                    </div>


                                </div>
                            </>
                        }
                        {
                            !isSendingSubmit &&

                            <>
                                <div className="jobs-apply-item-main-box">
                                    <div className="jobs-apply-main-item-control">
                                        <div className="jobs-apply-detail-control">
                                            <span className="jobs-apply-desc">{translate.job_apply.position}:</span>
                                            <span className="jobs-apply-title">{jobDetailModel.name}</span>
                                        </div>

                                        <div className="jobs-apply-detail-control">
                                            <span className="jobs-apply-desc">{translate.job_apply.job_type}:</span>
                                            <span className="jobs-apply-title">{valueConvert('job_type', jobDetailModel.jobType)}</span>
                                        </div>
                                        <div className="jobs-apply-detail-control">
                                            <span className="jobs-apply-desc">{translate.job_apply.salary}:</span>
                                            <span className="jobs-apply-title"> {
                                                (jobDetailModel.displaySalary) ?
                                                    jobDetailModel.salaryFrom.toLocaleString() + " - " + jobDetailModel.salaryTo.toLocaleString() + " " + jobDetailModel.salaryCurrency + valueConvert('salary', jobDetailModel.salaryType)
                                                    : "-"
                                            }</span>
                                        </div>
                                        <div className="jobs-apply-detail-control">
                                            <span className="jobs-apply-desc">{translate.job_apply.location}:</span>
                                            <span className="jobs-apply-title">{jobDetailModel.googlePlaceName}</span>
                                        </div>
                                        <div className="jobs-apply-detail-control">
                                            <span className="jobs-apply-desc">{translate.job_apply.job_role}:</span>
                                            <span className="jobs-apply-title">{
                                                languageService.getLanguage() == 'en' ?
                                                    jobDetailModel.jobRoleDisplayNameEn : jobDetailModel.jobRoleDisplayName}</span>
                                        </div>
                                        {
                                            inputJobModel && inputJobModel.filter(item => item.key != "NOT_REQUIRED").length > 0 &&
                                            <div className="jobs-apply-detail-control-full">
                                                <div className="jobs-apply-main-item-expand-control">

                                                    {
                                                        inputJobModel.filter(item => item.key != "NOT_REQUIRED").map((item: ApplicationInputModel, index: number) => {

                                                            return (
                                                                <div key={index}>
                                                                    <span className="jobs-apply-main-item-expand-title">{translate.application_form[item.key]}<span className="jobs-apply-main-item-expand-error">{item.status == 'MANDATORY' && "*"}</span></span>
                                                                    <Form.Control
                                                                        className={
                                                                            (isSubmit && item.status == "MANDATORY" && (!item.value)) ? "jobs-apply-item-form-input jobs-apply-item-form-input-error" : "jobs-apply-item-form-input"
                                                                        }
                                                                        type="number"
                                                                        name=""
                                                                        placeholder=""
                                                                        defaultValue={item.value}
                                                                        onChange={(e) => onTextChange(item.key, e.target.value, index)}
                                                                    />
                                                                    {
                                                                        (isExpectedSalary && item.status == "MANDATORY" && item.value == "")
                                                                            ? <span className="jobs-apply-main-item-expand-error">{translate.error.input}</span> :
                                                                            (item.error)
                                                                            && <span className="jobs-apply-main-item-expand-error">{item.error}</span>
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }


                                    </div>
                                </div>

                                {
                                    profileApplyMod && inputCandidateModel &&
                                    <div className="jobs-apply-item-main-box">
                                        <CandidateProfile profileApplyMod={profileApplyMod}
                                            inputCandidateModel={inputCandidateModel}
                                            setInputCandidateModel={setInputCandidateModel}
                                            setProfileApplyMod={setProfileApplyMod}
                                            setInputLicenseModel={setInputLicenseModel}
                                            inputLicenseModel={inputLicenseModel}
                                            setInputContactModel={setInputContactModel}
                                            inputContactModel={inputContactModel}
                                            inputLanguageModel={inputLanguageModel}
                                            setInputLanguageModel={setInputLanguageModel}
                                            setIsCandidateEdit={setIsCandidateEdit}
                                            isCandidateEdit={isCandidateEdit}
                                            isBigSubmit={isSubmit}
                                        ></CandidateProfile>
                                    </div>
                                }


                                {
                                    profileApplyMod && inputExperienceModel &&
                                    <div className="jobs-apply-item-main-box">
                                        <ExperienceApply profileApplyMod={profileApplyMod}
                                            inputExperienceModel={inputExperienceModel}
                                            setInputExperienceModel={setInputExperienceModel}
                                            setProfileApplyMod={setProfileApplyMod}
                                            setIsExperienceEdit={setIsExperienceEdit}
                                            isExperienceEdit={isExperienceEdit}
                                            isBigSubmit={isSubmit}
                                        ></ExperienceApply>
                                    </div>
                                }


                                {
                                    profileApplyMod && inputOtherModel && inputOtherModel.length > 0 &&
                                    <div className="jobs-apply-item-main-box">
                                        <QuestionsJobApply profileApplyMod={profileApplyMod}
                                            inputOtherModel={inputOtherModel}
                                            setInputOtherModel={setInputOtherModel}
                                            setProfileApplyMod={setProfileApplyMod}
                                            isQuestionEdit={isQuestionEdit}
                                            isBigSubmit={isSubmit}
                                            setIsQuestionEdit={setIsQuestionEdit}></QuestionsJobApply>
                                    </div>
                                }

                                <div className="job-apply-send-control-reverse">

                                    <div className="job-apply-send-text-header">{translate.job.application_form} : {jobDetailModel.name}</div>
                                    {
                                        !isCandidateEdit && !isExperienceEdit ?
                                            <Button className="job-apply-send-button" onClick={applyJobs}>{translate.job_apply.send_application}</Button> :

                                            <>

                                                <Button className="job-apply-send-button-disabled" onClick={applyJobs} >{translate.job_apply.send_application}</Button>
                                            </>

                                    }
                                </div>
                                {/* {
                                    !isCandidateEdit && !isExperienceEdit && !isQuestionEdit ?
                                        <Button className="job-apply-submit-application-button" onClick={applyJobs}>Submit Application</Button> :
                                        <div className="job-apply-submit-application-button-disabled-control">
                                            {isSubmit && <span className="job-apply-submit-application-button-disabled-text">กรุณากดบันทึกในแต่ละหัวข้อ</span>}

                                            <Button className="job-apply-submit-application-button-disabled" onClick={applyJobs}>Submit Application</Button>
                                        </div>

                                } */}
                            </>
                        }





                    </div>
                }
            </div>
            <Snackbar open={isReadySubmit} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={() => setIsReadySubmit(false)} >
                <Alert onClose={() => setIsReadySubmit(false)} severity="error" sx={{ width: '100%' }}>
                    กรุณากดบันทึกในแต่ละหัวข้อ
                </Alert>
            </Snackbar>

        </div>
    )
}

export default JobApplyComponent;