import apiPath from "../../api-path";
import instanceJobs from "./auth-jobs";



const uploadAnyFile = (fileUpload, company_name) => {
    
    return instanceJobs.post(apiPath.leela.company_jobs +company_name + "/candidate-files",  {
        files: fileUpload
    })
}

const applyJobs = (applicationForm, company_name, job_uuid) => {
    
    return instanceJobs.post(apiPath.leela.company_jobs +company_name + "/jobs/" + job_uuid + "/apply", applicationForm)
}

const getApplicationForm = (job_uuid, company_name) => {

    return instanceJobs.get(apiPath.leela.company_jobs +company_name + "/jobs/" + job_uuid + "/application-form")
}


const getSkillSuggestion = (Experience) => {

    return instanceJobs.post(apiPath.leela.skills, Experience)
}

const getJobsDetail = (job_uuid,company_name) => {

    return instanceJobs.get(apiPath.leela.company_jobs + company_name + "/jobs/" + job_uuid)
}
const JobsInstanceService = {
    uploadAnyFile,
    applyJobs,
    getApplicationForm,
    getSkillSuggestion,
    getJobsDetail
};

export default JobsInstanceService;