import { Button, Container, Form, Modal } from "react-bootstrap";
import "./builder.scss";
import 'react-quill/dist/quill.snow.css';
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ProvideContext } from "../../../context/provider.context";
import { ResumeJson, getOneResumeModel } from "../../../model/resume-builder/get-one-resume.model";
import inputService from "../../../services/resume-builder/input.service";
import config from "../../../config";
import ColorPickerModal from "../../../modal/resume-builder/color-picker.modal";
import Template_0_Component from "../template/0/template-0";
import Template_1_Component from "../template/1/template-1";
import Template_2_Component from "../template/2/template-2";
import { Education, Experience, Language, ProfileModel, Skill } from "../../../model/profile.model";
import EDUCATION_LEVEL_JSON from "../../../assets/json/education-level.json";
import { InputResumeBuilderModel, InputResumeObject } from "../../../model/resume-builder/input-resume-builder.model";
import EditProfileModal from "../../../modal/edit-profile.modal";
import CoverLetter from "../cover-letter/cover-letter";
import RamaService from "../../../services/sso/rama.service";
import tokenService from "../../../services/sso/token.service";
import MenuComponent from "../../menu/menu";
import AI_ICON from "../../../assets/images/ai-icon.png";
import { TranslateModel } from "../../../model/translate.model";
import { useFilePicker } from "use-file-picker";
import ResumeBuilderInstantService from "../../../services/resume-builder/resume-builder-instance.service";
import { useProfile } from "../../../context/profile.context";
import moment from "moment";
import { FileAmountLimitValidator, FileSizeValidator, FileTypeValidator } from "use-file-picker/validators";
import { FileErrorModel } from "../../../model/error-file-select.model";
import FileLimitModal from "../../../modal/file-limit-modal";
import UtilityService from "../../../utility/utility.service";
type FormData = {
    name: string;
    cover_letter: string;
    position: string;
    experience: string;
    expertise: string;
    language: string;
    interest: string;
    education: string;
    contact: string;
}
const BuilderComponent = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const location = useLocation();
    const navigate = useNavigate();
    const [templateId, setTemplateId] = useState("");
    const [inputTemplateModel, setInputTemplateModel] = useState<InputResumeBuilderModel>();
    const [editCoverLetter, setEditCoverLetter] = useState(false);
    const [isView, setIsView] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [resumeUUID, setResumeUUID] = useState("");
    const [fileUpload, setFileUpload] = useState<any>();
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [editProfileAction, setEditProfileAction] = useState("");
    const [isChangeColor, setIsChangeColor] = useState(false);
    const [interestingModel, setInterestingModel] = useState<string[]>(new Array());
    const [profile, error] = useProfile();
    const [isLimitError, setIsLimitError] = useState(false);
    const [fileError, setFileError] = useState<FileErrorModel>();
    const [resultResume, setResultResume] = useState<getOneResumeModel>();
    const { openFilePicker, filesContent, loading, errors, clear } = useFilePicker({
        multiple: true,
        readAs: 'DataURL',
        accept: ['image/*', '.pdf'],
        limitFilesConfig: { min: 0, max: 1 },
        validators: [
            new FileAmountLimitValidator({ max: 1 }),
            new FileTypeValidator(['jpg', 'png', 'jpeg']),
            new FileSizeValidator({ maxFileSize: config.MAX_ATTACHMENT_RESUME_IMAGE_SIZE * 1024 * 1024 })
        ],
        onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
            setFileUpload(filesContent[0])

            changeProfile(filesContent);
        },
        onFilesRejected: ({ errors }) => {
            console.log(errors);
            if (errors.length > 0) {
                const errorsResult = errors as FileErrorModel[];
                setIsLimitError(true);
                setFileError(errorsResult[0]);
            }
        }
    });

    const [scale, setScale] = useState(getScale());

    useEffect(() => {
        function handleResize() {
            setScale(getScale());
        }

        // Set the scale based on the current window width when the component mounts
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // Helper function to determine scale based on viewport width
    function getScale(): number {
        const width = window.innerWidth;
        if (width < 768) { // sm breakpoint
            return 0.43;
        } else if (width >= 768 && width < 1024) { // md breakpoint
            return 0.9;
        } else { // lg and above
            return 1;
        }
    }
    const param = useParams();

    useEffect(() => {
        if (resultResume && profile) {
            const templateDefault: InputResumeBuilderModel = {
                template_id: resultResume.template_id,
                is_save: resultResume.resume_json.is_save
            };

            if (resultResume.resume_json.is_save) {
                setTemplateId(resultResume.template_id);
                initialValue(resultResume, templateDefault);
            } else if (profile) {
                setTemplateId(resultResume.template_id);
                templateDefault.name = {
                    value: profile.firstName + " " + profile.lastName,
                    isEdit: false,
                    isShow: true
                }
                initialValueFromProfile(profile, templateDefault, resultResume);
            }

        }
    }, [resultResume])

    useEffect(() => {
        if (profile) {
            if (param.id) {
                setResumeUUID(param.id);
                getResumeData(param.id)
            }
        }
    }, [profile])

    const UpdateProfileModal = (profileUpdate) => {
    }


    const clickChangeTemplateColor = () => {
        setIsChangeColor(true);
    }

    const handleCloseChangeTemplateColor = () => {
        if (isChangeColor) {
            setIsChangeColor(false);
        } else {
            setIsChangeColor(true);
        }
    }

    const getResumeData = (resume_uuid) => {
        ResumeBuilderInstantService.getOneResume(resume_uuid).then((resobj) => {
            if (resobj.data) {

                const res: getOneResumeModel = resobj.data;
                console.log(res);
                setResultResume(res);
            }
        })
    }

    const initialValue = (resultRecieve: getOneResumeModel, templ: InputResumeBuilderModel) => {
        const result = resultRecieve.resume_json;
        if (result.color) {
            templ.color = {} as InputResumeObject;
            templ.color.value = result.color.value;
        }


        if (result.cover_letter) {
            templ.cover_letter = {} as InputResumeObject;
            templ.cover_letter.value = result.cover_letter.value;
        } else {
            templ.cover_letter = {} as InputResumeObject;
            templ.cover_letter.value = "";
        }

        if (result.name) {
            templ.name = {} as InputResumeObject;
            templ.name.value = result.name.value;
        }

        if (result.contact) {
            templ.contact = {} as InputResumeObject;
            templ.contact.value = result.contact.value;
        }

        if (result.experience) {
            templ.experience = {} as InputResumeObject;
            templ.experience.value = result.experience.value;
        }

        if (result.education) {
            templ.education = {} as InputResumeObject;
            templ.education.value = result.education.value;
        }
        if (result.expertise) {
            templ.expertise = {} as InputResumeObject;
            templ.expertise.value = result.expertise.value;
        }

        if (result.language) {
            templ.language = {} as InputResumeObject;
            templ.language.value = result.language.value;
        }

        if (result.position) {
            templ.position = {} as InputResumeObject;
            templ.position.value = result.position.value;
        }

        if (result.interest) {
            templ.interest = {} as InputResumeObject;
            templ.interest.value = result.interest.value;
        }


        setInputTemplateModel(templ);
        if (resumeUUID) {
            templ.is_save = true;
            ResumeBuilderInstantService.updateResumeJson(resumeUUID, templ).then((res) => {

            })
        }



    }


    const initialValueFromProfile = (profile: ProfileModel, templ: InputResumeBuilderModel, resultRecieve: getOneResumeModel) => {
        const result = resultRecieve.resume_json;
        templ.color = {} as InputResumeObject;
        templ.color.value = "#000";



        if (profile.firstName && profile.lastName) {
            templ.name = {} as InputResumeObject;
            templ.name.value = profile.firstName + " " + profile.lastName;
        }

        if (profile.phone) {
            templ.contact = {} as InputResumeObject;
            templ.contact.value = createContactFromProfile();
        }

        if (profile.experiences) {
            templ.experience = {} as InputResumeObject;
            templ.experience.value = createExpFromProfile();
        }

        if (profile.educations) {
            templ.education = {} as InputResumeObject;
            templ.education.value = createEduFromProfile();
        }
        if (profile.skills) {
            templ.expertise = {} as InputResumeObject;
            templ.expertise.value = createSkillFromProfile();
        }

        if (profile.languages) {
            templ.language = {} as InputResumeObject;
            templ.language.value = createLanguageFromProfile();
        }

        if (profile.experiences) {
            templ.position = {} as InputResumeObject;
            templ.position.value = createLastPosition();
        }
        templ.interest = {} as InputResumeObject;
        templ.interest.value = "";
        templ.cover_letter = {} as InputResumeObject;
        templ.cover_letter.value = "";
        setInputTemplateModel(inputTemplateModel => ({ ...templ }));
        if (resumeUUID) {
            templ.is_save = true;
            ResumeBuilderInstantService.updateResumeJson(resumeUUID, templ).then((res) => {

            })
        }



    }

    const createLanguageFromProfile = () => {


        if (profile && profile.languages.length > 0) {
            let languages = "<ul>";
            profile.languages.map((item: Language, index: number) => {
                let listen = "";
                let reading = "";
                let speaking = "";
                let writing = "";
                let mainLang = "";
                if (item.language == "en") {
                    mainLang = "English"
                } else if (item.language == "th") {
                    mainLang = "Thai"
                } else if (item.language == "jp") {
                    mainLang = "Japanese"
                } else if (item.language == "ch") {
                    mainLang = "Chinese"
                }
                if (item.listening) {
                    listen = "listening";
                }
                if (item.reading) {
                    reading = "reading";
                }
                if (item.speaking) {
                    speaking = "speaking";
                }
                if (item.writing) {
                    writing = "writing";
                }
                languages += "<li>" + mainLang + ": ";
                languages += "<ul>";
                if (listen) {
                    languages += "<li>" + listen + "</li>";
                }
                if (speaking) {
                    languages += "<li>" + speaking + "</li>";
                }
                if (reading) {
                    languages += "<li>" + reading + "</li>";
                }
                if (writing) {
                    languages += "<li>" + writing + "</li>";
                }
                languages += "</ul>";
                languages += "</li>";

            })
            languages += "</ul>"
            return languages;

        } else {
            return "";
        }

    }
    const createLastPosition = () => {
        let jobTitle = "";

        if (profile && profile.experiences.length > 0) {
            jobTitle = profile.experiences[profile.experiences.length - 1].jobTitle
        }
        return jobTitle;

    }

    const createExpFromProfile = () => {


        if (profile && profile.experiences.length > 0) {
            let workExp = "<ul>";
            profile.experiences.map((item: Experience, index: number) => {
                workExp += "<li>"
                if (item.startDate) {
                    workExp += "<span><strong>" +
                        moment(new Date(item.startDate)).format("MMMM YYYY") + "</strong>";
                }
                if (item.stillWorkHere) {
                    workExp += "<strong> - present</strong></span><br>";
                }

                if (item.endDate && !item.stillWorkHere) {
                    workExp += "<strong> - " + moment(new Date(item.endDate)).format("MMMM YYYY") + "</strong></span><br>";
                }

                if (item.jobTitle) {
                    workExp += "<span>Position: <strong>" + item.jobTitle + "</strong></span><br>";
                }
                if (item.companyName) {
                    workExp += "<span>Company: <strong>" + item.companyName + "</strong></span><br>";
                }
                if (item.responsibilities) {
                    workExp += "<span>Responsibility: <strong>" + item.responsibilities + "</strong></span><br>";
                }
                workExp += "</li> <br>";

            })
            workExp += "</ul>";
            return workExp;
        } else {
            return ""
        }



    }

    const createContactFromProfile = () => {
        let contact = "";
        if (profile && profile.address) {
            contact += "<span><strong>Address:</strong> " + profile.address + "</span><br>"
        } if (profile && profile.country) {
            contact += "<span><strong>Country:</strong> " + profile.country + "</span><br>"
        }
        if (profile && profile.phone) {
            contact += "<span><strong>Tel:</strong> " + profile.phone + "</span><br>"
        }
        if (profile && profile.email) {
            contact += "<span><strong>Email:</strong> " + profile.email + "</span><br>"
        }
        if (profile && profile.lineId) {
            contact += "<span><strong>LINE ID:</strong> " + profile.lineId + "</span><br>"
        } if (profile && profile.whatsappId) {
            contact += "<span><strong>Whats app:</strong> " + profile.whatsappId + "</span><br>"
        }
        if (profile && profile.wechatId) {
            contact += "<span><strong>Wechat:</strong> " + profile.wechatId + "</span><br>"
        }

        return contact;

    }
    const createSkillFromProfile = () => {

        if (profile && profile.skills.length > 0) {
            let skillExp = "<ul>";
            profile.skills.map((item: Skill, index: number) => {
                skillExp += "<li>" + item.skillName + "</li>";
            })
            skillExp += "</ul>";
            return skillExp;
        } else {
            return "";
        }


    }
    const createEduFromProfile = () => {


        if (profile && profile.educations.length > 0) {
            let workExp = "<ul>";
            profile.educations.map((item: Education, index: number) => {
                workExp += "<li>"
                if (item.startYear) {
                    workExp += "<span><strong>" + item.startYear + "</strong>";
                }

                if (item.graduationYear) {
                    workExp += "<strong> - " + item.graduationYear + "</strong></span><br>";
                }
                let valueRet = "";
                EDUCATION_LEVEL_JSON.map((items: any, indexs: number) => {
                    if (item.educationLevel == items.value) {
                        valueRet = items.name;
                    }
                })
                if (valueRet) {
                    workExp += "<span><strong>" + valueRet + "</strong></span><br>";
                }
                if (item.fieldOfStudy && item.faculty && item.institute) {
                    workExp += "<span><strong>" + item.fieldOfStudy + ", " + item.faculty + " at " + item.institute + "</strong></span><br>";
                }


                if (!item.fieldOfStudy && !item.faculty && item.institute) {
                    workExp += "<span><strong>at " + item.institute + "</strong></span> <br>";
                }

                if (item.gpax) {
                    workExp += "<span>GPA: <strong>" + item.gpax + "</strong></span><br>";
                }

                workExp += "<br></li>";

            })
            workExp += "</ul>";
            return workExp;
        } else {
            return ""
        }



    }




    const handleCloseEditProfile = () => {
        setShowEditProfileModal(false);
    }



    const clickView = () => {

        setIsView(true);
        if (inputTemplateModel) {

            // inputTemplateModel.profile_img = fileImg
            inputTemplateModel.is_save = true;
            console.log(inputTemplateModel);
            if (inputTemplateModel.is_profile_img) {
                if (inputTemplateModel.profile_img[0].includes("data:image")) {

                }
            } else {
                ResumeBuilderInstantService.updateResumeJson(resumeUUID, inputTemplateModel).then((res) => {
                    if (res.status == 200) {
                        UtilityService().clickSendEvent("save_resume_and_view", "submit");
                        // navigate(navigateConfig.resume.main);
                        window.open(config.url + 'resume/view/' + resumeUUID, "_self");
                    }
                })
            }

        }


    }
    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)
        return new Blob([ia], { type: mimeString })
    }

    const changeProfile = (fileContent) => {
        let fileImg = fileContent;

        if (fileImg) {
            const file = DataURIToBlob(fileImg[0].content);
            ResumeBuilderInstantService.updateProfileImg(resumeUUID, file, fileImg[0].name).then((res) => {

                if (res.data) {
                    if(resultResume){
                        const resultResumeMod = { ...resultResume };
                        if(res.data.image_url){
                            resultResumeMod.profile_img = res.data.image_url;
                            setResultResume(resultResumeMod);
                        }
                    }
                    
                   
                }
            });
        }
        // if (resultResume) {

        //     const resultResumeMod = { ...resultResume };
        //     if (resultResumeMod.profile_img) {

        //         resultResumeMod.profile_img = fileImg[0].content;
        //     }
        //     setResultResume(resultResumeMod);
        // }
    }

    const onTextChange = (action, value) => {
        if (inputTemplateModel) {
            const inputTemplateMod = { ...inputTemplateModel };
            if (action == 'name') {
                if (inputTemplateMod.name) {
                    inputTemplateMod.name.value = value
                }
            } else if (action == 'position') {
                if (inputTemplateMod.position) {
                    inputTemplateMod.position.value = value
                }
            }

            setInputTemplateModel(inputTemplateMod);
        }

    }

    const onEdit = (action, value) => {

        if (inputTemplateModel) {
            const inputTemplateMod = { ...inputTemplateModel };
            UtilityService().clickSendEvent("resume_builder_edit_"+action, "click");
            if (action == 'name') {
                if (inputTemplateMod.name) {
                    inputTemplateMod.name.isEdit = value
                }
            }
            if (action == 'contact') {
                if (inputTemplateMod.contact) {
                    if (value) {
                        setShowEditProfileModal(true)
                        setEditProfileAction('contact');
                    }
                }
            }
            if (action == 'position') {
                if (inputTemplateMod.position) {
                    inputTemplateMod.position.isEdit = value
                }
            }
            if (action == 'experience') {
                if (inputTemplateMod.experience) {
                    if (value) {
                        setShowEditProfileModal(true)
                        setEditProfileAction('experience');
                    }
                }
            }
            if (action == 'education') {
                if (inputTemplateMod.education) {
                    if (value) {
                        setShowEditProfileModal(true)
                        setEditProfileAction('education');
                    }
                }
            }
            if (action == 'language') {
                if (inputTemplateMod.language) {
                    if (value) {
                        setShowEditProfileModal(true)
                        setEditProfileAction('about');
                    }
                }
            }
            if (action == 'expertise') {
                if (inputTemplateMod.expertise) {
                    if (value) {
                        setShowEditProfileModal(true)
                        setEditProfileAction('skills');
                    }
                }
            }

            if (action == 'interest') {
                if (inputTemplateMod.interest) {
                    if (value) {
                        setShowEditProfileModal(true)
                        setEditProfileAction('interesting');
                    }
                }
            }

            if (action == 'cover_letter') {
                if (inputTemplateMod.cover_letter) {
                    if (value) {
                        setEditCoverLetter(true);
                    }
                }
            }
            if (action == 'profile_img') {
                openFilePicker();
            }


            setInputTemplateModel(inputTemplateMod);
        }

    }


    const setInterestingUpdate = (interestingModel: string[]) => {

        if (interestingModel && inputTemplateModel && inputTemplateModel.interest) {
            setInterestingModel(interestingModel);
            const inputTempMod = { ...inputTemplateModel };
            if (inputTempMod.interest) {
                let interestStr = "<ul>";
                interestingModel.map((item: string, index: number) => {
                    interestStr += "<li>" + item + "</li>";
                })
                interestStr += "</ul>";
                inputTempMod.interest.value = interestStr;
                setInputTemplateModel(inputTempMod);
            }

        }
    }

    const setColorMain = (colorString) => {
        if (inputTemplateModel) {
            if (inputTemplateModel["color"]) {
                const inputEdit = { ...inputTemplateModel };
                if (inputEdit.color) {
                    inputEdit.color.value = colorString;
                    setInputTemplateModel(inputEdit);
                }

            }
        }

    }

    const setCoverLetterUpdate = (coverLetter) => {
        if (inputTemplateModel) {
            const inputTemplMod = { ...inputTemplateModel };
            if (coverLetter && inputTemplMod.cover_letter) {
                inputTemplMod.cover_letter.value = coverLetter;
            } else {
                inputTemplMod.cover_letter = {} as InputResumeObject;
                inputTemplMod.cover_letter.value = coverLetter
            }

            setInputTemplateModel(inputTemplMod)
            setEditCoverLetter(false);
        }
    }

    const renderField = (fieldKey: string, title: string) => {
        if (inputTemplateModel && resultResume) {
            const fieldData = inputTemplateModel[fieldKey];

            if (!fieldData) return null;

            return (
                <div className="builder-form-item-left">
                    <div className="builder-form-item-title-control">
                        <span className="builder-form-item-title">
                            {title} {title == "Cover letter" && 
                                <img src={AI_ICON} className="builder-form-icon-ai"></img>
                            }
                        </span>
                        {
                            (fieldData.isEdit && fieldKey != "color") ?
                                <span className="builder-form-item-edit" onClick={() => onEdit(fieldKey, false)}>
                                    {translate.other.save}
                                </span> : fieldKey != "color" &&
                                <span className="builder-form-item-edit" onClick={() => onEdit(fieldKey, true)}>
                                    {translate.other.edit}
                                </span>
                        }
                    </div>
                    <div className="builder-form-item-divider"></div>
                    {
                        fieldKey == 'experience' || fieldKey == 'education' && fieldData.isEdit ?

                            <span className="builder-form-item-value" dangerouslySetInnerHTML={{ __html: fieldData.value }}></span> :
                            fieldKey == 'position' && fieldData.isEdit && fieldData.value ?
                                <Form.Select className="builder-form-item-input"
                                    defaultValue={fieldData.value}
                                    onChange={(e) => onTextChange(fieldKey, e.target.value)}>
                                    {profile &&
                                        profile.experiences && profile.experiences.length > 0 &&
                                        profile.experiences.map((item: Experience, index: number) => {
                                            return (
                                                <option value={item.jobTitle} key={index}>{item.jobTitle}</option>
                                            )

                                        })
                                    }

                                </Form.Select>
                                : fieldKey == 'position' && fieldData.isEdit && !fieldData.value ?
                                    <span className="builder-form-item-value">{translate.error.input_exp}</span> :
                                    fieldData.isEdit ?
                                        <Form.Control
                                            className="builder-form-item-input"
                                            type="text"
                                            value={fieldData.value}
                                            onChange={(e) => onTextChange(fieldKey, e.target.value)}
                                        /> :
                                        fieldKey == 'color' ? <div className="builder-form-color-control">
                                            {
                                                fieldData.value &&
                                                <>
                                                    <div className="builder-form-color-value" style={{ backgroundColor: fieldData.value }}></div>
                                                    <span>{fieldData.value}</span>
                                                </>

                                            }

                                        </div> :

                                            <span className="builder-form-item-value" dangerouslySetInnerHTML={{ __html: fieldData.value }}></span>

                    }
                    {
                        fieldKey == 'color' &&
                        <><Button className="button-form-color-button" onClick={clickChangeTemplateColor}>{translate.resume_builder.color_change}</Button></>
                    }
                    {
                        !fieldData.value && fieldKey != 'position' && fieldKey != 'profile_img' &&
                        <span className="builder-form-item-value">{translate.error.no_information}</span>
                    }
                    {
                        fieldKey == 'profile_img' && fieldData.length == 0 &&
                        <span className="builder-form-item-value">{translate.error.no_information}</span>
                    }
                </div>
            );
        }

    }

    // ...

    const fieldMapping = {
        color: translate.profile.color_resume,
        name: translate.profile.first_name,
        contact: translate.profile.contact,
        position: translate.job_apply.position,
        cover_letter: translate.resume_builder.cover_letter,
        experience: translate.application_form.experiences,
        education: translate.application_form.educations,
        language: translate.application_form.language,
        expertise: translate.application_form.skills,
        interest: translate.profile.activiy_interest


    };

    // ...


    return (

        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="builder">
                {
                    inputTemplateModel &&

                    <Container className="builder-control">
                        <div className="builder-form-control">


                            <div className="builder-form-item-control">
                                {
                                    resultResume && resultResume.is_profile_img &&
                                    <div className="builder-form-item-left">
                                        <div className="builder-form-item-title-control">
                                            <span className="builder-form-item-title">
                                                {translate.application_form.profileImage}
                                            </span>
                                            {

                                                <span className="builder-form-item-edit" onClick={() => onEdit("profile_img", true)}>
                                                    {translate.other.edit}
                                                </span>
                                            }
                                        </div>
                                        <div className="builder-form-item-divider"></div>
                                        <img className="builder-form-item-profile" src={resultResume.profile_img} onClick={openFilePicker}></img>
                                    </div>
                                }

                                {Object.keys(fieldMapping).map(fieldKey => renderField(fieldKey as keyof typeof inputTemplateModel, fieldMapping[fieldKey]))}


                            </div>
                            <div className="builder-form-item-control-right ">
                                <div className="builder-form-right-page-control" style={{
                                    transform: `scale(${scale})`,

                                }}>
                                    {
                                        inputTemplateModel && resultResume &&
                                            Number(templateId) == 0 ?
                                            <Template_0_Component templ={inputTemplateModel} containerRef={containerRef} resultResume={resultResume}></Template_0_Component> :
                                            Number(templateId) == 1 ?
                                                <Template_1_Component templ={inputTemplateModel} containerRef={containerRef} resultResume={resultResume}></Template_1_Component> :
                                                Number(templateId) == 2 ?
                                                    <Template_2_Component templ={inputTemplateModel} containerRef={containerRef} profile={profile}></Template_2_Component>
                                                    : <></>
                                    }

                                </div>
                            </div>

                        </div>
                        <Button className="builder-preview-button" onClick={clickView}>{translate.other.save_preview}</Button>
                    </Container>
                }

            </div>

            {
                showEditProfileModal && <EditProfileModal open={showEditProfileModal} setOpen={handleCloseEditProfile} action={editProfileAction}
                    profile={profile} interestingModel={interestingModel}
                    setInterestingUpdate={setInterestingUpdate} setProfile={UpdateProfileModal} isNew={false} isApplicationForm={false}></EditProfileModal>
            }

            {
                editCoverLetter &&
                <Modal show={editCoverLetter}>
                    <Modal.Body>
                        <CoverLetter setCoverLetterUpdate={setCoverLetterUpdate} templ={inputTemplateModel} profile={profile}
                        setEditCoverLetter={setEditCoverLetter}></CoverLetter>
                    </Modal.Body>
                </Modal>
            }
            {
                isChangeColor && <ColorPickerModal open={isChangeColor} setOpen={handleCloseChangeTemplateColor} setColorMain={setColorMain}></ColorPickerModal>
            }
            {
                isLimitError &&

                <FileLimitModal open={isLimitError} handleClose={() => setIsLimitError(false)} fileError={fileError} limitSize={config.MAX_ATTACHMENT_RESUME_IMAGE_SIZE} ></FileLimitModal>
            }


        </div>
    )
}

export default BuilderComponent;