import React, { ReactNode, useContext, useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import CookieConsent from 'react-cookie-consent';
import { Container } from 'react-bootstrap';
import LandingComponent from './components/landing/landing';
import ProfileComponent from './components/profile/profile';
import CheckingAuth from './components/checking-auth/checking-auth';
import LoginComponent from './components/login/login';
import OnBoardStep1Component from './components/onboarding/step-1/step-1';
import OnBoardComponent from './components/onboarding/onboard';
import { ResumeTemplateModel } from './model/resume-builder/resume-template.model';
import { ProfileModel } from './model/profile.model';
import { ProvideContext } from './context/provider.context';
import ViewResumeComponent from './components/resume-builder/view-resume/view-resume';
import NotFoundComponent from './components/not-found/not-found';
import ViewProfileComponent from './components/profile/view-profile/view-profile';
import MyResumeComponent from './components/profile/my-resume/my-resume';
import LogOutComponent from './components/login/logout';
import ChooseTemplate from './components/resume-builder/choose-template/choose-template';
import navigateConfig from './navigate.config';
import BuilderComponent from './components/resume-builder/builder/builder';
import JobsComponent from './components/jobs/jobs';
import JobApplyComponent from './components/jobs/apply/job-apply';
import SettingComponent from './components/setting/setting';
import TRANSLATE_EN from "./assets/json/en.json";
import TRANSLATE_TH from "./assets/json/th.json";
import { TranslateModel } from './model/translate.model';
import languageService from './services/language.service';
import InterviewComponent from './components/interview-ai/interview';
import InterviewAIHistory from './components/interview-ai/history/interview-history';
import CheckingPath from './components/checking-auth/checking-path/checking-path';
import PersonalityComponent from './components/profile/personality/personality';
import PersonalityTestIframe from './components/personality-test/personality-test';
import PersonalityResultComponent from './components/personality-test/result/personality-test-result';
import CareerCraftComponent from './components/career-craft/career-craft';
import CoursesRecommendComponent from './components/career-craft/courses/courses-recommend';
import CareerDetailComponent from './components/career-craft/career-detail/career-detail';
import WidthContext from './context/width.context';
import GuestComponent from './components/guest/guest';
import DropResumeComponent from './components/guest/drop-resume/drop-resume';
import ResumeCheckerComponent from './components/resume-checker/resume-checker';
import TalentSauceDropResumeComponent from './components/guest/drop-resume/talent-sauce/talent-sauce-drop-resume';
import RamaService from './services/sso/rama.service';
import GuestRequireLoginComponent from './components/guest/require-login/require-login';
import GuestProfileComponent from './components/guest/require-login/profile/guest-profile';
import GuestMyResumeComponent from './components/guest/require-login/resume/guest-my-resume';
import GuestInterviewAIComponent from './components/guest/require-login/interview-ai/guest-interview-ai';
export const tabletBreakpoint = 768; // width in pixels
export const desktopBreakpoint = 1024; // width in pixels
interface RouteChangeListenerProps {
  children: ReactNode;
}
const RouteChangeListener: React.FC<RouteChangeListenerProps> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    // Logic for route change
    console.log('Route changed to: ', location.pathname);
    
    // Add any other logic you need on route change
  }, [location]);

  return <>{children}</>;
};
const App = () => {
  const [templateModel, setTemplateModel] = useState<ResumeTemplateModel>();
  const [currentTemplate, setCurrentTemplate] = useState(0);
  const [profileModel, setProfileModel] = useState<ProfileModel>();
  const [menuKey, setMenuKey] = useState(0);
  const [languageGlobal, setLanguageGlobal] = useState("en");
  const [translateGlobal, setTranslateGlobal] = useState<TranslateModel>(languageService.getLanguage() == "en" ? TRANSLATE_EN : TRANSLATE_TH);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#2E3F88');
    document.documentElement.style.setProperty('--soft-primary-color', ' #EFF2FF');
    document.documentElement.style.setProperty('--text-blue-color', '#2E3F88');
    document.documentElement.style.setProperty('--secondary-color', '#EF4123');
    document.documentElement.style.setProperty('--red-color', '#EF4123');
    document.documentElement.style.setProperty('--button-primary-color', '#2E3F88');
    document.documentElement.style.setProperty('--link-color', '#0b4ca8');
    document.documentElement.style.setProperty('--button-share-color', '#0f784c');
    document.documentElement.style.setProperty('--text-color', '#2E3F88');
    document.documentElement.style.setProperty('--text-soft-color', '#8093B2');
    document.documentElement.style.setProperty('--text-gray-color', '#6e6e6e');
    document.documentElement.style.setProperty('--text-white-color', '#ffffff');
    document.documentElement.style.setProperty('--disabled-color', '#797979');
    document.documentElement.style.setProperty('--header-color', '#333333');
    document.documentElement.style.setProperty('--soft-red', '#ee7762');
    document.documentElement.style.setProperty('--divider-color', '#C3C9E4');
    document.documentElement.style.setProperty('--bg-color', '#F1F6FE');
    document.documentElement.style.setProperty('--border-line', '#3b3b3b');
    document.documentElement.style.setProperty('--border-input', '#c6c6c6');
    document.documentElement.style.setProperty('--bg-menu-gradient', 'linear-gradient(143deg, #102644 1.39%, #342C7F 69.73%)');
    document.documentElement.style.setProperty('--shadow-box', '0px 0px 18px -5px #2E3F88');
    document.documentElement.style.setProperty('--gradient-primary', 'linear-gradient(85deg, #AF34F4 0%, #7321E4 39.97%, #4008DC 100%)');
    
  }, [])

  return (
    <div className="App">

      <BrowserRouter>
        <WidthContext.Provider value={width}>
          <ProvideContext.Provider
            value={{
              templateModel, setTemplateModel,
              currentTemplate, setCurrentTemplate,
              profileModel, setProfileModel,
              menuKey, setMenuKey,
              languageGlobal, setLanguageGlobal,
              translateGlobal, setTranslateGlobal
            }}
          >
            <RouteChangeListener>
              <Routes>

                <Route path="/" element={<LandingComponent />} />
                <Route path={navigateConfig.login.main} element={<LoginComponent />} />
                <Route path={navigateConfig.onboard.main} element={<OnBoardComponent />} />
                <Route path={navigateConfig.checking.main} element={<CheckingAuth />} />
                <Route path={navigateConfig.profile.main} element={<ProfileComponent />} />
                <Route path={navigateConfig.resume.main} element={<MyResumeComponent />} />
                <Route path={navigateConfig.not_found.main} element={<NotFoundComponent />} />
                <Route path={navigateConfig.resume.choose_template} element={<ChooseTemplate />} />
                <Route path={navigateConfig.resume.builder + ":id"} element={<BuilderComponent />} />
                <Route path={navigateConfig.profile.view + ":id"} element={<ViewProfileComponent />} />
                <Route path={navigateConfig.resume.view + ":id"} element={<ViewResumeComponent />} />
                <Route path={navigateConfig.resume.view_only + ":id"} element={<ViewResumeComponent />} />
                <Route path={navigateConfig.jobs.interview} element={<InterviewComponent />} />
                <Route path={navigateConfig.jobs.interview_history} element={<InterviewAIHistory />} />
                <Route path={navigateConfig.personality.main} element={<PersonalityTestIframe />} />
                <Route path={navigateConfig.personality.result} element={<PersonalityResultComponent />} />
                <Route path={navigateConfig.jobs.main} element={<JobsComponent />} />
                {/* <Route path={navigateConfig.career_craft.main} element={<CareerCraftComponent />} />
              <Route path={navigateConfig.career_craft.courses} element={<CoursesRecommendComponent />} />
              <Route path={navigateConfig.career_craft.detail + ":id"} element={<CareerDetailComponent />} /> */}
                <Route path={navigateConfig.jobs.apply + ":id"} element={<JobApplyComponent />} />
                <Route path={navigateConfig.resume.checker} element={<ResumeCheckerComponent />} />
                <Route path={navigateConfig.setting.main} element={<SettingComponent />} />
                <Route path={navigateConfig.login.logout} element={<LogOutComponent />} />
                {/* GUEST */}
                <Route path={navigateConfig.guest.main} element={<GuestComponent />} />
                <Route path={navigateConfig.guest.profile} element={<GuestProfileComponent />} />
                <Route path={navigateConfig.guest.my_resume} element={<GuestMyResumeComponent />} />
                <Route path={navigateConfig.guest.interview_ai} element={<GuestInterviewAIComponent />} />
                <Route path={navigateConfig.guest.drop_resume_ts} element={<TalentSauceDropResumeComponent />} />
                <Route path={navigateConfig.guest.drop_resume} element={<DropResumeComponent />} />
                <Route path={navigateConfig.guest.drop_resume} element={<DropResumeComponent />} />
                {/* 👇️ only match this when no other routes match */}
                <Route path="*" element={<NotFoundComponent />} />
              </Routes>
            </RouteChangeListener>
          </ProvideContext.Provider>
        </WidthContext.Provider>
      </BrowserRouter>
      <CookieConsent
        enableDeclineButton
        declineButtonText="ยอมรับเท่าที่จำเป็น"
        location="bottom"
        buttonText="ยอมรับทั้งหมด"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        declineButtonStyle={{ fontSize: "13px", borderRadius: "12px" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "12px" }}
        expires={150}
      >
        <Container>


          เว็บไซต์ของเราใช้คุกกี้ เพื่อมอบประสบการณ์ที่ดียิ่งขึ้นแก่คุณ และเพื่อเสนอเนื้อหา
          บริการ และโฆษณาที่คุณอาจสนใจ คลิกดูข้อมูลเพิ่มเติมได้ที่  <a href="https://scoutout.co/terms-th/">เงื่อนไขการใช้บริการ</a>
          และ <a href="https://scoutout.net/privacy-th/">นโยบายความเป็นส่วนตัว</a>

        </Container>

      </CookieConsent>

    </div>
  );
}

export default App;
