import { QueryParamsModel } from "../model/query-params.model";
import storage from "./sso/storage";


class QueryParamService {
   

    setQueryParams(queryObject){
      localStorage.setItem(storage.query_path, JSON.stringify(queryObject));
    }
    getQueryParams() {
      return JSON.parse(localStorage.getItem(storage.query_path)!) as QueryParamsModel;
    }

    clearPath(){
        localStorage.removeItem(storage.query_path);
    }
  }
  
  export default new QueryParamService();