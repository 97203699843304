import React, { useState } from 'react';
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto'; // Chart.js v3 requires this import
import { ResultPersonalityModel } from '../../../model/personality/result-personality.model';
import templateConfig from '../../../template.config';

const RadarChartComponent = ({resultPersonality} : any) => {

    const [personality, setPersonality] = useState<ResultPersonalityModel>(resultPersonality);
  // Data for the radar chart
  const data = {
    labels: ['O', 'C', 'E', 'A', 'N'], // The axes labels
    datasets: [{
      label: personality.results.mbti_results.type,
      data: [personality.results.text_results[0].big_5_user_score,
      personality.results.text_results[1].big_5_user_score,
      personality.results.text_results[2].big_5_user_score,
      personality.results.text_results[3].big_5_user_score,
      personality.results.text_results[4].big_5_user_score], // Example data
      fill: true,
      backgroundColor: templateConfig.primaryColor,
      borderColor: templateConfig.primaryColor,
      pointBackgroundColor: templateConfig.primaryColor,
      pointBorderColor: templateConfig.primaryColor,
      pointHoverBackgroundColor: templateConfig.primaryColor,
      pointHoverBorderColor: templateConfig.primaryColor
    }]
  };

  // Options for the radar chart
  const options = {
    maintainAspectRatio: false,
    scales: {
        r: {
          angleLines: {
            display: true
          },
          pointLabels: {
            display: true, // Hides the labels around the chart
            font: {
                size: 14, // Change the font size as needed
                family: templateConfig.fontFamily.medium, // Optionally, set the font family
                // style: 'normal', // Optionally, set the font style
                // weight: 'bold', // Optionally, set the font weight
              },
              color: templateConfig.primaryColor,
          },
          ticks: {
            display: false // Hides the scale labels (the numbers)
          },
          grid: {
            circular: true // Makes the scale lines circular
          }
        }
      },
      plugins: {
        legend: {
          display: false // Hides the legend
        }
      },
      elements: {
        line: {
          borderWidth:2,
        }
      }
  };

  return (
    <div className='personality-graph-control'>
      <Radar data={data}  options={options} />
    </div>
  );
};

export default RadarChartComponent;
