import "./template-1.scss";
import { useEffect, useState } from "react";
import { InputResumeBuilderModel } from "../../../../model/resume-builder/input-resume-builder.model";
import { getOneResumeModel } from "../../../../model/resume-builder/get-one-resume.model";


const Template_1_Component = ({ containerRef, templ, resultResume }: any) => {
    const [result, setResult] = useState<getOneResumeModel>(resultResume);
    const [inputTemplateModel, setInputTemplateModel] = useState<InputResumeBuilderModel>(templ);

    return (

        <>
            {
                inputTemplateModel &&
                <div className="template-1-control">
                    <div className="template-1-form-control" ref={containerRef}>{
                        <div className="template-1-profile-control" style={{
                            backgroundColor:
                                inputTemplateModel.color ?
                                    inputTemplateModel.color.value : "#000"
                        }}>
                            <div className="template-1-profile-text-control">
                                <span className="template-1-name" dangerouslySetInnerHTML={{
                                    __html:
                                        inputTemplateModel.name ?
                                            inputTemplateModel.name.value : ""
                                }}></span>
                                <span className="template-1-position" dangerouslySetInnerHTML={{
                                    __html:
                                        inputTemplateModel.position ?
                                            inputTemplateModel.position.value : ""
                                }}></span>
                                <span className="template-1-cover-letter" dangerouslySetInnerHTML=

                                    {{
                                        __html:
                                            inputTemplateModel.cover_letter ?
                                                inputTemplateModel.cover_letter.value : ""
                                    }}></span>
                            </div>
                            <div className="template-1-cover-contact-control">
                                <span className="template-1-cover-contact" dangerouslySetInnerHTML=
                                    {{
                                        __html:
                                            inputTemplateModel.contact ?
                                                inputTemplateModel.contact.value : ""
                                    }}></span>
                            </div>
                        </div>
                    }

                        <div className="template-1-desc-control">
                            <div className="template-1-desc-left-control">
                                {
                                    inputTemplateModel.expertise && inputTemplateModel.expertise.value &&
                                    <div className="template-1-desc-left-section-control">
                                        <span className="template-1-desc-title">Skills</span>
                                        <div className="template-1-desc-divider"></div>
                                        <span className="template-1-desc-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.expertise ?
                                                        inputTemplateModel.expertise.value : ""
                                            }}></span>
                                    </div>
                                }
                                {
                                    inputTemplateModel.language && inputTemplateModel.language.value &&
                                    <div className="template-1-desc-left-section-control">
                                        <span className="template-1-desc-title">Language</span>
                                        <div className="template-1-desc-divider"></div>
                                        <span className="template-1-desc-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.language ?
                                                        inputTemplateModel.language.value : ""
                                            }}></span>
                                    </div>
                                }
                                {
                                    inputTemplateModel.interest && inputTemplateModel.interest.value &&
                                    <div className="template-1-desc-left-section-control">
                                        <span className="template-1-desc-title">Interest</span>
                                        <div className="template-1-desc-divider"></div>
                                        <span className="template-1-desc-content" dangerouslySetInnerHTML=
                                            {{
                                                __html:
                                                    inputTemplateModel.interest ?
                                                        inputTemplateModel.interest.value : ""
                                            }}></span>
                                    </div>
                                }
                            </div>
                            <div className="template-1-desc-right-control">
                                <div className="template-1-desc-right-section-control">
                                    <span className="template-1-desc-title">Experiences</span>
                                    <span className="template-1-desc-content" dangerouslySetInnerHTML=
                                        {{
                                            __html:
                                                inputTemplateModel.experience ?
                                                    inputTemplateModel.experience.value : ""
                                        }}></span>
                                </div>
                                <div className="template-1-desc-right-section-control">
                                    <span className="template-1-desc-title">Education</span>
                                    <span className="template-1-desc-content" dangerouslySetInnerHTML={{
                                        __html:
                                            inputTemplateModel.education ?
                                                inputTemplateModel.education.value : ""
                                    }}></span>
                                </div>
                            </div>

                        </div>
                        <div className="template-1-footer-control" style={{ backgroundColor: templ.color }}></div>
                    </div>
                </div>
            }
        </>
    )
}

export default Template_1_Component;