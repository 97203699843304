
import axios from "axios"
import config from "../../config"
import apiPath from "../../api-path"
import tokenService from "../sso/token.service"
import RamaService from "../sso/rama.service"


export default function InterviewAIService() {

    return {
       
        async applyJobs(applicationForm, company_name, job_uuid) {
            return axios.post(config.leela.host_url + apiPath.leela.public + apiPath.leela.company_jobs +company_name + "/jobs/" + job_uuid + "/apply",
            applicationForm, {
                headers: {
                    "Content-Type": 'application/json',
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                }
            }
            ).then(res => res.data).catch(err => Promise.reject(err))
        },
        async getJobDescription() {
            return axios.get(config.chat_gpt.base_url+ apiPath.gpt_interview + apiPath.path.jd_suggest, {
                headers: {
                    'content-type': 'application/json',
                    'x-api-key': config.chat_gpt.x_api_key
                }
            }
            ).then(res => res.data).catch(err => Promise.reject(err))
        },
        async getHistory() {
            return axios.get(config.chat_gpt.base_url+ apiPath.resume_interview + "log", {
                headers: {
                    'content-type': 'application/json',
                    'scoutout-authorization': tokenService.getLocalAccessToken(),
                }
            }
            ).then(res => res.data).catch(err => Promise.reject(err))
        },
       
    }
}