import TEMPLATE_0_IMG from "../../../assets/images/resume-builder/template-0.png";
import TEMPLATE_1_IMG from "../../../assets/images/resume-builder/template-1.png";
import TEMPLATE_2_IMG from "../../../assets/images/resume-builder/template-2.png";
import { Button, Container, Form } from 'react-bootstrap';
import "./choose-template.scss";
import ARROW_RIGHT from "../../../assets/images/resume-builder/arrow-right.png";
import ARROW_LEFT from "../../../assets/images/resume-builder/arrow-left.png";
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TEMPLATE_0 from "../../../assets/json/resume-template/template-0.json";
import TEMPLATE_1 from "../../../assets/json/resume-template/template-1.json";
import TEMPLATE_2 from "../../../assets/json/resume-template/template-2.json";
import UtilityService from '../../../utility/utility.service';
import inputService from "../../../services/resume-builder/input.service";
import { ProvideContext } from "../../../context/provider.context";
import { Carousel } from '@trendyol-js/react-carousel';
import MenuComponent from "../../menu/menu";
import navigateConfig from "../../../navigate.config";
import { TranslateModel } from "../../../model/translate.model";
import ResumeBuilderInstantService from "../../../services/resume-builder/resume-builder-instance.service";
import { useProfile } from "../../../context/profile.context";
import { SlotResumeModel } from "../../../model/resume-builder/slot-resume.model";
import ErrorModal from "../../../modal/error/error";
const ChooseTemplate = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const location = useLocation();
    const [currentKey, setCurrentKey] = useState(0);
    const { currentTemplate, setCurrentTemplate }: any = useContext(ProvideContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [resumeUUID, setResumeUUID] = useState("");
    const [name, setName] = useState("");
    const [isResumeFull, setIsResumeFull] = useState(false);
    const param = useParams();
    const [isSubmit, setIsSubmit] = useState(false);
    const [profile, error] = useProfile();


    useEffect(() => {

    }, [])

    useEffect(() => {
        if (profile) {
            if (param.id) {
                checkSlot();
                setResumeUUID(param.id);
            }
        }
    }, [profile])
    const onLeftClick = () => {
        setCurrentKey(currentKey - 1)
    }

    const onRightClick = () => {
        setCurrentKey(currentKey + 1)
    }
    const checkSlot = () => {

        ResumeBuilderInstantService.slotResume().then((res) => {
            if (res.data) {
                const result = res.data as SlotResumeModel;

                setIsResumeFull(false);
            }
        }).catch((errors) => {
            console.log(errors);
            if (errors.response.data.error == "Cannot create a new resume") {
                setIsResumeFull(true);
            }
        })
    }

    const clickChoose = () => {
        setIsSubmit(true);
        if (name && name.length >= 4) {
            let is_profile_img = false;
            let profile_img = "";
            if (currentKey == 0) {
                UtilityService().clickSendEvent("resume_builder_choose_template_0", "click")
                setCurrentTemplate(0);
                inputService.setTemplate(0);
                inputService.setInput(TEMPLATE_0)
                is_profile_img = true;
            } else if (currentKey == 1) {
                UtilityService().clickSendEvent("resume_builder_choose_template_1", "click")
                setCurrentTemplate(1);
                inputService.setTemplate(1);
                inputService.setInput(TEMPLATE_1)
                is_profile_img = false;
            } else if (currentKey == 2) {
                UtilityService().clickSendEvent("resume_builder_choose_template_2", "click")
                setCurrentTemplate(2);
                inputService.setTemplate(2);
                inputService.setInput(TEMPLATE_2)
                is_profile_img = true;
            }
            if (is_profile_img && profile) {
                if (profile.profileImageUrl) {
                    profile_img = profile.profileImageUrl;
                }

            }
            ResumeBuilderInstantService.slotResume().then((res) => {
                if (res.data) {
                    const result = res.data as SlotResumeModel;
                    ResumeBuilderInstantService.addResumeWithOutJson(name, currentKey, is_profile_img, profile_img).then((res) => {
                        if (res.data) {
                            const resumeID = res.data.resume_uuid;
                            ResumeBuilderInstantService.updateResumeTemplate(resumeID, currentKey, is_profile_img).then((res) => {
                                if (res.status == 200) {
                                    UtilityService().clickSendEvent("resume_builder_choose_template_success", "submit");
                                    navigate(navigateConfig.resume.builder + resumeID)
                                }
                            })

                        } else if (res.status == 400) {
                        }

                    }).catch(error => {
                    })

                }
            }).catch((errors) => {
                console.log(errors);
                if (errors.response.data.error == "Cannot create a new resume") {
                    setIsResumeFull(true);
                }
            })

        }



    }

    const onTextChange = (value) => {
        setName(value);
    }

    return (

        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="choose-template">
                <Container className='d-flex flex-column'>


                    <h1 className='choose-template-text'>{translate.resume_builder.choose_template}</h1>
                    <div className="choose-template-name-control">
                        <span className="choose-template-name-title">{translate.resume_builder.resume_name}</span>
                        <Form.Control as="input" type="text" onChange={(e) => onTextChange(e.target.value)}>
                        </Form.Control>
                        {
                            isSubmit && (!name || name.length < 4) &&
                            <span className="choose-template-name-error">{translate.error.input_name}</span>
                        }

                    </div>
                    <div className="choose-template-control">

                        <Carousel show={1} slide={1} swiping={false} infinite={false}
                            onLeftArrowClick={onLeftClick} onRightArrowClick={onRightClick}
                            rightArrow={<div className='d-flex h-100'>
                                <img src={ARROW_RIGHT} className='choose-template-arrow' />
                            </div>} leftArrow={<div className='d-flex h-100'>
                                <img src={ARROW_LEFT} className='choose-template-arrow' />
                            </div>}>


                            <div className='choose-template-slide-control'
                            >
                                <img src={TEMPLATE_0_IMG} className='choose-template-slide'></img>
                            </div>
                            <div className='choose-template-slide-control'>
                                <img src={TEMPLATE_1_IMG} className='choose-template-slide'></img>
                            </div>
                            {/* <div className='choose-template-slide-control'>
                                <img src={TEMPLATE_2_IMG} className='choose-template-slide'></img>
                            </div> */}

                        </Carousel>
                    </div>


                    <Button className='choose-template-button' onClick={clickChoose}>{translate.other.next}</Button>
                </Container>

            </div>
            {
                isResumeFull &&
                <ErrorModal open={isResumeFull} onclose={() => setIsResumeFull(false)} message={translate.error.resume_full}></ErrorModal>
            }
        </div>
    )
}

export default ChooseTemplate;