import { useContext, useEffect, useState } from "react";
import MenuComponent from "../menu/menu";
import "./setting.scss";
import { TranslateModel } from "../../model/translate.model";
import TRANSLATE_EN from "../../assets/json/en.json";
import TRANSLATE_TH from "../../assets/json/th.json";
import { useLanguage } from "../../provider/language.provider";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Alert, Divider, FormControlLabel, Snackbar, Switch } from "@mui/material";
import { Button, FormSelect } from "react-bootstrap";
import { SettingModel } from "../../model/setting/setting.model";
import RamaService from "../../services/sso/rama.service";
import { ProfileModel } from "../../model/profile.model";
import { ProvideContext } from "../../context/provider.context";
import languageService from "../../services/language.service";
import { useNavigate } from "react-router-dom";
import { ProfileExtModel } from "../../model/profile/profile-ext.model";
import ProfileService from "../../services/profile/profile.service";
import ProfileInstantService from "../../services/profile/profile-instance.service";
import { useProfile } from "../../context/profile.context";
import BG_CIRCLE_MAIN from "../../assets/images/circle-bg-main.svg";
import UtilityService from "../../utility/utility.service";

const SettingComponent = () => {
    const navigate = useNavigate();
    const { language, setLanguage } = useLanguage();
    const [currentTab, setCurrentTab] = useState("one");
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [profileModExt, setProfileModExt] = useState<ProfileExtModel>();
    const [isModalSnack, setIsModalSnack] = useState(false);
    const { translateGlobal, setTranslateGlobal, languageGlobal, setLanguageGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profile, error] = useProfile();
    useEffect(() => {
        if (languageService.getLanguage() == 'en') {
            setLanguageGlobal(language)
            setTranslateGlobal(TRANSLATE_EN)
        } else {
            setLanguageGlobal(language)
            setTranslateGlobal(TRANSLATE_TH)
        }
       
    }, [])

    useEffect(() => {
        if(profile){
            getSetting();
        }
    },[profile])

    useEffect(() => {
        if (profileModExt) {

        } else {
            getProfileExt();
        }
    }, [profileModExt])

    useEffect(() => {
        setTranslate(translateGlobal)
    }, [translateGlobal])

    const handleCloseSnack = () => {
        setIsModalSnack(false);
    }

    const getProfileExt = () => {
        ProfileInstantService.getProfile().then((res) => {
            if (res.data) {
                const resultProfile = res.data as ProfileExtModel;
                if (!resultProfile.profile_public_settings) {
                    resultProfile.profile_public_settings = {
                        about: true,
                        activities: true,
                        certificates: true,
                        contact: true,
                        educations: true,
                        experiences: true,
                        interest: true,
                        skills: true,
                        uploadFile: true,
                        personality: true,
                        resume: true
                    }
                }
                setProfileModExt(resultProfile);
            }
        })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    }
    const handleSwithChange = (newValue, action) => {
        UtilityService().clickSendEvent("settings_switch_"+action, "click");
        if (profileMod) {
            let profileEdit = { ...profileMod };
            if (action == "jobRecommendation") {
                profileEdit.jobRecommendation = newValue;
                setProfileMod(profileMod => profileEdit);
            } else if (action == "resumePublished") {
                profileEdit.resumePublished = newValue;
                setProfileMod(profileMod => profileEdit);
            }

        }

        if (profileModExt) {
            let profileExtEdit = { ...profileModExt }
            if (action == "profileExperience") {
                profileExtEdit.profile_public_settings.experiences = newValue;
            } else if (action == "profileEducation") {
                profileExtEdit.profile_public_settings.educations = newValue;
            } else if (action == "profileActivity") {
                profileExtEdit.profile_public_settings.activities = newValue;
            } else if (action == "profileCertificate") {
                profileExtEdit.profile_public_settings.certificates = newValue;
            }
            else if (action == "profileAbout") {
                profileExtEdit.profile_public_settings.about = newValue;
            } else if (action == "profileContact") {
                profileExtEdit.profile_public_settings.contact = newValue;
            } else if (action == "profileJobOpportunity") {
                profileExtEdit.profile_public_settings.interest = newValue;
            } else if (action == "profileSkill") {
                profileExtEdit.profile_public_settings.skills = newValue;
            } else if (action == "profileFile") {
                profileExtEdit.profile_public_settings.uploadFile = newValue;
            } else if (action == "profilePersonality") {
                profileExtEdit.profile_public_settings.personality = newValue;
            } else if (action == "profileResume") {
                profileExtEdit.profile_public_settings.resume = newValue;
            }
            setProfileModExt(profileMod => profileExtEdit);
        }
    }

    const UpdateSetting = () => {
        if (profileMod) {
            const settingMod: SettingModel = {
                jobRecommendation: profileMod.jobRecommendation,
                expectedMaxSalary: profileMod.expectedMaxSalary,
                expectedMinSalary: profileMod.expectedMinSalary,
                preferredJobLevel: profileMod.preferredJobLevel,
                preferredJobRole: profileMod.preferredJobRole,
                resumePublished: profileMod.resumePublished
            }
            RamaService.updateSetting(settingMod).then((res) => {
                if (res && res.status) {
                    if (res.status == 200) {
                        UtilityService().clickSendEvent("update_setting_complete", "submit");
                        setIsModalSnack(true);
                    }
                }

            })
        }

        if (profileModExt) {
            ProfileInstantService.updateProfilePublicSettings(profileModExt).then((res) => {

                if (res.data) {
                    setIsModalSnack(true);
                }
            })
        }

    }

    const getSetting = () => {
        setProfileMod(profile)
    }

    const onlanguagechange = (value) => {
        setLanguage(value)
    }

    const saveChangeLanguage = () => {

        languageService.setLang(language);
        navigate(0);
        // window.open("/setting", "_self");
    }
    return (
        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="setting-main">
            <img src={BG_CIRCLE_MAIN} className="bg-circle-main"></img>
                <div className="setting-header-control">

                    <span className="setting-header">{translate.setting.title}</span>

                </div>
                <div className="setting-main-control">
                    <Tabs
                        value={currentTab}
                        onChange={handleChange}
                        textColor="primary"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#2E3F88"
                            }
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        className="setting-tab-custom"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="one" label={translate.setting.notification} />
                        <Tab value="two" label={translate.setting.privacy} />
                        <Tab value="three" label={translate.setting.language} />
                        <Tab value="four" label={translate.setting.public_profile} />
                    </Tabs>
                    {
                        profileMod &&
                        <div className="setting-content-control">
                            {
                                currentTab == "one" &&

                                <div className="setting-content-inside-control">
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileMod.jobRecommendation}
                                            control={<Switch color="primary"
                                                defaultChecked={profileMod.jobRecommendation}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'jobRecommendation')} />}
                                            label={profileMod.jobRecommendation ? "Allow" : "Not allow"}
                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">Allow ScoutOut to recommend jobs to you via email</span>
                                            <span className="setting-content-inside-item-checkbox-desc">ยินยอมให้ ScoutOut ส่งอีเมลแนะนำงานที่เหมาะสำหรับคุณ</span>
                                        </div>
                                    </div>
                                    <Button className="setting-content-button-save" onClick={UpdateSetting}>Save</Button>
                                </div>

                            }
                            {
                                currentTab == "two" &&

                                <div className="setting-content-inside-control">
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileMod.resumePublished}
                                            control={<Switch color="primary"
                                                defaultChecked={profileMod.resumePublished}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'resumePublished')} />}
                                            label={profileMod.resumePublished ? "Allow" : "Not allow"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">Allow employers and third parties to access your profile</span>
                                            <span className="setting-content-inside-item-checkbox-desc">อนุญาตให้ผู้ว่าจ้างและบุคคลภายนอกเข้าถึงประวัติ/เรซูเม่ของคุณ</span>
                                        </div>
                                    </div>
                                    <Button className="setting-content-button-save" onClick={UpdateSetting}>Save</Button>
                                </div>

                            }
                            {
                                currentTab == "three" &&
                                <div className="setting-content-inside-control">
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormSelect
                                            className="setting-content-select"
                                            defaultValue={languageService.getLanguage() == "en" ? "en" : "th"}
                                            onChange={(e) => onlanguagechange(e.target.value)}
                                        >

                                            <option value='en'>EN</option>
                                            <option value='th'>ไทย</option>
                                        </FormSelect>
                                        {/* <LanguageSwitcher languages={['en', 'th']} /> */}
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">Change language not effect to some response from input of user.</span>
                                            <span className="setting-content-inside-item-checkbox-desc">การเปลี่ยนภาษาจะไม่มีผลกับผลลัพธ์การกรอกข้อมูลของ User</span>
                                        </div>
                                    </div>
                                    <Button className="setting-content-button-save" onClick={saveChangeLanguage}>{translate.other.save}</Button>
                                </div>

                            }
                            {
                                (currentTab == "four" && profileModExt) &&
                                <div className="setting-content-inside-control">
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.resume}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.resume}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileResume')} />}
                                            label={profileModExt.profile_public_settings.resume ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.my_resume_url}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.experiences}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.experiences}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileExperience')} />}
                                            label={profileModExt.profile_public_settings.experiences ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.experience}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.educations}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.educations}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileEducation')} />}
                                            label={profileModExt.profile_public_settings.educations ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.education}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.activities}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.activities}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileActivity')} />}
                                            label={profileModExt.profile_public_settings.activities ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.activiy_interest}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.certificates}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.certificates}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileCertificate')} />}
                                            label={profileModExt.profile_public_settings.certificates ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.certificates}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.about}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.about}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileAbout')} />}
                                            label={profileModExt.profile_public_settings.about ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.about}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.personality}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.personality}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profilePersonality')} />}
                                            label={profileModExt.profile_public_settings.personality ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.my_personality}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>

                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.contact}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.contact}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileContact')} />}
                                            label={profileModExt.profile_public_settings.contact ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.contact}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.interest}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.interest}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileJobOpportunity')} />}
                                            label={profileModExt.profile_public_settings.interest ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.job_opportunity}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.skills}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.skills}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileSkill')} />}
                                            label={profileModExt.profile_public_settings.skills ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.profile.skill}" Section</span>
                                        </div>
                                    </div>
                                    <Divider className="setting-divider"></Divider>
                                    <div className="setting-content-inside-item-checkbox">


                                        <FormControlLabel
                                            value={profileModExt.profile_public_settings.uploadFile}
                                            control={<Switch color="primary"
                                                defaultChecked={profileModExt.profile_public_settings.uploadFile}
                                                onChange={(e) => handleSwithChange(e.target.checked, 'profileFile')} />}
                                            label={profileModExt.profile_public_settings.uploadFile ? "Public" : "Private"}

                                            labelPlacement="bottom"
                                        />
                                        <div className="setting-content-inside-item-checkbox-title-control">
                                            <span className="setting-content-inside-item-checkbox-title">"{translate.application_form.uploadFiles}" Section</span>
                                        </div>
                                    </div>
                                    <Button className="setting-content-button-save" onClick={UpdateSetting}>{translate.other.save}</Button>
                                </div>


                            }


                        </div>
                    }

                </div>
            </div>
            <Snackbar open={isModalSnack} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleCloseSnack} >
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    Update successfull
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SettingComponent;