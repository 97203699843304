import { useContext, useEffect, useState } from "react";
import "../education/education.scss";
import { Activities, Experience, ProfileModel } from "../../../../model/profile.model";
import SelectItemModal from "../../../../model/select-item.model";
import { Button } from "react-bootstrap";
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from "@mui/material";
import EXPERIENCE_LEVEL_JSON from "../../../../assets/json/experience-type.json";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RamaService from "../../../../services/sso/rama.service";
import dayjs from 'dayjs';
import CLOSE_IMG from "../../../../assets/images/close.png";
import { ProvideContext } from "../../../../context/provider.context";
import { TranslateModel } from "../../../../model/translate.model";
import ProfileInstantService from "../../../../services/profile/profile-instance.service";
import UtilityService from "../../../../utility/utility.service";
const ActivitiesComponent = ({ profile, setProfile, isNew, setCloseModal, isApplicationForm }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [experienceLevel, setExperienceLevel] = useState<SelectItemModal>();
    const [currentActivites, setCurrentActivities] = useState<Activities>();
    const [editIndex, setEditIndex] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [yearExperience, setYearExperience] = useState<SelectItemModal[]>();
    const [isAddingNew, setAddingNew] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    useEffect(() => {

        if (profileMod) {
            setCurrentActivities(profileMod.activities[0])
            initExperienceYear();
            if (isNew) {
                addEmptyExperience();
            } else {
                if(profileMod.activities[0]){
                    if (profileMod.activities[0].endDate) {
                        const converDayJS = dayjs(profileMod.activities[0].endDate);
                        setEndDate(converDayJS);
                    }
    
                    if (profileMod.activities[0].startDate) {
                        const converDayJS = dayjs(profileMod.activities[0].startDate);
                        setStartDate(converDayJS);
                    }
                }
               
            }
        }

    }, [])


    const initExperienceYear = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const yearDataArray: SelectItemModal[] = [];

        for (let year = currentYear; year >= currentYear - 40; year--) {
            const yearData: SelectItemModal = {
                name: year + "",
                value: year + "",
            };
            yearDataArray.push(yearData);
        }
        setYearExperience(yearDataArray);
    }

    const addEmptyExperience = () => {
        let profileEdit = { ...profileMod };
        if (profileEdit.educations) {
            const addActivity: Activities = {
                role: "",
                startDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
                organizationName: "",
                summary: "",
                isOnGoing: false
            }
            profileEdit.activities.unshift(addActivity);
            setProfileMod(profileEdit);
            setEditIndex(0);
            setIsSaving(false);
            setAddingNew(true);
            setIsEditing(true);
            setCurrentActivities(addActivity)
        }
    }
    const textChange = (text, key, index) => {
        let currentEditChange = { ...currentActivites } as Activities;
        if (currentEditChange && currentActivites) {

            if (key == 'role') {
                currentEditChange.role = text
            } else if (key == 'organizationName') {
                currentEditChange.organizationName = text
            } else if (key == 'summary') {
                currentEditChange.summary = text
            }

            if (currentActivites) {
                setCurrentActivities(currentActivites => ({
                    ...currentEditChange
                }));
            }

        }

    }

    const clickEditExperience = (item, index) => {
        setEditIndex(index);
        setIsEditing(true);
        const currentActivites = item as Activities;
        setCurrentActivities(currentActivites)
    }

    const deleteItem = (index) => {
        let profileEdit = { ...profileMod };
        if (isApplicationForm) {
            profileEdit.activities.splice(index, 1);
            setIsEditing(false);
            setProfileMod(profileEdit);
            setProfile(profileEdit);
        } else if (profileEdit.activities[index] && currentActivites) {
            profileEdit.activities.splice(index, 1);
            RamaService.updateProfile(profileEdit).then((res) => {
                ProfileInstantService.updateProfilePublic(profileEdit);
                setProfileMod(profileEdit);
                setIsEditing(false);
                setProfile(profileEdit);
            })
        }
    }

    const cancelAddEducation = (index) => {
        let profileEdit = { ...profileMod };
        if (profileEdit.activities) {
            profileEdit.activities.splice(index, 1);
            setProfileMod(profileEdit)
            setEditIndex(9999)
            setIsEditing(false);
        }
    }

    const saveValue = (index) => {
        let profileEdit = { ...profileMod };
        setIsSaving(true);

        if (currentActivites && currentActivites.isOnGoing) {
            if (currentActivites
                && currentActivites.role
                && currentActivites.organizationName
                && currentActivites.summary
                && currentActivites.startDate
            ) {

                if (isApplicationForm) {
                    profileEdit.activities[index] = currentActivites
                    setProfileMod(profileEdit);
                    setIsEditing(false);
                    setAddingNew(false);
                    setProfile(profileEdit);
                } else {
                    profileEdit.activities[index] = currentActivites
                    RamaService.updateProfile(profileEdit).then((res) => {

                        if(res.data){
                            UtilityService().clickSendEvent("save_about_me", "submit");
                            ProfileInstantService.updateProfilePublic(profileEdit);
                            setProfileMod(profileEdit);
                            setIsEditing(false);
                            setAddingNew(false);
                            setProfile(profileEdit);
                        }
                     
                    })
                }

            }
        } else {
            if (currentActivites
                && currentActivites.role
                && currentActivites.organizationName
                && currentActivites.summary
                && currentActivites.startDate
                && currentActivites.endDate
            ) {
                if (isApplicationForm) {
                    profileEdit.activities[index] = currentActivites
                    setProfileMod(profileEdit);
                    setIsEditing(false);
                    setAddingNew(false);
                    setProfile(profileEdit);
                } else {
                    profileEdit.activities[index] = currentActivites
                    RamaService.updateProfile(profileEdit).then((res) => {
                        ProfileInstantService.updateProfilePublic(profileEdit);
                        setProfileMod(profileEdit);
                        setIsEditing(false);
                        setAddingNew(false);
                        setProfile(profileEdit);
                    })
                }

            }
        }
    }

    const changeCurrentWork = (value) => {
        let currentEditChange = { ...currentActivites } as Activities;

        if (currentActivites) {
            currentEditChange.isOnGoing = value;
            setCurrentActivities(currentActivites => ({
                ...currentEditChange
            }));
        }

    }

    const dateChange = (value, action) => {
        let currentEditChange = { ...currentActivites } as Activities;
        if (currentEditChange) {
            if (action == 'end') {
                currentEditChange.endDate = new Date(value).toISOString();
                setEndDate(value);
                if (currentEditChange.startDate) {
                    if (new Date(value) < new Date(currentEditChange.startDate)) {
                        setErrorDate(true);
                    } else {
                        setErrorDate(false);
                    }
                }
                currentEditChange.isOnGoing = false;
                setCurrentActivities(currentEducation => ({
                    ...currentEditChange
                }));
            } else {
                currentEditChange.startDate = new Date(value).toISOString();
                setStartDate(value);
                if (currentEditChange.endDate) {
                    if (new Date(currentEditChange.endDate) < new Date(value)) {
                        currentEditChange.endDate = "";
                        setErrorDate(true);
                    } else {
                        setErrorDate(false);
                    }
                }
                setCurrentActivities(currentEducation => ({
                    ...currentEditChange
                }));
            }

        }

    }
    const closeModal = () => {
        if (isAddingNew) {
            cancelAddEducation(editIndex);
            setAddingNew(false);
            setCloseModal();
        } else {
            setAddingNew(false);
            setCloseModal();
        }
    }
    return (
        <>
            <div className="education">
                <div className="education-close-control">
                    <img src={CLOSE_IMG} onClick={closeModal} className="education-close-img"></img>
                </div>
                <div className="d-flex">
                    <span className="education-header">กิจกรรม</span>
                    {
                        !isAddingNew && <Button className="education-item-add-button" onClick={addEmptyExperience}>+ {translate.other.add} </Button>
                    }
                </div>


                {
                    profileMod && profileMod.activities.map((item: Activities, index: number) => {

                        return (
                            <>{
                                (editIndex == index && currentActivites && isEditing) ?
                                    <div className="d-flex flex-column experience-grid-edit">
                                        <div className="experience-grid">

                                            <div className="education-item">
                                                <TextField inputProps={{ className: "education-item-input" }}
                                                    InputLabelProps={{ className: "education-item-label" }}
                                                    label={translate.activity_type.name} value={currentActivites.role} variant="outlined"
                                                    onChange={(e) => textChange(e.target.value, 'role', index)} />
                                                {
                                                    (isSaving && !currentActivites.role) &&
                                                    <span className="education-item-text-error">{translate.error.input}</span>
                                                }
                                            </div>
                                            <div className="education-item">
                                                <TextField inputProps={{ className: "education-item-input" }}
                                                    InputLabelProps={{ className: "education-item-label" }}
                                                    label={translate.activity_type.company} variant="outlined" value={currentActivites.organizationName}
                                                    onChange={(e) => textChange(e.target.value, 'organizationName', index)} />
                                                {
                                                    (isSaving && !currentActivites.organizationName) &&
                                                    <span className="education-item-text-error">{translate.error.input}</span>
                                                }
                                            </div>

                                            <div className="education-item">
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DemoContainer components={['DatePicker']} >

                                                        <DatePicker
                                                            value={startDate}
                                                            onChange={(e) => dateChange(e, 'start')}
                                                            className="education-item-input-select" label={translate.activity_type.start_date} views={['month', 'year']} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className="education-item">
                                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                    <DemoContainer components={['DatePicker']} >

                                                        <DatePicker
                                                            value={endDate}
                                                            disabled={currentActivites.isOnGoing}
                                                            minDate={startDate}
                                                            onChange={(e) => dateChange(e, 'end')}
                                                            className="education-item-input-select" label={translate.profile.end_year} views={['month', 'year']} />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                {
                                                    errorDate &&
                                                    <span className="education-item-text-error">{translate.error.input_date_end}</span>
                                                }
                                            </div>
                                            <div className="education-item">
                                                <FormControlLabel control={<Checkbox checked={currentActivites.isOnGoing}
                                                    onChange={(e) => changeCurrentWork(e.target.checked)} />}
                                                    className="experience-current-work-control" label={translate.activity_type.still_doing} />
                                            </div>
                                            <div className="education-item">
                                            </div>


                                        </div>
                                        <div className="education-item">
                                            <span className="education-form-title">{translate.activity_type.responsibility}</span>
                                            <TextareaAutosize
                                                className="education-textarea-control"
                                                minRows={5}
                                                value={currentActivites.summary}
                                                onChange={(e) => textChange(e.target.value, 'summary', index)} />
                                            {
                                                (isSaving && !currentActivites.summary) &&
                                                <span className="education-item-text-error">{translate.error.input}</span>
                                            }
                                        </div>
                                        <div className="education-item"></div>
                                        <div className="d-flex">
                                            <Button className="education-item-edit-button" onClick={() => saveValue(index)}>{translate.other.save}</Button>
                                            {
                                                item.role ? <Button className="education-item-edit-button-cancel" onClick={() => setIsEditing(false)}>{translate.other.cancel}</Button>
                                                    :
                                                    <Button className="education-item-edit-button-cancel" onClick={() => cancelAddEducation(editIndex)}>{translate.other.cancel}</Button>
                                            }
                                        </div>


                                    </div>
                                    :
                                    <div className="education-grid" key={index}>

                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.role}</span>
                                            <span className="education-item-desc">{item.role ? item.role : translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.organization_name}</span>
                                            <span className="education-item-desc">{item.organizationName ? item.organizationName : translate.other.not_specified}</span>
                                        </div>

                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.start_date}</span>
                                            <span className="education-item-desc">{item.startDate ? new Date(item.startDate).toLocaleDateString() : translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.end_year}</span>
                                            <span className="education-item-desc">{item.isOnGoing ? translate.profile.is_going_on :
                                                item.endDate ? new Date(item.endDate).toLocaleDateString() :
                                                    translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item">
                                            <span className="education-item-title">{translate.profile.responsibility}</span>
                                            <span className="education-item-desc">{item.summary ? item.summary : translate.other.not_specified}</span>
                                        </div>
                                        <div className="experience-item"></div>
                                        <div className="education-item-edit-button-control">
                                            <Button className="education-item-edit-button" onClick={() => clickEditExperience(item, index)}>{translate.other.edit}</Button>
                                            <Button className="education-item-edit-button-cancel" onClick={() => deleteItem(index)}>{translate.other.delete}</Button>
                                        </div>
                                    </div>
                            }

                            </>
                        )
                    })
                }



            </div>
        </>

    )

}

export default ActivitiesComponent;