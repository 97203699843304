const local = {
    ga_4: "G-JBLW3M5JP7",
    url: "http://localhost:4200/",
    app_name: "ONEPROFILE",
    authen: {
        sso_url: "https://d2zjecrqpgwbzj.cloudfront.net",
        client_id: "ONEPROFILE",
        redirect_uri: "http://localhost:4200/checking",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramatest.scoutout.net"
        }
    },
    leela: {
        host_url: "https://leeladev.scoutout.net/api/v1/"
    },
    resume_builder: {
        upload_resume: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url: "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        }
    },
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    },
    iframe: {
        interivew_ai: "https://interview-question-test.web.app",
        personality_test: "https://scoutout-personality-test.web.app"
    },
    career_site: {
        base_url: ".devphaona.scoutout.xyz",
    },
    personality_api: {
        base_url: "https://asia-east2-scoutout-personality-test.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
    },view_resume: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },career_craft: {
        base_url : "https://ramadev.scoutout.net/api"
    },
    resume_checker: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        main: "https://resume-checker-test.web.app"
    },
    salary_checker:{
        url: "https://salary.scoutout.co?utm_source=ONEPROFILE"
    },
    jobboard:{
        url: "https://d2mkkyov94e9cz.cloudfront.net"
    }
};
const dev = {
    ga_4: "G-JBLW3M5JP7",
    url: "https://oneprofile-test.web.app/",
    app_name: "ONEPROFILE",
    authen: {
        sso_url: "https://d2zjecrqpgwbzj.cloudfront.net",
        client_id: "ONEPROFILE",
        redirect_uri: "https://oneprofile-test.web.app/checking",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramatest.scoutout.net"
        }
    },
    leela: {
        host_url: "https://leeladev.scoutout.net/api/v1/"
    },
    resume_builder: {
        upload_resume: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url: "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        }
    },
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    },
    career_site: {
        base_url: ".devphaona.scoutout.xyz",
    }
    ,
    iframe: {
        interivew_ai: "https://interview-question-test.web.app",
        personality_test: "https://scoutout-personality-test.web.app"
    },
    personality_api: {
        base_url: "https://asia-east2-scoutout-personality-test.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
    },view_resume: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },career_craft: {
        base_url : "https://ramadev.scoutout.net/api"
    },
    resume_checker: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        main: "https://resume-checker-test.web.app"
    },
    salary_checker:{
        url: "https://salary.scoutout.co?utm_source=ONEPROFILE"
    },
    jobboard:{
        url: "https://d2mkkyov94e9cz.cloudfront.net"
    }
};
const test = {
    ga_4: "G-JBLW3M5JP7",
    url: "https://oneprofile-test.web.app/",
    app_name: "ONEPROFILE",
    authen: {
        sso_url: "https://d2zjecrqpgwbzj.cloudfront.net",
        client_id: "ONEPROFILE",
        redirect_uri: "https://oneprofile-test.web.app/checking",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramatest.scoutout.net"
        }
    },
    leela: {
        host_url: "https://leeladev.scoutout.net/api/v1/"
    },
    resume_builder: {
        upload_resume: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url: "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        }
    },
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    },
    career_site: {
        base_url: ".testphaona.scoutout.xyz",
    }
    ,
    iframe: {
        interivew_ai: "https://interview-question-test.web.app",
        personality_test: "https://scoutout-personality-test.web.app"
    },
    personality_api: {
        base_url: "https://asia-east2-scoutout-personality-test.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
    },view_resume: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },career_craft: {
        base_url : "https://career-api.scoutoutapi.com/api"
    },
    resume_checker: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        main: "https://resume-checker.jobhack.co"
    },
    salary_checker:{
        url: "https://salary.scoutout.co?utm_source=ONEPROFILE"
    },
    jobboard:{
        url: "https://d2mkkyov94e9cz.cloudfront.net"
    }
};
const prod = {
    ga_4: "G-5ZFGD3HXQT",
    ga: "",
    url: "https://resume.jobhack.co/",
    app_name: "ONEPROFILE",
    authen: {
        sso_url: " https://account.scoutout.co",
        client_id: "ONEPROFILE",
        redirect_uri: "https://resume.jobhack.co/checking",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://account-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://career-api.scoutoutapi.com"
        }
    },
    leela: {
        host_url: "https://leeladev.scoutout.net/api/v1/"
    },
    resume_builder: {
        upload_resume: {
            x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
            base_url: "https://f3clcckfr7.execute-api.ap-southeast-1.amazonaws.com/production"
        },
    },
    chat_gpt: {
        base_url: "https://dprod-api.scoutout.net/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    },
    career_site: {
        base_url: ".scoutcareers.com",
    }
    ,
    iframe: {
        interivew_ai: "https://interview.jobhack.co",
        personality_test: "https://quiz.scoutout.co"
    },
    personality_api: {
        base_url: "https://asia-east2-scoutout-flunkey.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
    }, view_resume: {
        x_api_key: "1TUCk4v9nYaKrYQqoELs7aEaiXXbuis91jbjiEw1",
        base_url: "https://bbyxui1fsg.execute-api.ap-southeast-1.amazonaws.com/production",
    },career_craft: {
        base_url : "https://career-api.scoutoutapi.com/api"
    },
    resume_checker: {
        x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
        main: "https://resume-checker.jobhack.co"
    },
    salary_checker:{
        url: "https://salary.scoutout.co?utm_source=ONEPROFILE"
    },
    jobboard:{
        url: "https://app.jobhack.co"
    }
};
const prod_one_profile = {
    ga_4: "G-5ZFGD3HXQT",
    ga: "",
    url: "https://app.oneprofile.me/",
    app_name: "ONEPROFILE",
    authen: {
        sso_url: " https://account.scoutout.co",
        client_id: "ONEPROFILE",
        redirect_uri: "https://app.oneprofile.me/checking",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://account-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://career-api.scoutoutapi.com"
        }
    },
    leela: {
        host_url: "https://connect-api.scoutoutapi.com/api/v1/"
    },
    resume_builder: {
        upload_resume: {
            x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
            base_url: "https://f3clcckfr7.execute-api.ap-southeast-1.amazonaws.com/production"
        },
    },
    chat_gpt: {
        base_url: "https://dprod-api.scoutout.net/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    },
    career_site: {
        base_url: ".scoutcareers.com",
    }
    ,
    iframe: {
        interivew_ai: "https://interview.jobhack.co",
        personality_test: "https://quiz.scoutout.co"
    },
    personality_api: {
        base_url: "https://asia-east2-scoutout-flunkey.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
    }, view_resume: {
        x_api_key: "1TUCk4v9nYaKrYQqoELs7aEaiXXbuis91jbjiEw1",
        base_url: "https://bbyxui1fsg.execute-api.ap-southeast-1.amazonaws.com/production",
    },career_craft: {
        base_url : "https://career-api.scoutoutapi.com/api"
    },
    resume_checker: {
        x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
        main: "https://resume-checker.jobhack.co"
    },
    salary_checker:{
        url: "https://salary.scoutout.co?utm_source=ONEPROFILE"
    },
    jobboard:{
        url: "https://app.jobhack.co"
    }
};

const local_360 = {
    ga_4: "G-JBLW3M5JP7",
    url: "http://localhost:4200/",
    app_name: "ONEPROFILE",
    authen: {
        sso_url: "https://d2zjecrqpgwbzj.cloudfront.net",
        client_id: "ONEPROFILE",
        redirect_uri: "http://localhost:4200/checking",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramatest.scoutout.net"
        }
    },
    leela: {
        host_url: "https://leeladev.scoutout.net/api/v1/"
    },
    resume_builder: {
        upload_resume: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url: "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        }
    },
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    },
    iframe: {
        interivew_ai: "https://interview-question-test.web.app",
        personality_test: "https://scoutout-personality-test.web.app"
    },
    career_site: {
        base_url: ".devphaona.scoutout.xyz",
    },
    personality_api: {
        base_url: "https://asia-east2-scoutout-personality-test.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
    },view_resume: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },career_craft: {
        base_url : "https://ramadev.scoutout.net/api"
    },
    resume_checker: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        main: "https://resume-checker-test.web.app"
    },
    salary_checker:{
        url: "https://salary.scoutout.co?utm_source=ONEPROFILE"
    },
    jobboard:{
        url: "https://d2mkkyov94e9cz.cloudfront.net"
    }
};
const test_360 = {
    ga_4: "G-JBLW3M5JP7",
    url: "https://360-profile-test.web.app/",
    app_name: "ONEPROFILE",
    authen: {
        sso_url: "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id: "ONEPROFILE",
        redirect_uri: "https://360-profile-test.web.app/checking",
        grant_type: "authorization_code",
        length: 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramatest.scoutout.net"
        }
    },
    leela: {
        host_url: "https://leeladev.scoutout.net/api/v1/"
    },
    resume_builder: {
        upload_resume: {
            x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
            base_url: "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
        }
    },
    chat_gpt: {
        base_url: "https://testlambda.scoutout.net:444/",
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8"
    },
    iframe: {
        interivew_ai: "https://interview-question-test.web.app",
        personality_test: "https://scoutout-personality-test.web.app"
    },
    career_site: {
        base_url: ".testphaona.scoutout.xyz",
    },
    personality_api: {
        base_url: "https://asia-east2-scoutout-personality-test.cloudfunctions.net",
        x_api_key_quiz: "ZE2OLJ0zzsPiSZuFqgXSbhJqybPudofwUq9ORjeW",
    },view_resume: {
        base_url: "https://qq4im6io2j.execute-api.ap-southeast-1.amazonaws.com/dev",
        x_api_key: "1NS7S3uGQE48qaQ67f5Q14EOPtluJVsfaYSmZKp4",
    },career_craft: {
        base_url : "https://ramadev.scoutout.net/api"
    },
    resume_checker: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        main: "https://resume-checker-test.web.app"
    },
    salary_checker:{
        url: "https://salary.scoutout.co?utm_source=ONEPROFILE"
    },
    jobboard:{
        url: "https://d2mkkyov94e9cz.cloudfront.net"
    }
};
const config =
    process.env.REACT_APP_ENVIRONMENT === 'production' ? prod :
    process.env.REACT_APP_ENVIRONMENT === 'dev' ? dev :
    process.env.REACT_APP_ENVIRONMENT === 'one_profile_production' ? prod_one_profile:
    process.env.REACT_APP_ENVIRONMENT === '360_local' ? local_360:
    process.env.REACT_APP_ENVIRONMENT === '360_test' ? test_360:
        process.env.REACT_APP_ENVIRONMENT === 'local' ? local
            : test;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 10,
    MAX_ATTACHMENT_RESUME_IMAGE_SIZE: 1,
    ...config
};