import { useEffect } from "react";
import ApiService from "../../services/api.service";
import tokenService from "../../services/sso/token.service";
import { useNavigate } from "react-router-dom";


const LogOutComponent = () => {

    const navigate = useNavigate();

    useEffect(() => {

        localStorage.clear();
        tokenService.clearAll();
        navigate('/');
    },[])

    return (
        <></>
    )
}

export default LogOutComponent;