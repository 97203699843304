import { useContext, useEffect, useState } from "react";
import "./step-3.scss";
import "../form-input.scss";
import { ProfileModel } from "../../../model/profile.model";
import { Button, Form } from "react-bootstrap";
import ApiService from "../../../services/api.service";
import RamaService from "../../../services/sso/rama.service";
import config from "../../../config";
import { useNavigate } from "react-router-dom";
import ProfileService from "../../../services/profile/profile.service";
import { ProfileExtModel } from "../../../model/profile/profile-ext.model";
import { ProvideContext } from "../../../context/provider.context";
import { TranslateModel } from "../../../model/translate.model";
import navigationService from "../../../services/navigation.service";
import { CircularProgress } from "@mui/material";
import ProfileInstantService from "../../../services/profile/profile-instance.service";
import MAG_IMAGE from "../../../assets/images/magnifying.png";
import CLOUD_IMAGE from "../../../assets/images/cloud.png";
import storage from "../../../services/sso/storage";
import { SettingModel } from "../../../model/setting/setting.model";
import UtilityService from "../../../utility/utility.service";

const OnBoardStep3Component = ({ profileMod, updateProfileModel, clickMoveStep }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profile, setProfile] = useState<ProfileModel>(profileMod);
    const [profileExt, setProfileExt] = useState<ProfileExtModel>(profileMod);
    const [isSaving, setIsSaving] = useState(false);
    const [urlValue, setUrlValue] = useState("");
    const [isError, setIsError] = useState(false);
    const [isPathInValid, setIsPathInValid] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getProfileExt();
    }, [])
    const textChange = (value) => {
        const val: string = value + "";
        setUrlValue(val.toLocaleLowerCase());
        const reg = /^[a-zA-Z0-9]+$/;

        if (reg.test(value)) {

            setIsError(false);
        } else {
            setIsError(true);
        }
    }


    const getProfileExt = () => {

        ProfileInstantService.getProfile().then((res) => {

            if (res.data) {
                setProfileExt(res.data);
            }
        }).catch((err) => {
            if (err.response.status == 400) {

            }
        })
    }
    const submit = () => {

        if (urlValue && urlValue.length >= 6 && profile && !isError && profileExt) {
            setIsSaving(true);
            const profileSubmit: ProfileExtModel = {
                profile_json: profile,
                profile_path: urlValue,
                looking_for_jobs: true,
                profile_public: true,
                profile_public_settings: {
                    about: true,
                    activities: true,
                    certificates: true,
                    contact: true,
                    educations: true,
                    experiences: true,
                    interest: true,
                    skills: true,
                    uploadFile: true,
                    personality: true,
                    resume: true
                }
            }
            const settingMod: SettingModel = {
                expectedMaxSalary: 0,
                expectedMinSalary: 0,
                preferredJobLevel: "n/a",
                preferredJobRole: [
                    "", "", ""]
            }

            RamaService.updateSetting(settingMod).then((res) => {
                if (res.status == 200) {
                    ProfileInstantService.updateProfileExt(profileSubmit).then((res) => {
                        if (res.data) {
                            UtilityService().clickSendEvent("submit_onboarding","submit");
                            if (navigationService.getPath()) {
                                navigationService.handleNavigation(navigate);
                            } else {
                                if (localStorage.getItem(storage.path_redirect)) {
                                    const page = localStorage.getItem(storage.path_redirect);
                                    localStorage.setItem(storage.path_redirect, "");
                                    navigate(page + '');


                                } else {
                                    navigate('/profile');
                                }

                            }
                        } else if (res.status == 400) {

                        }


                    }).catch((err) => {
                        setIsSaving(false);

                        if (err.response.status == 400) {

                            if (err.response.data.error == "profile path exists") {
                                setIsPathInValid(true);

                            }
                        }
                    })
                } else {

                }

            })

        } else {
        }
    }
    return (
        <>
            <div className="step-3-main form-input-main">
                {
                    (profile && !isSaving) ?
                        <div className="step-3-main-form-control">
                            <img src={MAG_IMAGE} className="magnify-img"></img>
                            <img src={CLOUD_IMAGE} className="cloud-img"></img>
                            <span className="step-3-form-title">ตั้งค่า URL ของคุณ</span>

                            <div className="step-3-form-input-control">
                                <div className="form-input-control  ">
                                    <span className="step-3-total-url">Url นี้จะใช้เพื่อทำให้โปรไฟล์คุณเป็นสาธารณะ</span>
                                    <div className="step-3-url-control">
                                        <div className="d-flex">

                                            <span className="step-3-total-url-text" >{config.url}

                                            </span>
                                            <Form.Control className={
                                                isSaving && !urlValue ? "form-input-custom-error step-3-form-margin" : "form-input-custom step-3-form-margin"
                                            }
                                                placeholder="url ควรเป็นชื่อหรือนามสกุลภาษาอังกฤษ ex. natchatby"
                                                onChange={(e) => textChange(e.target.value)}
                                                required
                                            >
                                            </Form.Control>
                                        </div>

                                    </div>
                                    {
                                        (isError || urlValue.length < 6) &&
                                        <span className="form-error">กรุณากรอกแค่ a-z,0-9 และไม่ต่ำกว่า 6 ตัวอักษร</span>
                                    }{
                                        isPathInValid &&
                                        <span className="form-error">Path is already exist.</span>
                                    }

                                </div>
                            </div>
                            <div className="step-3-button-control">
                                <Button className="form-input-button-back" onClick={() => clickMoveStep(2)}>{translate.other.back}</Button>
                                <Button className="form-input-button" onClick={() => submit()}>{translate.other.save}</Button>
                            </div>

                        </div> :
                        isSaving &&
                        <div className="step-3-main-form-control">
                            <div className="step-3-loading-control">
                                <div className="step-3-loading-main">
                                    <CircularProgress className="step-3-loading-progress"></CircularProgress>
                                    <span className="step-3-loading-text">กำลังส่งข้อมูล</span>
                                </div>

                            </div>
                        </div>
                }
            </div>

        </>
    )
}

export default OnBoardStep3Component;