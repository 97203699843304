
import "./profile.scss";
import { useEffect, useState } from "react";
import tokenService from "../../services/sso/token.service";
import MyInfoComponent from "./my-info/my-info";
import { useLocation, useNavigate } from "react-router-dom";
import MenuComponent from "../menu/menu";
import { useProfile } from "../../context/profile.context";
import BG_CIRCLE_MAIN  from "../../assets/images/circle-bg-main.svg";

const ProfileComponent = () => {
    const location = useLocation();
    const [menu, setMenu] = useState(0);
    const [currentPath, setCurrentPath] = useState("");
    const [profile, error] = useProfile();
    const navigate = useNavigate();
    useEffect(() => {
        if(profile){
            tokenService.setProfile(profile);
            if (location.pathname == "/profile/resume") {
                setMenu(1);
                setCurrentPath("resume")
            } else if (location.pathname == "/profile/resume/builder/choose-template") {
                setMenu(1.1);
                setCurrentPath("resume/builder/choose-template")
            } else if (location.pathname == "/profile/resume/builder/input") {
                setMenu(1.2);
                setCurrentPath("resume/builder/input")
            }
            else if (location.pathname == "/profile") {
                setMenu(0);
                setCurrentPath("profile")
            }
        }

    }, [profile])

   

    useEffect(() => {
        try{
            if(error){
                if(error.response.status != undefined){
                    if(error.response.status == 404){
                        navigate("/login")
                    }  
                }
            }
        }catch{
            navigate("/login")
        }
        
    },[error])

    return (
        <>
            {
            profile &&
                <div className="main-control">
                    <MenuComponent></MenuComponent>
                    <div className="profile">
                        <img src={BG_CIRCLE_MAIN} className="bg-circle-main"></img>
                        <div className="profile-right-control">

                            <MyInfoComponent profile={profile}></MyInfoComponent>


                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default ProfileComponent;