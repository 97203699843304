import "./step-1.scss";
import "../form-input.scss";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import FORM_MAIN_PROFILE from "../../../assets/json/profile/main-profile.json";
import RamaService from "../../../services/sso/rama.service";
import tokenService from "../../../services/sso/token.service";
import { ProfileModel } from "../../../model/profile.model";
import { format } from "date-fns";
import ApiService from "../../../services/api.service";
import { MysaProfileModel } from "../../../model/mysa-profile.model";
import { CircularProgress, TextField } from "@mui/material";
import { useProfile } from "../../../context/profile.context";
import MAG_IMAGE from "../../../assets/images/magnifying.png";
import CLOUD_IMAGE from "../../../assets/images/cloud.png";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import UtilityService from "../../../utility/utility.service";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/locale/en-GB'; // Use en-GB locale to get DD/MM/YYYY format
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

// Register and set the locale for DatePicker
registerLocale('en-GB', enGB);
setDefaultLocale('en-GB');
const OnBoardStep1Component = ({ clickMoveStep, updateProfileModel }: any) => {

    const [formControl, setFormControl] = useState(FORM_MAIN_PROFILE);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [profile, error] = useProfile();
    const [startDate, setStartDate] = useState(dayjs());
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {

        if (profile) {
            tokenService.setProfile(profile);
            setProfileMod(profile)
            const formM = [...formControl];
            formControl.map((item, index) => {

                if (item.id == 'firstName') {
                    item.value = profile.firstName;
                } else if (item.id == 'lastName') {
                    item.value = profile.lastName;
                } else if (item.id == 'dob') {
                    if (profile.dateOfBirth) {
                        // const formattedDate = format(new Date(profile.dateOfBirth), 'yyyy-MM-dd');
                        item.value = convertDate(profile.dateOfBirth);
                        setSelectedDate(new Date(profile.dateOfBirth))
                    }

                } else if (item.id == 'email') {
                    item.value = profile.email;
                } else if (item.id == 'tel') {
                    item.value = profile.phone;
                }
                formM[index].value = item.value;
            })
            setFormControl(formM);
        } else {
            console.log("NO PROFILE")
            RamaService.createProfile().then((res) => {
                initNewProfile();
            }).catch((err) => {
                initNewProfile();
            })
        }

    }, [profile])

    const convertDate = (dateRaw: string) => {
        const date = new Date(dateRaw);

        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const year = date.getUTCFullYear();

        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    }
    useEffect(() => {

        if (error) {
            if (error.response.status == 404) {
                ApiService().getMysaProfile().then((res) => {

                    if (res) {
                        const profileMysa = res as MysaProfileModel;
                        const formM = [...formControl];
                        formControl.map((item, index) => {

                            if (item.id == 'firstName') {
                                item.value = profileMysa.firstName;
                            } else if (item.id == 'lastName') {
                                item.value = profileMysa.lastName;
                            } else if (item.id == 'dob') {
                                // const formattedDate = format(new Date(), 'yyyy-MM-dd');
                                item.value = "";
                            } else if (item.id == 'email') {
                                item.value = profileMysa.email;
                            } else if (item.id == 'tel') {
                                item.value = '';
                            }
                            formM[index].value = item.value;
                        })
                        setFormControl(formM);
                    }


                })
            }
        }
    }, [error])

    const initNewProfile = () => {
        RamaService.getProfile().then((resProfile) => {
            if (resProfile.data) {
                const profileLoad = resProfile.data;
                console.log(profileLoad);
                tokenService.setProfile(profileLoad);
                setProfileMod(profileLoad)
                const formM = [...formControl];
                formControl.map((item, index) => {

                    if (item.id == 'firstName') {
                        item.value = profileLoad.firstName;
                    } else if (item.id == 'lastName') {
                        item.value = profileLoad.lastName;
                    } else if (item.id == 'dob') {
                        if (profileLoad.dateOfBirth) {
                            const formattedDate = format(new Date(profileLoad.dateOfBirth), 'yyyy-MM-dd');
                            item.value = formattedDate;
                        }

                    } else if (item.id == 'email') {
                        item.value = profileLoad.email;
                    } else if (item.id == 'tel') {
                        item.value = profileLoad.phone;
                    }
                    formM[index].value = item.value;
                })
                setFormControl(formM);
            }
        })
    }

    const onTextChange = (value, index) => {
        if (formControl) {
            const formMod = [...formControl];
            if (formMod[index].id == 'email') {
                const regular = new RegExp(formMod[index].validate.reg, "i")
                if (regular.test(value)) {
                    formMod[index].value = value;
                    formMod[index].validate.is_error = false
                } else {
                    formMod[index].value = value;
                    formMod[index].validate.is_error = true
                }
            }
            else if (formMod[index].id == 'tel') {
                const regular = new RegExp(formMod[index].validate.reg)
                if (regular.test(value)) {
                    formMod[index].value = value;
                    formMod[index].validate.is_error = false
                } else {
                    formMod[index].value = value;
                    formMod[index].validate.is_error = true
                }
            } else {
                if (formMod[index].id == 'dob') {
                    setSelectedDate(value);
                }
                formMod[index].value = value;
            }
            setFormControl(formMod);
        }

    }
    const clickSubmit = () => {
        setIsSubmit(true);
        if (formControl) {
            const isValidate = formControl.filter(item => item.require && item.value == '').length == 0 ? true : false;
            if (isValidate && profileMod) {
                tokenService.setProfile(profileMod);

                formControl.map((item, index) => {
                    if (item.id == 'firstName') {
                        profileMod.firstName = item.value
                    } else if (item.id == 'lastName') {
                        profileMod.lastName = item.value
                    } else if (item.id == 'dob') {
                        profileMod.dateOfBirth = new Date(item.value).toISOString()
                    } else if (item.id == 'email') {
                        profileMod.email = item.value
                    } else if (item.id == 'tel') {
                        profileMod.phone = item.value
                    }
                })
                setProfileMod(profileMod);
                updateProfile(profileMod);
                clickMoveStep(1);
            }
            // getProfile();

        }
    }


    const updateProfile = (profileModel) => {
        RamaService.updateProfile(profileModel).then((res) => {

            if (res) {
                UtilityService().clickSendEvent("update_onboard_step_1_complete", "submit");
                setIsLoading(false);
                updateProfileModel(profileModel);
                clickMoveStep(2);
            }
        })
    }

    return (
        <>
            <div className="step-1-main">

                <div className="step-1-main-form-control  form-input-main">
                    <img src={MAG_IMAGE} className="magnify-img"></img>
                    <img src={CLOUD_IMAGE} className="cloud-img"></img>
                    <span className="step-1-form-title">เริ่มต้นอัปเดทโปรไฟล์</span>
                    {
                        isLoading ?

                            <div className="onboard-loading-progress">
                                <div className="onboard-loading-item">
                                    <CircularProgress className="onboard-loading"></CircularProgress>

                                    <span className="onboard-progress-text">กำลังบันทึกข้อมูล</span>
                                </div>

                            </div> : <>

                                <Form className="step-1-form-grid">
                                    {
                                        formControl && formControl.map((item, index) => {

                                            return (
                                                <div className="form-input-control">
                                                    <span className="form-title-text">{item.name}</span>
                                                    {
                                                        item.type == 'date' ?

                                                            // <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            //     <DatePicker
                                                            //         label="Select Date"
                                                            //         maxDate={startDate}
                                                            //         value={item.value && dayjs(item.value)}
                                                            //         onChange={(e) => onTextChange(e,index)}
                                                            //         inputFormat="DD/MM/YYYY"
                                                            //         renderInput={(params) => <TextField {...params} />}
                                                            //     />
                                                            // </LocalizationProvider>
                                                            <Form.Group controlId="formDate" >
                                                                <DatePicker
                                                                    className="form-input-custom"
                                                                    selected={selectedDate}
                                                                    onChange={(date) => onTextChange(date, index)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    locale="en-GB"
                                                                    customInput={<FormControl />}
                                                                />
                                                            </Form.Group>
                                                            :
                                                            <Form.Control type="input" className="form-input-custom"
                                                                value={item.value}

                                                                placeholder={item.placeholder}
                                                                required={item.require} onChange={(e) => onTextChange(e.target.value, index)}
                                                            ></Form.Control>
                                                    }

                                                    {
                                                        isSubmit && !item.value ? <span className="form-error">{item.validate.error}</span> :

                                                            item.validate.is_error && <span className="form-error">{item.validate.reg_error}</span>
                                                    }

                                                </div>
                                            )
                                        })
                                    }

                                </Form>
                                <Button className="form-input-button" onClick={clickSubmit}>ถัดไป</Button>
                            </>


                    }

                </div>
            </div>
        </>
    )
}

export default OnBoardStep1Component;