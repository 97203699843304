import { Button } from "react-bootstrap";
import config from "../../config";
import featureConfig from "../../feature.config";
import "./landing.scss";
import { useNavigate } from "react-router-dom";


const Landing360Component = () => {

    const navigate = useNavigate();
    const clickLogin = () => {
        navigate("/login")

    }

    return (

        <div className="landing-main">

            <div className="landing-control">
                <img src={config.url+featureConfig.logo_image.main} className="landing-logo"></img>
                <Button className="landing-button" onClick={clickLogin}>เข้าสู่ระบบ</Button>
            </div>
        </div>

    )
}

export default Landing360Component;