import { useLocation, useNavigate } from "react-router";
import "./resume-checker.scss";
import MenuComponent from "../menu/menu";
import { useContext, useEffect, useState } from "react";
import { ProvideContext } from "../../context/provider.context";
import { TranslateModel } from "../../model/translate.model";
import { Autocomplete, Box, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { Experience, ProfileModel } from "../../model/profile.model";
import { AllResumeModel } from "../../model/all-resume.model";
import RamaService from "../../services/sso/rama.service";
import GPTService from "../../services/gpt.service";
import { JobRole, JobRoleSuggestionModel } from "../../model/job-role-suggestion.model";
import JOB_ROLE_JSON from "../../assets/json/job-role-suggestion.json";
import EXP_JSON from "../../assets/json/exp-suggestion.json";
import { GetResumeIdModel } from "../../model/get-resume.model";
import { Button } from "react-bootstrap";
import { InputResumeCheckerModel } from "../../model/resume-checker/input-resume-checker.model";
import ResultResumeCheckerModel from "../../model/resume-checker/result-resume-checker.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleInfo, faCircleXmark, faClose, faShare } from "@fortawesome/free-solid-svg-icons";
import UploadResumeModal from "../../modal/jobs/upload-resume/upload-resume.modal";
import { useProfile } from "../../context/profile.context";
import BG_CIRCLE_MAIN from "../../assets/images/circle-bg-main.svg";
import Lottie from "lottie-react";
import AI_LOADING from "../../assets/json/ai-loading.json";
import UtilityService from "../../utility/utility.service";

const ResumeCheckerComponent = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [activeStep, setActiveStep] = useState(0);
    const [allResume, setAllResume] = useState<AllResumeModel>();
    const [isNewJob, setIsNewJob] = useState("");
    const [flattenedRoles, setFlattenedRoles] = useState(new Array());
    const [listJD, setListJD] = useState(new Array());
    const [resumeName, setResumeName] = useState("");
    const [isUploadNew, setIsUploadNew] = useState(false);
    const [isUploadResume, setIsUploadResume] = useState(false);
    const [resultModel, setResultModel] = useState<ResultResumeCheckerModel>();
    const [inputMod, setInputMod] = useState<InputResumeCheckerModel>({
        job_description: "",
        job_title: "",
        resume_key: ""
    });
    const [resumeIndex, setResumeIndex] = useState(99);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profile, error] = useProfile();
    const steps = [
        translate.resume_checker.step_1,
        translate.resume_checker.step_2,
    ];

    useEffect(() => {

        if (profile) {
            getResumeList();
            getRoleOptions();
        }

    }, [profile])
    const onClickStep = (value) => {

        if (value == 2) {

        } else if (value == 1) {
            // if(inputInterview && inputInterview.resume_key && inputInterview.job_title  && inputInterview.job_salary){
            //     setActiveStep(1);
            // }
        }
        else {
            setActiveStep(value);
        }

    }
    const radioCheck = (value) => {
        setIsNewJob(value);
        if (value == "new_job") {

        }

    }

    const setKey = (resume_name, resume_key) => {
        if (inputMod) {
            const inputModEdit = { ...inputMod }
            inputModEdit.resume_key = resume_key;

            setInputMod(inputModEdit);
            setResumeName(resume_name);
            setIsUploadNew(true);
        }

    }

    const clickUploadNewResume = () => {
        setIsUploadResume(true);
    }
    function getRoleOptions() {
        let flatItem = new Array();
        const listJDArray = new Array();
        JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {

            const jobPush = {
                label: item.category,
                options: new Array()
            }

            let strgArray = new Array();
            item.job_roles.map((items: JobRole) => {

                if (items.job_title) {
                    const listJDEdit = {
                        title: items.job_title,
                        description: items.job_description
                    }
                    listJDArray.push(listJDEdit);
                    strgArray.push(items.job_title);
                }

            })

            jobPush.options = strgArray;
            flatItem.push(jobPush);

        })
        setListJD(listJDArray);
        setFlattenedRoles(flatItem)
    }
    const getResumeList = () => {
        GPTService.getAllResume().then((res) => {

            if (res) {
                const result = res.data as AllResumeModel;
                const filterOut = result.resumes.filter(item => item.resume_key)
                result.resumes = filterOut
                if (filterOut.length > 0) {
                    setAllResume(result);
                }

            }

        }).catch((err) => {

        })
    }
    const onSelectChange = (action, index, event?) => {
        if (profile) {
            const inputModEdit = { ...inputMod };
            if (action == "resume") {
                if (allResume && allResume.resumes[index].resume_key) {
                    inputModEdit.resume_key = allResume.resumes[index].resume_key;
                    setResumeName(allResume.resumes[index].resume_name);
                    setResumeIndex(index);
                }
            } else if (action == "jobTitle") {
                const jd = listJD.filter(item => item.title == index);
                if (jd.length > 0) {
                    inputModEdit.job_title = jd[0].title;
                    inputModEdit.job_description = jd[0].description;
                }
            }
            setInputMod(inputModEdit);
        }
    }

    const onResetClick = () => {

        setResumeName("");
        setActiveStep(0);
        setResumeIndex(99);
        setIsSubmit(false);
        setIsLoading(false);
        setInputMod({
            job_description: "",
            job_title: "",
            resume_key: ""
        })
    }

    const handleUploadResume = () => {
        setIsUploadResume(false);
        getResumeList();
    }

    const clearResume = () => {
        setIsUploadNew(false);
    }

    const onSubmit = () => {
        setIsSubmit(true);
        if (inputMod.job_title && inputMod.job_description && inputMod.resume_key) {
            const formData = new FormData();
            for (var key in inputMod) {
                formData.append(key, inputMod[key]);
            }
            setIsLoading(true);
            GPTService.postResumeChecker(formData).then((res) => {
                if (res.data) {
                    UtilityService().clickSendEvent("resume_checker_result_complete", "submit");
                    setActiveStep(1);
                    setIsLoading(false);
                    setResultModel(res.data);
                }else if(res.status != 200){
                    UtilityService().clickSendEvent("resume_checker_failed", "submit");
                }
            }).catch((err) => {
                setIsSubmit(false);
                setIsLoading(false);
            })
        }
    }


    return (
        <>
            <div className="main-control">
                <MenuComponent></MenuComponent>
                <div className="resume-checker ">
                    <img src={BG_CIRCLE_MAIN} className="bg-circle-main"></img>
                    <div className="resume-checker-control">
                        <div className="resume-checker-header-control">
                            <h1 className="resume-checker-header">{translate.resume_checker.title}</h1>
                        </div>

                        <div className="resume-checker-form-control">
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label, index) => (
                                        <Step onClick={() => onClickStep(index)} key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            {
                                activeStep == 0 && profile &&

                                <div className="resume-checker-form-grid">
                                    <div className="resume-checker-form-left">

                                        <div className="resume-checker-form-input-control">
                                            <span className="resume-checker-form-input-header">1. {translate.resume_checker.choose_resume}</span>
                                            {
                                                !isUploadNew && allResume && allResume.resumes.length > 0 ?
                                                    <FormControl className="resume-checker-form-input">
                                                        <Select
                                                            label={translate.interview_ai.info_step_2}
                                                            value={resumeIndex}
                                                            defaultValue={resumeIndex}
                                                            disabled={isLoading}
                                                            variant="outlined"
                                                            onChange={(e) => onSelectChange("resume", e.target.value)}
                                                        >
                                                            <MenuItem value={99} >{translate.interview_ai.info_step_2}</MenuItem>
                                                            {
                                                                allResume.resumes.map((item: GetResumeIdModel, index: number) => {
                                                                    return (
                                                                        <MenuItem value={index} key={index}>{item.resume_name}</MenuItem>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                        {
                                                            isSubmit && !inputMod.resume_key &&
                                                            <span className="resume-checker-form-input-error">{translate.interview_ai.info_step_2}</span>
                                                        }
                                                    </FormControl> :
                                                    allResume && allResume.resumes.length == 0 ?
                                                        <div className="resume-checker-input-resume-no-resume-control">
                                                            <span className="resume-checker-input-resume-no-resume-text">{translate.error.resume_not_found}</span>
                                                            <Button className="resume-checker-input-resume-upload-new-button"
                                                                onClick={clickUploadNewResume}>{translate.interview_ai.info_step_2_upload_resume}</Button>
                                                        </div> : <></>
                                            }
                                            {
                                                isUploadNew &&
                                                <div className="resume-checker-upload-new">
                                                    <span className="resume-checker-upload-new-title">{translate.resume_checker.resume_upload}: </span>
                                                     <span className="resume-checker-upload-new-name">{resumeName}</span>
                                                     <FontAwesomeIcon icon={faClose} className="resume-checker-upload-new-abort"
                                                     onClick={clearResume}></FontAwesomeIcon>
                                                     </div>
                                            }
                                            {
                                                !isUploadNew &&
                                                <div className="resume-checker-input-resume-no-resume-control">
                                                    <div className="resume-checker-upload-resume-new-control">

                                                        <span
                                                        >
                                                            {translate.resume_checker.no_resume_1}
                                                        </span>
                                                        <span className="resume-checker-upload-resume-new-link" onClick={clickUploadNewResume}>{translate.resume_checker.no_resume_click}</span>
                                                    </div>

                                                </div>
                                            }

                                        </div>
                                        <div className="resume-checker-form-input-control">
                                            <span className="resume-checker-form-input-header">2. {translate.resume_checker.choose_job_title}</span>

                                            {flattenedRoles &&
                                                <div className="resume-checker-form-input">
                                                    <div className="resume-checker-form-input-item-control">
                                                        <Autocomplete
                                                            disabled={isLoading}
                                                            options={flattenedRoles.map(group => group.options).flat()}
                                                            groupBy={(option) =>
                                                                flattenedRoles.find(group => group.options.includes(option)).label
                                                            }
                                                            className="education-item-input"
                                                            onInputChange={(event, newValue) => {
                                                                onSelectChange("jobTitle", newValue, event);
                                                            }}
                                                            renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                                        />
                                                    </div>


                                                </div>
                                            }
                                        </div>
                                        <Button className={
                                            isLoading ? "resume-checker-form-submit-button-disabled" : "resume-checker-form-submit-button"
                                        } onClick={onSubmit}>{translate.resume_checker.start_check}</Button>
                                    </div>
                                    <div className="resume-checker-form-right">
                                        <div className="resume-checker-form-right-detail-control">
                                            {
                                                inputMod && inputMod.job_title && inputMod.job_description && inputMod.resume_key && !isLoading && !isSubmit ?

                                                    <>
                                                        <span className="resume-checker-form-right-header">{translate.resume_checker.input_header}</span>
                                                        <div className="resume-checker-form-right-content-item">
                                                            <span className="resume-checker-form-right-title">{translate.resume_checker.input_resume}</span>
                                                            <span className="resume-checker-form-right-description">{resumeName}</span>
                                                        </div>
                                                        <div className="resume-checker-form-right-content-item">
                                                            <span className="resume-checker-form-right-title">{translate.resume_checker.input_job_title}</span>
                                                            <span className="resume-checker-form-right-description">{inputMod.job_title}</span>
                                                        </div>
                                                        <div className="resume-checker-form-right-content-item">
                                                            <span className="resume-checker-form-right-title">{translate.resume_checker.input_job_description}</span>
                                                            <span className="resume-checker-form-right-description">{inputMod.job_description}</span>
                                                        </div>

                                                    </> : !isLoading && !isSubmit ?
                                                        <div className="resume-checker-form-right-wait-input-control">
                                                            <div className="resume-checker-form-right-wait-input-item">
                                                                <FontAwesomeIcon icon={faCircleInfo} className="resume-checker-form-right-wait-input-icon"></FontAwesomeIcon>
                                                                <span className="resume-checker-form-right-wait-input-text">{translate.resume_checker.please_select_resume}</span>
                                                            </div>

                                                        </div> :

                                                        <div className="resume-checker-form-right-wait-input-control">
                                                            <div className="resume-checker-form-right-wait-input-item">
                                                            <Lottie animationData={AI_LOADING} loop={true} className="resume-checker-ai-loading"/>
                                                                <span className="resume-checker-form-right-wait-input-text">{translate.resume_checker.loading}</span>
                                                            </div>

                                                        </div>
                                            }

                                        </div>
                                    </div>


                                </div>
                            }
                            {
                                activeStep == 1 && profile && resultModel &&

                                <>

                                    <div className="resume-checker-result-control">
                                        <div className="resume-checker-form-right-detail-control">
                                            <>
                                                <span className="resume-checker-form-right-header">{translate.resume_checker.input_header}</span>
                                                <div className="resume-checker-form-right-content-item">
                                                    <span className="resume-checker-form-right-title">{translate.resume_checker.input_resume}</span>
                                                    <span className="resume-checker-form-right-description">{resumeName}</span>
                                                </div>
                                                <div className="resume-checker-form-right-content-item">
                                                    <span className="resume-checker-form-right-title">{translate.resume_checker.input_job_title}</span>
                                                    <span className="resume-checker-form-right-description">{inputMod.job_title}</span>
                                                </div>
                                                <div className="resume-checker-form-right-content-item">
                                                    <span className="resume-checker-form-right-title">{translate.resume_checker.input_job_description}</span>
                                                    <span className="resume-checker-form-right-description">{inputMod.job_description}</span>
                                                </div>
                                                <Button className="resume-checker-reset-button" onClick={onResetClick}>{translate.resume_checker.recheck}</Button>
                                            </>

                                        </div>
                                        <div className="resume-checker-result-item-control">

                                            <div className="resume-checker-result-top-section-control">
                                                <span className="resume-checker-result-top-text mx-auto">{translate.resume_checker.your_result}</span>
                                                {/* <div className="resume-checker-result-top-share">
                                                    <FontAwesomeIcon icon={faShare} className="resume-checker-result-top-icon"></FontAwesomeIcon>
                                                    <span className="resume-checker-reesult-top-text">แชร์</span>
                                                </div> */}
                                            </div>
                                            <div className="resume-checker-result-match-control">
                                                <span className="resume-checker-result-match-text">{translate.resume_checker.match_score}</span>
                                                <span className="resume-checker-result-match-result">{resultModel.score}%</span>
                                            </div>

                                            {

                                                resultModel.horoscope.employment && resultModel.horoscope.finance &&
                                                <div className="resume-checker-result-card-control">
                                                    <span className="resume-checker-result-card-header">{translate.resume_checker.your_luck}</span>
                                                    <div className="resume-checker-result-card-grid-item">
                                                        <span className="resume-checker-result-card-title">{translate.resume_checker.your_work}</span>
                                                        <span className="resume-checker-result-card-desc">{resultModel.horoscope.finance}</span>
                                                    </div>
                                                    <div className="resume-checker-result-card-grid-item">
                                                        <span className="resume-checker-result-card-title">{translate.resume_checker.your_money}</span>
                                                        <span className="resume-checker-result-card-desc">{resultModel.horoscope.employment}</span>
                                                    </div>
                                                </div>
                                            }


                                            <div className="resume-checker-result-card-control">
                                                <span className="resume-checker-result-card-header">{translate.resume_checker.suggest_1}</span>
                                                <span className="resume-checker-result-card-sub-header">{translate.resume_checker.suggest_2}</span>
                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">{translate.resume_checker.suggest_3}</span>
                                                    <span className="resume-checker-result-item-value-suggest-text">{translate.resume_checker.suggest_4}
                                                        <span className="resume-checker-form-bold-extra">{resultModel.skills.opportunity_skills.length}</span>
                                                        {translate.resume_checker.suggest_5}</span>
                                                </div>
                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">
                                                        <span className="resume-checker-form-bold-extra-green">{resultModel.skills.key_skills.length} </span>{translate.resume_checker.suggest_6}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <div className="resume-checker-result-tag-active-skill-control">
                                                            {
                                                                resultModel.skills.key_skills.map((item) => {

                                                                    return (
                                                                        <div className="resume-checker-result-tag-active-skill">
                                                                            <span className="my-auto">{item}</span></div>
                                                                    )
                                                                })
                                                            }

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">
                                                        <span className="resume-checker-form-bold-extra-red">{resultModel.skills.opportunity_skills.length} </span>{translate.resume_checker.suggest_7}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <div className="resume-checker-result-tag-active-skill-control">
                                                            {
                                                                resultModel.skills.opportunity_skills.map((item) => {

                                                                    return (
                                                                        <div className="resume-checker-result-tag-red-skill">
                                                                            <span className="my-auto">{item}</span></div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">{resultModel.skills.unused_skills.length}{translate.resume_checker.suggest_8}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <div className="resume-checker-result-tag-active-skill-control">
                                                            {
                                                                resultModel.skills.unused_skills.map((item) => {

                                                                    return (
                                                                        <div className="resume-checker-result-tag-other-skill">
                                                                            <span className="my-auto">{item}</span></div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="resume-checker-result-card-control">
                                                <span className="resume-checker-result-card-header">{translate.resume_checker.suggest_9}</span>
                                                <span className="resume-checker-result-card-sub-header"></span>

                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">{translate.resume_checker.suggest_10}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <span className="resume-checker-result-item-value-header">
                                                        {translate.resume_checker.suggest_11}
                                                        </span>
                                                        <div className="resume-checker-result-item-value-check-control">
                                                            <FontAwesomeIcon
                                                                icon={resultModel.data.phone.is_passed ? faCircleCheck : faCircleXmark}
                                                                className={resultModel.data.phone.is_passed ? "resume-checker-result-item-value-icon" : "resume-checker-result-item-value-icon-red"}
                                                            ></FontAwesomeIcon>
                                                            {
                                                                resultModel.data.phone.is_passed &&
                                                                <span className="resume-checker-result-item-value-bold">{resultModel.data.phone.value}</span>
                                                            }
                                                        </div>
                                                        <span className="resume-checker-result-item-value-desc">{resultModel.data.phone.description}</span>

                                                    </div>
                                                </div>
                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">{translate.resume_checker.email}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <span className="resume-checker-result-item-value-header">
                                                        {translate.resume_checker.suggest_12}
                                                        </span>
                                                        <div className="resume-checker-result-item-value-check-control">
                                                            <FontAwesomeIcon
                                                                icon={resultModel.data.email.is_passed ? faCircleCheck : faCircleXmark}
                                                                className={resultModel.data.email.is_passed ? "resume-checker-result-item-value-icon" : "resume-checker-result-item-value-icon-red"}
                                                            ></FontAwesomeIcon>
                                                            {
                                                                resultModel.data.email.is_passed &&
                                                                <span className="resume-checker-result-item-value-bold">{resultModel.data.email.value}</span>
                                                            }

                                                        </div>
                                                        <span className="resume-checker-result-item-value-desc">{resultModel.data.email.description}</span>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="resume-checker-result-card-control">
                                                <span className="resume-checker-result-card-header">{translate.resume_checker.suggest_13}</span>
                                                <span className="resume-checker-result-card-sub-header"></span>

                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">{translate.resume_checker.suggest_14}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <div className="resume-checker-result-item-value-check-document-control">
                                                            <FontAwesomeIcon
                                                                icon={resultModel.document.word_count.is_passed ? faCircleCheck : faCircleXmark}
                                                                className={resultModel.document.word_count.is_passed ? "resume-checker-result-item-value-icon" : "resume-checker-result-item-value-icon-red"}
                                                            ></FontAwesomeIcon>
                                                            {
                                                                resultModel.document.word_count.is_passed &&
                                                                <span className="resume-checker-result-item-value-bold">{translate.resume_checker.suggest_15} {resultModel.document.word_count.value} คำ</span>
                                                            }
                                                        </div>
                                                        <span className="resume-checker-result-item-value-desc">{resultModel.document.word_count.description}</span>
                                                    </div>
                                                </div>
                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">{translate.resume_checker.suggest_16}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <div className="resume-checker-result-item-value-check-document-control">
                                                            <FontAwesomeIcon
                                                                icon={resultModel.document.file_extension.is_passed ? faCircleCheck : faCircleXmark}
                                                                className={resultModel.document.file_extension.is_passed ? "resume-checker-result-item-value-icon" : "resume-checker-result-item-value-icon-red"}
                                                            ></FontAwesomeIcon>
                                                            <span className="resume-checker-result-item-value-bold">{translate.resume_checker.suggest_17} {resultModel.document.file_extension.value}</span>
                                                        </div>
                                                        <span className="resume-checker-result-item-value-desc">{resultModel.document.file_extension.description}</span>
                                                    </div>
                                                </div>
                                                <div className="resume-checker-result-card-grid-item-wide">
                                                    <span className="resume-checker-result-card-title">{translate.resume_checker.suggest_16}</span>
                                                    <div className="resume-checker-result-item-value-control">
                                                        <div className="resume-checker-result-item-value-check-document-control">
                                                            <FontAwesomeIcon
                                                                icon={resultModel.document.file_name.is_passed ? faCircleCheck : faCircleXmark}
                                                                className={resultModel.document.file_name.is_passed ? "resume-checker-result-item-value-icon" : "resume-checker-result-item-value-icon-red"}
                                                            ></FontAwesomeIcon>
                                                            {
                                                                resultModel.document.word_count.is_passed &&
                                                                <span className="resume-checker-result-item-value-bold">{translate.resume_checker.suggest_17} {resultModel.document.file_name.value}</span>
                                                            }
                                                        </div>
                                                        <span className="resume-checker-result-item-value-desc">{resultModel.document.file_name.description}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                isUploadResume &&
                <UploadResumeModal open={isUploadResume} handleClose={handleUploadResume} setKey={setKey}></UploadResumeModal>
            }
        </>
    )
}

export default ResumeCheckerComponent;