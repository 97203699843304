import "./personality-test-result.scss";
import PERSONALITY_JSON from "../../../assets/json/personality/result-personality.json";
import { useContext, useEffect, useState } from "react";
import { ResultPersonalityModel, TextResult, Trait } from "../../../model/personality/result-personality.model";
import { CircularProgress, Divider } from "@mui/material";
import { ProvideContext } from "../../../context/provider.context";
import { TranslateModel } from "../../../model/translate.model";
import { Button } from "react-bootstrap";
import { ProfileModel } from "../../../model/profile.model";
import RamaService from "../../../services/sso/rama.service";
import { ProfileExtModel } from "../../../model/profile/profile-ext.model";
import ProfileService from "../../../services/profile/profile.service";
import { useNavigate } from "react-router-dom";
import navigateConfig from "../../../navigate.config";
import PersonalityTestService from "../../../services/personality-test/personality-test.service";
import RadarChartComponent from "../../profile/personality/radar-graph";
import MenuComponent from "../../menu/menu";
import ProfileInstantService from "../../../services/profile/profile-instance.service";
import { useProfile } from "../../../context/profile.context";
import UtilityService from "../../../utility/utility.service";


const PersonalityResultComponent = () => {

    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [resultPersonality, setResultPersonality] = useState<ResultPersonalityModel>();
    const [isStartTest, setIsStartTest] = useState(false);
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [profileModExt, setProfileModExt] = useState<ProfileExtModel>();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [profile, error] = useProfile();
    useEffect(() => {
        if (profileModExt) {
            if (profileModExt.personality_answer_id) {
                setIsLoading(true);
                PersonalityTestService().getResult(profileModExt.personality_answer_id).then((res) => {
                    if (res) {
                        UtilityService().clickSendEvent("personality_test_get_result_complete", "submit");
                        setIsLoading(false);
                        setResultPersonality(res);
                    }
                })
            }
        } 
    }, [profileModExt])

    useEffect(() => {

        if(profile){
            setProfileMod(profile)
            ProfileInstantService.getProfile().then((res) => {
                if (res.data) {
                    const profileExt = res.data as ProfileExtModel;
                    setProfileModExt(profileExt);
                }
            })
        }
    },[profile])



    const clickStartTest = () => {
        navigate(navigateConfig.personality.main)
    }


    return (
        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="personality-result">
                <div className="personality-test-result-header-control">
                    <span className="personality-test-result-header">{translate.personality.title}</span>
                </div>
                {
                    resultPersonality ?
                        <>
                            <RadarChartComponent resultPersonality={resultPersonality}></RadarChartComponent>
                            <span className="personality-type-text">{resultPersonality.results.mbti_results.type}</span>
                            <Divider textAlign="center" className="personality-divider-with-text">รายละเอียด</Divider>
                            {
                                resultPersonality.results.text_results.map((item: TextResult, index: number) => {

                                    return (

                                        <div className="personality-detail-control" key={index}>
                                            <span className="personality-detail-title">
                                                {item.big_5_trait == 'O' && item.big_5_trait + " (" + translate.personality.O + ")"}
                                                {item.big_5_trait == 'C' && item.big_5_trait + " (" + translate.personality.C + ")"}
                                                {item.big_5_trait == 'E' && item.big_5_trait + " (" + translate.personality.E + ")"}
                                                {item.big_5_trait == 'A' && item.big_5_trait + " (" + translate.personality.A + ")"}
                                                {item.big_5_trait == 'N' && item.big_5_trait + " (" + translate.personality.N + ")"}

                                                :</span>
                                            <span className="personality-detail-desc">{(item.big_5_user_score * 100).toFixed(0) + '%'}</span>
                                        </div>
                                    )
                                })
                            }

                            <Divider textAlign="center" className="personality-divider-with-text">Traits</Divider>
                            <div className="personality-trait-control">
                                {
                                    resultPersonality.results.mbti_results.traits.map((item: Trait, index: number) => {

                                        return (
                                            <div className="personality-trait-item-control" key={index}>
                                                <img src={item.image_url} className="personality-trait-item-img"></img>
                                                <span className="personality-trait-item-text">{item.name}</span>
                                            </div>
                                        )
                                    })
                                }

                            </div>

                            <Button className="personality-test-again-button" onClick={clickStartTest}>ทำแบบทดสอบใหม่</Button>
                        </> :
                        isLoading ?
                            <>
                                <div className="personality-loading-control">
                                    <div className="personality-loading-main">
                                        <CircularProgress className="personality-loading-progress"></CircularProgress>
                                        <span className="personality-loading-text">กำลังโหลดข้อมูล</span>
                                    </div>

                                </div>
                            </> :
                            <div className="personality-add-control">
                                <div className="personality-add-main-control">
                                    <span className="personality-add-main-title">ยังไม่มีผลแบบทดสอบแบบคลิกภาพ</span>
                                    <Button className="personality-add-button" onClick={clickStartTest}>เริ่มทำแบบทดสอบ</Button>
                                </div>
                            </div>


                }


            </div>
        </div>
    )
}

export default PersonalityResultComponent;