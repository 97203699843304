import apiPath from "../api-path";
import config from "../config";
import instanceGPT from "./auth-gpt.service";
import instanceResume from "./auth-resume.service";



const uploadResumeOneProfile = (fileUpload, filename) => {

    let headers = {
        headers: {
            "x-api-key": config.resume_builder.upload_resume.x_api_key
        }
    }
    const formData = new FormData();
    formData.append("resume", fileUpload, filename);
    formData.append("type", "one_profile");
    return instanceResume.post("/resume/upload", formData, headers)
}

const uploadResumeBuilder = (fileUpload, filename) => {

    let headers = {
        headers: {
            "x-api-key": config.resume_builder.upload_resume.x_api_key
        }
    }
    const formData = new FormData();
    formData.append("resume", fileUpload, filename);
    formData.append("type", "resume_builder");
    return instanceResume.post("/resume/upload", formData, headers)
}



const ResumeService = {
    uploadResumeOneProfile,
    uploadResumeBuilder
};

export default ResumeService;