
import { Button } from "react-bootstrap";
import "./interview.scss";
import MenuComponent from "../menu/menu";
import { Autocomplete, Box, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ProvideContext } from "../../context/provider.context";
import { TranslateModel } from "../../model/translate.model";
import { Experience, ProfileModel } from "../../model/profile.model";
import RamaService from "../../services/sso/rama.service";
import { PostInterviewAI } from "../../model/interview-ai/post-interview.model";
import { AllResumeModel } from "../../model/all-resume.model";
import { GetResumeIdModel } from "../../model/get-resume.model";
import UploadResumeModal from "../../modal/jobs/upload-resume/upload-resume.modal";
import IframeQuestion from "./iframe-question/iframe-question";
import { JobRole, JobRoleSuggestionModel } from "../../model/job-role-suggestion.model";
import JOB_ROLE_JSON from "../../assets/json/job-role-suggestion.json";
import EXP_JSON from "../../assets/json/exp-suggestion.json";
import { useNavigate } from "react-router-dom";
import navigateConfig from "../../navigate.config";
import GPTService from "../../services/gpt.service";
import { useProfile } from "../../context/profile.context";
import BG_CIRCLE_MAIN from "../../assets/images/circle-bg-main.svg";
const InterviewComponent = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate] = useState<TranslateModel>(translateGlobal);
    const [activeStep, setActiveStep] = useState(0);
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [inputInterview, setInputInterview] = useState<PostInterviewAI>();
    const [allResume, setAllResume] = useState<AllResumeModel>();
    const [isUploadResume, setIsUploadResume] = useState(false);
    const [isNewJob, setIsNewJob] = useState("");
    const [flattenedRoles, setFlattenedRoles] = useState(new Array());
    const [salary, setSalary] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [resumeIndex, setResumeIndex] = useState(99);
    const [isSubmit, setIsSubmit] = useState(false);
    const navigate = useNavigate();
    const [profile, error] = useProfile();
    const steps = [
        translate.interview_ai.step_1,
        translate.interview_ai.step_2,
        translate.interview_ai.step_3,
    ];

    useEffect(() => {
        if (profile) {
            setProfileMod(profile);
        }
    }, [profile])

    useEffect(() => {

        if (!profileMod) {
            const inputInitial: PostInterviewAI = {
                job_description: "",
                job_experience: 0,
                job_salary: 0,
                job_title: "",
                question_amount: 5,
                question_language: "ENGLISH",
                resume_key: "",
                resume_name: "",
                resume_uuid: ""
            }
            setInputInterview(inputInitial);
            getResumeList();
        }

    }, [profileMod])

    const onClickStep = (value) => {

        if (value == 2) {

        } else if (value == 1) {
            if (inputInterview && inputInterview.resume_key && inputInterview.job_title && inputInterview.job_salary) {
                setActiveStep(1);
            }
        }
        else {
            setActiveStep(value);
        }

    }


    const onSelectChange = (action, index) => {
        if (profileMod) {
            if (action == "exp") {
                const experience = profileMod.experiences[index] as Experience;
                let exp_year = 0;
                const endDate = profileMod.experiences[index].endDate + ""
                if (profileMod.experiences[index].stillWorkHere) {
                    exp_year = calculateExperience(new Date(profileMod.experiences[index].startDate), new Date())
                } else if (profileMod.experiences[index].endDate != undefined) {
                    calculateExperience(new Date(profileMod.experiences[index].startDate), new Date(endDate))
                }
                const postItem: PostInterviewAI = {
                    job_title: experience.jobTitle,
                    job_experience: exp_year,
                    job_salary: experience.salary,
                    question_amount: 5,
                    question_language: "ENGLISH"
                }
                JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {

                    item.job_roles.filter(items => items.job_title == experience.jobTitle).map((itemDesc: JobRole) => {
                        postItem.job_description = itemDesc.job_description;
                    })
                })
                setInputInterview(postItem);

            } else if (action == "resume") {
                if (allResume) {
                    const inputInterviewEdit = { ...inputInterview };
                    inputInterviewEdit.resume_uuid = allResume.resumes[index].resume_uuid;
                    inputInterviewEdit.resume_name = allResume.resumes[index].resume_name;
                    inputInterviewEdit.resume_key = allResume.resumes[index].resume_key;
                    setInputInterview(inputInterviewEdit)
                    setResumeIndex(index);
                }

            } else if (action == "jobTitle") {
                const inputMod = { ...inputInterview };
                inputMod.job_title = index;
                JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {

                    item.job_roles.filter(items => items.job_title == index).map((itemDesc: JobRole) => {
                        inputMod.job_description = itemDesc.job_description;
                    })
                })
                setInputInterview(inputMod)
            }
        }
    }
    function calculateExperience(startDate: Date, endDate: Date): number {
        let years = endDate.getFullYear() - startDate.getFullYear();
        let months = endDate.getMonth() - startDate.getMonth();
        let days = endDate.getDate() - startDate.getDate();

        if (days < 0) {
            months -= 1;
            days += new Date(endDate.getFullYear(), endDate.getMonth(), 0).getDate();
        }

        if (months < 0) {
            years -= 1;
            months += 12;
        }
        return years;
    }


    const radioCheck = (value) => {
        setIsNewJob(value);
        if (value == "new_job") {
            getRoleOptions();
        }

        const inputInitial: PostInterviewAI = {
            job_description: "",
            job_experience: 0,
            job_salary: 0,
            job_title: "",
            question_amount: 5,
            question_language: "ENGLISH",
            resume_key: "",
            resume_name: "",
            resume_uuid: ""
        }
        setInputInterview(inputInitial);
    }

    const getResumeList = () => {
        GPTService.getAllResume().then((res) => {

            if (res) {
                const result = res.data as AllResumeModel;
                const filterOut = result.resumes.filter(item => item.resume_key)
                result.resumes = filterOut
                if (filterOut.length > 0) {
                    setAllResume(result);
                }

            }

        }).catch((err) => {

            if (err.response.status == 401) {
                if (profile) {
                    getResumeList();
                }
            }
        })
    }

    const handleUploadResume = () => {
        setIsUploadResume(false);
        getResumeList();
    }

    const clickUploadNewResume = () => {
        setIsUploadResume(true);
    }

    const submit = () => {
        setIsSubmit(true);
        if (inputInterview && inputInterview.resume_key && inputInterview.job_title && inputInterview.job_salary) {
            setActiveStep(1);
        }


    }

    function getRoleOptions() {
        let flatItem = new Array();
        JOB_ROLE_JSON.map((item: JobRoleSuggestionModel) => {

            const jobPush = {
                label: item.category,
                options: new Array()
            }
            let strgArray = new Array();
            item.job_roles.map((items: JobRole) => {

                if (items.job_title) {
                    strgArray.push(items.job_title);
                }

            })
            jobPush.options = strgArray;
            flatItem.push(jobPush);

        })
        setFlattenedRoles(flatItem)
    }

    const onSelectChangeNewJob = (key, value) => {
        const inputMod = { ...inputInterview };
        if (key == "exp") {

            EXP_JSON.filter(item => item.value == value).map((item => {

                inputMod.job_experience = item.value
            }))
            setInputInterview(inputMod);

        }

    }
    const onTextChange = (key, value) => {
        const inputMod = { ...inputInterview };
        if (key == "salary") {
            const salaryRegex = /^(?:[0-9]|[1-9][0-9]{1,5})$/;
            const normalizedSalary = value.replace(/,/g, '');
            // Validate salary
            if (salaryRegex.test(normalizedSalary) || normalizedSalary === '') {
                setIsValid(true);
                inputMod.job_salary = value;
            } else {
                setIsValid(false);
            }
            setSalary(value)
        }
        setInputInterview(inputMod);
    }

    const clickHistory = () => {
        navigate(navigateConfig.jobs.interview_history);
    }
    return (

        <div className="main-control">
            <MenuComponent></MenuComponent>
            <div className="interview-main">
            <img src={BG_CIRCLE_MAIN} className="bg-circle-main"></img>
                <div className="interview-main-control">
                    <div className="interview-main-header-control">
                        <span className="interview-main-header">{translate.interview_ai.title}</span>
                        <Button className="interview-history-button" onClick={clickHistory}> {translate.interview_ai.history_title}</Button>

                    </div>

                    <div className="interview-form-control">

                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label, index) => (
                                    <Step onClick={() => onClickStep(index)} key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>

                        {
                            activeStep == 0 && profileMod &&

                            <div className="interview-form-grid">
                                <div className="interview-form-left">
                                    <span className="interview-form-input-header">1. {translate.interview_ai.info_step_1}</span>
                                    <FormControl className="interview-form-input">
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            onChange={(e) => radioCheck(e.target.value)}
                                        >
                                            <FormControlLabel value="exp" control={<Radio />} label={translate.interview_ai.info_step_1_1} />
                                            <FormControlLabel value="new_job" control={<Radio />} label={translate.interview_ai.info_step_1_2} />
                                        </RadioGroup>
                                    </FormControl>
                                    {
                                        isNewJob == "new_job" ?

                                            <div className="interview-form-input">
                                                <div className="interview-form-input-item-control">
                                                    <InputLabel className="interview-form-input-title">{translate.interview_ai.job_title}</InputLabel>
                                                    <Autocomplete
                                                        options={flattenedRoles.map(group => group.options).flat()}
                                                        groupBy={(option) =>
                                                            flattenedRoles.find(group => group.options.includes(option)).label
                                                        }
                                                        className="education-item-input"
                                                        onInputChange={(event, newValue) => {
                                                            onSelectChange("jobTitle", newValue);
                                                            // Do something with the selected name
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                                    />
                                                </div>
                                                <div className="interview-form-input-item-control">
                                                    <InputLabel className="interview-form-input-title">{translate.interview_ai.job_experience}</InputLabel>
                                                    <Select
                                                        onChange={(e) => onSelectChangeNewJob("exp", e.target.value)}
                                                        className="education-item-input"
                                                    >
                                                        {
                                                            EXP_JSON.map((item: any, index: number) => {

                                                                return (
                                                                    index == 0 ?
                                                                        <MenuItem value={item.value} key={index} selected>{item.name}</MenuItem> :
                                                                        <MenuItem value={item.value} key={index}>{item.name}</MenuItem>

                                                                )
                                                            })
                                                        }

                                                    </Select>
                                                </div>
                                                <div className="interview-form-input-item-control">
                                                    <InputLabel className="interview-form-input-title">{translate.interview_ai.expected_salary}</InputLabel>
                                                    <TextField
                                                        value={salary}
                                                        onChange={(e) => onTextChange("salary", e.target.value)}
                                                        type="number"
                                                        style={{ borderColor: isValid ? '' : 'red' }}
                                                    ></TextField>
                                                    {
                                                        !isValid && <span className="interview-form-input-error">{
                                                            translate.error.input_salary}</span>
                                                    }
                                                    {
                                                        isSubmit && inputInterview && inputInterview.job_salary == 0 &&
                                                        <span className="interview-form-input-error">{
                                                            translate.error.input_salary}</span>
                                                    }
                                                </div>
                                            </div> : isNewJob == "exp" &&
                                            profileMod.experiences && profileMod.experiences.length > 0 &&
                                            <FormControl className="interview-form-input">
                                                <InputLabel id="demo-simple-select-label">{translate.error.choose_experience}</InputLabel>
                                                <Select
                                                    label={translate.error.choose_experience}
                                                    onChange={(e) => onSelectChange("exp", e.target.value)}
                                                >
                                                    {
                                                        profileMod.experiences.map((item: Experience, index: number) => {

                                                            return (
                                                                <MenuItem value={index} key={index}>{item.jobTitle}</MenuItem>
                                                            )
                                                        })
                                                    }



                                                </Select>
                                            </FormControl>


                                    }


                                    <div className="interview-form-input-control">
                                        <span className="interview-form-input-header">2. {translate.interview_ai.info_step_2}</span>
                                        {
                                            inputInterview && allResume && allResume.resumes.length > 0 ?
                                                <FormControl className="interview-form-input">
                                                    <InputLabel id="demo-simple-select-label">{translate.interview_ai.info_step_2}</InputLabel>
                                                    <Select
                                                        label={translate.interview_ai.info_step_2}
                                                        value={resumeIndex}
                                                        defaultValue={resumeIndex}
                                                        variant="outlined"
                                                        onChange={(e) => onSelectChange("resume", e.target.value)}
                                                    >
                                                        <MenuItem value={99} >{translate.interview_ai.info_step_2}</MenuItem>
                                                        {
                                                            allResume.resumes.map((item: GetResumeIdModel, index: number) => {

                                                                return (
                                                                    <MenuItem value={index} key={index}>{item.resume_name}</MenuItem>
                                                                )
                                                            })
                                                        }



                                                    </Select>
                                                    {
                                                        isSubmit && inputInterview.resume_key == "" &&
                                                        <span className="interview-form-input-error">{translate.interview_ai.info_step_2}</span>
                                                    }
                                                </FormControl> :
                                                allResume && allResume.resumes.length == 0 ?
                                                    <div className="interview-input-resume-no-resume-control">
                                                        <span className="interview-input-resume-no-resume-text">{translate.error.resume_not_found}</span>
                                                        <Button className="interview-input-resume-upload-new-button"
                                                            onClick={clickUploadNewResume}>{translate.interview_ai.info_step_2_upload_resume}</Button>
                                                    </div> : <></>
                                        }
                                        <div className="interview-input-resume-no-resume-control">
                                            <Divider textAlign="center" className="interview-input-resume-no-resume-or-text">{translate.other.or}</Divider>
                                            <Button className="interview-input-resume-upload-new-button"
                                                onClick={clickUploadNewResume}>{translate.interview_ai.info_step_2_upload_resume}</Button>
                                        </div>
                                    </div>
                                </div>
                                <Divider className="interview-divider"></Divider>
                                <div className="interview-form-right">

                                    {
                                        inputInterview && inputInterview.job_title &&

                                        <>
                                            <span className="interview-form-input-header">{translate.interview_ai.confirm_info}</span>
                                            <div className="interview-input-confirm-control">
                                                <div className="interview-input-confirm-main">
                                                    <span className="interview-input-confirm-main-title">
                                                        {translate.interview_ai.job_title}:
                                                    </span>
                                                    <span className="interview-input-confirm-main-desc">
                                                        {inputInterview.job_title}
                                                    </span>
                                                </div>
                                                {
                                                    inputInterview.job_description &&
                                                    <div className="interview-input-confirm-main">
                                                        <span className="interview-input-confirm-main-title">
                                                            {translate.interview_ai.job_description}:
                                                        </span>
                                                        <span className="interview-input-confirm-main-desc">
                                                            {inputInterview.job_description}
                                                        </span>
                                                    </div>
                                                }

                                                <div className="interview-input-confirm-main">
                                                    <span className="interview-input-confirm-main-title">
                                                        {translate.interview_ai.job_experience}:
                                                    </span>
                                                    <span className="interview-input-confirm-main-desc">
                                                        {inputInterview.job_experience}
                                                    </span>
                                                </div>
                                                {
                                                    (Number(inputInterview.job_salary) > 0) &&
                                                    <div className="interview-input-confirm-main">
                                                        <span className="interview-input-confirm-main-title">
                                                            {translate.interview_ai.job_salary}:
                                                        </span>
                                                        <span className="interview-input-confirm-main-desc">
                                                            {Number(inputInterview.job_salary).toLocaleString()} {translate.profile.baht}
                                                        </span>
                                                    </div>
                                                }

                                                <div className="interview-input-confirm-main">
                                                    <span className="interview-input-confirm-main-title">
                                                        {translate.interview_ai.question_language}:
                                                    </span>
                                                    <span className="interview-input-confirm-main-desc">
                                                        {inputInterview.question_language}
                                                    </span>
                                                </div>
                                                {
                                                    inputInterview.resume_name &&

                                                    <div className="interview-input-confirm-main">
                                                        <span className="interview-input-confirm-main-title">
                                                            {translate.interview_ai.resume_name}:
                                                        </span>
                                                        <span className="interview-input-confirm-main-desc">
                                                            {inputInterview.resume_name}
                                                        </span>
                                                    </div>
                                                }

                                                <Button className="interview-input-confirm-button"
                                                    onClick={submit}>Confirm</Button>

                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                        {
                            (activeStep == 1 || activeStep == 2) &&
                            <IframeQuestion inputInterview={inputInterview} setActive={setActiveStep} activeStep={activeStep}></IframeQuestion>
                        }

                    </div>

                </div>

            </div>

            {
                isUploadResume &&
                <UploadResumeModal open={isUploadResume} handleClose={handleUploadResume}></UploadResumeModal>
            }
        </div>
    )
}

export default InterviewComponent;