
import { useContext, useEffect, useState } from "react";
import "./cover-letter.scss";
import { ResumeTemplateModel } from "../../../model/resume-builder/resume-template.model";
import { Experience, ProfileModel } from "../../../model/profile.model";
import { Button, Form } from "react-bootstrap";
import { CoverLetterInputModel } from "../../../model/resume-builder/cover-letter-input.model";
import { CircularProgress, TextareaAutosize } from "@mui/material";
import { InputResumeBuilderModel } from "../../../model/resume-builder/input-resume-builder.model";
import { TranslateModel } from "../../../model/translate.model";
import { ProvideContext } from "../../../context/provider.context";
import RamaService from "../../../services/sso/rama.service";
import ResumeBuilderInstantService from "../../../services/resume-builder/resume-builder-instance.service";
import { useProfile } from "../../../context/profile.context";
import Lottie from "lottie-react";
import AI_LOADING from "../../../assets/json/ai-loading.json";

const CoverLetter = ({ setCoverLetterUpdate, templ, profile, setEditCoverLetter }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [inputTemplateModel, setInputTemplateModel] = useState<InputResumeBuilderModel>(templ);
    const [profileMod, setProfileMod] = useState<ProfileModel>(profile);
    const [coverLetterInput, setCoverLetterInput] = useState<CoverLetterInputModel>();
    const [resultStr, setResultStr] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {


        if (inputTemplateModel.cover_letter && inputTemplateModel.cover_letter.value) {
            setResultStr(inputTemplateModel.cover_letter.value)
        } else if (inputTemplateModel && !resultStr) {
            getCoverLetter();
        }

    }, [])
    function removeHtmlTags(input) {
        if (typeof input !== 'string') {
            return input; // Return input as is if it's not a string
        }
        return input.replace(/<[^>]*>/g, '');
    }


    const getCoverLetter = () => {
        if (inputTemplateModel) {
            setIsLoading(true);
            const coverLetMod: CoverLetterInputModel = {
                education: removeHtmlTags(inputTemplateModel.education!.value),
                experience: removeHtmlTags(inputTemplateModel.experience!.value),
                position: inputTemplateModel.position!.value
            }
            ResumeBuilderInstantService.createCoverLetter(coverLetMod).then((res) => {
                if (res.data) {
                    setIsLoading(false);
                    setResultStr(res.data.cover_letter);
                }else if(res.status != 200){
                    setIsLoading(false);
                    setResultStr("");
                }
            }).catch((err) => {
                if (err.response.status == 401) {
                    const [callProfile, error] = useProfile();

                    if (callProfile) {
                        setProfileMod(callProfile);
                        getCoverLetter();
                    }

                }else{
                    setIsLoading(false);
                    setResultStr("");
                }
            })
        }

    }
    const onTextChange = (value) => {
        setResultStr(value);
    }

    const submit = () => {
        setCoverLetterUpdate(resultStr);
    }
    return (

        <>
            <div className="cover-letter-main">
                <div className="cover-letter-control">
                    <span className="cover-letter-text-title">{translate.resume_builder.cover_letter}</span>
                    <div className="cover-letter-form-control">

                        {
                            !isLoading ?

                                <>
                                    <TextareaAutosize
                                        className="cover-letter-form-input"
                                        minRows={5}
                                        value={resultStr}
                                        onChange={(e) => onTextChange(e.target.value)} />

                                    <Button className="cover-letter-save-button" onClick={submit}>{translate.other.save}</Button>
                                    <Button className="cover-letter-cancel-button " onClick={() => setEditCoverLetter(false)}>{translate.other.cancel}</Button>
                                </>

                                : <div className="cover-letter-loading-control">
                                    <div className="cover-letter-loading-main">
                                    <Lottie animationData={AI_LOADING} loop={true} className="cover-letter-loading-progress"/>
                                        {/* <CircularProgress className="cover-letter-loading-progress"></CircularProgress> */}
                                        <span className="cover-letter-loading-text">{translate.resume_builder.loading_cover_letter}</span>
                                    </div>
                                </div>
                        }


                    </div>


                </div>


            </div>
        </>
    )
}

export default CoverLetter;