import { useLocation, useNavigate } from "react-router";
import "./iframe-question.scss";
import { useContext, useEffect, useRef, useState } from "react";
import config from "../../../config";
import { ProfileModel } from "../../../model/profile.model";
import tokenService from "../../../services/sso/token.service";
import { PostInterviewAI } from "../../../model/interview-ai/post-interview.model";
import ResultInterviewModel from "../../../model/interview-ai/result-interview.model";
import RamaService from "../../../services/sso/rama.service";
import { Button } from "react-bootstrap";
import { useProfile } from "../../../context/profile.context";
import UtilityService from "../../../utility/utility.service";

const IframeQuestion = ({ inputInterview, setActive, activeStep }) => {
    const [urlIframe, setUrlIframe] = useState("");
    const [candidateID, setCandidateID] = useState("");
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [inputInterviewMod, setInputInterviewMod] = useState<PostInterviewAI>(inputInterview);
    const [profile, error] = useProfile();
    useEffect(() => {

        if (profileMod && inputInterviewMod) {
            if (tokenService.getUser()) {
                const queryString = JSON.stringify(inputInterviewMod);
                const params = new URLSearchParams()
                params.append('keyToken', tokenService.getLocalAccessToken());
                params.append('isHeader', 'false');
                params.append('timeStamp', (new Date().toISOString()));
                params.append('input', queryString)
                const url = config.iframe.interivew_ai + "/ext-loading" + "?" + new URLSearchParams(params)
                setUrlIframe(url);
                UtilityService().clickSendEvent("interview_ai_start_iframe", "load");
            }
        } else {
            setInputInterviewMod(inputInterview)
        }

    }, [profileMod]);

    useEffect(() => {
        if (profile) {
            setProfileMod(profile);
        }
    },[profile])

  
    const iframeRef = useRef(null);

    // Function to handle messages received from the iframe
    const handleMessage = (event) => {
        // Check if the message is from a trusted source (your iframe's origin)
        if (event.origin === config.iframe.interivew_ai) {
            // Access the URL data sent from the iframe
            const candidateId = event.data as ResultInterviewModel;
            if (candidateId && candidateId.is_complete) {
                setActive(2);
            }

        }
    };

    // Attach the message event listener when the component mounts
    useEffect(() => {
        window.addEventListener('message', handleMessage);

        // Cleanup the listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);


    return (

        <div className="iframe-question">
            {
                urlIframe && <iframe
                    src={urlIframe}
                    width="100%"
                    height="100%"
                    ref={iframeRef}
                ></iframe>
            }


        </div>
    )
}

export default IframeQuestion;