import { Modal } from "react-bootstrap";
import { HistoryInterviewModel } from "../../model/interview-ai/history-interview.model";
import { useContext, useState } from "react";
import "./interview-result.modal.scss";
import { Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { ProvideContext } from "../../context/provider.context";
import { TranslateModel } from "../../model/translate.model";

const InterviewResultModal = ({ open, handleClose, result }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [resultMod, setResult] = useState<HistoryInterviewModel>(result);

    return (

        <>
            <div className="interview-result-modal-main">
                <Modal show={open} onHide={handleClose} className="interview-result-modal-main" >

                    <Modal.Body>

                        {
                            resultMod &&

                            <div className="interview-result-main-control">
                                <div className="interview-result-header-control">
                                    <span className="interview-result-title">
                                        {translate.interview_ai.history_view_title}
                                    </span>
                                    <FontAwesomeIcon onClick={handleClose} icon={faClose} className="interview-result-close"></FontAwesomeIcon>
                                </div>

                                <Divider textAlign="left" className="interview-result-divider">
                                {translate.interview_ai.history_view_all_questions}
                                </Divider>
                                <div className="interview-result-flex-control">

                                    {
                                        resultMod.log_request.questions.map((item: string, index: number) => {

                                            return (
                                                <div key={index} className="interview-result-flex-item">
                                                    <span className="interview-result-flex-question" >
                                                        {index + 1}. {item}
                                                    </span>
                                                    <span className="interview-result-flex-answer" >
                                                        - {resultMod.log_request.answers[index]}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                                <Divider textAlign="left" className="interview-result-divider">
                                {translate.interview_ai.history_view_all_result}
                                </Divider>
                                <div className="interview-result-flex-control">
                                    <div className="d-flex flex-column">

                                        <span className="interview-result-flex-answer" >
                                            - {resultMod.log_response.result}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }


                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default InterviewResultModal;