import { Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";

import EXPERIENCE_LEVEL_JSON from "../../../assets/json/experience-type.json";
import SelectItemModal from "../../../model/select-item.model";
import { Experience, ProfileModel } from "../../../model/profile.model";
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import "./step-2.scss";
import RamaService from "../../../services/sso/rama.service";
import { ProvideContext } from "../../../context/provider.context";
import { TranslateModel } from "../../../model/translate.model";
import moment from "moment";
import { useProfile } from "../../../context/profile.context";
import MAG_IMAGE from "../../../assets/images/magnifying.png";
import CLOUD_IMAGE from "../../../assets/images/cloud.png";
import UtilityService from "../../../utility/utility.service";

const OnBoardStep2Component = ({ profileMod, updateProfileModel, clickMoveStep }: any) => {
    const [currentExperience, setCurrentExperience] = useState<Experience>();
    const [experienceLevel, setExperienceLevel] = useState<SelectItemModal>();
    const [isSaving, setIsSaving] = useState(false);
    const minDate = new Date().getFullYear() - 60 + "-01"
    const maxDate = new Date().getFullYear() + "-12";
    const [startDate, setStartDate] = useState<Dayjs>();
    const [endDate, setEndDate] = useState<Dayjs>();
    const [errorDate, setErrorDate] = useState(false);
    const [profile, error] = useProfile();
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [isLoading, setIsLoading] = useState(false);
    const textFieldRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (textFieldRef && textFieldRef.current) {
            const handleWheel = e => e.preventDefault();
            textFieldRef.current.addEventListener("wheel", handleWheel);

            return () => {
                if (textFieldRef && textFieldRef.current) {
                    textFieldRef.current.removeEventListener("wheel", handleWheel);
                }
            };
        }

    }, [textFieldRef]);


    useEffect(() => {
        if (profile) {
            if (profile.experiences.length == 0) {
                const addExperience: Experience = {
                    companyName: "",
                    employmentType: "",
                    jobTitle: "",
                    responsibilities: "",
                    is_new_grad: false,
                    salary: 0,
                    salaryCurrency: "THB",
                    startDate: new Date().toISOString(),
                    stillWorkHere: false,
                    endDate: ""
                }

                setCurrentExperience(addExperience)
            } else {
                const addExperience: Experience = {
                    companyName: profile.experiences[0].companyName,
                    employmentType: profile.experiences[0].employmentType,
                    jobTitle: profile.experiences[0].jobTitle,
                    responsibilities: profile.experiences[0].responsibilities,
                    is_new_grad: profile.experiences[0].is_new_grad,
                    salary: profile.experiences[0].salary,
                    salaryCurrency: "THB",
                    startDate: profile.experiences[0].startDate,
                    stillWorkHere: profile.experiences[0].stillWorkHere,
                    endDate: profile.experiences[0].endDate
                }
                if (addExperience.endDate) {
                    const converDayJS = dayjs(addExperience.endDate);
                    setEndDate(converDayJS);
                }

                if (addExperience.startDate) {
                    const converDayJS = dayjs(addExperience.startDate);
                    setStartDate(converDayJS);
                }

                setCurrentExperience(addExperience)
            }

        }
    }, [profile])

    const textChange = (text, key) => {

        let currentEditChange = { ...currentExperience } as Experience;
        if (currentEditChange && currentExperience) {

            if (key == 'employmentType') {
                currentEditChange.employmentType = text
                initExperienceLevel(text);
            } else if (key == 'companyName') {
                currentEditChange.companyName = text
            } else if (key == 'jobTitle') {
                currentEditChange.jobTitle = text
            } else if (key == 'salary') {
                const valueNumber = text.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                currentEditChange.salary = valueNumber
            } else if (key == 'responsibilities') {
                currentEditChange.responsibilities = text
            }


            if (currentExperience) {
                setCurrentExperience(currentEducation => ({
                    ...currentEditChange
                }));
            }

        }

    }
    const initExperienceLevel = (educationlv) => {
        let valueRet = "";
        EXPERIENCE_LEVEL_JSON.map((item: any, index: number) => {
            if (educationlv == item.value) {
                valueRet = item.name;
                setExperienceLevel(item)
            }
        })
    }
    const dateChange = (value, action) => {
        if (currentExperience) {
            let currentEditChange = { ...currentExperience } as Experience;
            if (currentEditChange) {
                if (action == 'end') {
                    currentEditChange.endDate = new Date(value).toISOString();
                    currentEditChange.stillWorkHere = false;
                    setEndDate(value);
                    setCurrentExperience(currentEducation => ({
                        ...currentEditChange
                    }));
                    if (startDate) {
                        if (new Date(value) < new Date(startDate.toDate())) {
                            setErrorDate(true);
                        } else {
                            setErrorDate(false);
                        }
                    }
                } else {
                    setStartDate(value);
                    currentEditChange.startDate = new Date(value.$d).toISOString();
                    if (endDate) {
                        if (new Date(endDate.toDate()) < new Date(value)) {
                            currentEditChange.endDate = "";
                            setErrorDate(true);
                        } else {
                            setErrorDate(false);
                        }
                    }

                    setCurrentExperience(currentEducation => ({
                        ...currentEditChange
                    }));
                }

            }
        }

    }
    const changeCurrentWork = (value) => {
        let currentEditChange = { ...currentExperience } as Experience;

        if (currentExperience) {
            currentEditChange.stillWorkHere = value;
            setCurrentExperience(currentEducation => ({
                ...currentEditChange
            }));
        }
    }
    const changeNewGraduate = (value) => {
        let currentEditChange = { ...currentExperience } as Experience;

        if (currentExperience) {
            currentEditChange.is_new_grad = value;
            setCurrentExperience(currentEducation => ({
                ...currentEditChange
            }));
        }
    }

    const submit = () => {

        if (currentExperience) {
            setIsSaving(true);
            setIsLoading(true);
            if (currentExperience.is_new_grad) {
                setIsLoading(false);
                updateProfileModel(profile);
                clickMoveStep(3);
            } else {
                if (profile && profile.experiences
                    && currentExperience.employmentType
                    && currentExperience.companyName
                    && currentExperience.jobTitle
                    && currentExperience.salary
                    && currentExperience.startDate
                    && (currentExperience.endDate || currentExperience.stillWorkHere)
                    && currentExperience.responsibilities
                ) {
                    const Exp: Experience[] = new Array();
                    Exp.push(currentExperience);
                    profile.experiences = Exp

                    RamaService.updateProfile(profile).then((res) => {

                        if (res) {
                            UtilityService().clickSendEvent("update_onboard_step_2_complete","submit");
                            setIsLoading(false);
                            updateProfileModel(profile);
                            clickMoveStep(3);
                        }
                    }).catch((err) => {
                        setIsLoading(false);
                    })
                } else {
                    setIsLoading(false);
                }
            }
        }

    }
    return (
        <>
            <div className="step-2-main form-input-main">
                {
                    currentExperience &&
                    <div className="step-2-main-form-control">
                        <img src={MAG_IMAGE} className="magnify-img"></img>
                        <img src={CLOUD_IMAGE} className="cloud-img"></img>
                        <span className="step-2-form-title">ประสบการณ์ของคุณเป็นยังไงบ้าง</span>
                        {
                            isLoading ?

                                <div className="onboard-loading-progress">
                                    <div className="onboard-loading-item">
                                        <CircularProgress className="onboard-progress"></CircularProgress>

                                        <span className="onboard-progress-text">กำลังบันทึกข้อมูล</span>
                                    </div>

                                </div> : <>
                                    <div className="step-2-form-grid">
                                        <div className="form-input-control">
                                            <FormControlLabel control={<Checkbox checked={currentExperience.is_new_grad}
                                                onChange={(e) => changeNewGraduate(e.target.checked)} />}
                                                className="experience-current-work-control" label="ยังไม่มีประสบการณ์ทำงาน" />
                                        </div>
                                        <div className="form-input-control">
                                        </div>
                                        <div className="form-input-control">
                                            <span className="form-title-text">ประเภทการจ้าง</span>
                                            <Form.Select className="form-input-custom"
                                                onChange={(e) => textChange(e.target.value, 'employmentType')}
                                                disabled={currentExperience.is_new_grad}
                                                value={currentExperience.employmentType}
                                                required
                                            >
                                                <option value="" className="education-menu-item">เลือก</option>
                                                {
                                                    EXPERIENCE_LEVEL_JSON && EXPERIENCE_LEVEL_JSON.map((item2: SelectItemModal, index2: number) => {
                                                        return (
                                                            <option value={item2.value} className="education-menu-item" key={index2}>{item2.name}</option>


                                                        )
                                                    })
                                                }

                                            </Form.Select>
                                            {
                                                (isSaving && !currentExperience.employmentType && !currentExperience.is_new_grad) &&
                                                <span className="form-error">{translate.error.input}</span>
                                            }

                                        </div>
                                        <div className="form-input-control">
                                            <span className="form-title-text">ชื่อบริษัท (Company Name)</span>
                                            <Form.Control className="form-input-custom"
                                                onChange={(e) => textChange(e.target.value, 'companyName')}
                                                disabled={currentExperience.is_new_grad}
                                                value={currentExperience.companyName}
                                                required
                                            >


                                            </Form.Control>
                                            {
                                                (isSaving && !currentExperience.companyName && !currentExperience.is_new_grad) &&
                                                <span className="form-error">{translate.error.input}</span>
                                            }

                                        </div>

                                        <div className="form-input-control">
                                            <span className="form-title-text">ชื่อตำแหน่ง (Job title)</span>
                                            <Form.Control className="form-input-custom"
                                                onChange={(e) => textChange(e.target.value, 'jobTitle')}
                                                disabled={currentExperience.is_new_grad}
                                                value={currentExperience.jobTitle}
                                                required
                                            >


                                            </Form.Control>
                                            {
                                                (isSaving && !currentExperience.jobTitle && !currentExperience.is_new_grad) &&
                                                <span className="form-error">{translate.error.input}</span>
                                            }

                                        </div>


                                        <div className="form-input-control">
                                            <span className="form-title-text">เงินเดือนล่าสุด (Salary)</span>
                                            <Form.Control className="form-input-custom" type="number" ref={textFieldRef}
                                                onChange={(e) => textChange(e.target.value, 'salary')}
                                                min={0}
                                                disabled={currentExperience.is_new_grad}
                                                value={currentExperience.salary}
                                                required
                                            >


                                            </Form.Control>
                                            {
                                                (isSaving && !currentExperience.salary && !currentExperience.is_new_grad) &&
                                                <span className="form-error">{translate.error.input}</span>
                                            }

                                        </div>
                                        <div className="form-input-control">
                                            <span className="form-title-text">ปีที่เริ่มงาน</span>
                                            <div className="form-input-custom">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                        <DatePicker views={['month', 'year']}
                                                            disabled={currentExperience.is_new_grad}
                                                            maxDate={dayjs()}
                                                            value={startDate}
                                                            onChange={(newValue) => dateChange(newValue, 'start')}
                                                        />

                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>

                                            {
                                                (isSaving && !currentExperience.startDate && !currentExperience.is_new_grad) &&
                                                <span className="form-error">{translate.error.input}</span>
                                            }

                                        </div>
                                        <div className="form-input-control">
                                            <span className="form-title-text">ปีสุดท้าย</span>
                                            <div className="form-input-custom">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                        <DatePicker views={['month', 'year']}
                                                            disabled={
                                                                currentExperience.stillWorkHere || currentExperience.is_new_grad
                                                            }
                                                            minDate={startDate && startDate}
                                                            maxDate={dayjs()}
                                                            value={endDate}
                                                            onChange={(newValue) => dateChange(newValue, 'end')}
                                                        />

                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>

                                            {
                                                (isSaving && !currentExperience.endDate && !currentExperience.is_new_grad) &&
                                                <span className="form-error">{translate.error.input}</span>
                                            }
                                            {
                                                errorDate &&
                                                <span className="form-error">{translate.error.input_date_end}</span>
                                            }

                                        </div>


                                        <div className="form-input-control">
                                            <FormControlLabel control={<Checkbox
                                                checked={currentExperience.stillWorkHere}
                                                disabled={currentExperience.is_new_grad}
                                                onChange={(e) => changeCurrentWork(e.target.checked)} />}
                                                className="experience-current-work-control" label={translate.profile.still_work_here} />
                                        </div>



                                    </div>
                                    <div className="form-input-control">
                                        <span className="form-title-text">หน้าที่ความรับผิดชอบ (Responsibilities)</span>
                                        <Form.Control as="textarea" className="form-input-custom-textarea" type="textarea"
                                            rows={5}
                                            disabled={currentExperience.is_new_grad}
                                            value={currentExperience.responsibilities}
                                            onChange={(e) => textChange(e.target.value, 'responsibilities')}
                                            required
                                        >


                                        </Form.Control>
                                        {
                                            (isSaving && !currentExperience.responsibilities && !currentExperience.is_new_grad) &&
                                            <span className="form-error">{translate.error.input}</span>
                                        }

                                    </div>
                                    <div className="step-2-button-control">
                                        <Button className="form-input-button-back" onClick={() => clickMoveStep(1)}>{translate.other.back}</Button>
                                        <Button className="form-input-button" onClick={() => submit()}>{translate.other.save}</Button>
                                    </div>

                                </>
                        }
                    </div>
                }
            </div>

        </>
    )
}

export default OnBoardStep2Component;