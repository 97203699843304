import { CircularProgress, FormControlLabel, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import MenuComponent from "../menu/menu"
import "./jobs.scss";
import { useContext, useEffect, useState } from "react";
import { ProvideContext } from "../../context/provider.context";
import RamaService from "../../services/sso/rama.service";
import { ProfileExtModel } from "../../model/profile/profile-ext.model";
import ErrorModal from "../../modal/error/error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faBookmark, faBriefcase, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as faBookmarkRegular, faClock, faBuilding } from "@fortawesome/free-regular-svg-icons";
import { ObjectValue, TranslateModel } from "../../model/translate.model";
import { Job, JobListModel } from "../../model/jobs/job-list.model";
import { Button, Row } from "react-bootstrap";
import navigateConfig from "../../navigate.config";
import { useNavigate } from "react-router-dom";
import { AppliedJob, ProfileModel } from "../../model/profile.model";
import AllCompanyComponent from "./all-company/company";
import ProfileInstantService from "../../services/profile/profile-instance.service";
import { useProfile } from "../../context/profile.context";
import BG_CIRCLE_MAIN from "../../assets/images/circle-bg-main.svg";
import config from "../../config";

const JobsComponent = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const { profileModel }: any = useContext(ProvideContext);
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [profileExt, setProfileExt] = useState<ProfileExtModel>();
    const [errorHandler, setErrorHandler] = useState(false);
    const [currentTab, setCurrentTab] = useState("one");
    const [profile,error] = useProfile();
   
    const navigate = useNavigate();


    useEffect(() => {
        if (profileMod) {
            getProfileExt();
        }
    }, [profileMod])

    // useEffect(() => {
    //     try{
    //         if(error){
    //             if(error.response.status != undefined){
    //                 if(error.response.status == 404){
    //                     authenticateUrl();
    //                 }  
    //             }
    //         }
    //     }catch{
    //         authenticateUrl();
    //     }
    // },[error])

    useEffect(() => {
        if (profile) {
            setProfileMod(profile)

            ProfileInstantService.getProfile().then((resExt) => {
                setProfileExt(resExt.data);
            })
        }

    },[profile])

    const onSwitchChange = (value) => {

        ProfileInstantService.updateLookingForJobs(value).then((res) => {
            if (res.data) {
                setProfileExt(res.data);
            }

        }).catch((err) => {
            setErrorHandler(true);
        })
    }

    const getProfileExt = () => {
        ProfileInstantService.getProfile().then((res) => {
            if (res.data) {
                setProfileExt(res.data);
            }
        })
    }

    const findJobs = () => {

        window.open(config.jobboard.url, "_blank");
    }
   
    function Row(props: { item: AppliedJob, index }) {
        const { item, index } = props;
        const [open, setOpen] = useState(false);

        return (
            <>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell align="right" width={'50px'} className="jobs-table-list-desc">{index + 1}</TableCell>
                    <TableCell align="right" className="jobs-table-list-desc">{item.jobName}</TableCell>
                    <TableCell align="right" className="jobs-table-list-desc">{item.companyName}</TableCell>
                    <TableCell align="right" className="jobs-table-list-desc">{item.appliedSource}</TableCell>
                    <TableCell align="right" className="jobs-table-list-desc">{new Date(item.appliedAt).toLocaleString()}</TableCell>

                </TableRow>

            </>
        );
    }
    return (
        <div className="main-control">
            <MenuComponent></MenuComponent>
            <img src={BG_CIRCLE_MAIN} className="bg-circle-main"></img>
            <div className="jobs-main">
         
                <div className="jobs-header-control">

                    <div className="jobs-header-left-control">
                    <span className="jobs-header">{translate.job.job_applied}</span>
                      
                    </div>
               
                    {
                        profileExt &&
                        <div className="jobs-header-looking-for-jobs-control">
                            <FormControlLabel control={<Switch defaultChecked={profileExt?.looking_for_jobs}

                                onChange={(e) => onSwitchChange(e.target.checked)} />} label={translate.job.looking_for_job} className="jobs-header-looking-for-jobs-switch" />
                        </div>
                    }

                </div>
                <Button className="jobs-header-find-work-button" onClick={findJobs}>{translate.job_apply.search_job}</Button>
                {/* <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    textColor="primary"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#112644"
                        }
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    className="setting-tab-custom"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <Tab value="one" label={translate.job.all_job} />
                    <Tab value="two" label={translate.job.job_applied} />
                    <Tab value="three" label={translate.job.all_companies} />
                    
                </Tabs> */}
                {

                    profileMod ?
                        <></> :
                        <div className="jobs-loading-main">
                            <div className="jobs-loading-control">
                                <FontAwesomeIcon icon={faBriefcase} className="jobs-loading-icon"></FontAwesomeIcon>
                                <span className="jobs-loading-text">{translate.other.loading}</span>
                            </div>
                        </div>
                }
                <div className="jobs-table-list-control">
                        {
                            profileMod && profileMod.appliedJobs.length > 0 ?
                            <TableContainer >
                                <Table className="jobs-table-list-header" aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right" style={{ width: 100 }} className="jobs-table-list-header">No.</TableCell>
                                            <TableCell align="right" className="jobs-table-list-header">Job Name</TableCell>
                                            <TableCell align="right" className="jobs-table-list-header">Company Name</TableCell>
                                            <TableCell align="right" className="jobs-table-list-header">Source</TableCell>
                                            <TableCell align="right" className="jobs-table-list-header">Applied At</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            profileMod.appliedJobs.map((item: AppliedJob, index: number) => {

                                                return (
                                                    <Row key={item.jobUuid} item={item} index={index} />
                                                )
                                            })
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer> : <div className="not-apply-job-control">
                                <div className="not-apply-job-flex">
                                <FontAwesomeIcon icon={faBriefcase} className="not-apply-job-img"></FontAwesomeIcon>
                                <span className="not-apply-job-text">{translate.job_apply.not_apply}</span>
                                    </div>
                                </div>

                        }
                    </div>
                {/* {
                    currentTab == "one" &&

                    <div className="jobs-card-list-control">
                        {
                            jobsList && jobsList.jobs.length > 0 &&

                            jobsList.jobs.map((item: Job, index: number) => {

                                return (
                                    <div className="jobs-card-item" key={index}>
                                        <div className="jobs-card-top-section-control">
                                            <div className="jobs-card-company-control">
                                                <div className="jobs-card-company-default">
                                                    {
                                                        item.company_logo_image_url ?
                                                            <img src={item.company_logo_image_url} className="jobs-card-company-default-img"></img>
                                                            : <FontAwesomeIcon icon={faBuilding} className="jobs-card-company-default-icon"></FontAwesomeIcon>

                                                    }

                                                </div>
                                            </div>
                                            <div className="jobs-card-content-control">
                                                <div className="jobs-card-share-section-control">
                                                    <FontAwesomeIcon icon={faArrowUpFromBracket} className="jobs-card-share-icon"></FontAwesomeIcon>
                                                    <FontAwesomeIcon icon={faBookmarkRegular} className="jobs-card-share-icon"></FontAwesomeIcon>
                                                </div>
                                                <div className="jobs-card-job-detail-control">
                                                    <div className="jobs-card-job-detail-left-control">
                                                        <span className="jobs-card-job-title">{item.job_name}</span>
                                                        <div className="jobs-card-criteria-control">
                                                            <div className="jobs-card-criteria-item">
                                                                <FontAwesomeIcon icon={faBuilding} className="jobs-card-criteria-item-icon"></FontAwesomeIcon>
                                                                <span className="jobs-card-criteria-item-title">{item.company_name}</span>
                                                            </div>
                                                            <div className="jobs-card-criteria-item">
                                                                <FontAwesomeIcon icon={faClock} className="jobs-card-criteria-item-icon"></FontAwesomeIcon>
                                                                <span className="jobs-card-criteria-item-title">{convertJobTYPE(item.job_type)}</span>
                                                            </div>
                                                            <div className="jobs-card-criteria-item">
                                                                <FontAwesomeIcon icon={faBriefcase} className="jobs-card-criteria-item-icon"></FontAwesomeIcon>
                                                                <span className="jobs-card-criteria-item-title">{item.job_role_name}</span>
                                                            </div>



                                                        </div>
                                                    </div>
                                                    <div className="jobs-card-job-detail-right-control">
                                                        <div className="jobs-card-job-detail-right-item-control">
                                                            {
                                                                item.display_salary ?
                                                                    <span className="jobs-card-job-detail-salary">{item.salary_from.toLocaleString()} - {item.salary_to.toLocaleString()} {translate.profile.baht} {convertSalaryTYPE(item.salary_type.toLocaleString())}</span>
                                                                    : <span className="jobs-card-job-detail-salary">{translate.job.salary_not_specified}</span>
                                                            }
                                                            <Button className="jobs-card-job-detail-apply-job" onClick={() => clickApplyJob(item)}>{translate.job.apply_job}</Button>
                                                            <span className="jobs-card-job-detail-view-job" onClick={() => clickViewJob(item.source_url)}>{translate.job.view_job}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    currentTab == "two" &&

                    
                }
                {
                    (currentTab == "three") &&
                    <AllCompanyComponent></AllCompanyComponent>
                } */}

            </div>
            {
                errorHandler &&
                <ErrorModal open={errorHandler} onclose={setErrorHandler} message={translate.error.smt_wrong}></ErrorModal>
            }
        </div>
    )
}
export default JobsComponent;