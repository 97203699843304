
import axios from "axios"
import config from "../../config"
import apiPath from "../../api-path"
import tokenService from "../sso/token.service"
import RamaService from "../sso/rama.service"


export default function DropResumeService() {

    return {
      async uploadResumeOneProfile(fileUpload, filename) {
            const formData = new FormData();
            formData.append("resume", fileUpload, filename);
            formData.append("type", "one_profile");
            return axios.post(config.resume_builder.upload_resume.base_url + "/resume/upload", formData, {
                headers: {
                    "x-api-key": config.resume_builder.upload_resume.x_api_key
                }
            })
                .then(res => res.data)
                .catch(errors => errors);
        }, async postDropResume(objectResult) {
            return axios.post(config.chat_gpt.base_url + apiPath.product_path + "mini_profile", objectResult,{
                headers: {
                    'content-type': 'application/json'
                }
            }
            ).then(res => res.data).catch(err => Promise.reject(err))
        },
       
    }
}