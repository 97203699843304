import apiPath from "../api-path";
import config from "../config";
import instanceGPT from "./auth-gpt.service";


const getAllResume = () => {
    return instanceGPT.get(apiPath.gpt_interview + apiPath.resume);
}

const postResumeChecker = (objectResult) => {
    const headers = {
        headers: {
            "content-type": "multipart/form-data",
            "x-api-key": config.resume_checker.x_api_key
        }};
    return instanceGPT.post(apiPath.resume_checker.base + apiPath.resume_checker.analyze, objectResult, headers);

}

const addResumeWithResumeKey = (resume_name, tempalte_id, resume_key) => {
    const resumeJson = {
        "resume_name": resume_name,
        "template_id":tempalte_id,
        "resume_key" : resume_key
    }
    return instanceGPT.post(apiPath.gpt_interview + apiPath.resume,resumeJson)
}


const resumeParser = (objectResult) => {
    return instanceGPT.post(apiPath.product_path + apiPath.path.resume_parser, objectResult)
}

const GPTService = {
    getAllResume,
    postResumeChecker,
    resumeParser,
    addResumeWithResumeKey
};

export default GPTService;