// useProfile.ts

import { useState, useEffect, useContext } from 'react';
import { ProfileModel } from '../model/profile.model';
import RamaService from '../services/sso/rama.service';
import AuthFunction from '../services/sso/auth.function';
import config from '../config';
import tokenService from '../services/sso/token.service';
import ProfileInstantService from '../services/profile/profile-instance.service';
import { ProfileExtModel } from '../model/profile/profile-ext.model';


export const useProfile = () => {
  const [profile, setProfile] = useState<ProfileModel | undefined>();
  const [error, setError] = useState<any>();
  useEffect(() => {
    const getProfile = async () => {
      RamaService.getProfile().then((res) => {

        if (res.data) {
          ProfileInstantService.getProfile().then((resProfileExt) => {

            if (resProfileExt.data) {

              const profilExt = resProfileExt.data as ProfileExtModel;

              if (!profilExt.profile_json) {
                profilExt.profile_public_settings = {
                  about: true,
                  activities: true,
                  certificates: true,
                  contact: true,
                  educations: true,
                  experiences: true,
                  interest: true,
                  skills: true,
                  uploadFile: true,
                  personality: true,
                  resume: true
                }
                profilExt.profile_json = res.data
                ProfileInstantService.updateProfilePublic(profilExt)
              }
            }
          }).catch((err) => {
            console.log(err);
          })
          setProfile(res.data);
        } else if (res.status != 200) {
        }
      }).catch((err) => {
        setError(err);

      })
    };

    getProfile();
  }, []); // Empty dependency array means this effect runs once on mount

  return [profile, error];
};
