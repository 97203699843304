

const navigateConfig = {
    login: {
        main: "login",
        logout: "/logout"
    },
    setting:{
        main: "/setting"
    },
    resume:{
        main: "/resumes",
        choose_template : "/resume/choose-template/",
        builder: "/resume/builder/",
        checker: "/resume/checker",
        view: "/resume/view/",
        view_only: "/resume/view/only/"
    },
    profile: {
        main: "/profile",
        view: "/profile/view/"
    },
    jobs:{
        main: "/jobs",
        detail: "/jobs/detail",
        apply: "/jobs/apply/",
        interview: "/interview-ai",
        interview_history: "/interview-ai/history"
    },
    not_found:{
        main: "/not-found"
    },
    checking:{
        main: "/checking"
    },
    onboard:{
        main: "/onboard"
    },
    personality: {
        main: "/personality-test",
        result: "/personality-test/result"
    },
    career_craft: {
        main: "/careers",
        detail: "/career/detail/",
        courses: "/careers/courses-recommend"
    },
    guest: {
        main: "/guest",
        profile: "/guest/profile",
        my_resume: "/guest/my-resume",
        salary_checker: "/guest/salary-checker",
        interview_ai: "/guest/interview-ai",
        drop_resume: "/guest/drop-resume",
        drop_resume_ts: "/guest/talent-sauce/drop-resume",
        resume_checker: "/guest/resume-checker",
        resume_checker_ts: "/guest/talent-sauce/resume-checker"
    }
}

export default {
    // Add common config values here
    ...navigateConfig
};