import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ColorPicker from "react-pick-color";
import ReactQuill from "react-quill";


const ColorPickerModal = ({ open, setOpen, setColorMain }: any) => {
    const [color, setColor] = useState("#fff");
    const handleClose = () => {
        setOpen(false);
    };

    const submit = () => {
        setColorMain(color);
        handleClose();
    }

    const changeText = (e) => {
        setColor(e);
    }

    return (

        <>
            <Modal show={open} onHide={handleClose} className="profile-modal">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ColorPicker color={color} className="builder-color-picker" onChange={(color) => changeText(color.hex)} />
                    <Button onClick={submit}>Submit</Button>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ColorPickerModal;