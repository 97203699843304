import { useContext, useEffect, useState } from "react"
import { Language, ProfileModel } from "../../../../model/profile.model"
import CLOSE_IMG from "../../../../assets/images/close.png";
import { Autocomplete, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import "../education/education.scss";
import { Button, Form } from "react-bootstrap";

import "./job-opportunity.scss";
import { TranslateModel } from "../../../../model/translate.model";
import JOB_LEVEL_JSON from "../../../../assets/json/jobs/job_level.json";
import { JobRoleProfileModel } from "../../../../model/profile/job-roles.model";
import ProfileService from "../../../../services/profile/profile.service";
import { ProvideContext } from "../../../../context/provider.context";
import NumericFormatCustom from "../../../../utility/numbericFormatInput";
import { OptionInputSelectModel } from "../../../../model/jobs/application-form.model";
import { SettingModel } from "../../../../model/setting/setting.model";
import RamaService from "../../../../services/sso/rama.service";
import UtilityService from "../../../../utility/utility.service";
const JobOpportunityComponent = ({ profile, setProfile, setCloseModal }: any) => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [isSaving, setIsSaving] = useState(false);
    const [settingModel, setSettingModel] = useState<SettingModel>();
    const [jobRoles, setJobRoles] = useState<JobRoleProfileModel[]>();
    const [value, setValue] = useState(null);
    const [flattenedRoles, setFlattenedRoles] = useState(new Array());
    const [isSave, setIsSave] = useState(false);
    const [duplicateIndices, setDuplicateIndices] = useState([]);
    const closeModal = () => {
        console.log(profile);
        setCloseModal();
    }

    useEffect(() => {
        if (profile) {
            setProfileMod(profile);
        }
    }, [profile])

    useEffect(() => {
        if (profileMod) {
            let jR1 = "";
            let jR2 = "";
            let jR3 = "";
            if (profileMod.preferredJobRole[0]) {
                jR1 = profileMod.preferredJobRole[0]
            }
            if (profileMod.preferredJobRole[1]) {
                jR2 = profileMod.preferredJobRole[1]
            }
            if (profileMod.preferredJobRole[2]) {
                jR3 = profileMod.preferredJobRole[2]
            }
            const settingModel: SettingModel = {
                expectedMaxSalary: profileMod.expectedMaxSalary,
                expectedMinSalary: profileMod.expectedMinSalary,
                jobRecommendation: profileMod.jobRecommendation,
                preferredJobLevel: profileMod.preferredJobLevel,
                preferredJobRole: [jR1, jR2, jR3]
            }
            setSettingModel(settingModel);
            getJobRoles();
        }

    }, [profileMod])

    useEffect(() => {
        if (jobRoles && jobRoles.length > 0) {
            getRoleOptions(jobRoles)
        }
    }, [jobRoles])



    const onSelectChange = (action, value, event) => {
        if (settingModel) {
            console.log(event);
            console.log(action + " value " + value);
            const settingEdit = { ...settingModel };
            if (settingEdit.preferredJobRole) {


                if (action == "job_1") {
                    const isDup = settingEdit.preferredJobRole.filter((item) => item == value);
                    settingEdit.preferredJobRole[0] = value;
                } else if (action == "job_2") {
                    settingEdit.preferredJobRole[1] = value;
                } else if (action == "job_3") {
                    const isDup = settingEdit.preferredJobRole.filter((item) => item == value);
                    settingEdit.preferredJobRole[2] = value;
                }
                setIsSave(true);
                if (settingEdit.preferredJobRole[0] == "") {
                    if (settingEdit.preferredJobRole[1].length > 0) {
                        settingEdit.preferredJobRole[0] = settingEdit.preferredJobRole[1];
                        settingEdit.preferredJobRole[1] = "";
                    } else {
                        settingEdit.preferredJobRole[1] = "";
                    }

                    if (settingEdit.preferredJobRole[2].length > 0) {
                        settingEdit.preferredJobRole[1] = settingEdit.preferredJobRole[2];
                        settingEdit.preferredJobRole[2] = "";
                    } else {
                        settingEdit.preferredJobRole[2] = "";
                    }


                    settingEdit.expectedMaxSalary = 0;
                    settingEdit.expectedMinSalary = 0;
                    settingEdit.preferredJobLevel = "n/a";
                }
                if (settingEdit.preferredJobRole[1] == "") {
                    settingEdit.preferredJobRole[2] = "";
                }
                const dupReset = [];
                setDuplicateIndices(dupReset);
                setSettingModel(settingEdit);

            }

        }

    }

    const getJobRoles = () => {

        ProfileService().getJobRolesLeela().then((res) => {

            if (res) {
                setJobRoles(res);
            }
        })
    }
  
    const saveValue = () => {
        if (isSave && settingModel) {
            setIsSaving(true);
            if (settingModel.preferredJobRole) {

                const array = settingModel.preferredJobRole;


                const duplicateIndices2: any = [];

                for (let i = 0; i < array.length; i++) {
                    for (let j = i + 1; j < array.length; j++) {
                        if (array[i] === array[j]) {
                            duplicateIndices2.push(j);
                        }
                    }
                }

                if (duplicateIndices2.length > 0) {
                    setDuplicateIndices(duplicateIndices2);
                    setIsSaving(false);
                } else {
                    console.log("The array has only unique values.");
                    RamaService.updateSetting(settingModel).then((res) => {

                        if(res.data){
                            UtilityService().clickSendEvent("save_job_opportunity", "submit");
                            setCloseModal(false);
                            const profileModEdit = { ...profile } as ProfileModel;
                            profileModEdit.expectedMaxSalary = settingModel.expectedMaxSalary;
                            profileModEdit.expectedMinSalary = settingModel.expectedMinSalary;
                            if (settingModel.jobRecommendation) {
                                profileModEdit.jobRecommendation = settingModel.jobRecommendation;
                            }
    
                            if (settingModel.preferredJobLevel) {
                                profileModEdit.preferredJobLevel = settingModel.preferredJobLevel;
                            }
    
                            if (settingModel.preferredJobRole) {
                                profileModEdit.preferredJobRole = settingModel.preferredJobRole;
                            }
    
                            setProfile(profileModEdit);
                        }
                       
                    })
                }
            }


        }

    }

    const onSelectJobLevelChange = (value) => {
        if (settingModel) {
            const settingEdit = { ...settingModel } as SettingModel
            settingEdit.preferredJobLevel = value;
            setSettingModel(settingModel);
        }

    }

    const textChange = (action, value) => {
        if (settingModel) {
            const settingEdit = { ...settingModel } as SettingModel
            if (action == "expectedMinSalary") {
                settingEdit.expectedMinSalary = value;
            } else if (action == "expectedMaxSalary") {
                settingEdit.expectedMaxSalary = value;
            }
            setIsSave(true);
            setSettingModel(settingEdit);

        }


    }
    function getRoleOptions(roles) {
        let roleOptions = new Array();
        roles.forEach(role => {
            let groupName = role.name;
            roleOptions.push({
                label: groupName,
                options: [groupName, ...(role.subRole?.map(subRole => subRole.name) || [])]
            });
        });
        setFlattenedRoles(roleOptions)
    }



    return (

        <>
            <div className="education job-opportunity-main">
                <div className="education-close-control">
                    <img src={CLOSE_IMG} onClick={closeModal} className="education-close-img"></img>
                </div>
                <div className="d-flex">
                    <span className="education-header">{translate.profile.job_opportunity}</span>
                </div>
                {
                    isSaving ? <div className="profile-edit-loading-control">

                        <div className="profile-edit-loading-flex">
                            <CircularProgress className="profile-edit-progress"></CircularProgress>
                            <span className="profile-edit-text">{translate.other.updating}</span>
                        </div>
                    </div>

                        :
                        <div className="d-flex flex-column experience-grid-edit">
                            {
                                jobRoles && flattenedRoles && settingModel && settingModel.preferredJobRole &&

                                <div className="job-opportunity-item-control">
                                    <Divider textAlign="left" className="job-opportunity-item-divider">Interested Job</Divider>
                                    <FormControl className="job-opportunity-item" itemID="1" fullWidth>
                                        <span className="job-opportunity-title">1. {translate.profile.interest_job}</span>
                                        <Autocomplete
                                            options={flattenedRoles.map(group => group.options).flat()}
                                            groupBy={(option) =>
                                                flattenedRoles.find(group => group.options.includes(option)).label
                                            }
                                            style={{ width: 300 }}
                                            value={settingModel.preferredJobRole[0]}
                                            className="job-opportunity"
                                            id="1"

                                            onInputChange={(event, newValue) => {
                                                onSelectChange("job_1", newValue, event);
                                                // Do something with the selected name
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Roles" variant="outlined" />}
                                        />
                                    </FormControl>
                                    {duplicateIndices.length > 0 && duplicateIndices.filter((item) => item == 0).length > 0 &&
                                        <span>ข้อมูลซ้ำกรุณาเลือกใหม่</span>

                                    }
                                    {
                                        settingModel.preferredJobRole[0] &&
                                        <>
                                            <FormControl className="job-opportunity-item" itemID="2" fullWidth>
                                                <span className="job-opportunity-title">2. {translate.profile.interest_job}</span>
                                                <Autocomplete
                                                    options={flattenedRoles.map(group => group.options).flat()}
                                                    groupBy={(option) =>
                                                        flattenedRoles.find(group => group.options.includes(option)).label
                                                    }
                                                    style={{ width: 300 }}
                                                    id="2"
                                                    value={settingModel.preferredJobRole[1]}
                                                    className="job-opportunity"
                                                    onInputChange={(event, newValue) => {
                                                        onSelectChange("job_2", newValue, event);
                                                        // Do something with the selected name
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Roles" variant="outlined" />}
                                                />
                                                {duplicateIndices.length > 0 && duplicateIndices.filter((item) => item == 1).length > 0 &&
                                                    <span className="job-opportunity-item-red-error">ข้อมูลซ้ำกรุณาเลือกใหม่</span>

                                                }
                                            </FormControl>

                                        </>
                                    }
                                    {
                                        settingModel.preferredJobRole[0] && settingModel.preferredJobRole[1] &&
                                        <FormControl className="job-opportunity-item" fullWidth>
                                            <span className="job-opportunity-title">3. {translate.profile.interest_job}</span>
                                            <Autocomplete
                                                options={flattenedRoles.map(group => group.options).flat()}
                                                groupBy={(option) =>
                                                    flattenedRoles.find(group => group.options.includes(option)).label
                                                }
                                                style={{ width: 300 }}
                                                value={settingModel.preferredJobRole[2]}
                                                className="job-opportunity"
                                                id="3"
                                                onInputChange={(event, newValue) => {
                                                    onSelectChange("job_3", newValue, event);
                                                    // Do something with the selected name
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Roles" variant="outlined" />}
                                            />
                                            {duplicateIndices.length > 0 && duplicateIndices.filter((item) => item == 2).length > 0 &&
                                                <span  className="job-opportunity-item-red-error">ข้อมูลซ้ำกรุณาเลือกใหม่</span>

                                            }
                                        </FormControl>
                                    }

                                    {
                                        settingModel.preferredJobRole[0] &&

                                        <>
                                            <Divider textAlign="left" className="job-opportunity-item-divider">Expected Salary</Divider>
                                            <FormControl className="job-opportunity-item" fullWidth>
                                                <span className="job-opportunity-title">{translate.profile.minimum_salary}</span>
                                                <TextField inputProps={{ className: "education-item-input" }}
                                                    InputLabelProps={{ className: "education-item-label" }}
                                                    InputProps={{
                                                        inputComponent: NumericFormatCustom as any,
                                                    }}
                                                    name="numberformat"
                                                    value={settingModel.expectedMinSalary} variant="outlined"
                                                    onChange={(e) => textChange('expectedMinSalary', e.target.value)} />

                                            </FormControl>
                                            <FormControl className="job-opportunity-item" fullWidth>
                                                <span className="job-opportunity-title">{translate.profile.maximum_salary}</span>
                                                <TextField inputProps={{ className: "education-item-input" }}
                                                    InputLabelProps={{ className: "education-item-label" }}
                                                    InputProps={{
                                                        inputComponent: NumericFormatCustom as any,
                                                    }}
                                                    name="numberformat"
                                                    value={settingModel.expectedMaxSalary} variant="outlined"
                                                    onChange={(e) => textChange('expectedMaxSalary', e.target.value)} />

                                            </FormControl>
                                            <Divider textAlign="left" className="job-opportunity-item-divider">{translate.profile.prefer_job_role}</Divider>
                                            <FormControl className="job-opportunity-item" fullWidth>
                                                <span className="job-opportunity-title">{translate.profile.prefer_job_role}</span>
                                                <Select

                                                    inputProps={{ className: "education-item-input-select" }}
                                                    value={settingModel.preferredJobLevel}
                                                    label={translate.profile.prefer_job_role}
                                                    onChange={(e) => onSelectJobLevelChange(e.target.value)}
                                                >
                                                    {
                                                        JOB_LEVEL_JSON.map((item: OptionInputSelectModel, index2: number) => {
                                                            return (
                                                                item.value == settingModel.preferredJobLevel ?
                                                                    <MenuItem value={item.value} className="education-menu-item" key={index2} selected>{item.key}</MenuItem> :
                                                                    <MenuItem value={item.value} className="education-menu-item" key={index2}>{item.key}</MenuItem>

                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </>
                                    }
                                </div>

                            }

                            <div className="education-item-edit-button-control">
                                <Button className=
                                    {
                                        isSave ? "education-item-edit-button" : "education-item-edit-button-disable"
                                    }
                                    onClick={() => saveValue()}>{translate.other.save}</Button>
                                <Button className="education-item-edit-button-cancel" onClick={() => closeModal()}>{translate.other.cancel}</Button>
                            </div>

                        </div>
                }


            </div>

        </>
    )
}

export default JobOpportunityComponent;