import { Button, Modal } from "react-bootstrap"
import "./remove-resume.scss";
import { useContext, useState } from "react";
import { ProvideContext } from "../context/provider.context";
import { TranslateModel } from "../model/translate.model";

const RemoveReseumeModal = ({ open, handleClose, confirmDelete, resume }: any) => {

    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    return (

        <>
            <Modal show={open} onHide={handleClose} backdrop='static' keyboard={false} className="confirm-profile">
                <Modal.Body className="confirm-profile-control">
                    <span className="confirm-profile-header">
                        {translate.other.confirm_delete_resume} {resume.resume_name}
                    </span>
                    <div className="confirm-profile-button-control">
                        <Button className="confirm-profile-button-confirm" onClick={() => confirmDelete()}> {translate.other.delete}</Button>
                        <Button className="confirm-profile-button-no-update" onClick={() => handleClose()}> {translate.other.cancel}</Button>

                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}

export default RemoveReseumeModal;