import { useState } from "react";
import { ResultImportResumeModel } from "../../../model/import-resume/result-import-resume.model";
import { Table } from "react-bootstrap";
import "./resume-parser.scss";

const ResumeParserComponent = ({ dataMod }: any) => {

    const [data, setData] = useState<ResultImportResumeModel>(dataMod);

    return (

        <>
            <div className="resume-parser">
                {
                    data &&
                    <>
                        <div className="resume-parser-control">
                            <div className="resume-parser-title">
                                <span>Name</span>
                            </div>
                            <div className="resume-parser-desc">
                                {data.firstName} + {data.lastName}
                            </div>
                        </div>
                        <div className="resume-parser-divider"></div>
                        <div className="resume-parser-control">
                            <div className="resume-parser-title">
                                <span>Email</span>
                            </div>
                            <div className="resume-parser-desc">
                                {data.email}
                            </div>
                        </div>
                        {
                            data.address &&
                            <>
                                <div className="resume-parser-divider"></div>
                                <div className="resume-parser-control">
                                    <div className="resume-parser-title">
                                        <span>Address</span>
                                    </div>
                                    <div className="resume-parser-desc">
                                        {data.address}
                                    </div>
                                </div>
                            </>
                        }

                        <div className="resume-parser-divider"></div>
                        <div className="resume-parser-control">
                            <div className="resume-parser-title">
                                <span>Education</span>
                            </div>
                            <div className="resume-parser-desc">
                                {data.educations && data.educations.map((edu, index) => (
                                    <div key={index} className="d-flex flex-column">
                                        <span className="resume-parser-exp-bold">{edu.institute}</span>
                                        <span className="resume-parser-exp-bold">{edu.fieldOfStudy}</span>
                                        <span className="resume-parser-exp-desc">{edu.faculty}</span>
                                        <span className="resume-parser-exp-desc">{edu.honor}</span>
                                        <span className="resume-parser-exp-desc">{edu.educationLevel}</span>
                                        {/* Display other education fields here */}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="resume-parser-divider"></div>
                        <div className="resume-parser-control">
                            <div className="resume-parser-title">
                                <span>Experience</span>
                            </div>
                            <div className="resume-parser-desc">
                                {data.experiences && data.experiences.map((exp, index) => (
                                    <div key={index} className="d-flex flex-column">
                                        <span className="resume-parser-exp-bold">{exp.companyName}</span>
                                        <span className="resume-parser-exp-bold">{exp.jobTitle}</span>
                                        <span className="resume-parser-exp-desc">{exp.responsibilities}</span>
                                        <div className="resume-parser-divider"></div>
                                        {/* Display other experience fields here */}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="resume-parser-divider"></div>
                        <div className="resume-parser-control">
                            <div className="resume-parser-title">
                                <span>Skills</span>
                            </div>
                            <div className="resume-parser-desc">
                                <div className="resume-parser-skill-tag-control">
                                    {data.skills && data.skills.map((skill, index) => (
                                        <div className="resume-parser-skill-tag" key={index}>
                                            <span className="my-auto">{skill.skillName}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </>
                }

            </div >

        </>
    )
}

export default ResumeParserComponent;