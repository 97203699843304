import { useContext, useEffect, useRef, useState } from "react";

import "./personality-test.scss"
import { ProfileModel } from "../../model/profile.model";
import tokenService from "../../services/sso/token.service";
import config from "../../config";
import RamaService from "../../services/sso/rama.service";
import { AnswerIdPersonalityModel } from "../../model/personality/answer-id-personality.model";
import ProfileService from "../../services/profile/profile.service";
import { ProfileExtModel } from "../../model/profile/profile-ext.model";
import PersonalityTestService from "../../services/personality-test/personality-test.service";
import PersonalityComponent from "../profile/personality/personality";
import MenuComponent from "../menu/menu";
import { CircularProgress } from "@mui/material";
import { ProvideContext } from "../../context/provider.context";
import { TranslateModel } from "../../model/translate.model";
import { useNavigate } from "react-router-dom";
import navigateConfig from "../../navigate.config";
import ProfileInstantService from "../../services/profile/profile-instance.service";
import { useProfile } from "../../context/profile.context";
import templateConfig from "../../template.config";
import UtilityService from "../../utility/utility.service";



const PersonalityTestIframe = () => {
    const { translateGlobal }: any = useContext(ProvideContext)
    const [translate, setTranslate] = useState<TranslateModel>(translateGlobal);
    const [urlIframe, setUrlIframe] = useState("");
    const [profileMod, setProfileMod] = useState<ProfileModel>();
    const [answerResult, setAnswerResult] = useState<AnswerIdPersonalityModel>();
    const [profileModExt, setProfileModExt] = useState<ProfileExtModel>();
    const [isShowResult, setShowResult] = useState(false);
    const [profile, error] = useProfile();
    const navigate = useNavigate();

    useEffect(() => {

        if (profileMod) {
            if (tokenService.getUser()) {
                const params = new URLSearchParams()
                params.append('keyToken', tokenService.getLocalAccessToken());
                params.append('isHeader', 'false');
                params.append('mainColor', templateConfig.softBGColor);
                params.append('timeStamp', (new Date().toISOString()));
                const url = config.iframe.personality_test + "/questions" + "?" + new URLSearchParams(params)
                setUrlIframe(url);
                UtilityService().clickSendEvent("personality_test_start_iframe", "submit");
            }
        } else {
            getProfileExt();
        }

    }, [profileMod]);

    useEffect(() => {
        if(profile){
            setProfileMod(profile);
            getProfileExt();
        }
    },[profile])

    const getProfileExt = () => {
        ProfileInstantService.getProfile().then((res) => {
            if (res.data) {
                const profileExt = res.data as ProfileExtModel;
                setProfileModExt(profileExt);
            }
        })
    }

    
    const iframeRef = useRef(null);

    // Function to handle messages received from the iframe
    const handleMessage = (event) => {
        // Check if the message is from a trusted source (your iframe's origin)
        if (event.origin === config.iframe.personality_test) {
            //Access the URL data sent from the iframe
            const answerID = event.data as AnswerIdPersonalityModel;
            if (answerID && answerID.is_complete) {
                if (answerID.is_complete) {
                    navigate(navigateConfig.personality.result);
                    
                }
            }

        }
    };

    // Attach the message event listener when the component mounts
    useEffect(() => {
        window.addEventListener('message', handleMessage);

        // Cleanup the listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
   

    return (
        <>
            <div className="main-control">
                <MenuComponent></MenuComponent>
                <div className="personality-test-main">
                    <div className="personality-test-main-header-control">
                        <span className="personality-test-main-header">{translate.personality.title}</span>
                    </div>
                    <div className="iframe-personality">
                        {
                            (urlIframe && !isShowResult) && <iframe
                                src={urlIframe}
                                width="100%"
                                height="100%"
                                ref={iframeRef}
                            ></iframe>
                        }
                        {
                            (isShowResult && answerResult && profileModExt) &&
                            <PersonalityComponent profileExtMod={profileModExt}></PersonalityComponent>
                        }
                        {/* {
                            isLoading &&
                            <div className="personality-test-main-loading-control">
                                <div className="personality-test-main-loading">
                                    <CircularProgress className="personality-test-main-loading-progress"></CircularProgress>
                                    <span className="personality-test-main-loading-text">{translate.other.loading}</span>
                                    </div>
                            </div>
                        } */}


                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalityTestIframe;