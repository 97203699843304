import instanceProfile from "./auth-profile.service";



const getProfile = () => {
    
    return instanceProfile.get("profile")
}



const updateLookingForJobs = (value) => {
    const objectResult = {
        looking_for_jobs: value
    }

    return instanceProfile.post("profile",objectResult)
}
const updateProfileExt = (objectResult) => {
  
    return instanceProfile.post("profile",objectResult)
}
const updateProfilePublic = (value) => {
    const objectResult = {
        profile_json: value
    }
    return instanceProfile.post("profile",objectResult)
}

const updateProfilePublicSettings = (objectResult) => {
    return instanceProfile.post("profile",objectResult)
}

const updatePersonalityID = (value) => {
    const objectResult = {
        personality_answer_id: value
    }
    return instanceProfile.post("profile",objectResult)
}

const updatePublicProfileURL = (objectResult) => {
    return instanceProfile.post("profile",objectResult)
}

const setDefaultResume = (resume_uuid) => {
    return instanceProfile.post("profile/resume/" + resume_uuid + "/default", {})
}

const updatePublicProfileStatus = (objectResult) => {
    return instanceProfile.post("profile",objectResult)
}



const ProfileInstantService = {
    getProfile,
    updateLookingForJobs,
    updateProfilePublic,
    updateProfilePublicSettings,
    updatePersonalityID,
    updatePublicProfileURL,
    updateProfileExt,
    setDefaultResume,
    updatePublicProfileStatus
};

export default ProfileInstantService;