import { Modal } from "react-bootstrap";
import "./redirect.modal.scss";
import { CircularProgress } from "@mui/material";
import RESUME_BUILDER_LOGO from "../assets/images/resume-builder-logo.png"

const RedirectModal = ({ open, setOpen, product }: any) => {
    
    const handleClose = () => {
        setOpen(false);
    }
    return (

        <>
            <Modal show={open} onHide={handleClose} >
             
                <Modal.Body className="redirect-modal">
                    <CircularProgress className="redirect-modal-loading"></CircularProgress>
                    <span className="redirect-modal-text">ระบบกำลังนำท่านไปยังเว็บไซด์</span>
                    <img src={RESUME_BUILDER_LOGO} className="redirect-modal-logo"></img>
                </Modal.Body>
               

            </Modal>
        </>
    )
}

export default RedirectModal;